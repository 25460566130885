 
  import React, { useState, useEffect, useRef } from 'react';
  import Header from "../../layouts/Header";
  import HeaderMobile from "../../layouts/HeaderMobile";
  import { Card, Button, Container } from "react-bootstrap";
  import '../../styles/globals.css';
  import { Link } from "react-router-dom";
  
  import $ from 'jquery';
  
  import 'datatables.net';
  
  const Job = () => {
    const tableRef = useRef(null);
    const [activeButton, setActiveButton] = useState('open');
  
    const handleButtonClick = (button) => {
      setActiveButton(button);
    };
  
    useEffect(() => {

      if ($.fn.DataTable.isDataTable('#commonTable')) {
        tableRef.current = $('#commonTable').DataTable();
        tableRef.current.destroy();
      }
     

      if ($.fn.DataTable) {
 
        tableRef.current = $('#commonTable').DataTable({
          // data: [
          //   // Sample data
          //   [1,'Software Developer', 'Indeed', 'John Doe', 'johndeo@gmail.com', '23/10/2023', 'Active'],
          //   [2,'Frontend Developer', 'Indeed', 'Jack Deo', 'johndeo@gmail.com', '23/10/2023', 'Active'],
          //   [3,'Software Developer', 'Google', 'Alice Johnson', 'alice@gmail.com', '23/10/2023', 'Active'],
          //   [4,'Software Developer', 'Microsoft', 'Bob Smith', 'bob@gmail.com', '23/10/2023', 'Inactive'],
          //   [5,'Web Developer', 'Amazon', 'Eva Brown', 'eva@gmail.com', '23/10/2023', 'Active'],
          //   [6,'UI/UX Designer', 'Apple', 'Charlie Wilson', 'charlie@gmail.com', '23/10/2023', 'Active'],
          //   [7,'Database Administrator', 'Facebook', 'David Lee', 'david@gmail.com', '23/10/2023', 'Inactive'],
          //   [8,'Network Engineer', 'LinkedIn', 'Ella White', 'ella@gmail.com', '23/10/2023', 'Active'],
          //   [9,'Data Analyst', 'Twitter', 'Frank Davis', 'frank@gmail.com', '23/10/2023', 'Active'],
          //   [10,'Software Tester', 'Netflix', 'Grace Thomas', 'grace@gmail.com', '23/10/2023', 'Active'],
          //   [11,'Project Manager', 'Spotify', 'Henry Clark', 'henry@gmail.com', '23/10/2023', 'Inactive'],
          // ],
            // Additional 20 records
            // ['Frontend Developer', 'Google', 'Michael Brown', 'michael@gmail.com', '23/10/2023', 'Active'],
            // ['Backend Developer', 'Microsoft', 'Linda Johnson', 'linda@gmail.com', '23/10/2023', 'Inactive'],
            // ['Mobile App Developer', 'Amazon', 'William Smith', 'william@gmail.com', '23/10/2023', 'Active'],
            // ['DevOps Engineer', 'Apple', 'Susan Wilson', 'susan@gmail.com', '23/10/2023', 'Active'],
            // ['UI Designer', 'Facebook', 'Richard Lee', 'richard@gmail.com', '23/10/2023', 'Inactive'],
            // ['Network Administrator', 'LinkedIn', 'Olivia White', 'olivia@gmail.com', '23/10/2023', 'Active'],
            // ['Data Scientist', 'Twitter', 'James Davis', 'james@gmail.com', '23/10/2023', 'Active'],
            // ['QA Tester', 'Netflix', 'Mary Thomas', 'mary@gmail.com', '23/10/2023', 'Active'],
            // ['Product Manager', 'Spotify', 'Robert Clark', 'robert@gmail.com', '23/10/2023', 'Inactive'],
            // ['Full Stack Developer', 'Google', 'Karen Brown', 'karen@gmail.com', '23/10/2023', 'Active'],
            // ['Frontend Engineer', 'Amazon', 'Matthew Smith', 'matthew@gmail.com', '23/10/2023', 'Active'],
            // ['Database Analyst', 'Apple', 'Sarah Wilson', 'sarah@gmail.com', '23/10/2023', 'Active'],
            // ['Network Specialist', 'Facebook', 'Daniel Lee', 'daniel@gmail.com', '23/10/2023', 'Inactive'],
            // ['Business Analyst', 'LinkedIn', 'Patricia White', 'patricia@gmail.com', '23/10/2023', 'Active'],
            // ['Security Engineer', 'Twitter', 'John Davis', 'john@gmail.com', '23/10/2023', 'Active'],
            // ['Mobile App Designer', 'Netflix', 'Jennifer Thomas', 'jennifer@gmail.com', '23/10/2023', 'Active'],
            // ['Cloud Architect', 'Spotify', 'Thomas Clark', 'thomas@gmail.com', '23/10/2023', 'Inactive'],
            // ['Game Developer', 'Google', 'Nancy Brown', 'nancy@gmail.com', '23/10/2023', 'Active'],
            // ['AI Specialist', 'Amazon', 'Kevin Smith', 'kevin@gmail.com', '23/10/2023', 'Active'],
  
          columns: [
            { title: 'Job Title' },
            { title: 'Ref#' },
            { title: 'Candidates' },
            { title: 'Email' },
            { title: 'Date Posted' },
            { title: 'Job Status' },
            { title: 'Actions' },
          ],
          paging: true,
          ordering: true,
          searching: true,
          columnDefs: [
            {
              targets: -1,
              orderable: false,
              searchable: false,
              render: function (data, type, full, meta) {
                return createActionButton(full);
              },
            },
          ],
          responsive: true,
        });
        
      }
  
      const updatePaginationStyles = () => {
        const paginationElements = $('.paginate_button');
        paginationElements.each(function (index) {
          const $this = $(this);
          if ($this.hasClass('current')) {
            $this.css({
              border: '0px solid blue',
              padding: '3px 8px 3px 8px',
              borderRadius: '3px',
              color: 'white',
              fontWeight: 500,
              background: '#506fd9',
            });
          }
        });
      };
  
      tableRef.current.on('draw.dt', updatePaginationStyles);
      updatePaginationStyles();
    }, []);
  
    const createActionButton = (data) => {
      const editButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill view" viewBox="0 0 16 16">
          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
        </svg>`;
      const deleteButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill edit " viewBox="0 0 16 16">
          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>`;
      const viewButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill delete " viewBox="0 0 16 16">
          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
        </svg>`;
      const pdfButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-pdf-fill" viewBox="0 0 16 16">
      <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
      <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
    </svg>`;
  
      // return `${editButton} ${deleteButton} ${viewButton}`;
      return `${editButton} <span style="margin-right: 8px;"></span> ${deleteButton} <span style="margin-right: 8px;"></span> ${viewButton} <span style="margin-right: 8px;"></span> ${pdfButton}`;
    };
  
    $('#commonTable tbody').on('click', 'button', function () {
      const data = tableRef.current.row($(this).parents('tr')).data();
      const action = $(this).data('action');
  
      switch (action) {
        case 'view':
          console.log(`View: ${data[0]} ${data[1]}`);
          break;
        case 'edit':
          console.log(`Edit: ${data[0]} ${data[1]}`);
          break;
        case 'delete':
          console.log(`Delete: ${data[0]} ${data[1]}`);
          break;
        case 'pdf':
          console.log(`Pdf: ${data[0]} ${data[1]}`);
          break;
        default:
          break;
      }
    });
  
    return (
      <React.Fragment>
        <HeaderMobile />
        <Header />
        <div className="main p-4 p-lg-5 mt-5">
          <div className="breadcrumb-warning d-flex justify-content-between ot-card">
            <div>
              <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                <li className="mx-2">
                  <h4>Jobs|</h4>
                </li>
                <li className="mx-2">
                  <Button
                    variant=""
                    className={`mb-3 btn-1 ${activeButton === 'posted' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('posted')}
                  >
                    Posted Jobs(0)
                  </Button>
                </li>
                <li className="mx-2">
                  <Button
                    variant=""
                    className={`mb-3 btn-1 ${activeButton === 'career' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('career')}
                  >
                    Posted On a Career Site(0)
                  </Button>
                </li>
              </ol>
            </div>
            <nav aria-label="breadcrumb">
              <Button variant="primary" className="mb-3">
                Post a Job
              </Button>
            </nav>
          </div>
  
          <Card className="popup-card">
            <Card.Body>
              <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                <div>
                  <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                    <li className="mx-1">
                      <Button
                        variant=""
                        className={`mb-3 btn-1 ${activeButton === 'open' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('open')}
                        style={{
                          backgroundColor: activeButton === 'open' ? '#506fd9' : 'white',
                          color: activeButton === 'open' ? 'white' : 'black',
                        }}
                      >
                        Open and Paused(0)
                      </Button>
                    </li>
                    <li className="mx-2">
                      <Button
                        variant=""
                        className={`mb-3 btn-1 ${activeButton === 'closed' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('closed')}
                        style={{
                          backgroundColor: activeButton === 'closed' ? '#506fd9' : 'white',
                          color: activeButton === 'closed' ? 'white' : 'black',
                        }}
                      >
                        Closed(0)
                      </Button>
                    </li>
                  </ol>
                </div>
  
                <nav aria-label="breadcrumb">
                  <Button variant="primary" className="mb-3">
                    <Link to="/pages/recruitment/jobform">
                      <span style={{ color: "white" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg>
                        Create a Job
                      </span>
                    </Link>
                  </Button>
                </nav>
              </div>
  
              <table id="commonTable" className="display table   table-striped table-curved" width="100%" ref={tableRef}></table>
            </Card.Body>
          </Card>
        </div>
      </React.Fragment>
    );
  }
  
  export default Job;
  