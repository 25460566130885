import React, { useState, useEffect } from "react";
import _debounce from 'lodash/debounce';
import { Modal, Button } from "react-bootstrap";
import Header from "../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addDesignation, addEmployee, viewDepartment, viewDesignation, viewShift, addShift, viewBranch, addBranch, addEmployeeAddImage } from "../../services/api/api";
import { addDepartment } from "../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Select from 'react-select';
import imageCompression from 'browser-image-compression';
import { InterestsOutlined } from "@mui/icons-material";



const EmployeeForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submittingDep, setSubmittingDep] = useState(false);
  const [submittingDes, setSubmittingDes] = useState(false);
  const [submittingShift, setSubmittingShift] = useState(false);
  const [showDepartmentModal, setShowDepartmentModal] = useState(false);
  const [showDesignationModal, setShowDesignationModal] = useState(false);
  const [showShiftModal, setShowShiftModal] = useState(false);

  const [departmentName, setDepartmentName] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [shiftNumber, setShiftNumber] = useState("");

  const [departmentErrors, setDepartmentErrors] = useState({});
  const [designationErrors, setDsignationErrors] = useState({});
  const [shiftErrors, setShiftErrors] = useState({});

  const [s, setS] = useState();

  const [selectedImage, setSelectedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [cacheBuster, setCacheBuster] = useState(0);


  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedBranchIds, setSelectedBranchIds] = useState([]);
  const [selectedBranchOptions, setSelectedBranchOptions] = useState([]);
  const [submittingBr, setSubmittingBr] = useState(false);
  const [branchErrors, setBranchErrors] = useState({});
  const [branchName, setBranchName] = useState("");
  const [showBranchModal, setShowBranchModal] = useState(false);
  const [branches, setBranchs] = useState([]);



  const handleBranchSelectChange = (selectedOptions) => {
    setSelectedBranchOptions(selectedOptions);
    // Extract the selected branch IDs and update the state
    const selectedIds = selectedOptions.map((option) => option.value); // label gives name of the selected option
    setSelectedBranchIds(selectedIds);
    // console.log(selectedOptions);
    // console.log(selectedIds); id 1... is passed
  };

  // useEffect(() => {

  //   fetchBranch();

  // }, [])
  useEffect(() => {
    const debouncedFetchBranch = _debounce(fetchBranch, 100);

    debouncedFetchBranch();

    return () => {
      debouncedFetchBranch.cancel();
    };
  }, []);



  const handleClose = () => {
    setShowDepartmentModal(false);
    setDepartmentName("");
    setDepartmentErrors({});

  };

  const handleCloseDesignationModal = () => {
    setShowDesignationModal(false);
    setDesignationName('');
    setDsignationErrors({});
  };

  const handleCloseShiftModal = () => {
    setShowShiftModal(false);
    setDesignationName('');
    setShiftErrors({});
  };

  const navigate = useNavigate();
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    joining_date: "",
    birth_date: "",
    phone: "",
    department: "",
    designation: "",
    salary: "",
    shift_no: "",
    password: "",
    timezone: "",
    image_path: null,
  });

  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [shifts, setShifts] = useState([]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setState({
      first_name: "",
      last_name: "",
      email: "",
      joining_date: "",
      birth_date: "",
      phone: "",
      department: "",
      designation: "",
      salary: "",
      shift_no: "",
      password: "",
      timezone: "",
      image_path: null,
    });
    setErrors({});
    // fetchDepartments(); 
  }, []);

  // useEffect(() => {
  //   fetchDepartments();
  //   fetchDesignation()
  //   fetchShift()
  // }, []);

  // useEffect(() => {
  //   fetchDepartments()
  // }, [])
  useEffect(() => {
    const debouncedFetchDepartments = _debounce(fetchDepartments, 100);

    debouncedFetchDepartments();

    return () => {
      debouncedFetchDepartments.cancel();
    };
  }, []);
  // useEffect(() => {
  //   fetchDesignation()
  // }, [])
  useEffect(() => {
    const debouncedFetchDesignation = _debounce(fetchDesignation, 100);

    debouncedFetchDesignation();

    return () => {
      debouncedFetchDesignation.cancel();
    };
  }, []);
  // useEffect(() => {
  //   fetchShift()
  // }, [])
  useEffect(() => {
    const debouncedFetchShift = _debounce(fetchShift, 100);

    debouncedFetchShift();

    return () => {
      debouncedFetchShift.cancel();
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Resetting the error to an empty string first
    }));

    if (name === 'phone' && !/^\d*$/.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Please enter numeric values only',
      }));
    }

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchDepartments = async () => {
    try {
      const formattedData = await viewDepartment();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyDepartment = formattedData[0].company_department;
      setMasterId(formattedData[0]._id);
      const departmentsArray = Object.values(companyDepartment).map(item => ({
        _id: item._id,
        department_name: item.department_name || 'N/A',
      }));

      setDepartments(departmentsArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(true);
    }
  };
  const handleDepartmentSubmit = async (e) => {
    e.preventDefault();
    if (submittingDep) {
      return;
    }


    // Check for an empty department name
    if (!departmentName.trim()) {
      setDepartmentErrors({ department_name: "Department name is required" });
      return;
    }

    setSubmittingDep(true);
    try {
      const response = await addDepartment({ department_name: departmentName });
      const data = JSON.parse(response);
      if (data.success === true) {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 5000,
        });
        // Optional: Navigate to "/pages/employee/employeeform"
        navigate("/admin/employeeform");
      } else {
        toast.warning(data.message, {
          position: "top-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.success("Department Added successfully", {
        position: "top-right",
        autoClose: 1000,
      });
    } finally {
      // Close modal regardless of success or failure
      handleClose();
      setSubmittingDep(false);
      fetchDepartments();

    }
  };

  const handleDepartmentChange = (e) => {
    setDepartmentName(e.target.value);
    if (departmentErrors.department_name) {
      setDepartmentErrors({ department_name: '' });
    }
  };

  const fetchDesignation = async () => {
    try {
      const formattedData = await viewDesignation();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyDesignation = formattedData[0].company_designation;
      setMasterId(formattedData[0]._id);
      const designationsArray = Object.values(companyDesignation).map(item => ({
        _id: item._id,
        designation_name: item.designation_name || 'N/A',
      }));

      setDesignations(designationsArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  // const handleDesignationSubmit = async (e) => {
  //   e.preventDefault();

  //   if (submittingDes) {
  //     return;
  //   }

  //   if (!designationName.trim()) {
  //     setDsignationErrors({ designation_name: "Designation name is required" });
  //     return;
  //   }
  //   setSubmittingDes(true);
  //   try {
  //     const response = await addDesignation({ designation_name: designationName });
  //     const data = JSON.parse(response);

  //     if (data.success === true) {
  //       toast.success(data.message, {
  //         position: "top-center",
  //         autoClose: 1000,
  //       });
  //       fetchDesignation();
  //     } else {
  //       toast.warning(data.message, {
  //         position: "top-center",
  //         autoClose: 1000,
  //       });
  //     }
  //   } catch (error) {
  //     toast.success("Designation Added successfully", {
  //       position: "top-center",
  //       autoClose: 1000,
  //     });
  //   } finally {

  //     handleCloseDesignationModal();
  //     setSubmittingDes(false);
  //     fetchDesignation();

  //   }
  // };

  const handleDesignationSubmit = async (e) => {
    e.preventDefault();

    if (submittingDes) {
      return;
    }

    if (!designationName.trim()) {
      setDsignationErrors({ designation_name: "Designation name is required" });
      return;
    }

    setSubmittingDes(true);
    try {
      const response = await addDesignation({ designation_name: designationName });
      const data = response.data; // Assuming response.data contains the JSON data

      if (data.success === true) {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        fetchDesignation();
      } else {
        toast.warning(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.success("Designation Added Successfully", {
        position: "top-center",
        autoClose: 1000,
      });
    } finally {
      handleCloseDesignationModal();
      setSubmittingDes(false);
      fetchDesignation();
    }
  };


  const handleDesignationChange = (e) => {
    setDesignationName(e.target.value);
    if (designationErrors.designation_name) {
      setDsignationErrors({ designation_name: '' });
    }
  };


  const fetchShift = async () => {

    try {
      const formattedData = await viewShift();
      console.log(formattedData);
      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyShift = formattedData[0].company_time;
      setMasterId(formattedData[0]._id);
      const shiftsArray = Object.values(companyShift).map(item => ({
        _id: item._id,
        shift_no: item.shift_no || 'N/A',
        company_start_time: item.company_start_time || 'N/A',
        company_end_time: item.company_end_time || 'N/A',
        timezone: item.timezone || 'N/A',
      }));

      setShifts(shiftsArray);
      console.log(shiftsArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatShiftTime = (start, end) => {
    const formatTime = (time) => {
      const date = new Date(`1970-01-01T${time}`);
      return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };

    return `(${formatTime(start)} To ${formatTime(end)})`;
  };

  // Rest of your code remains unchanged


  // const handleShiftSubmit = async (e) => {
  //   e.preventDefault();
  //   if (submittingShift) {
  //     return;
  //   }
  //   if (!shiftNumber.trim()) {
  //     setShiftErrors({ shift_no: "Shift is required" });
  //     return;
  //   }
  //   setSubmittingShift(true);
  //   try {
  //     const response = await addShift({ shift_no: shiftNumber });
  //     const data = JSON.parse(response);
  //     console.log(response);

  //     if (data.success === true) {
  //       toast.success(data.message, {
  //         position: "top-center",
  //         autoClose: 5000,
  //       });
  //       fetchShift();
  //     } else {
  //       toast.warning(data.message, {
  //         position: "top-center",
  //         autoClose: 5000,
  //       });
  //     }
  //   } catch (error) {
  //     toast.success("Shift Added successfully", {
  //       position: "top-center",
  //       autoClose: 1000,
  //     });
  //   } finally {
  //     handleCloseShiftModal();
  //     setSubmittingShift(false);
  //     // fetchShift();




  //   }
  // };

  // const handleShiftNumberChange = (e) => {
  //   setShiftNumber(e.target.value);
  //   if (shiftErrors.shift_no) {
  //     setShiftErrors({ shift_no: '' });
  //   }
  // };

  const validateForm = (state) => {
    let errors = {};

    if (!state.first_name) {
      errors.first_name = "First Name is required";
    }
    if (!state.last_name) {
      errors.last_name = "Last Name is required";
    }
    if (!state.email) {
      errors.email = "Email is required";
    }
    if (!state.joining_date) {
      errors.joining_date = "Joining Date is required";
    }
    if (!state.birth_date) {
      errors.birth_date = "Birth Date is required";
    }
    if (!state.phone) {
      errors.phone = "Phone is required";
    } else if (!/^\d{10}$/.test(state.phone)) {
      errors.phone = "Phone number must be exactly 10 digits";
    }
    if (!state.department) {
      errors.department = "Department is required";
    }
    if (!state.designation) {
      errors.designation = "Designation is required";
    }
    if (!state.shift) {
      errors.shift = "Shift is required";
    }
    if (!state.salary) {
      errors.salary = "Salary is required";
    }
    // if (!state.selectedBranchOptions || state.selectedBranchOptions.length === 0) {
    //   errors.branch = "Branch is required";
    // }
    if (!state.password) {
      errors.password = "Password is required";
    }

    return errors;
  };




  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submitting) {
      return;
    }

    const formErrors = validateForm(state);

    console.log("output", state);
    // Check for any errors in form validation
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    setSubmitting(true);
    try {
      const payloadBranchid = {
        ...state,
        branch_id: selectedBranchIds.join(','),
      };
      const response = await addEmployee(payloadBranchid);
      // const data = JSON.parse(response);
      console.log(response.id);
      let intId = parseInt(response.id);
      if (response) {
        const imageApiResponse = await addEmployeeAddImage(intId);
        navigate("/admin/employees");
      }


      // if (response.message == 'Employee added successfully') {
      //   toast.success(response.message, {
      //     position: "top-center",
      //     autoClose: 1000,
      //   });
      // } else {
      //   toast.error('Failed to add employee', {
      //     position: "top-center",
      //     autoClose: 1000,
      //   });
      //   console.log("Submit button end!")
      //   // navigate("/admin/employees");
      // }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred", {
        position: "top-right",
        autoClose: 1000,
      });
    } finally {

      setSubmitting(false);
    }
  };




  const [loading, setLoading] = useState(true);
  const [masterId, setMasterId] = useState();

  const handleNearbyDepartmentClick = () => {
    setShowDepartmentModal(true);
  };

  const handleNearbyDesignationClick = () => {
    setShowDesignationModal(true);
  };

  const handleNearbyShiftClick = () => {
    setShowShiftModal(true);
  };







  const handleNearbyBranchClick = () => {
    setShowBranchModal(true);
  };

  const handleCloseBranchModal = () => {
    setShowBranchModal(false);
    setBranchName('');
    setBranchErrors({});
  };
  const fetchBranch = async () => {

    try {
      const formattedData = await viewBranch();

      console.log(formattedData);
      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyBranch = formattedData[0].company_branch;
      setMasterId(formattedData[0]._id);
      const branchsArray = Object.values(companyBranch).map(item => ({
        _id: item._id,
        branch_name: item.branch_name || 'N/A',
      }));

      setBranchs(branchsArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleBranchNameChange = (e) => {
    const { value } = e.target;
    setBranchName(value);
    // Clear the designation error when the user starts typing
    setBranchErrors((prevErrors) => ({
      ...prevErrors,
      branch_name: "",
    }));
  };
  const handleBranchSubmit = async (e) => {
    e.preventDefault();
    if (submittingBr) {
      return;
    }
    if (!branchName.trim()) {
      setBranchErrors({ branch_name: "Branch name is required" });
      return;
    }
    setSubmittingBr(true);

    try {
      const response = await addBranch({ branch_name: branchName });
      const data = JSON.parse(response);
      console.log(data);

      if (data.success === true) {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 5000,
        });
        fetchBranch();
      } else {
        toast.warning(data.message, {
          position: "top-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      toast.success("Branch Added successfully", {
        position: "top-center",
        autoClose: 1000,
      });
    } finally {
      handleCloseBranchModal();
      setSubmittingBr(false);
      fetchBranch();
    }
  };

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedImage(file);

  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     const base64Image = reader.result.split(',')[1];
  //     setState(prevState => ({ ...prevState, image_path: base64Image }));
  //   };

  //   reader.readAsDataURL(file);
  // };


  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);

    if (!file) return;

    // Compress image
    if (file.size > 3 * 1024 * 1024) { // 3 MB
      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(file, options);
        setCompressedImage(URL.createObjectURL(compressedFile));
        setImage(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    } else {
      setCompressedImage(URL.createObjectURL(file));
      setImage(file);
    }

    // Convert image to base64
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Image = reader.result.split(',')[1];
      setState((prevState) => ({ ...prevState, image_path: base64Image }));
    };
    reader.readAsDataURL(file);

    // Update cache buster to force image refresh
    setCacheBuster((prev) => prev + 1);
  };



  return (
    <React.Fragment>
      <Header />
      {/* Your other layout code here */}
      <div className="main p-4 p-lg-5 mt-5">
        {/* Your layout structure */}
        <div className="mb-4 border-bottom text-center">
          <h3 className="mb-0 pb- border-bottom custome-btn">Add Employee</h3>
        </div>
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/employees">Employees</Link>
              </li>
              <li className="breadcrumb-item active">Add Employee</li>
            </ol>
          </div>
          {/* <nav aria-label="breadcrumb">
            <Link to="/pages/employee/employees" className="btn btn-danger mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link></nav> */}
          <nav aria-label="breadcrumb">
            <Link
              to="/admin/employees"
              className="btn bg-danger mb-3"
              style={{
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                padding: '6px 12px',
                borderRadius: '4px',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left me-1"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              Back
            </Link>
          </nav>


        </div>



        <div className="row"
          style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >


          <div className="col-md-12 formcontrols">
            {/* <div className="bg-white p-4 rounded" style={{ height: "100%" }}> */}
            <div className="bg-white p-4 rounded" style={{ height: "1000px" }}>
              <form onSubmit={handleSubmit}>


                <div className="row">
                  <div className="col-md-4">

                    <div className="mb-3">
                      <label htmlFor="first_name" className="form-label fw-bold">
                        First Name
                      </label>
                      <input
                        type="text"
                        className={`form-control custom-input ${errors.first_name && "is-invalid"}`}
                        id="first_name"
                        name="first_name"
                        value={state.first_name}
                        onChange={handleChange}
                        placeholder="Enter"
                      />
                      {errors.first_name && (
                        <div className="invalid-feedback">{errors.first_name}</div>
                      )}
                    </div>

                  </div>
                  <div className="col-md-4">

                    <div className="mb-3">
                      <label htmlFor="last_name" className="form-label fw-bold">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className={`form-control custom-input ${errors.last_name && "is-invalid"}`}
                        id="last_name"
                        name="last_name"
                        value={state.last_name}
                        onChange={handleChange}
                        placeholder="Enter"
                      />
                      {errors.last_name && (
                        <div className="invalid-feedback">{errors.last_name}</div>
                      )}
                    </div>

                  </div>
                  <div className="col-md-4">

                    <div className="mb-3">
                      <label htmlFor="email" className="form-label fw-bold">
                        Email
                      </label>
                      <input
                        type="email"
                        className={`form-control custom-input ${errors.email && "is-invalid"}`}
                        id="email"
                        name="email"
                        value={state.email}
                        onChange={handleChange}
                        placeholder="Enter"
                      />
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>

                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="joining_date" className="form-label fw-bold">
                        Joining Date
                      </label>
                      <input
                        type="date"
                        className={`form-control custom-input ${errors.joining_date && "is-invalid"}`}
                        id="joining_date"
                        name="joining_date"
                        value={state.joining_date}
                        onChange={handleChange}
                        placeholder="Enter"
                      />
                      {errors.joining_date && (
                        <div className="invalid-feedback">{errors.joining_date}</div>
                      )}
                    </div>
                  </div>


                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label fw-bold">
                        Phone
                      </label>
                      <input
                        type="text"
                        className={`form-control custom-input ${errors.phone && "is-invalid"}`}
                        id="phone"
                        name="phone"
                        value={state.phone}
                        onChange={handleChange}
                        placeholder="Enter"
                      />
                      {errors.phone && (
                        <div className="invalid-feedback">{errors.phone}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="salary" className="form-label fw-bold">
                        Annual Salary
                      </label>
                      <input
                        type="text"
                        className={`form-control custom-input ${errors.salary && "is-invalid"}`}
                        id="salary"
                        name="salary"
                        value={state.salary}
                        onChange={handleChange}
                        placeholder="Enter"
                      />
                      {errors.salary && (
                        <div className="invalid-feedback">{errors.salary}</div>
                      )}
                    </div>
                  </div>
                </div>



                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label fw-bold">
                        Password
                      </label>
                      <input
                        type="password"
                        className={`form-control custom-input ${errors.password && "is-invalid"}`}
                        id="password"
                        name="password"
                        value={state.password}
                        onChange={handleChange}
                        placeholder="Enter"
                      />
                      {errors.password && (
                        <div className="invalid-feedback">{errors.password}</div>
                      )}
                    </div>




                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="department" className="form-label fw-bold">
                        Department
                      </label>
                      <span
                        className="ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={handleNearbyDepartmentClick}
                      >
                        {/* Plus icon */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="grey"
                          className="bi bi-plus-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          {/* Plus icon path */}
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                        </svg>
                      </span>

                      {/* Modal for adding department */}
                      <Modal show={showDepartmentModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Add Department</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="mb-3">
                            <label htmlFor="department_name" className="form-label fw-bold">
                              Department Name
                            </label>
                            <input
                              type="text"
                              className={`form-control custom-input ${departmentErrors.department_name && "is-invalid"}`}
                              id="department_name"
                              name="department_name"
                              value={departmentName}
                              onChange={handleDepartmentChange}
                              placeholder="Enter department"
                              style={{
                                borderRadius: '10px',
                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                              }}
                            />
                            {departmentErrors.department_name && (
                              <div className="invalid-feedback">{departmentErrors.department_name}</div>
                            )}
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary d-flex align-items-center justify-content-center position-relative"
                            onClick={handleDepartmentSubmit}
                            disabled={submittingDep}
                          >
                            {submittingDep && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {submittingDep ? 'Submitting department...' : 'Add Department'}
                          </button>
                        </Modal.Body>
                      </Modal>

                      {/* <select
                        className={`form-select ${errors.department && "is-invalid"}`}
                        id="department"
                        name="department"
                        value={state.department || ''}
                        onChange={handleChange}
                      >
                        <option value="">Select Department</option>
                        {departments.map((department) => (
                          <option key={department._id} value={department.department_name}>
                            {department.department_name}
                          </option>
                        ))}
                      </select> */}
                      <select
                        className={`form-select ${errors.department && "is-invalid"}`}
                        id="department"
                        name="department"
                        value={state.department || ''}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Select Department</option>
                        {departments.map((department) => (
                          <option key={department._id} value={department._id}>
                            {department.department_name}
                          </option>
                        ))}
                      </select>


                      {/* Department selection error */}
                      {errors.department && (
                        <div className="invalid-feedback">{errors.department}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="designation" className="form-label fw-bold">
                        Designation
                      </label>
                      <span
                        className="ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={handleNearbyDesignationClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="grey" // Change the fill color to blue
                          className="bi bi-plus-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                        </svg>
                      </span>

                      <Modal show={showDesignationModal} onHide={handleCloseDesignationModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Add Designation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="mb-3">
                            <label htmlFor="designation_name" className="form-label fw-bold">
                              Designation Name
                            </label>
                            <input
                              type="text"
                              className={`form-control custom-input ${designationErrors.designation_name && "is-invalid"}`}
                              id="designation_name"
                              name="designation_name"
                              value={designationName}
                              onChange={handleDesignationChange}
                              placeholder="Enter"
                              style={{
                                borderRadius: '10px',
                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                              }}
                            />
                            {designationErrors.designation_name && (
                              <div className="invalid-feedback">{designationErrors.designation_name}</div>
                            )}
                          </div>


                          <button
                            type="submit"
                            className="btn btn-primary d-flex align-items-center justify-content-center position-relative"
                            onClick={handleDesignationSubmit}
                            disabled={submittingDes}
                          >
                            {submittingDes && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {submittingDes ? 'Submitting designation...' : 'Add Designation'}
                          </button>




                        </Modal.Body>
                      </Modal>
                      <select
                        className={`form-select ${errors.designation && "is-invalid"}`}
                        id="designation"
                        name="designation"
                        value={state.designation}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Select Designation</option>
                        {designations.map((designation) => (
                          <option key={designation._id} value={designation._id}>
                            {designation.designation_name}
                          </option>
                        ))}
                      </select>

                      {errors.designation && (
                        <div className="invalid-feedback">{errors.designation}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">


                </div>


                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="birth_date" className="form-label fw-bold">
                        Birth Date
                      </label>
                      <input
                        type="date"
                        className={`form-control custom-input ${errors.birth_date && "is-invalid"}`}
                        id="birth_date"
                        name="birth_date"
                        value={state.birth_date}
                        onChange={handleChange}
                        placeholder="Enter"
                      />
                      {errors.birth_date && (
                        <div className="invalid-feedback">{errors.birth_date}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="shift" className="form-label fw-bold">
                        Shift
                      </label>
                      {/* <span
                        className="ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={handleNearbyShiftClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="blue"
                          className="bi bi-plus-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                        </svg>
                      </span> */}

                      {/* <Modal show={showShiftModal} onHide={handleCloseShiftModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Add Shift</Modal.Title>
                        </Modal.Header>
                       
                        <Modal.Body>
                          <div className="mb-3">
                            <label htmlFor="shift_no" className="form-label fw-bold">
                              Shift Number
                            </label>
                            <input
                              type="text"
                              className={`form-control custom-input ${shiftErrors.shift_no && "is-invalid"}`}
                              id="shift_no"
                              name="shift_no"
                              value={shiftNumber}
                              onChange={handleShiftNumberChange}
                            />

                            {shiftErrors.shift_no && (
                              <div className="invalid-feedback">{shiftErrors.shift}</div>
                            )}
                          </div>

                          <button className="btn btn-primary" onClick={handleShiftSubmit}
                            disabled={submitting}>

                            {submitting ? 'Submitting...' : 'Submit'}
                          </button>
                        </Modal.Body>

                      </Modal> */}
                      {/* <select
                        className={`form-select ${errors.shift && "is-invalid"}`}
                        id="shift_no"
                        name="shift"
                        value={state.shift}
                        onChange={handleChange}
                      >
                        <option value="">Select Shift</option>
                        {shifts.map((shift) => (
                          <option key={shift._id} value={shift.shift_no}>
                            {`${shift.shift_no} ${formatShiftTime(shift.company_start_time, shift.company_end_time)}`}
                          </option>
                        ))}
                      </select> */}
                      <select
                        className={`form-select ${errors.shift && "is-invalid"}`}
                        id="shift_no"
                        name="shift"
                        value={state.shift}
                        onChange={handleChange}
                      >
                        <option value="" disabled selected>Select Shift</option>
                        {shifts.map((shift) => (
                          <option key={shift._id} value={shift.shift_no}>
                            {`${shift.timezone} ${formatShiftTime(shift.company_start_time, shift.company_end_time)}`}
                          </option>
                        ))}
                      </select>




                      {errors.shift && (
                        <div className="invalid-feedback">{errors.shift}</div>
                      )}

                    </div>
                  </div>
                  <div className="col-md-4">


                    <div className="mb-3">
                      <label htmlFor="designation" className="form-label fw-bold">
                        Branch
                      </label>
                      <span
                        className="ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={handleNearbyBranchClick}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="grey"
                          className="bi bi-plus-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                        </svg>
                      </span>

                      <Modal show={showBranchModal} onHide={handleCloseBranchModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Add Branch</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="mb-3">
                            <label htmlFor="branch_name" className="form-label fw-bold">
                              Branch Name
                            </label>
                            <input
                              type="text"
                              className={`form-control  custom-input ${branchErrors.branch_name && "is-invalid"}`}
                              id="branch_name"
                              name="branch_name"
                              value={branchName}
                              onChange={handleBranchNameChange}
                              placeholder="Enter Branch"
                              style={{
                                borderRadius: '10px',
                                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                              }}
                            />
                            {branchErrors.branch_name && (
                              <div className="invalid-feedback">{branchErrors.branch_name}</div>
                            )}
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary d-flex align-items-center justify-content-center position-relative"
                            onClick={handleBranchSubmit}
                            disabled={submittingBr}
                          >
                            {submittingBr && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {submittingBr ? 'Submitting branch...' : 'Add Branch'}
                          </button>
                        </Modal.Body>
                      </Modal>
                      {/* {branches.map((branch) => (
                                                <div key={branch._id} className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`branchCheckbox-${branch._id}`}
                                                        value={branch._id}
                                                        checked={selectedBranchIds.includes(branch._id)}
                                                        onChange={() => handleBranchCheckboxChange(branch._id)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`branchCheckbox-${branch._id}`}>
                                                        {branch.branch_name}
                                                    </label>
                                                </div>
                                            ))} */}
                      {/* <Select
                                                isMulti
                                                options={branches.map((branch) => ({
                                                    value: branch._id,
                                                    label: branch.branch_name,
                                                }))}
                                                value={selectedBranchOptions}
                                                onChange={handleBranchSelectChange}
                                                placeholder="Select branches"
                                            /> */}


                      {/* <Select
                        className="border border-secondary rounded"
                        isMulti
                        options={branches.map((branch) => ({
                          value: branch.branch_name,
                          label: branch.branch_name,
                        }))}
                        value={selectedBranchOptions}
                        onChange={handleBranchSelectChange}
                        placeholder="Select branches"
                        closeMenuOnSelect={false}
                        components={{ DropdownIndicator: () => null }}
                        isSearchable
                        hideSelectedOptions={false}
                      /> */}

                      {/* <Select
                        className="border border-secondary rounded"
                        isMulti
                        options={branches.map((branch) => ({
                          value: branch.branch_name, // Assuming _id is the ID field of the branch object
                          label: branch.branch_name,
                        }))}
                        value={selectedBranchOptions}
                        onChange={handleBranchSelectChange}
                        placeholder="Select branches"
                        closeMenuOnSelect={false}
                        components={{ DropdownIndicator: () => null }}
                        isSearchable
                        hideSelectedOptions={false}
                      /> */}

                      <Select
                        className="border border-secondary rounded"
                        isMulti
                        options={branches.map((branch) => ({
                          value: branch._id, // Assuming _id is the ID field of the branch object
                          label: branch.branch_name,
                        }))}
                        value={selectedBranchOptions}
                        onChange={handleBranchSelectChange}
                        placeholder="Select branches"
                        closeMenuOnSelect={false}
                        components={{ DropdownIndicator: () => null }}
                        isSearchable
                        hideSelectedOptions={false}
                      />


                      {errors.branch && (
                        <div className="invalid-feedback">{errors.branch}</div>
                      )}
                    </div>
                  </div>
                </div>



                <div className="row">
                  <div className="col-md-4">
                    <div className="image-upload-container">
                      <div className="mb-3">
                        <label htmlFor="image" className="form-label fw-bold">
                          Add Profile
                        </label>
                        <input
                          // required
                          type="file"
                          id="image"
                          name="image"
                          className="form-control "
                          onChange={handleImageChange}
                        />
                        {/* {selectedImage && (
                          <div className="image-preview mt-3">
                            <img alt="Selected" src={URL.createObjectURL(selectedImage)} className="preview-image fixed-size-image" />
                          </div>
                        )} */}
                        {/* {selectedImage ? (
                          <div className="image-preview mt-3">
                            <img
                              alt="Selected"
                              src={
                                selectedImage
                                  ? URL.createObjectURL(selectedImage)
                                  : `${state.image_path}?cb=${cacheBuster}`
                              }
                              className="fixed-size-image preview-image"
                            />
                          </div>
                        ) : null} */}
                        {selectedImage || state.image_path ? (
                          <div className="image-preview mt-3">
                            <img
                              alt="Selected"
                              src={
                                selectedImage
                                  ? URL.createObjectURL(selectedImage)
                                  : `${state.image_path}?cb=${cacheBuster}`
                              }
                              className="fixed-size-image preview-image"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>




                {/* <button type="submit" className="btn btn-primary"
                  disabled={submitting}>

                  {submitting ? 'Submitting...' : 'Submit'}
                </button> */}
                <button
                  type="submit"
                  className={`custom-submit-btn ${submitting ? 'disabled' : ''}`}
                  disabled={submitting}
                >
                  {submitting ? 'Submitting...' : 'Submit'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmployeeForm;















