import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
   var isAuthenticated = false;
    const token =  sessionStorage.getItem('isLoggedIn');
    if (token) {
        isAuthenticated = true;
    } else {
        isAuthenticated = false;
    }
  return isAuthenticated ? children : <Navigate to="/signin" replace />;
};

export default RequireAuth;







