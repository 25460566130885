import React, { useState } from "react";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addLeave } from "../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";

const AddLeave = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [state, setState] = useState({
        leave_type: "",

    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setSubmitting(true);

        try {
            const response = await addLeave(state);
            const data = JSON.parse(response);

            if (data.success === true) {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                });
            } else {
                toast.success(data.message, {
                    position: "top-center",
                    autoClose: 5000,
                });
                navigate("/pages/leave");
            }
        } catch (error) {
            toast.success("leave Added successfully", {
                position: "top-right",
                autoClose: 3000,
            });
            navigate("/pages/leave");
        } finally {
            setSubmitting(false);
        }
    };

    const validateForm = (state) => {
        let errors = {};

        if (!state.leave_type) {
            errors.leave_type = "leave name is required";
        }
        // Add validations for other fields if needed

        return errors;
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
            <div className="mb-4 border-bottom text-center">
                        <h3 className="mb-0 pb- border-bottom custome-btn">Add Leave</h3>
                    </div>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/pages/leave">Leaves</Link>
                            </li>
                            <li className="breadcrumb-item active">Add Leave</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Link to="/pages/leave" className="btn btn-danger mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>
                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >


                    <div className="col-md-12 formcontrols">
                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="leave_type" className="form-label">
                                        Leave type
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.leave_type && "is-invalid"}`}
                                        id="leave_type"
                                        name="leave_type"
                                        value={state.leave_type}
                                        onChange={handleChange}
                                        placeholder="Enter leave type"
                                    />
                                    {errors.leave_type && (
                                        <div className="invalid-feedback">{errors.leave_type}</div>
                                    )}
                                </div>
                                {/* Other department fields can be added here */}
                                <Button
                                   className="custom-submit-btn"
                                    type="submit"
                                    disabled={submitting}
                                >
                                    {submitting && (
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                    {submitting ? 'Submitting Leave...' : 'Add Leave'}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddLeave
