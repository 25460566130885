import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { updateDepartment, editHoliday, updateHoliday } from '../../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
const EditHoliday = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const { id, masterId } = useParams();

    const [state, setState] = useState({
        _id: '',
        company_id: '',
        holiday_name: '',
        holiday_date: '',
        holiday_description: '',
    });

    const [errors, setErrors] = useState({});

    // useEffect(() => {
    //     async function fetchHolidayData() {
    //         try {
    //             const holidayData = await editHoliday(id, masterId);

    //             if (holidayData && holidayData.success) {
    //                 const holiday = holidayData.success;

    //                 setState({
    //                     _id: holiday._id || '',
    //                     company_id: holiday.company_id || '',
    //                     holiday_name: holiday.holiday_name || '',
    //                     holiday_date: holiday.holiday_date || '',
    //                     holiday_description: holiday.holiday_description || '',
    //                 });
    //             } else {
    //                 console.error('No holiday data found');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching holiday data:', error);
    //         }
    //     }

    //     fetchHolidayData();
    // }, [id, masterId]);

    useEffect(() => {
        const fetchHolidayData = debounce(async () => {
            try {
                setLoading(true);
                const holidayData = await editHoliday(id, masterId);

                if (holidayData && holidayData.success) {
                    const holiday = holidayData.success;

                    setState({
                        _id: holiday._id || '',
                        company_id: holiday.company_id || '',
                        holiday_name: holiday.holiday_name || '',
                        holiday_date: holiday.holiday_date || '',
                        holiday_description: holiday.holiday_description || '',
                    });
                } else {
                    console.error('No holiday data found');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching holiday data:', error);
                setLoading(false);
            }
        }, 100);

        fetchHolidayData();

        return () => {
            fetchHolidayData.cancel();
        };
    }, [id, masterId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } if (submitting) {
            return;
        }
        setSubmitting(true);

        try {
            const response = await updateHoliday(id, masterId, state);
            if (response && response.success && response.success.length > 0) {
                toast.success(response.success[0].message, {
                    position: 'top-center',
                    autoClose: 1000,
                });
            } else {
                toast.success('Holiday updated successfully', {
                    position: 'top-center',
                    autoClose: 1000,
                });
                navigate('/admin/holidays');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred', {
                position: 'top-right',
                autoClose: 1000,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary
        return errors;
    };

    const [displayedDate, setDisplayedDate] = useState('');
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US');
    };
    useEffect(() => {
        if (state.holiday_date) {
            setDisplayedDate(formatDate(state.holiday_date));
        }
    }, [state.holiday_date]);

    const handleDateChange = (event) => {
        const newDate = event.target.value;
        setState((prevState) => ({
            ...prevState,
            holiday_date: newDate,
        }));
        setDisplayedDate(formatDate(newDate));
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />



            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Edit Holiday</h3>
                </div>
                <div>

                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="/admin/holidays">Holidays</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit Holidays</li>
                            </ol>
                        </div>
                        <nav aria-label="breadcrumb">
                            <Link to="/admin/holidays" className="btn btn-danger mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                    </div>

                    {loading &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }

                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >


                        <div className="col-md-12 formcontrols">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="holiday_name" className="form-label">
                                                    Holiday name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.holiday_name && 'is-invalid'}`}
                                                    id="holiday_name"
                                                    name="holiday_name"
                                                    value={state.holiday_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.holiday_name && (
                                                    <div className="invalid-feedback">{errors.holiday_name}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">

                                            <div className="mb-3">
                                                <label htmlFor="holiday_date" className="form-label">
                                                    Holiday Date
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${errors.holiday_date && 'is-invalid'}`}
                                                    id="holiday_date"
                                                    name="holiday_date"
                                                    value={state.holiday_date}
                                                    onChange={handleDateChange}
                                                />
                                                {errors.holiday_date && (
                                                    <div className="invalid-feedback">{errors.holiday_date}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <button type="submit" className="custom-submit-btn" onClick={handleSubmit}
                                        disabled={submitting}>

                                        {submitting ? 'Submitting...' : 'Update'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    );
};

export default EditHoliday;
