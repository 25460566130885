import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import HeaderMobile from '../../../layouts/HeaderMobile';
import Header from '../../../layouts/Header';
import { Card, Button, Modal, Row, Col, Form } from "react-bootstrap";
import 'datatables.net';
import { viewAddEmployeeLeave, deleteAddEmployeeLeave } from '../../../services/api/api'
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx'; // Import XLSX library


const ViewAddEmpLeave = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [employeeDataData, setAddEmployeeDataData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteEMployeeLeaveId, setDeleteEmployeeLeaveId] = useState(null);
  const [masterId, setMasterId] = useState();
  const [searchText, setSearchText] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [leaveData, setLeaveData] = useState({});
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);


  const handleViewEmployee = async (params) => {
    console.log(params);
    setModalOpen(true);
    setLeaveData(params.row)

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    // setSalaryForm({ ...salaryForm, [name]: value });
    // console.log(salaryForm);
  };

  useEffect(() => {

    const generatedYears = Array.from({ length: 31 }, (_, i) => 2020 + i);
    setYears(generatedYears);

    const monthNames = [
      'january', 'february', 'march', 'april', 'may', 'june',
      'july', 'august', 'september', 'october', 'november', 'december'
    ];
    setMonths(monthNames);
  }, []);


  // const fetchData = async () => {
  //   try {
  //     const formattedData = await viewAddEmployeeLeave();

  //     if (!formattedData || !formattedData.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const masterData = formattedData[0];
  //     const employeeLeaveData = masterData.employee_leave;

  //     setMasterId(masterData._id);

  //     const addEmployeeLeaveArray = Object.values(employeeLeaveData).map(item => ({
  //       _id: item._id,
  //       employee_name: item.employee_name || 'N/A',
  //       leave_type: item.leave_type || 'N/A',
  //       from_date: item.from_date || 'N/A',
  //       to_date: item.to_date || 'N/A',
  //       total_days: item.total_days || 'N/A',
  //       remaining_leaves: item.remaining_leaves !== null ? item.remaining_leaves.toString() : '0',
  //       leave_reason: item.leave_reason || 'N/A',
  //     }));

  //     setAddEmployeeDataData(addEmployeeLeaveArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);


  const fetchData = async () => {
    try {
      const formattedData = await viewAddEmployeeLeave();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const masterData = formattedData[0];
      const employeeLeaveData = masterData.employee_leave;

      setMasterId(masterData._id);

      const addEmployeeLeaveArray = Object.values(employeeLeaveData).map(item => ({
        _id: item._id,
        employee_name: item.employee_name || 'N/A',
        leave_type: item.leave_type || 'N/A',
        from_date: item.from_date || 'N/A',
        to_date: item.to_date || 'N/A',
        total_days: item.total_days || 'N/A',
        remaining_leaves: item.remaining_leaves !== null ? item.remaining_leaves.toString() : '0',
        leave_reason: item.leave_reason || 'N/A',
      }));

      setAddEmployeeDataData(addEmployeeLeaveArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Create a new worksheet with the data
    const worksheet = XLSX.utils.json_to_sheet(
      employeeDataData.map((row, index) => ({
        'Sr.': index + 1,
        'Employee Name': row.employee_name || '', // Update field names here
        'Leave Type': row.leave_type || '', // Update field names here
        'From Date': row.from_date || '', // Update field names here
        'To Date': row.to_date || '', // Update field names here
        'Total Days': row.total_days || '', // Update field names here
        'Remaining Leaves': row.remaining_leaves || '', // Update field names here
        'Leave Reason': row.leave_reason || '', // Update field names here
      }))
    );
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // Convert the workbook to a binary string
    const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Convert the binary string to a Blob
    const excelBlob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = 'Employee Leaves Data.xlsx';
    // Trigger the download
    downloadLink.click();
  };
  const reversedRows = [...employeeDataData].reverse();

  const columns = [

    {
      field: 'id',
      headerName: 'Sr.',
      width: 50,
      align: 'center', headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <span>
          {reversedRows.indexOf(params.row) + 1}
        </span>
      ),
    },
    { field: 'employee_name', headerName: 'Employee name', width: 100, flex: 3, align: 'center', headerAlign: 'center', flex: 2, },
    { field: 'leave_type', headerName: 'Leave Type', width: 150, flex: 3, align: 'center', headerAlign: 'center', flex: 2, },
    { field: 'from_date', headerName: 'From Date', width: 150, flex: 3, align: 'center', headerAlign: 'center', flex: 2, },
    { field: 'to_date', headerName: 'To Date', width: 150, flex: 4, align: 'center', headerAlign: 'center', flex: 2, },
    { field: 'remaining_leaves', headerName: 'Remaining Leaves', width: 150, flex: 5, align: 'center', headerAlign: 'center', flex: 2, },
    { field: 'leave_reason', headerName: 'Leave Reason', width: 150, flex: 6, align: 'center', headerAlign: 'center', flex: 2, },

    {
      field: 'actions', headerName: 'Actions', width: 136, flex: 2, renderCell: (params) => (
        <>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              const id = params.row._id;
              navigate(`/edit_designation/${id}/${masterId}`);
              // console.log("viewemployee id", id);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="blue"
            className="bi bi-eye-fill"
            viewBox="0 0 16 16"
            style={{ cursor: 'pointer', marginRight: '16px' }}
            onClick={() => handleViewEmployee(params)}
          >
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      )
    },
  ];



  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return [...employeeDataData].reverse();
    }

    return [...employeeDataData].reverse().filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const handleDeleteEmployeeLeave = async (id, masterId) => {
    setDeleteEmployeeLeaveId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteAddEmployeeLeave(id, masterId);
      if (response && response.status === 'success') {
        if (!toast.isActive('addEmployeeLeaveDeletedToast')) {
          toast.success('Employee Leave deleted successfully', { toastId: 'addEmployeeLeaveDeletedToast', autoClose: 1000 });
        }
        fetchData();
      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting department:', error);
    }
  };

  const openConfirmationModal = (id) => {
    setDeleteEmployeeLeaveId(id);
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  const closeLeaveModal = () => {
    setModalOpen(false);
  };

  return (
    <React.Fragment>
      {/* Header components */}
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        {/* Breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/pages/empleave/empleave"> Employee Leaves</Link></li>
              <li class="breadcrumb-item active">Lists</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">

          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Button className='bg-success border-0' onClick={exportToExcel}>Export Data to Excel Sheet</Button>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="searchInput"></label>
                <input
                  type="text"
                  className='p-1 w-75 ms-2'
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>
            </div>

            <div className="col-md-5 mb-3">
              <Row>
                <Col md={4} className="mb-3 pt-3">
                  <Form.Group>
                    <Form.Control
                      as="select"
                      name="year"
                      
                      onChange={(e) => {
                        handleChange(e)
                        // handleviewsalarystructuresetup(e)
                      }}
                    >
                      <option value="">Select Year</option>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md={4} className="mb-3 pt-3">
                  <Form.Group>
                    <Form.Control
                      as="select"
                      name="month"
                      onChange={(e) => {
                        handleChange(e)
                        // handleviewsalarystructuresetup(e)
                      }}
                    >
                      <option value="">Select Month</option>
                      {months.map((month) => (
                        <option key={month} value={month}>
                          {month.charAt(0).toUpperCase() + month.slice(1)}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: 500, width: '100%' }}>
                <DataGrid
                  rows={filterRows()}
                  columns={columns}
                  pageSize={5}
                  getRowId={(row) => row._id}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />

              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal show={modalOpen} onHide={closeLeaveModal} centered size="xl">

        <Modal.Header closeButton>
          <Modal.Title>
            <p className="">Leave Details</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#a7cbf2' }}>

          <div className="row">
            <div className="col-md-12">
              <div className="card mb-3 shadow-sm">
                <div className="card-body">

                  <div className="field-box mb-3">
                    <strong>Employee Name:</strong> {leaveData.employee_name}
                  </div>
                  <div className="field-box mb-3">
                    <strong>From:</strong> {leaveData.from_date}
                  </div>
                  <div className="field-box mb-3">
                    <strong>To Name:</strong> {leaveData.to_date}
                  </div>
                  <div className="field-box mb-3">
                    <strong>Total Days:</strong> {leaveData.total_days}
                  </div>
                  <div className="field-box mb-3">
                    <strong>Remaining Leaves:</strong> {leaveData.remaining_leaves}
                  </div>
                  <div className="field-box mb-3">
                    <strong>Leave Type:</strong> {leaveData.leave_type}
                  </div>
                  <div className="field-box mb-3">
                    <strong>Reason:</strong> {leaveData.leave_reason}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>


        <div>
          <Modal.Body style={{ background: '#a7cbf2' }}>
          </Modal.Body>

        </div>

        <Modal.Footer style={{ background: '#d3d3d375' }}>
          <Button variant="secondary" >Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this Employee Leave?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleDeleteEmployeeLeave(deleteEMployeeLeaveId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ViewAddEmpLeave
