import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { Card, Modal, Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { getId, viewEmployeeBreakCount } from '../../../services/api/api';
import DatePicker from 'react-datepicker';
import { Calendar } from 'react-bootstrap-icons';

const EmployeeBreakCount = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const datepickerRef = useRef(null); // Add a new ref for the DatePicker
  const [breakcountData, setBreakcountData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedDateFilter, setSelectedDateFilter] = useState(new Date());
  const [filteredBreakData, setFilteredBreakData] = useState([]);
  const [showSecondModal, setShowSecondModal] = useState(false); // Fix this line
  // useEffect(() => {
  //   const empId = getId();
  //   fetchBreakData(empId, selectedDateFilter);

  // }, [selectedDateFilter]);
 
  useEffect(() => {
    const empId = getId();
    const debouncedFetchBreakData = _debounce(() => fetchBreakData(empId, selectedDateFilter), 100);

    debouncedFetchBreakData();

    return () => {
        debouncedFetchBreakData.cancel();
    };
}, [selectedDateFilter]);

  const reversedRows = [...breakcountData].reverse();

  // const calculateTotalDuration = (breaks) => {
  //   const totalDuration = breaks.reduce((total, breakItem) => total + breakItem.total_break_duration, 0);
  //   const hours = Math.floor(totalDuration / 60);
  //   const minutes = totalDuration % 60;

  //   return `${hours}h ${minutes}m`;
  // };
  const calculateTotalDuration = (breaks) => {
    let totalDuration = breaks.reduce((total, breakItem) => total + breakItem.total_break_duration, 0);
    
    // If total duration is 0, set it to 1 minute
    if (totalDuration === 0) {
      totalDuration = 1;
    }
  
    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;
  
    return `${hours}h ${minutes}m`;
  };
  




  const columns = [
    { field: 'id', headerName: 'Sr.', width: 50, align: 'center', flex: 1, headerAlign: 'center' },
    { field: 'date', headerName: 'Date', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
    { field: 'name', headerName: 'Name', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
   
    { field: 'breakduration', headerName: 'Total Break Duration', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
   
    // {
    //   field: 'totalDuration',
    //   headerName: 'Total Break Duration',
    //   width: 150,
    //   align: 'center',
    //   headerAlign: 'center',
    //   flex: 2,
    //   renderCell: (params) => (
    //     <div>
    //       {calculateTotalDuration(params.row.breaks)}
    //     </div>
    //   ),
    // },
  ];

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setShowSecondModal(true); // Fix this line
  };

  const handleSecondModalClose = () => {
    setShowSecondModal(false);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const formatTime = (time) => {
    const formattedTime = new Date(`2022-01-01 ${time}`);
    return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const filterRows = () => {
    if (!searchText && !selectedDateFilter) {
      return reversedRows.map((row, index) => ({ ...row, id: index + 1 }));
    }

    return reversedRows
      .filter((row) =>
        Object.values(row).some(
          (value) =>
            typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
        ) &&
        (!selectedDateFilter || row.date === selectedDateFilter.toISOString().split('T')[0])
      )
      .map((row, index) => ({ ...row, id: index + 1 }));
  };

  const fetchBreakData = async (employeeId, selectedDate) => {
    try {
      setLoading(true);
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1;
      const formattedData = await viewEmployeeBreakCount(employeeId, year, month);

      if (!formattedData || !formattedData.data.length) {
        throw new Error('Failed to fetch data.');
      }

      const breakList = formattedData.data[0].break_list;

      const branchArray = breakList.flatMap((item, index) =>
        Object.entries(item.attendance).map(([date, attendanceData]) => ({
          id: `${index}_${date}`,
          date: attendanceData.date,
          name: attendanceData.name,
          // breakduration:attendanceData.total_break_duration,
          breakduration: `${attendanceData.total_break_duration} minutes`,
          breaks: attendanceData.breaks.map(breakItem => ({
            type: breakItem.type,
            time: formatTime(breakItem.time),
            total_break_duration: breakItem.total_break_duration || 0,
          })),
        }))
      );

      setBreakcountData(branchArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  const filterCurrentDateBreaks = () => {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];

    const filteredData = reversedRows
      .filter((row) =>
        row.date === formattedCurrentDate &&
        row.breaks.some((breakItem) => breakItem.type === 'IN') &&
        !row.breaks.some((breakItem) => breakItem.type === 'OUT')
      )
      .map((row, index) => {
        const outValue = null;

        return { ...row, id: index + 1, out: outValue };
      });

    setFilteredBreakData(filteredData);
  };

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };

  const openModalWithFilteredData = () => {
    filterCurrentDateBreaks();
    handleModalShow();
  };


  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/empbreakdata">Employee Break Details</Link>
              </li>
              <li className="breadcrumb-item active">List</li>
            </ol>
          </div>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div className="mb-4 border-bottom text-center">
              <h3 className="mb-0 pb- border-bottom custome-btn">Break Details</h3>
            </div>
            <div class="alert alert-success" role="alert">
            <strong>Note :</strong> To view break details , click on the employee row.
            </div>
            <div className="mb-3 border rounded p-3" style={{ backgroundColor: '#f0f0f0' }}>
              <strong>Break Info Monthly:</strong> {selectedDateFilter && selectedDateFilter.toLocaleString('default', { month: 'long', year: 'numeric' })}
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center mb-3">
              <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center mb-3">
                <div className="d-flex align-items-center mb-2 mb-md-0 mr-2 col-12 col-md-8">
                  <div className="input-group">
                    <DatePicker
                      selected={selectedDateFilter}
                      onChange={(date) => setSelectedDateFilter(date)}
                      dateFormat="MM/dd/yyyy"
                      className="form-control"
                      ref={datepickerRef}
                    />
                  </div>
                </div>

                {/* <div className="d-flex align-items-center col-12 col-md-6" style={{ marginLeft: '20px' }}>
                  <button className="btn btn-primary w-100" onClick={openModalWithFilteredData}>
                    On a break employees
                  </button>
                </div> */}
              </div>
              <div className="d-flex align-items-center col-6">
                <label htmlFor="searchInput" className="me-2">
            
                </label>
                <input
                  type="text"
                  className="p-2 ms-2 w-100"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>
            </div>

            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (

              <div style={{ height: 400, width: '100%' }}>
                {/* <div className="mb-3 border rounded" style={{ backgroundColor: '#f0f0f0' }}> */}
                  {/* <strong>Note :</strong> To view break details , click on the employee row. */}
                  {/* <a
                  href="#employee-break-details"
                  style={{
                    textDecoration: 'none',
                    color: '#007bff',
                    borderBottom: '1px dashed #007bff',
                  }}
                >
                  Employee Name
                </a> */}
                {/* </div> */}
                <DataGrid
                  rows={filterRows()}
                  columns={columns}
                  pageSize={5}
                  onRowClick={handleRowClick}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
      {/* -----------------------second modal--------------------- */}
      <Modal show={showSecondModal} onHide={handleSecondModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedRow && (
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Break details of  {selectedRow.name}</span>
                </div>

              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRow && (
            <>
              <h4 style={{ backgroundColor: '#f0f0f0', marginBottom: "5px" }} className='text-center'>{selectedRow.date}</h4>
            </>
          )}
          <table className="table table-bordered border-dark">
            <thead>
              <tr>
                <th className='text-white bg-secondary' style={{ padding: '10px', textAlign: 'center' }}>Sr.</th>
                <th className='text-white bg-secondary' style={{ padding: '10px', textAlign: 'center' }}>Start Break</th>
                <th className='text-white bg-secondary' style={{ padding: '10px', textAlign: 'center' }}>End Break</th>
              </tr>
            </thead>
            <tbody>
              {selectedRow &&
                selectedRow.breaks.map((breakItem, index) => {
                  if (breakItem.type === 'IN') {
                    const nextBreak = selectedRow.breaks[index + 1];
                    return (
                      <tr key={index}>
                        <td style={{ padding: '10px', textAlign: 'center' }}>{Math.floor(index / 2) + 1}</td>
                        <td style={{ padding: '10px', textAlign: 'center' }}>{formatTime(breakItem.time)}</td>
                        <td style={{ padding: '10px', textAlign: 'center' }}>
                          {nextBreak?.type === 'OUT' ? formatTime(nextBreak.time) : ''}
                        </td>
                      </tr>
                    );
                  }
                  return null; // Skip "OUT" entries to avoid empty rows
                })}
            </tbody>
          </table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSecondModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* -------------------end second modal------------------------------- */}

      {/* ---------------------------open current break modal---------------- */}
      <Modal show={showModal} onHide={handleModalClose} >
        <Modal.Header closeButton>
          <Modal.Title>Filtered Break Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filteredBreakData.length > 0 ? (
            <>
              <p>The employees is on a break now</p>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Breakduration</th>
                    {/* Add more headers for additional fields */}
                  </tr>
                </thead>
                <tbody>
                  {filteredBreakData.map((rowData) => (
                    <tr key={rowData.id}>
                      <td>{rowData.date}</td>
                      <td>{rowData.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{ textAlign: 'center', padding: '20px' }}>No employees on a break</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ------------------------end currnet break modal--------------------------------- */}

    </React.Fragment >
  );
};

export default EmployeeBreakCount;


















