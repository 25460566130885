import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import { addapplicatestatus } from "../services/api/api";

const Applicantstatusform = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        status_name: "",
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        // Clear state and errors on component mount
        setState({
            status_name: "",
        });
        setErrors({});
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     // Validation
    //     const validationErrors = {};
    //     if (!state.status_name.trim()) {
    //         validationErrors.status_name = 'Status name is required';
    //     }

    //     if (Object.keys(validationErrors).length > 0) {
    //         setErrors(validationErrors);
    //         return;
    //     }

    //     try {
    //         setIsSubmitting(true);
    //         const response = await addapplicatestatus(state.status_name);

    //         // Ensure response is valid and has a message
    //         if (response && response.message) {
    //             toast.success(response.message, {
    //                 position: "top-center",
    //                 autoClose: 1000,
    //             });
    //             navigate("/pages/settings/addapplicantstatus");
    //         } else {
    //             // Handle unexpected response
    //             toast.error("Unexpected response from server", {
    //                 position: "top-center",
    //                 autoClose: 1000,
    //             });
    //         }
    //     } catch (error) {

    //     } finally {
    //         setIsSubmitting(false);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        const validationErrors = {};
        if (!state.status_name.trim()) {
            validationErrors.status_name = 'Status name is required';
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            setIsSubmitting(true);

            const response = await addapplicatestatus(state.status_name);
            console.log(response);

            if (response) {
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
                navigate("/pages/settings/addapplicantstatus");
            } else {
                toast.error("Unexpected response from server", {
                    position: "top-center",
                    autoClose: 10000,
                });
            }
            setIsSubmitting(false);
        } catch (error) {
            toast.error("Error adding status: " + (error.message || "Something went wrong"), {
                position: "top-right",
                autoClose: 10000,
            });
            setIsSubmitting(false);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Add Status</h3>
                </div>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/pages/settings/addapplicantstatus">Applicant Status</Link>
                            </li>
                            <li className="breadcrumb-item active">Add Status</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Link to="/pages/settings/addapplicantstatus" className="btn btn-danger mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>

                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >
                    <div className="col-md-12 formcontrols">
                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <label htmlFor="status_name" className="form-label">
                                                Status name
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.status_name && "is-invalid"}`}
                                                id="status_name"
                                                name="status_name"
                                                value={state.status_name}
                                                onChange={handleChange}
                                                placeholder="Enter Status Name"
                                            />
                                            {errors.status_name && (
                                                <div className="invalid-feedback">{errors.status_name}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="custom-submit-btn" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Add Status'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Applicantstatusform;
