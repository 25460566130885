import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import '../../styles/globals.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css"; // Import date picker CSS
import { CountryDropdown } from "react-country-region-selector";

const Jobform = () => {
  const [formData, setFormData] = useState({
    jobTitle: "",
    company: "",
    openings: "",
    country: "",
    language: "",
    advertisingLocation: "",
    location: "",
    jobType: "",
    schedule: new Date(), // Initialize with the current date
    pay: "",
    supplementalPay: "",
    benefits: "",
    jobDescription: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCountryChange = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      country: val,
      // Reset the region when the country changes if needed
      state: '',
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.jobTitle) errors.jobTitle = "Job Title is required.";
    if (!formData.company) errors.company = "Company is required.";
    if (!formData.openings === "" || formData.openings <= 0) errors.openings = "Number of Openings must be greater than 0.";
    if (!formData.country) errors.country = "Country is required.";
    if (!formData.language) errors.language = "Language is required.";
    if (!formData.advertisingLocation) errors.advertisingLocation = "Advertising Location is required.";
    if (!formData.location) errors.location = "Location is required.";
    if (!formData.jobType) errors.jobType = "Job Type is required.";
    if (!formData.schedule) errors.schedule = "Schedule is required.";
    if (!formData.pay) errors.pay = "Pay is required.";
    if (!formData.supplementalPay) errors.supplementalPay = "Supplemental Pay is required.";
    if (!formData.benefits) errors.benefits = "Benefits is required.";
    if (!formData.jobDescription) errors.jobDescription = "Job Description is required.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Form is valid, you can submit it to your server or perform any desired action
      console.log(formData);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <h4>Add Job</h4>

        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/dashboard/helpdesk">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/pages/recruitment/job">Jobs</Link>
              </li>
              <li className="breadcrumb-item active">Add Job</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/pages/recruitment/job" className="btn btn-danger mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link>
          </nav>
        </div>

        <div className="row" style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden" }}>
          <div className="col-md-12">
            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
              <form onSubmit={handleSubmit} className="form-labels">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="jobTitle" className="form-label">Job Title</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.jobTitle && "is-invalid"}`}
                        id="jobTitle"
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleInputChange}
                      />
                      {formErrors.jobTitle && <div className="invalid-feedback">{formErrors.jobTitle}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="company" className="form-label">Company</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.company && "is-invalid"}`}
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleInputChange}
                      />
                      {formErrors.company && <div className="invalid-feedback">{formErrors.company}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="openings" className="form-label">Number of Openings</label>
                      <input
                        type="number"
                        className={`form-control ${formErrors.openings && "is-invalid"}`}
                        id="openings"
                        name="openings"
                        value={formData.openings}
                        onChange={handleInputChange}
                      />
                      {formErrors.openings && <div className="invalid-feedback">{formErrors.openings}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">Country</label>
                      <CountryDropdown
                        className={`form-control ${formErrors.country && 'is-invalid'}`}
                        value={formData.country}
                        onChange={(val) => handleCountryChange(val)}
                      />
                      {/* <select
                        className={`form-select ${formErrors.country && "is-invalid"}`}
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                      > */}
                        {/* <option value="" selected disabled>Select Country</option>
                        <option value="USA">India</option>
                        <option value="USA">USA</option>
                        <option value="Canada">Canada</option> */}
                        {/* Add more countries here */}
                      {/* </select> */}
                      {formErrors.country && <div className="invalid-feedback">{formErrors.country}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="language" className="form-label">Language</label>
                      <select
                        className={`form-select ${formErrors.language && "is-invalid"}`}
                        id="language"
                        name="language"
                        value={formData.language}
                        onChange={handleInputChange}
                      >
                        <option value="" selected disabled>Select Language</option>
                        <option value="English">English</option>
                        <option value="Spanish">Spanish</option>
                        {/* Add more languages here */}
                      </select>
                      {formErrors.language && <div className="invalid-feedback">{formErrors.language}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="advertisingLocation" className="form-label">Advertising Location</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.advertisingLocation && "is-invalid"}`}
                        id="advertisingLocation"
                        name="advertisingLocation"
                        value={formData.advertisingLocation}
                        onChange={handleInputChange}
                      />
                      {formErrors.advertisingLocation && <div className="invalid-feedback">{formErrors.advertisingLocation}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="location" className="form-label">Location</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.location && "is-invalid"}`}
                        id="location"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                      />
                      {formErrors.location && <div className="invalid-feedback">{formErrors.location}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="jobType" className="form-label">Job Type</label>
                      <select
                        className={`form-select ${formErrors.jobType && "is-invalid"}`}
                        id="jobType"
                        name="jobType"
                        value={formData.jobType}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Job Type</option>
                        <option value="Full-Time">Full-Time</option>
                        <option value="Part-Time">Part-Time</option>
                        {/* Add more job types here */}
                      </select>
                      {formErrors.jobType && <div className="invalid-feedback">{formErrors.jobType}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="schedule" className="form-label">Schedule</label>
                      <DatePicker
                        selected={formData.schedule}
                        onChange={(date) => setFormData({ ...formData, schedule: date })}
                        className={`form-control ${formErrors.schedule && "is-invalid"}`}
                        id="schedule"
                        name="schedule"
                      />
                      {formErrors.schedule && <div className="invalid-feedback">{formErrors.schedule}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="pay" className="form-label">Pay</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.pay && "is-invalid"}`}
                        id="pay"
                        name="pay"
                        value={formData.pay}
                        onChange={handleInputChange}
                      />
                      {formErrors.pay && <div className="invalid-feedback">{formErrors.pay}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="supplementalPay" className="form-label">Supplemental Pay</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.supplementalPay && "is-invalid"}`}
                        id="supplementalPay"
                        name="supplementalPay"
                        value={formData.supplementalPay}
                        onChange={handleInputChange}
                      />
                      {formErrors.supplementalPay && <div className="invalid-feedback">{formErrors.supplementalPay}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="benefits" className="form-label">Benefits</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.benefits && "is-invalid"}`}
                        id="benefits"
                        name="benefits"
                        value={formData.benefits}
                        onChange={handleInputChange}
                      />
                      {formErrors.benefits && <div className="invalid-feedback">{formErrors.benefits}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="jobDescription" className="form-label">Job Description</label>
                      <textarea
                        className={`form-control ${formErrors.jobDescription && "is-invalid"}`}
                        id="jobDescription"
                        name="jobDescription"
                        value={formData.jobDescription}
                        onChange={handleInputChange}
                        rows="4"
                      />
                      {formErrors.jobDescription && <div className="invalid-feedback">{formErrors.jobDescription}</div>}
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Jobform;
