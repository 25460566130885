// import React, { useState, useEffect } from 'react';
// import _debounce from 'lodash/debounce';

// import { viewEmployeeProfile, getId, editEmployee, getemployeeId } from '../../services/api/api';
// import { useNavigate, useParams } from 'react-router-dom';
// import Header from '../../layouts/Header';
// import HeaderMobile from '../../layouts/HeaderMobile';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Card, CardBody, Form } from 'react-bootstrap';
// import { Link } from "react-router-dom";
// import $ from 'jquery';
// import img05 from "../../assets/img/img05.jpg";


// const Empprofile = () => {
//     const [activeTab, setActiveTab] = useState('bank_details');
//     const navigate = useNavigate();
//     const [employeeData, setEmployeeData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [id, setId] = useState('');
//     useEffect(() => {
//         const id = getemployeeId();
//         setId(id);
//     }, []);

//     console.log(id);


//     const [state, setState] = useState({
//         _id: '',
//         company_id: '',
//         first_name: '',
//         last_name: '',
//         email: '',
//         joining_date: '',
//         phone: '',
//         department: '',
//         designation: '',
//         password: '',
//         salary: '',
//         shift: '',
//         branch: '',
//         bank_information: [],
//         emergency_contact: [],
//         // bank_name: '',
//         // account_no: '',

//         // Other fields...
//     });

//     // useEffect(() => {
//     //     async function fetchemployeeData() {
//     //         try {

//     //             const employeeData = await editEmployee(id);
//     //             console.log(employeeData);


//     //             if (employeeData && employeeData.success && employeeData.success.length > 0) {
//     //                 const employee = employeeData.success[0];

//     //                 setState({
//     //                     _id: employee._id || '',
//     //                     company_id: employee.company_id || '',
//     //                     first_name: employee.first_name || '',
//     //                     last_name: employee.last_name || '',
//     //                     email: employee.email || '',
//     //                     joining_date: employee.joining_date || '',
//     //                     phone: employee.phone || '',
//     //                     department: employee.department || '',
//     //                     designation: employee.designation || '',
//     //                     branch: employee.branch || '',
//     //                     salary: employee.salary || '',
//     //                     shift: employee.shift || '',
//     //                     file: employee.file || [],
//     //                     bank_information: employee.bank_information || [],
//     //                     emergency_contact: employee.emergency_contact || [],
//     //                 });
//     //             } else {
//     //                 console.error('No employee data found');
//     //             }
//     //         } catch (error) {
//     //             console.error('Error fetching employee data:', error);
//     //         }
//     //     }

//     //     fetchemployeeData();
//     // }, [id]);


//     useEffect(() => {
//         const fetchemployeeData = async () => {

//             try {
//                 setLoading(true);
//                 const employeeData = await editEmployee(id);
//                 console.log(employeeData);
    
//                 if (employeeData && employeeData.success && employeeData.success.length > 0) {
//                     const employee = employeeData.success[0];
    
//                     setState({
//                         _id: employee._id || '',
//                         company_id: employee.company_id || '',
//                         first_name: employee.first_name || '',
//                         last_name: employee.last_name || '',
//                         email: employee.email || '',
//                         joining_date: employee.joining_date || '',
//                         phone: employee.phone || '',
//                         department: employee.department || '',
//                         designation: employee.designation || '',
//                         branch: employee.branch || '',
//                         salary: employee.salary || '',
//                         shift: employee.shift || '',
//                         file: employee.file || [],
//                         bank_information: employee.bank_information || [],
//                         emergency_contact: employee.emergency_contact || [],
//                     });
//                 } else {
//                     console.error('No employee data found');
//                 }
//             } catch (error) {
//                 console.error('Error fetching employee data:', error);
//             }
//             finally {
//                 setLoading(false);
//             }
//         };
    
//         const debouncedFetchEmployeeData = _debounce(fetchemployeeData, 2000);
    
//         debouncedFetchEmployeeData();
    
//         return () => {
//             debouncedFetchEmployeeData.cancel();
//         };
//     }, [id]);
    
//     useEffect(() => {
//         $('.ebtn').on('click', function () {
//             const id = $(this).data('id');
//             navigate(`/edit_emp/${id}`);
//             console.log("viewemployee id", id);
//         });
//     }, [navigate]);




//     const renderTabContent = () => {
//         switch (activeTab) {
//             case 'bank_details':
//                 return (
//                     <div className="col-md-12">
//                         <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none", }}>
//                             <CardBody >
//                                 <h3 className='mb-4'>Bank Information</h3>
//                                 <div className="d-flex">
//                                     <div className="div me-5">
//                                         <h6><strong>Bank Name</strong></h6>
//                                         <h6><strong>Bank Account No. </strong></h6>
//                                         <h6><strong>IFSC Code</strong></h6>
//                                         <h6><strong>Branch Name</strong></h6>
//                                         <h6><strong>PAN No.</strong></h6>
//                                     </div>
//                                     <div className="div">
//                                         {state.bank_information.map((bank, index) => (
//                                             <React.Fragment key={index}>
//                                                 <h6>{bank.bank_name}</h6>
//                                                 <h6>{bank.account_no}</h6>
//                                                 <h6>{bank.IFSC_code}</h6>
//                                                 <h6>{bank.branch_name}</h6>
//                                                 <h6>{bank.PAN_no}</h6>
//                                             </React.Fragment>
//                                         ))}
//                                     </div>
//                                 </div>

//                             </CardBody>
//                         </Card>
//                     </div>
//                 );
//             case 'emergency_contact':
//                 return (
//                     <div className="col-md-12">
//                         <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none", }}>
//                             <CardBody >
//                                 <h3 className='mb-4'>Emergency Contact</h3>
//                                 <div className="d-flex">
//                                     <div className="div me-5">
//                                         <h6><strong>Name</strong> </h6>
//                                         <h6><strong>Relationship</strong></h6>
//                                         <h6><strong>Phone</strong></h6>
//                                     </div>
//                                     {/* <div className="div">
//                                         <h6> khuhsal vadhavana </h6>
//                                         <h6> brother</h6>
//                                         <h6>1234567890</h6>
//                                     </div> */}
//                                     <div className="div">
//                                         {state.emergency_contact.map((contact, index) => (
//                                             <React.Fragment key={index}>
//                                                 <h6>{contact.emergency_name}</h6>
//                                                 <h6>{contact.emergency_relation}</h6>
//                                                 <h6>{contact.emergency_phone}</h6>
//                                             </React.Fragment>
//                                         ))}
//                                     </div>
//                                 </div>


//                             </CardBody>
//                         </Card>
//                     </div>

//                 );
//             default:
//                 return null;
//         }
//     };



//     // const fetchData = async () => {
//     //     try {
//     //         const formData = {};
//     //         const response = await viewEmployeeProfile(id, formData);
//     //         if (!response || !response.success || !response.data) {
//     //             throw new Error("Failed to fetch data.");
//     //         }

//     //         //   setEmployeeData(response.data.id);
//     //         setEmployeeData(response.data[0]._id)
//     //         // console.log(response.data[0]._id)
//     //         // const match = response.data[0]._id == id
//     //         // console.log( match)
//     //     } catch (error) {
//     //         console.error("Error fetching data:", error);
//     //     }
//     //     finally {
//     //         setLoading(false);
//     //     }
//     // };
//     // useEffect(() => {
//     //     fetchData(id);
//     // }, [id]);



//     return (
//         <React.Fragment>
//             <HeaderMobile />
//             <Header />

//             <div className="main p-4 p-lg-5 mt-5">
          
//                 <div>

//                     <h4>Employee profile</h4>
//                     <div className="breadcrumb-warning d-flex justify-content-between ot-card">
//                         <div>
//                             <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
//                                 <li className="breadcrumb-item">
//                                     <Link to="/employee/dashboard">Dashboard</Link>
//                                 </li>
//                                 {/* <li className="breadcrumb-item active">
//                                     <Link to="/pages/hr/branch">Branches</Link>
//                                 </li> */}
//                                 <li className="breadcrumb-item active">Employee profile</li>
//                             </ol>
//                         </div>
//                         <nav aria-label="breadcrumb">
//                             {/* <Link to="/pages/hr/branch" className="btn btn-danger mb-3">
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
//                                     <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
//                                 </svg>
//                                 Back
//                             </Link> */}
//                         </nav>
//                     </div>


//                     <div className="row"
                   
//                     >
//                         {loading ? (
//                     <div className="loader-container">
//                         <div className="loader"></div>
//                     </div>
//                 ) : (
                       
//                         <div className="col-md-12" >
//                             <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none" }}>
//                                 <CardBody>
//                                     <svg
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         width="16"
//                                         height="16"
//                                         fill="currentColor"
//                                         className="bi bi-pencil-fill edit ebtn"
//                                         viewBox="0 0 16 16"
//                                         data-id={state._id}
//                                         style={{ cursor: "pointer" }}
//                                     >
//                                         <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
//                                     </svg>
//                                     <div className="d-flex">
                                        
//                                         <div style={{ borderBlockColor: "black", background: "white", display: "flex", justifyContent: "center", alignSelf: "center" }} className="left">
//                                             {/* <img className='rounded' src='img05' /> */}
//                                             <img
//                                                 src={img05}
//                                                 alt="User Profile"
//                                                 className="img-fluid rounded-circle"
//                                                 style={{ width: "252px", height: "163px" }}
//                                             />
//                                         </div>
                                        

//                                         <div className="right ms-5">
                                            
//                                             <h2>{state.first_name} {state.last_name}</h2>
//                                             <h6>{state.designation}</h6>
//                                             <div className="d-flex">
//                                                 <div className='me-5'>
//                                                     <h6 className='mt-3'>Employee ID</h6>
//                                                     <h6 className='mt-3'>Date of Joining</h6>
//                                                     <h6 className='mt-3'>Email Id </h6>
//                                                     <h6 className='mt-3'>Phone no</h6>
//                                                 </div>
//                                                 <div>
//                                                     <h6 className='mt-3'>4653564</h6>
//                                                     <h6 className='mt-3'>{state.joining_date}</h6>
//                                                     <h6 className='mt-3'>{state.email}</h6>
//                                                     <h6 className='mt-3'>{state.phone}</h6>
//                                                 </div>

//                                             </div>
//                                         </div>
//                                     </div>
//                                 </CardBody>
//                             </Card>
//                         </div>
//                 )}
//                         <div className="col-md-12 mt-2">
//                             <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none" }}>
//                                 <CardBody>
//                                     <ul className="nav nav-tabs ">
//                                         {/* <li className="nav-item">
//                                             <a className={`nav-link ${activeTab === 'personal_information' ? 'active border border-grey text-decoration-none' : ''} `} onClick={() => setActiveTab('personal_information')} href="javascript:void(0)">Personal Information</a>
//                                         </li> */}
//                                         <li className="nav-item">
//                                             <a className={`nav-link ${activeTab === 'bank_details' ? 'active border border-grey text-decoration-none' : ''} `} onClick={() => setActiveTab('bank_details')} href="javascript:void(0)">Bank Details</a>
//                                         </li>
//                                         <li className="nav-item">
//                                             <a className={`nav-link ${activeTab === 'emergency_contact' ? 'active border border-grey text-decoration-none' : ''} `} onClick={() => setActiveTab('emergency_contact')} href="javascript:void(0)">Emergency Contact</a>
//                                         </li>

//                                     </ul>
//                                     {renderTabContent()}



//                                 </CardBody>
//                             </Card>
//                         </div>




//                     </div>

//                 </div>
                
//             </div>
            
//         </React.Fragment>
//     );
// };

// export default Empprofile;


import React, { useState, useEffect } from 'react';

import _debounce from 'lodash/debounce';

import { viewEmployeeProfile, getId, editEmployee, getemployeeId } from '../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import $ from 'jquery';
import img05 from "../../assets/img/img05.jpg";


const Empprofile = () => {
    const [activeTab, setActiveTab] = useState('bank_details');
    const navigate = useNavigate();
    const [employeeData, setEmployeeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState('');
    useEffect(() => {
        const id = getemployeeId();
        setId(id);
    }, []);

    console.log(id);


    const [state, setState] = useState({
        _id: '',
        company_id: '',
        first_name: '',
        last_name: '',
        email: '',
        joining_date: '',
        phone: '',
        department: '',
        designation: '',
        password: '',
        salary: '',
        shift: '',
        branch: '',
        bank_information: [],
        emergency_contact: [],
        // bank_name: '',
        // account_no: '',

        // Other fields...
    });

    // useEffect(() => {
    //     async function fetchemployeeData() {
    //         try {

    //             const employeeData = await editEmployee(id);
    //             console.log(employeeData);


    //             if (employeeData && employeeData.success && employeeData.success.length > 0) {
    //                 const employee = employeeData.success[0];

    //                 setState({
    //                     _id: employee._id || '',
    //                     company_id: employee.company_id || '',
    //                     first_name: employee.first_name || '',
    //                     last_name: employee.last_name || '',
    //                     email: employee.email || '',
    //                     joining_date: employee.joining_date || '',
    //                     phone: employee.phone || '',
    //                     department: employee.department || '',
    //                     designation: employee.designation || '',
    //                     branch: employee.branch || '',
    //                     salary: employee.salary || '',
    //                     shift: employee.shift || '',
    //                     file: employee.file || [],
    //                     bank_information: employee.bank_information || [],
    //                     emergency_contact: employee.emergency_contact || [],
    //                 });
    //             } else {
    //                 console.error('No employee data found');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching employee data:', error);
    //         }
    //     }

    //     fetchemployeeData();
    // }, [id]);


    useEffect(() => {
        const fetchemployeeData = async () => {
            try {
                const employeeData = await editEmployee(id);
                console.log(employeeData);
    
                if (employeeData && employeeData.success && employeeData.success.length > 0) {
                    const employee = employeeData.success[0];
    
                    setState({
                        _id: employee._id || '',
                        company_id: employee.company_id || '',
                        first_name: employee.first_name || '',
                        last_name: employee.last_name || '',
                        email: employee.email || '',
                        joining_date: employee.joining_date || '',
                        phone: employee.phone || '',
                        department: employee.department || '',
                        designation: employee.designation || '',
                        branch: employee.branch || '',
                        salary: employee.salary || '',
                        shift: employee.shift || '',
                        file: employee.file || [],
                        bank_information: employee.bank_information || [],
                        emergency_contact: employee.emergency_contact || [],
                    });
                } else {
                    console.error('No employee data found');
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };
    
        const debouncedFetchEmployeeData = _debounce(fetchemployeeData, 100);
    
        debouncedFetchEmployeeData();
    
        return () => {
            debouncedFetchEmployeeData.cancel();
        };
    }, [id]);
    
    useEffect(() => {
        $('.ebtn').on('click', function () {
            const id = $(this).data('id');
            navigate(`/edit_emp/${id}`);
            console.log("viewemployee id", id);
        });
    }, [navigate]);




    const renderTabContent = () => {
        switch (activeTab) {
            case 'bank_details':
                return (
                    <div className="col-md-12">
                        <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none", }}>
                            <CardBody >
                                <h3 className='mb-4'>Bank Information</h3>
                                <div className="d-flex">
                                    <div className="div me-5">
                                        <h6><strong>Bank Name</strong></h6>
                                        <h6><strong>Bank Account No. </strong></h6>
                                        <h6><strong>IFSC Code</strong></h6>
                                        <h6><strong>Branch Name</strong></h6>
                                        <h6><strong>PAN No.</strong></h6>
                                    </div>
                                    <div className="div">
                                        {state.bank_information.map((bank, index) => (
                                            <React.Fragment key={index}>
                                                <h6>{bank.bank_name}</h6>
                                                <h6>{bank.account_no}</h6>
                                                <h6>{bank.IFSC_code}</h6>
                                                <h6>{bank.branch_name}</h6>
                                                <h6>{bank.PAN_no}</h6>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>

                            </CardBody>
                        </Card>
                    </div>
                );
            case 'emergency_contact':
                return (
                    <div className="col-md-12">
                        <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none", }}>
                            <CardBody >
                                <h3 className='mb-4'>Emergency Contact</h3>
                                <div className="d-flex">
                                    <div className="div me-5">
                                        <h6><strong>Name</strong> </h6>
                                        <h6><strong>Relationship</strong></h6>
                                        <h6><strong>Phone</strong></h6>
                                    </div>
                                    {/* <div className="div">
                                        <h6> khuhsal vadhavana </h6>
                                        <h6> brother</h6>
                                        <h6>1234567890</h6>
                                    </div> */}
                                    <div className="div">
                                        {state.emergency_contact.map((contact, index) => (
                                            <React.Fragment key={index}>
                                                <h6>{contact.emergency_name}</h6>
                                                <h6>{contact.emergency_relation}</h6>
                                                <h6>{contact.emergency_phone}</h6>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>


                            </CardBody>
                        </Card>
                    </div>

                );
            default:
                return null;
        }
    };



    // const fetchData = async () => {
    //     try {
    //         const formData = {};
    //         const response = await viewEmployeeProfile(id, formData);
    //         if (!response || !response.success || !response.data) {
    //             throw new Error("Failed to fetch data.");
    //         }

    //         //   setEmployeeData(response.data.id);
    //         setEmployeeData(response.data[0]._id)
    //         // console.log(response.data[0]._id)
    //         // const match = response.data[0]._id == id
    //         // console.log( match)
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    //     finally {
    //         setLoading(false);
    //     }
    // };
    // useEffect(() => {
    //     fetchData(id);
    // }, [id]);



    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />

            <div className="main p-4 p-lg-5 mt-5">
                <div>

                    <h4>Employee profile</h4>
                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/employee/dashboard">Dashboard</Link>
                                </li>
                                {/* <li className="breadcrumb-item active">
                                    <Link to="/pages/hr/branch">Branches</Link>
                                </li> */}
                                <li className="breadcrumb-item active">Employee profile</li>
                            </ol>
                        </div>
                        <nav aria-label="breadcrumb">
                            {/* <Link to="/pages/hr/branch" className="btn btn-danger mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link> */}
                        </nav>
                    </div>


                    <div className="row"
                    // style={{
                    //     boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    //     borderRadius: "10px",
                    //     overflow: "hidden",
                    // }}
                    >
                        {/* <div className="col-md-12">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <h2>Employee profile</h2>
                                <div className="container mt-5">

                                </div>ṣ
                            </div>
                        </div> */}
                        <div className="col-md-12" >
                            <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none" }}>
                                <CardBody>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-pencil-fill edit ebtn"
                                        viewBox="0 0 16 16"
                                        data-id={state._id}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                    </svg>
                                    <div className="d-flex">
                                        <div style={{ borderBlockColor: "black", background: "white", display: "flex", justifyContent: "center", alignSelf: "center" }} className="left">
                                            {/* <img className='rounded' src='img05' /> */}
                                            <img
                                                src={img05}
                                                alt="User Profile"
                                                className="img-fluid rounded-circle"
                                                style={{ width: "252px", height: "163px" }}
                                            />
                                        </div>

                                        <div className="right ms-5">
                                            <h2>{state.first_name} {state.last_name}</h2>
                                            <h6>{state.designation}</h6>
                                            <div className="d-flex">
                                                <div className='me-5'>
                                                    <h6 className='mt-3'>Employee ID</h6>
                                                    <h6 className='mt-3'>Date of Joining</h6>
                                                    <h6 className='mt-3'>Email Id </h6>
                                                    <h6 className='mt-3'>Phone no</h6>
                                                </div>
                                                <div>
                                                    <h6 className='mt-3'>4653564</h6>
                                                    <h6 className='mt-3'>{state.joining_date}</h6>
                                                    <h6 className='mt-3'>{state.email}</h6>
                                                    <h6 className='mt-3'>{state.phone}</h6>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-12 mt-2">
                            <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden", border: "none" }}>
                                <CardBody>
                                    <ul className="nav nav-tabs ">
                                        {/* <li className="nav-item">
                                            <a className={`nav-link ${activeTab === 'personal_information' ? 'active border border-grey text-decoration-none' : ''} `} onClick={() => setActiveTab('personal_information')} href="javascript:void(0)">Personal Information</a>
                                        </li> */}
                                        <li className="nav-item">
                                            <a className={`nav-link ${activeTab === 'bank_details' ? 'active border border-grey text-decoration-none' : ''} `} onClick={() => setActiveTab('bank_details')} href="javascript:void(0)">Bank Details</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${activeTab === 'emergency_contact' ? 'active border border-grey text-decoration-none' : ''} `} onClick={() => setActiveTab('emergency_contact')} href="javascript:void(0)">Emergency Contact</a>
                                        </li>

                                    </ul>
                                    {renderTabContent()}



                                </CardBody>
                            </Card>
                        </div>




                    </div>

                </div>
            </div>
        </React.Fragment>
    );
};

export default Empprofile;



