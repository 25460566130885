import React, { useEffect, useState, useRef, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Button, Card, Col, Row, Nav, Table, ProgressBar, Form, Modal } from "react-bootstrap";
import _debounce from 'lodash/debounce';

import Header from "../layouts/Header.js";
import Footer from "../layouts/Footer.js";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "../components/Avatar.js";
import { Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import ReactApexChart from "react-apexcharts";


import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img12 from "../assets/img/img12.jpg";
import img15 from "../assets/img/img15.jpg";
import img16 from "../assets/img/img16.jpg";
import img17 from "../assets/img/img17.jpg";
import img18 from "../assets/img/img18.jpg";

// files for current time clock
// import App from './App.js';
import "../assets/css/clockstyle.css";
import Clock from "../clock.js";
// import Upcomingbirthday from "./upcomingbirthday.jsx";
import { basicSetup, dateviewemployeebreaklist, getBasicSetup, getemployeedatewiseattendance, getId, searchEmployeeAttendance, searchEmployeeBreak, viewAddEmployeeLeave, viewEmployee, viewEmployeehistory } from "../services/api/api.js";



export default function AdminDashboard() {
  const navigate = useNavigate();
  const [showModalpresentlist, setShowModalpresentlist] = useState(false);
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [showAbsentList, setShowAbsentList] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceDatapresentlist, setAttendanceDatapresentlist] = useState([]);
  const [attendanceDataAbsentlist, setAttendanceDataAbsentlist] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [checkedInEmployees, setCheckedInEmployees] = useState([]);
  const [currentEmployeesCount, setCurrentEmployeesCount] = useState(0);
  const [setupItems, setSetupItems] = useState({});

  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedDateFilter, setSelectedDateFilter] = useState(new Date());

  const [breakcountData, setBreakcountData] = useState([]);
  const reversedRows = [...breakcountData];
  const [showModalbreak, setShowModalbreak] = useState(false);
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filteredBreakData, setFilteredBreakData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [flag, setFlag] = useState(false);
  const [searchParams, setSearchParams] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });








  const handlePresentButtonClick = () => {
    setShowEmployeeList(true);
    setShowAbsentList(false);
  };

  const handleAbsentButtonClick = () => {
    setShowEmployeeList(false);
    setShowAbsentList(true);
  };



  //Current Ticket Status
  const dataBar1 = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: '#506fd9',
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: '#85b6ff',
      barPercentage: 0.5
    }, {
      data: [8, 30, 40, 35, 40, 45, 35, 30, 25, 10, 20, 15],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const optionBar1 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 80,
        ticks: {
          color: '#a1aab3',
          font: {
            size: 10
          }
        },
        grid: {
          borderColor: '#e2e5ec',
          borderWidth: 1.5,
          color: 'rgba(65,80,95,.08)'
        }
      },
      x: {
        ticks: {
          color: '#313c47'
        },
        grid: {
          color: 'rgba(65,80,95,.08)'
        }
      }
    }
  };

  const dataBar2 = {
    labels: ['Modification', 'Code Request', 'Feature Request', 'Bug Fix', 'Integration', 'Production'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 75],
      backgroundColor: ['#506fd9', '#85b6ff', '#33d685', '#ffc107', '#0dcaf0', '#ea4c89'],
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const optionBar2 = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: '#e2e5ec',
          color: '#f3f5f9'
        },
        ticks: {
          font: {
            size: 11
          }
        }
      },
      y: {
        grid: {
          borderWidth: 0,
          color: '#f3f5f9'
        },
        ticks: {
          color: '#465463',
          font: {
            size: 13
          }
        }
      }
    }
  };



  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true,
      sparkline: { enabled: true }
    },
    colors: ['#506fd9'],
    stroke: {
      curve: 'straight',
      width: 2
    },
    xaxis: { max: 40 },
    yaxis: {
      min: 0,
      max: 80
    },
    fill: {
      type: 'solid',
      opacity: 0.2
    },
    tooltip: { enabled: false }
  }

  // Time to Resolved Complain
  const optionDonut1 = {
    chart: { parentHeightOffset: 0 },
    colors: ['#506fd9', '#e5e9f2'],
    dataLabels: { enabled: false },
    legend: { show: false }
  };

  const optionDonut2 = {
    chart: { parentHeightOffset: 0 },
    colors: ['#4c5366', '#e5e9f2'],
    dataLabels: { enabled: false },
    legend: { show: false }
  }

  // Agent Performance Score
  const optionTwo = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true
    },
    colors: ['#4f6fd9'],
    grid: {
      borderColor: 'rgba(72,94,144, 0.08)',
      padding: {
        top: -20,
        left: 0,
        right: -8
      },
      yaxis: {
        lines: { show: false }
      }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 1.5
    },
    xaxis: {
      overwriteCategories: ['', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC', ''],
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '10px'
        }
      },
    },
    yaxis: {
      min: 0,
      max: 50,
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    legend: { show: false },
    tooltip: { enabled: false }
  }

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);


  const [employeehistoryData, setEmployeehistoryData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [totalActiveEmployees, setTotalActiveEmployees] = useState(0);
  const [totalDeletedEmployees, setTotalDeletedEmployees] = useState(0);

  const fetchtotalempData = async () => {
    try {
      setLoading(true);
      const formData = {};
      const response = await viewEmployeehistory(formData);

      console.log(response);
      if (!response || !response.success) {
        throw new Error("Failed to fetch data or empty response.");
      }

      const employeeData = response.success;
      setEmployeehistoryData(employeeData);

      // Calculate total active employees
      const activeEmployees = employeeData.filter(employee => employee.delete_status === "NO");


      // Calculate total deleted employees
      const deletedEmployees = employeeData.filter(employee => employee.delete_status === "YES");


      setTotalActiveEmployees(activeEmployees.length);
      setTotalDeletedEmployees(deletedEmployees.length);

      console.log("Fetched Employee Data:", employeeData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    setupData()
  }, [flag])

  // const setupData = async () => {
  //   try {
  //     setLoading(true);

  //     const response = await getBasicSetup();
  //     console.log(response);

  //     // Storing only the 'basicSetup' part of the response in sessionStorage
  //     if (response && response.success && response.data.length > 0) {
  //       const basicSetupData = response.data[0].basicSetup;

  //       // Correct usage of sessionStorage.setItem
  //       sessionStorage.setItem('setupData', JSON.stringify(basicSetupData));
  //     }

  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const setupData1 = async () => {
    try {
      setLoading(true);

      const response = await getBasicSetup();
      console.log(response);

      if (response && response.success && response.data.length > 0) {
        const basicSetupData = response.data[0].basicSetup;

        // Store the data in sessionStorage
        sessionStorage.setItem('setupData', JSON.stringify(basicSetupData));

        // Check if all keys except 'allDone' have a value of "true"
        const allKeysExceptAllDoneTrue = Object.keys(basicSetupData).every(
          key => key === 'allDone' || basicSetupData[key] === 'true'
        );



        // Log or use the result as needed
        if (allKeysExceptAllDoneTrue) {
          console.log('All keys except "allDone" have a value of "true".');
          const storedSetupData = JSON.parse(sessionStorage.getItem('setupData'));

          // Apply the spread operator to keep the stored values, but update `companyProfile`
          const updateProfileData = {
            ...storedSetupData, // Spread the stored values
            allDone: "true", // Update companyProfile field
          };
          console.log(updateProfileData);


          // Second API call with updated data
          const secondResponse = await basicSetup(updateProfileData);
          console.log(secondResponse);
        } else {
          setShowSetupModal(true)

          console.log('Not all keys except "allDone" have a value of "true".');
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const setupData = async () => {
    try {
      // Set loading state if needed
      // setLoading(true);

      const response = await getBasicSetup();
      console.log(response);

      if (response && response.success && response.data.length > 0) {
        const basicSetupData = response.data[0].basicSetup;

        // Store the data in sessionStorage
        sessionStorage.setItem('setupData', JSON.stringify(basicSetupData));

        // Filter the setup items with false values (excluding 'allDone')
        const falseItems = Object.keys(basicSetupData)
          .filter(key => key !== 'allDone' && basicSetupData[key] === 'false')
          .reduce((obj, key) => {
            obj[key] = basicSetupData[key];
            return obj;
          }, {});

        setSetupItems(falseItems);

        // Check if all keys except 'allDone' have a value of "true"
        const allKeysExceptAllDoneTrue = Object.keys(basicSetupData).every(
          key => key === 'allDone' || basicSetupData[key] === 'true'
        );

        if (allKeysExceptAllDoneTrue) {
          console.log('All keys except "allDone" have a value of "true".');
          const storedSetupData = JSON.parse(sessionStorage.getItem('setupData'));

          // Apply the spread operator to keep the stored values, but update `allDone`
          const updateProfileData = {
            ...storedSetupData, // Spread the stored values
            allDone: "true", // Update 'allDone' field
          };
          console.log(updateProfileData);

          // Second API call with updated data
          // const secondResponse = await basicSetup(updateProfileData);
          // console.log(secondResponse);
        } else {
          setShowSetupModal(true);
          console.log('Not all keys except "allDone" have a value of "true".');
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Reset loading state if needed
      // setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchempData = _debounce(fetchtotalempData, 100);
    debouncedFetchempData();

    return () => {
      debouncedFetchempData.cancel();
    };
  }, []);


  const handleModalClosebreak = () => {
    setShowModalbreak(false);
  };

  const handleModalCloseSetup = () => {
    setShowSetupModal(false);
  };

  const [onLeaveEmployeesCount, setOnLeaveEmployeesCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [masterId, setMasterId] = useState(null);

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = async () => {
    try {
      setLoading(true);
      const formattedData = await viewAddEmployeeLeave();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const masterData = formattedData[0];
      const employeeLeaveData = masterData.employee_leave;

      setMasterId(masterData._id);

      const addEmployeeLeaveArray = Object.values(employeeLeaveData).map((item, index) => ({
        id: item._id,
        serial: index + 1,
        employee_id: item.employee_id,
        employee_name: item.employee_name || 'N/A',
        leave_type: item.leave_type || 'N/A',
        from_date: item.from_date || 'N/A',
        to_date: item.to_date || 'N/A',
        total_days: item.total_days || 'N/A',
        remaining_leaves: item.remaining_leaves !== null ? item.remaining_leaves.toString() : '0',
        leave_reason: item.leave_reason || 'N/A',
      }));

      setLeaveData(addEmployeeLeaveArray);
      setOnLeaveEmployeesCount(addEmployeeLeaveArray.length); // Store the count of on-leave employees
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is done
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const columns = [
    // { field: 'employee_id', headerName: 'ID', width: 100 },
    { field: 'serial', headerName: 'Sr. No.', width: 120 },
    { field: 'employee_name', headerName: 'Name', width: 200 },
    { field: 'leave_type', headerName: 'Type', width: 150 },
    { field: 'from_date', headerName: 'From', width: 150 },
    { field: 'to_date', headerName: 'To', width: 150 },
    { field: 'total_days', headerName: 'Days', width: 100 },
  ];












  function getFormattedDateTime() {
    const now = new Date();

    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear().toString().slice(-2);

    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    console.log(formattedDate, formattedTime);


    return `${formattedDate} ${formattedTime}`;
  }

  useEffect(() => {
    setCurrentDateTime(getFormattedDateTime());
  }, []);

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [searchParams, selectedMonth, selectedYear, currentDateTime]);

  const fetchData = async () => {
    try {
      setLoading(true);
      // const currentDate = new Date().toISOString().slice(0, 10);
      const now = new Date();
      const currentDate = `${now.getDate().toString().padStart(2, '0')}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getFullYear()}`;
      console.log(currentDateTime);

      const apiResponse = await getemployeedatewiseattendance(currentDateTime);
      console.log("apiResponse", apiResponse);

      if (
        !apiResponse
        // ||
        // !apiResponse.data ||
        // !apiResponse.data.attendance_list ||
        // !apiResponse.data.attendance_list.length
      ) {
        setAttendanceData([]);
        return;
      }

      const attendanceList = apiResponse.data.attendance_list;
      console.log(attendanceList);
      const formattedData = attendanceList.map((employee) => {
        console.log(employee);
        return {
          id: employee.id,
          name: employee.name,
          attendances: employee.attendance.map((attendance) => {
            console.log(attendance);
            return {
              date: attendance.date,
              status: attendance.status,
              time: attendance.time,
              // IN: attendance.IN,
              // OUT: attendance.OUT,
              // attendanceId: attendance.attendanceId,
              // name: employee.name,
            };
          }),
        };
      });

      setAttendanceData(formattedData);

      setTimeout(() => {

        console.log("formattedData", formattedData);
      }, 5000)

      console.log(currentDate);

      // const presentEmployees = formattedData.filter((employee) => {
      //   const todayAttendance = employee.attendances.find(
      //     (attendance) => attendance.date == currentDate
      //   );
      //   // return todayAttendance && todayAttendance.IN;
      //   return todayAttendance;
      // });
      // console.log(presentEmployees);
      // console.log(presentEmployees.length);
      // setPresentCount(presentEmployees.length);

      const presentEmployees = formattedData.filter((employee) => {
        const todayAttendance = employee.attendances.find(
          (attendance) => attendance.date === currentDate && attendance.status === 1
        );
        return todayAttendance;
      });

      console.log(presentEmployees);
      console.log(presentEmployees.length);
      setPresentCount(presentEmployees.length);


      setAttendanceDatapresentlist(presentEmployees);

      const formData = {};
      const response = await viewEmployee(formData);
      console.log("response", response);
      if (!response || !response.success || !response.data) {
        throw new Error("Failed to fetch data.");
      }

      setEmployeehistoryData(response.totalEmployee);
      const mappedEmployees = response.data.map((item, index) => ({
        id: item.employeeData._id,
        serial: index + 1,
        firstName: item.employeeData.first_name,
        lastName: item.employeeData.last_name,
      }));

      const absentEmployees = mappedEmployees.filter((employee) => {
        return !presentEmployees.find((emp) => emp.id === employee.id);
      });
      console.log(absentEmployees);
      console.log(absentEmployees.length);

      setAttendanceDataAbsentlist(absentEmployees);
      setAbsentCount(absentEmployees.length);


      // Update the checked-in status for each employee for the current date
      const checkedInEmployeeIds = presentEmployees.map(
        (employee) => employee.id
      );
      setCheckedInEmployees(checkedInEmployeeIds);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  const filterRows = () => {
    if (!searchText && !selectedDateFilter) {
      return reversedRows.map((row, index) => ({ ...row, id: index + 1 }));
    }

    return reversedRows
      .filter((row) =>
        Object.values(row).some(
          (value) =>
            typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
        ) &&
        (!selectedDateFilter || row.date === selectedDateFilter.toISOString().split('T')[0])
      )
      .map((row, index) => ({ ...row, id: index + 1 }));
  };
  const calculateTotalDuration = (breaks) => {
    const totalDuration = breaks.reduce((total, breakItem) => total + breakItem.duration_minutes, 0);
    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;
    return `${hours}h ${minutes}m`;
  };

  const formatTime = (time) => {
    const formattedTime = new Date(`2022-01-01 ${time}`);
    return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  // const fetchBreakData = async (date) => {
  //   try {
  //     const formattedDate = formatDateString(date);
  //     setLoading(true);
  //     const year = date.getFullYear();
  //     const month = date.getMonth() + 1;
  //     const formattedData = await dateviewemployeebreaklist(formattedDate);

  //     console.log(formattedData);

  //     const breakList = formattedData.data.break_list;
  //     console.log(breakList);

  //     const branchArray = breakList.flatMap((item, index) =>
  //       Object.entries(item.attendance).map(([date, attendanceData]) => {
  //         // Get the type of the last break item
  //         const lastBreak = attendanceData.breaks.slice(-1)[0];
  //         const lastBreakType = lastBreak ? lastBreak.type : null;

  //         return {
  //           id: `${index}_${date}`,
  //           date: attendanceData.date,
  //           total_minutes: attendanceData.total_minutes,
  //           name: attendanceData.name,
  //           type: lastBreakType, // Use the type of the last break
  //           time: lastBreak ? formatTime(lastBreak.time) : null,
  //           // breaks: attendanceData.breaks.map(breakItem => ({
  //           //   type: breakItem.type,
  //           //   time: formatTime(breakItem.time),
  //           //   duration_minutes: breakItem.duration_minutes || 0,
  //           // })),
  //           breaks: attendanceData[lastBreak]
  //         };
  //       })
  //     );


  //     console.log(branchArray);

  //     setBreakcountData(branchArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchBreakData = async (date) => {
    try {
      const formattedDate = formatDateString(date);
      setLoading(true);
      const formattedData = await dateviewemployeebreaklist(formattedDate);

      const breakList = formattedData.data.break_list;

      const branchArray = breakList.flatMap((item, index) =>
        Object.entries(item.attendance).flatMap(([date, attendanceData]) => {
          const rows = [];
          let currentRow = {
            id: `${index}_${date}`,
            date: attendanceData.date,
            total_minutes: attendanceData.total_minutes,
            name: attendanceData.name,
            breaksIn: [],
            breaksOut: [],
            totalTime: 0, // Total time in minutes
            totalTimeFormatted: '0h 0m', // Formatted total time
            status: '', // New field for status
          };

          attendanceData.breaks.forEach((breakItem, breakIndex) => {
            if (breakItem.type === "IN") {
              currentRow.breaksIn.push(breakItem);
            }

            if (breakItem.type === "OUT") {
              currentRow.breaksOut.push(breakItem);

              // Convert IN and OUT times to Date objects for calculation
              const inTime = currentRow.breaksIn[currentRow.breaksIn.length - 1]?.time;
              const outTime = breakItem.time;

              if (inTime && outTime) {
                const inMinutes = convertToMinutes(inTime);
                const outMinutes = convertToMinutes(outTime);
                const difference = outMinutes - inMinutes; // Difference in minutes
                currentRow.totalTime += difference; // Add the difference to total time
              }

              // Convert total minutes to hours and minutes
              const { hours, minutes } = convertToHoursAndMinutes(currentRow.totalTime);
              currentRow.totalTimeFormatted = `${hours}h ${minutes}m`;

              // Push the row for OUT breaks
              rows.push({
                ...currentRow,
                status: 'IN', // Status is set to 'IN' if there's an OUT break
              });

              // Prepare new row for potential next IN breaks
              currentRow = {
                id: `${index}_${date}_${breakIndex + 1}`,
                date: attendanceData.date,
                total_minutes: attendanceData.total_minutes,
                name: attendanceData.name,
                breaksIn: [],
                breaksOut: [],
                totalTime: 0, // Reset total time
                totalTimeFormatted: '0h 0m', // Reset formatted total time
                status: '', // New field for status
              };
            }
          });

          // Final row determination
          if (currentRow.breaksIn.length > 0 || currentRow.breaksOut.length > 0) {
            // Convert final total minutes to hours and minutes
            const { hours, minutes } = convertToHoursAndMinutes(currentRow.totalTime);
            rows.push({
              ...currentRow,
              status: currentRow.breaksOut.length > 0 ? 'IN' : 'OUT', // Status is 'IN' if there's an OUT break, otherwise 'OUT'
              totalTimeFormatted: `${hours}h ${minutes}m`,
            });
          } else if (currentRow.breaksIn.length > 0) {
            rows.push({
              ...currentRow,
              status: 'OUT',
              totalTimeFormatted: '0h 0m',
            });
          }

          return rows;
        })
      );

      setBreakcountData(branchArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const convertToMinutes = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const convertToHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  };


  useEffect(() => {
    if (selectedDateFilter == null) {

      fetchBreakData(selectedDate);
    }
    else {
      fetchBreakData(selectedDateFilter);
    }

    return () => { };
  }, [selectedDateFilter]);

  // const fetchBreakData = async (date) => {
  //   try {
  //     setLoading(true);
  //     const year = date.getFullYear();
  //     const month = date.getMonth() + 1;
  //     const formattedData = await searchEmployeeBreak(year, month);

  //     if (!formattedData || !formattedData.data.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const breakList = formattedData.data[0].break_list;
  //     console.log(breakList);


  //     const branchArray = breakList.flatMap((item, index) =>
  //       Object.entries(item.attendance).map(([date, attendanceData]) => ({
  //         id: `${index}_${date}`,
  //         date: attendanceData.date,
  //         total_minutes: attendanceData.total_minutes,
  //         name: attendanceData.name,
  //         type: attendanceData.breaks[0].type,
  //         time: formatTime(attendanceData.breaks[0].time),
  //         breaks: attendanceData.breaks.map(breakItem => ({
  //           type: breakItem.type,
  //           time: formatTime(breakItem.time),
  //           duration_minutes: breakItem.duration_minutes || 0,
  //         })),
  //       }))
  //     );
  //     console.log(branchArray);

  //     setBreakcountData(branchArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (selectedDateFilter == null) {

  //     fetchBreakData(selectedDate);
  //   }
  //   else {
  //     fetchBreakData(selectedDateFilter);
  //   }

  //   return () => { };
  // }, [selectedDateFilter]);

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setShowSecondModal(true);
  };
  const handleSecondModalClose = () => {
    setShowSecondModal(false);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterCurrentDateBreaks = () => {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];

    const filteredData = breakcountData
      .filter((row) =>
        row.date === formattedCurrentDate &&
        row.breaks.some((breakItem) => breakItem.type === 'IN') &&
        !row.breaks.some((breakItem) => breakItem.type === 'OUT' && !breakItem.duration_minutes) &&
        row.breaks[row.breaks.length - 1]?.type !== 'OUT'
      );

    setFilteredBreakData(filteredData);
  };
  const datepickerRef = useRef(null);

  // const columnsNew = [
  //   { field: 'date', headerName: 'Date', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
  //   { field: 'name', headerName: 'Name', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
  //   {
  //     field: 'total_minutes',
  //     headerName: 'Total Break Duration(in Minutes)',
  //     width: 50,
  //     align: 'center',
  //     headerAlign: 'center',
  //     flex: 2,
  //     valueFormatter: (params) => {
  //       const value = params.value;
  //       return value ? value.toFixed(2) : '0.00';
  //     },
  //   },
  //   {
  //     field: 'status',
  //     headerName: 'Status',
  //     width: 100,
  //     align: 'center',
  //     headerAlign: 'center',
  //     flex: 2,
  //     renderCell: (params) => (
  //       // console.log(params)

  //       <div className={`status-${params.row.type}`}>{params.row.type}</div>
  //     ),
  //   },
  // ];

  const columnsNew = [
    { field: 'date', headerName: 'Date', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
    { field: 'name', headerName: 'Name', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
    {
      field: 'breaksIn',
      headerName: 'IN Breaks',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      flex: 3,
      renderCell: (params) => (
        <div>
          {params.row.breaksIn.map((breakItem, index) => (
            <div key={index} style={{ color: 'green' }}>
              {breakItem.time}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'breaksOut',
      headerName: 'OUT Breaks',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      flex: 3,
      renderCell: (params) => (
        <div>
          {params.row.breaksOut.map((breakItem, index) => (
            <div key={index} style={{ color: 'red' }}>
              {breakItem.time}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const status = params.value;
        const statusColor = status === 'OUT' ? 'red' : 'green';
        return <span style={{ color: statusColor, fontWeight: 'bold' }}>{status}</span>;
      },
    },
    {
      field: 'totalTimeFormatted',
      headerName: 'Total Time (h m)',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <span>{params.row.totalTimeFormatted}</span>, // Render formatted total time
    },
  ];

  useEffect(() => {
    if (selectedDateFilter == null) {

      fetchBreakData(selectedDate);
    }
    else {
      fetchBreakData(selectedDateFilter);
    }

    return () => { };
  }, [selectedDateFilter]);

  // useEffect(() => {
  //   filterCurrentDateBreaks();
  // }, [breakcountData]);




  const handleClick = (event) => {
    const action = event.target.getAttribute('data-action');
    if (action == "companyProfile") {
      navigate('/companyprofile')
    }
    if (action == "companyTime") {
      navigate('/pages/hr/shiftsform')
    }
    if (action == "companyBranch") {
      navigate('/pages/hr/branchform')
    }
    if (action == "companyLogo") {
      navigate('/companylogo')
    }
    if (action == "workingDays") {
      navigate('/pages/workingdays/addworkingdays')
    }
    if (action == "department") {
      navigate('/admin/adddepartment')
    }
    if (action == "designation") {
      navigate('/admin/designation')
    }
  };

  // Example usage
  <button className="btn-primary" data-action="setup" onClick={handleClick}>Setup</button>


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="/admin/dashboard">Dashboard</Link></li>
              {/* <li className="breadcrumb-item active" aria-current="page">Helpdesk Service</li> */}
            </ol>
            <h4 className="main-title mb-0">Welcome to Dashboard</h4>
          </div>
          <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <Button variant="white" className="btn-icon"><i className="ri-share-line fs-18 lh-1"></i></Button>
            <Button variant="white" className="btn-icon"><i className="ri-printer-line fs-18 lh-1"></i></Button>
            <Button variant="primary" className="btn-icon"><i className="ri-bar-chart-2-line fs-18 lh-1"></i></Button>
          </div>
        </div>

        <Row className="g-3">
          <div class="row">
            <div class="col-sm-3">
              <div class="card bg-primary text-white mb-3">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="helpdesk-icon"><i class="ri-bell-line"></i></div>
                    <div class="ms-3">
                      {/* <h2 class="card-value mb-0" style={{ color: 'white' }}>{currentEmployeesCount}</h2> */}
                      <div>
                        {loading ? (
                          <div className="loader-containerx">
                            <div className="loaderx"></div>
                          </div>
                        ) : (
                          <h2 class="card-value mb-0" style={{ color: 'white' }}>{totalActiveEmployees}</h2>
                        )}
                      </div>
                      {/* <small class="text-success">+3.82%</small> */}
                      <p class="card-label mb-0">Current Employee</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card bg-ui-02 text-white mb-3">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="helpdesk-icon"><i class="ri-blaze-fill"></i></div>
                    <div class="ms-3">
                      {/* <h2 class="card-value mb-0" style={{ color: 'white' }}>2</h2> */}
                      {/* <h2 className="card-value mb-0" style={{ color: 'white' }}>
                        {totalActiveEmployees + totalDeletedEmployees}
                      </h2> */}
                      <div>
                        {loading ? (
                          <div className="loader-containerx">
                            <div className="loaderx"></div>
                          </div>
                        ) : (
                          <h2 className="card-value mb-0" style={{ color: 'white' }}>
                            {totalActiveEmployees + totalDeletedEmployees}
                          </h2>
                        )}
                      </div>

                      {/* <small class="text-danger">-0.08%</small> */}
                      <p class="card-label mb-0">Total Employee</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="card bg-ui-03 text-white mb-3">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="helpdesk-icon"><i class="ri-star-smile-line"></i></div>
                    <div class="ms-3">
                      {/* <h2 class="card-value mb-0" style={{color:'white'}}>198</h2> */}
                      <h3 className="mb-0" style={{ color: 'white' }}>
                        <span style={{ color: 'green' }} >P</span> - {presentCount}
                        <span style={{ color: 'red' }}>A</span> - {absentCount}
                      </h3>

                      {/* <small class="text-danger">-0.03%</small> */}
                      {/* <p class="card-label mb-0">Present/Absent</p> */}
                      {/* <Button className='' onClick={() => setShowModalpresentlist(true)}>
                        Present/Absent
                      </Button> */}
                      <span
                        onClick={() => setShowModalpresentlist(true)}
                        style={{ cursor: 'pointer' }}
                      >
                        Present/Absent
                      </span>


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card bg-ui-02 text-white mb-3" onClick={handleShowModal} style={{ cursor: 'pointer' }}>
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="helpdesk-icon"><i className="ri-blaze-fill"></i></div>
                    <div className="ms-3">
                      <div>
                        {loading ? (
                          <div className="loader-containerx">
                            <div className="loaderx"></div>
                          </div>
                        ) : (
                          <h2 className="card-value mb-0" style={{ color: 'white' }}>
                            {onLeaveEmployeesCount}
                          </h2>
                        )}
                      </div>
                      <p className="card-label mb-0">On Leave Employees</p>
                    </div>
                  </div>
                </div>
              </div>

              <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                  <Modal.Title>On Leave Employees</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  {/* {leaveData.length > 0 ? (
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={leaveData}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                            />
                        </div>
                    ) : (
                        <p>No employees are currently on leave.</p>
                    )} */}
                  {loading ? (
                    <p>Loading...</p>
                  ) : leaveData.length > 0 ? (
                    <div style={{ height: 400, width: '100%' }}>
                      <DataGrid
                        rows={leaveData}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </div>
                  ) : (
                    <p>No employees are currently on leave.</p>
                  )}

                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="col-sm-3">



            </div>
          </div>

          {/* {[
            {
              "bg": "primary",
              "icon": "ri-bell-line",
              "value": '3',
             
              "success": true,
              "label": "Current Employee",
              "avatar": [img10, img11, img9, img8],
             
            }
            , {
              "bg": "ui-02",
              "icon": "ri-blaze-fill",
              "value": "40",
             
              "success": false,
              "label": "Total Employee",
              "avatar": [img15, img16, img17, img18],
              
            }, {
              "bg": "ui-03",
              "icon": "ri-star-smile-line",
              "value": "198",
              "success": false,
              "label": "Attandance",
              "avatar": [img7, img8, img9, img10],
            }
          ].map((item, index) => (
            <Col sm="4" key={index}>
              <Card className="card-one">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className={"helpdesk-icon text-white bg-" + item.bg}><i className={item.icon}></i></div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h2 className="card-value d-flex align-items-baseline mb-0">{item.value} <small className={"text-" + (item.success ? "success" : "")}>{item.percent}</small></h2>
                      <label className="card-label fs-sm fw-medium mb-1">{item.label}</label>
                      <div className="mutual-badge">
                        <ul>
                          {item.avatar.map((avatar, ind) => (
                            <li key={ind}><Avatar img={avatar} /></li>
                          ))}
                        </ul>
                        
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))} */}
          <Col md="7" xl="8">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Current Employee</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="chartjs-one">
                  <Bar data={dataBar1} options={optionBar1} />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="5" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Current Time</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                {/* <div className="position-absolute p-4">
                  <h1 className="card-value d-flex align-items-baseline mb-0">268</h1>
                  <p className="fs-xs mb-2"><span className="text-success fs-numerals">0.3%</span> higher than last month</p>
                  <p className="fs-sm">The total number of complaints that have been received.</p>
                </div> */}
                {/* <ReactApexChart series={seriesOne} options={optionOne} type="area" height={280} /> */}
                {/*********clock code start from here **********/}
                <div className="App">
                  <Clock />
                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md="6">
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center">
                  <Col>
                    <div className="apex-donut-one">
                      <ReactApexChart series={[86, 14]} options={optionDonut1} width={160} height={160} type="donut" />
                      <div>
                        <h4 className="ff-numerals text-dark mb-0">86%</h4>
                        <span className="fs-xs text-secondary">Reached</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" xl="7">
                    <h2 className="card-value mb-3"></h2>
                    <h6 className="card-label text-dark fw-semibold mb-1">Total attendance</h6>
                    <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center">
                  <Col>
                    <div className="apex-donut-one">
                      <ReactApexChart series={[68, 32]} options={optionDonut2} width={160} height={160} type="donut" />
                      <div>
                        <h4 className="ff-numerals text-dark mb-0">68%</h4>
                        <span className="fs-xs text-secondary">Reached</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" xl="7">
                    <h2 className="card-value mb-3"></h2>
                    <h6 className="card-label text-dark fw-semibold mb-1">Your attendance</h6>
                    <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col> */}

          <Col md="7" xl="8">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Break History</Card.Title>
                <div className="d-flex align-items-center col-12 col-md-6" style={{ marginLeft: '20px', color: "white" }}>
                  <button className="btn btn-primary w-100" onClick={() => setShowModalbreak(true)}>
                    On a break employees
                  </button>
                </div>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>





              <Card.Body>
                <div className="input-group">

                  <DatePicker
                    selected={selectedDateFilter}
                    // onChange={(date) => setSelectedDateFilter(date)}
                    onChange={(date) => {
                      setSelectedDateFilter(date);
                      fetchBreakData(date);
                    }}
                    dateFormat="MM/dd/yyyy"
                    className="form-control"
                    ref={datepickerRef}
                  />
                </div>
                <br />

                <div className="d-flex align-items-center mb-2 mb-md-0 mr-2 col-12 col-md-6">

                </div>
                {loading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                ) : (
                  // <div style={{ height: 400, width: '100%' }}>
                  //   <DataGrid
                  //     rows={filterRows()}
                  //     columns={columnsnew}
                  //     pageSize={5}
                  //     // onRowClick={handleRowClick}
                  //     components={{
                  //       NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  //     }}
                  //   />
                  // </div>

                  <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                      rows={reversedRows}
                      columns={columnsNew}
                      pageSize={5}
                      onRowClick={handleRowClick}
                      components={{
                        NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                      }}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md="5" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Upcoming Birthday</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {/* <Upcomingbirthday /> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>

      <Modal
        show={showModalpresentlist}
        onHide={() => setShowModalpresentlist(false)}
      >
        <Modal.Header closeButton className="modalHeader">
          <Modal.Title style={{ color: "#FFFFFF" }}>Employee Attendance List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="newbtn11">
            <button
              className="btn btnnew-light"
              onClick={handlePresentButtonClick}
            >
              Present List
            </button>
            <button
              className="btn btnnew-light"
              onClick={handleAbsentButtonClick}
            >
              Absent List
            </button>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {showEmployeeList && (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {attendanceDatapresentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>{employee.name}</td>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>
                          <span style={{ backgroundColor: 'green', color: 'white', borderRadius: '5px', padding: '3px 8px' }}>Present</span>
                        </td>
                      </tr>
                    ))} */}
                    {attendanceDatapresentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {employee.name}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              borderRadius: "5px",
                              padding: "3px 8px",
                            }}
                          >
                            Present
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {showAbsentList && (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceDataAbsentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {employee.firstName} {employee.lastName}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "5px",
                              padding: "3px 8px",
                            }}
                          >
                            Absent
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalpresentlist(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showModalbreak} onHide={handleModalClosebreak} >
        <Modal.Header closeButton>
          <Modal.Title>Filtered Break Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filteredBreakData.length > 0 ? (
            <>
              <p>The employees is on a break now</p>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    {/* Add more headers for additional fields */}
                  </tr>
                </thead>
                <tbody>
                  {filteredBreakData.map((rowData) => (
                    <tr key={rowData.id}>
                      <td>{rowData.date}</td>
                      <td>{rowData.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{ textAlign: 'center', padding: '20px' }}>No employees on a break</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClosebreak}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={showSetupModal} onHide={handleModalCloseSetup} >
        <Modal.Header closeButton>
          <Modal.Title>Get Started</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ul className="activity-group mb-5">
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Company Profile</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="companyProfile" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center">
                <span className="fs-sm">
                  <strong>Company Time</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="companyTime" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Company Branch</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="companyBranch" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Company Logo</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="companyLogo" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Working Days</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="workingDays" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Department</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="department" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Designation</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="designation" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
          </ul>
        </Modal.Body>






        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCloseSetup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={showSetupModal} onHide={handleModalCloseSetup}>
        <Modal.Header closeButton>
          <Modal.Title>Get Started</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="activity-group mb-5">
            {Object.keys(setupItems).map(key => (
              <li className="activity-item like" key={key}>
                <p className="d-sm-flex align-items-center mb-0">
                  <span className="fs-sm">
                    <strong>{key.replace(/([A-Z])/g, ' $1').trim()}</strong>
                  </span>
                  <span className="fs-xs text-secondary ms-auto">
                    <button className="btn-primary" data-action={key} onClick={handleClick}>
                      Setup
                    </button>
                  </span>
                </p>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCloseSetup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}