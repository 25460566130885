
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import { basicSetup, editCompanyprofile, getcompanyId, updateCompanyprofile } from '../../services/api/api';
import { updateEmployee } from '../../services/api/api';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';



const Companyprofile = () => {
    const navigate = useNavigate();
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const currentPath = window.location.pathname;
    const parts = currentPath.split('/');
    // const id = parts[parts.length - 1];
    const [loading, setLoading] = useState(true);
    const [masterId, setMasterId] = useState();
    // const { id } = useParams();
    const { id } = useParams();
    const [companyId, setcompanyId] = useState('');
    useEffect(() => {
        const companyId = getcompanyId();
        setcompanyId(companyId);
    }, []);



    console.log(companyId);
    const handlefaxChange = (val) => {
        setState((prevState) => ({
            ...prevState,
            fax: val,
        }));
    };

    const handleCountryChange = (val) => {
        setState((prevState) => ({
            ...prevState,
            country: val,
            // Reset the region when the country changes if needed
            state: '',
        }));
    };

    const handleRegionChange = (val) => {
        setState((prevState) => ({
            ...prevState,
            state: val,
        }));
    };

    // .
    const [state, setState] = useState({
        _id: '',
        company_id: '',
        company_name: '',
        admin_name: '',
        company_address: '',
        country: '',
        fax: '',
        city: '',
        state: '',
        pincode: '',
        company_email: '',
        admin_contact: '',

    });

    const [errors, setErrors] = useState({});
    // useEffect(() => {
    //     async function fetchCompanyProfileData() {
    //         try {

    //             const companyProfileData = await editCompanyprofile({ companyId });
    //             console.log("companyProfile", companyProfileData);
    //             if (companyProfileData && companyProfileData.success[0]) {
    //                 const companyProfile = companyProfileData.success[0];
    //                 setState({
    //                     company_id: companyProfile._id || '',
    //                     company_name: companyProfile.company_name || '',
    //                     admin_name: companyProfile.admin_name || '',
    //                     company_address: companyProfile.company_address || '',
    //                     country: companyProfile.country || '',
    //                     fax: companyProfile.fax || '',
    //                     city: companyProfile.city || '',
    //                     state: companyProfile.state || '',
    //                     pincode: companyProfile.pincode || '',
    //                     company_email: companyProfile.company_email || '',
    //                     admin_contact: companyProfile.admin_contact || '',


    //                 });
    //             } else {

    //             }
    //         } catch (error) {
    //             console.error('Error fetching company profile data:', error);
    //         }

    //     }

    //     fetchCompanyProfileData();
    // }, [companyId]);



    // useEffect(() => {

    //     const fetchCompanyProfileData = async () => {
    //       try {
    //         const companyProfileData = await editCompanyprofile({ companyId });
    //         console.log("companyProfile", companyProfileData);
    //         if (companyProfileData && companyProfileData.success[0]) {
    //           const companyProfile = companyProfileData.success[0];
    //           setState({
    //             company_id: companyProfile._id || '',
    //             company_name: companyProfile.company_name || '',
    //             admin_name: companyProfile.admin_name || '',
    //             company_address: companyProfile.company_address || '',
    //             country: companyProfile.country || '',
    //             fax: companyProfile.fax || '',
    //             city: companyProfile.city || '',
    //             state: companyProfile.state || '',
    //             pincode: companyProfile.pincode || '',
    //             company_email: companyProfile.company_email || '',
    //             admin_contact: companyProfile.admin_contact || '',
    //           });
    //         }
    //       } catch (error) {
    //         console.error('Error fetching company profile data:', error);
    //       }
    //     };

    //     const debouncedFetchCompanyProfileData = _debounce(fetchCompanyProfileData, 100);

    //     debouncedFetchCompanyProfileData();

    //     return () => {
    //       debouncedFetchCompanyProfileData.cancel();
    //     };

    //   }, [companyId]);
    useEffect(() => {
        if (sessionStorage.getItem("userType") !== "employee") {
            const fetchCompanyProfileData = async () => {
                try {
                    setLoading(true);
                    const companyProfileData = await editCompanyprofile({ companyId });
                    console.log("companyProfile", companyProfileData);
                    if (companyProfileData && companyProfileData.success[0]) {
                        const companyProfile = companyProfileData.success[0];
                        setState({
                            company_id: companyProfile._id || '',
                            company_name: companyProfile.company_name || '',
                            admin_name: companyProfile.admin_name || '',
                            company_address: companyProfile.company_address || '',
                            country: companyProfile.country || '',
                            fax: companyProfile.fax || '',
                            city: companyProfile.city || '',
                            state: companyProfile.state || '',
                            pincode: companyProfile.pincode || '',
                            company_email: companyProfile.company_email || '',
                            admin_contact: companyProfile.admin_contact || '',
                        });
                    }
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching company profile data:', error);
                    setLoading(false);
                }
            };

            const debouncedFetchCompanyProfileData = _debounce(fetchCompanyProfileData, 100);

            debouncedFetchCompanyProfileData();

            return () => {
                debouncedFetchCompanyProfileData.cancel();
            };
        }
    }, [companyId]);





    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,

        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    //     e.preventDefault();
    //     const validationErrors = validateForm(state);
    //     if (Object.keys(validationErrors).length > 0) {
    //       setErrors(validationErrors);
    //       return;
    //     }

    //     try {
    //       const response = await updateCompanyprofile(companyId, state);
    //     //   if (response && response.success) {
    //     //     toast.success('Company profile updated successfully', {
    //         if (response && response.success && response.success.length > 0) {
    //             toast.success(response.success[0].message, {
    //           position: 'top-center',
    //           autoClose: 5000,
    //         });

    //         // navigate('/admin/dashboard'); 
    //       } else {
    //         toast.error('Failed to update company profile', {
    //           position: 'top-center',
    //           autoClose: 5000,
    //         });
    //       }
    //     } catch (error) {
    //       toast.error('An error occurred while updating company profile', {
    //         position: 'top-right',
    //         autoClose: 1000,
    //       });
    //     }
    //   };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const validationErrors = validateForm(state);
    //     if (Object.keys(validationErrors).length > 0) {
    //       setErrors(validationErrors);
    //       return;
    //     }

    //     try {
    //       const response = await updateCompanyprofile(companyId, state);
    //       if (response && response.success) {
    //         toast.success(response.success.message, {
    //           position: 'top-center',
    //           autoClose: 5000,
    //         });
    //         navigate('/companyprofile');
    //       } else {
    //         toast.error('Failed to update company profile', {
    //           position: 'top-center',
    //           autoClose: 5000,
    //         });
    //       }
    //     } catch (error) {
    //       console.error('An error occurred:', error);
    //       toast.error('An error occurred while updating company profile', {
    //         position: 'top-right',
    //         autoClose: 1000,
    //       });
    //     }
    //   };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        if (submitting) {
            return;
        }
        setSubmitting(true);

        try {
            // First API call to update the company profile
            const response = await updateCompanyprofile(companyId, state);
            console.log(response);

            if (response) {
                toast.success(response.message, {
                    position: 'top-center',
                    autoClose: 1000,
                });

                // Retrieve stored data from sessionStorage
                const storedSetupData = JSON.parse(sessionStorage.getItem('setupData'));

                // Apply the spread operator to keep the stored values, but update `companyProfile`
                const updateProfileData = {
                    ...storedSetupData, // Spread the stored values
                    companyProfile: "true", // Update companyProfile field
                };
                console.log(updateProfileData);
                

                // Second API call with updated data
                const secondResponse = await basicSetup(updateProfileData);
                console.log(secondResponse);

                if (secondResponse && secondResponse.success) {
                    toast.success('Company details updated successfully', {
                        position: 'top-center',
                        autoClose: 1000,
                    });
                } else {
                    toast.error('Failed to update company details', {
                        position: 'top-center',
                        autoClose: 1000,
                    });
                }

                setSubmitting(false);
                // navigate('/admin/dashboard');
            } else {
                toast.error('Failed to update company profile', {
                    position: 'top-center',
                    autoClose: 1000,
                });
                setSubmitting(false);
            }
        } 
        catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred while updating company profile', {
                position: 'top-right',
                autoClose: 1000,
            });
            setSubmitting(false);
        }
    };



    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const validationErrors = validateForm(state);
    //     if (Object.keys(validationErrors).length > 0) {
    //         setErrors(validationErrors);
    //         return;
    //     } if (submitting) {
    //         return;
    //     }
    //     setSubmitting(true);

    //     try {
    //         const response = await updateCompanyprofile(companyId, state);
    //         console.log(response)
    //         if (response && response.message === "Admin updated successfully") {
    //             toast.success(response.message, {
    //                 position: 'top-center',
    //                 autoClose: 1000,
    //             })
    //             setSubmitting(false);

    //             // navigate('/companyprofile');
    //             navigate('/admin/dashboard');
    //         } else {
    //             toast.error('Failed to update company profile', {
    //                 position: 'top-center',
    //                 autoClose: 1000,
    //             });
    //         }
    //     } catch (error) {
    //         console.error('An error occurred:', error);
    //         toast.error('An error occurred while updating company profile', {
    //             position: 'top-right',
    //             autoClose: 1000,
    //         });
    //     }
    // };

    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary
        return errors;
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Company Profile</h3>
                </div>
                <div>

                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="#">Company Profile</Link>
                                </li>
                            </ol>
                        </div>
                    </div>

                    {loading &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }
                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >
                        <div className="col-md-12 formcontrols">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <form onSubmit={handleSubmit}>


                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="company_name" className="form-label">
                                                    Company Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.company_name && 'is-invalid'}`}
                                                    id="company_name"
                                                    name="company_name"
                                                    value={state.company_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_name && <div className="invalid-feedback">{errors.company_name}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="admin_name" className="form-label">
                                                    Contact Person
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.admin_name && 'is-invalid'}`}
                                                    id="admin_name"
                                                    name="admin_name"
                                                    value={state.admin_name}
                                                    onChange={handleChange}
                                                />
                                                {errors.admin_name && <div className="invalid-feedback">{errors.admin_name}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="company_address" className="form-label">
                                                    Address
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.company_address && 'is-invalid'}`}
                                                    id="company_address"
                                                    name="company_address"
                                                    value={state.company_address}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_address && <div className="invalid-feedback">{errors.company_address}</div>}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">

                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="country" className="form-label">
                                                    Country
                                                </label>
                                                <CountryDropdown
                                                    className={`form-control ${errors.country && 'is-invalid'}`}
                                                    value={state.country}
                                                    onChange={(val) => handleCountryChange(val)}
                                                />
                                                {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="fax" className="form-label">
                                                    Fax
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.fax && 'is-invalid'}`}
                                                    id="fax"
                                                    name="fax"
                                                    value={state.fax}
                                                    onChange={(e) => handlefaxChange(e.target.value)}
                                                />
                                                {errors.fax && <div className="invalid-feedback">{errors.fax}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="city" className="form-label">
                                                    City
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.city && 'is-invalid'}`}
                                                    id="city"
                                                    name="city"
                                                    value={state.city}
                                                    onChange={handleChange}
                                                />
                                                {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">

                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="state" className="form-label">
                                                    State
                                                </label>
                                                <RegionDropdown
                                                    className={`form-control ${errors.state && 'is-invalid'}`}
                                                    country={state.country}
                                                    value={state.state || ''}
                                                    blankOptionLabel="Select State"
                                                    onChange={(val) => handleRegionChange(val)}
                                                />
                                                {errors.state && <div className="invalid-feedback">{errors.state}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="pincode" className="form-label">
                                                    Postal Code
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.pincode && 'is-invalid'}`}
                                                    id="pincode"
                                                    name="pincode"
                                                    value={state.pincode}
                                                    onChange={handleChange}
                                                />
                                                {errors.pincode && <div className="invalid-feedback">{errors.pincode}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="company_email" className="form-label">
                                                    Email
                                                </label>
                                                <input
                                                    type="email"
                                                    className={`form-control ${errors.company_email && 'is-invalid'}`}
                                                    id="company_email"
                                                    name="company_email"
                                                    value={state.company_email}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                                {errors.company_email && <div className="invalid-feedback">{errors.company_email}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="admin_contact" className="form-label">
                                                    Phone number
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.admin_contact && 'is-invalid'}`}
                                                    id="admin_contact"
                                                    name="admin_contact"
                                                    value={state.admin_contact}
                                                    onChange={handleChange}
                                                />
                                                {errors.admin_contact && <div className="invalid-feedback">{errors.admin_contact}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-6">


                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                    </div> */}
                                    <button type="submit" className="custom-submit-btn" onClick={handleSubmit}
                                        disabled={submitting}>

                                        {submitting ? 'Updatting...' : 'Update'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Companyprofile;