import React, { useState, useEffect } from 'react';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card } from "react-bootstrap";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { deleteSalarysetup, empSalarySetup,  } from '../../services/api/api';
import { toast } from "react-toastify";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const ViewempSalarysetup = () => {
    
  const [salarySetupData, setSalarySetupData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [masterId, setMasterId] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const responseData = await empSalarySetup();

      if (!responseData || !responseData.data) {
        throw new Error('Failed to fetch data.');
      }

      const masterId = responseData.data[0]._id;
      console.log("masterIdsalarysetup",masterId);
  
      setSalarySetupData(responseData.data);
      setMasterId(masterId);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (id) => {
    if (masterId) {
      navigate(`/admin/edit_salary_setup/${id}/${masterId}`);
    } else {
      console.error('Master ID is not available.');
    }
  };
  
  const [index, setIndex] = useState();
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
 
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <React.Fragment>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => handleEditClick(params.row.id)}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row.id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row.id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </React.Fragment>
      )
    },
  ];
  const [deleteSalarysetupId, setdeleteSalarysetupId] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  
  const openConfirmationModal = (id) => {
    setdeleteSalarysetupId(id);
    setShowConfirmation(true);
  };
  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };

  const rows = salarySetupData.flatMap((item) => {
    if (item.emp_salary_master && typeof item.emp_salary_master === 'object') {
      return Object.values(item.emp_salary_master).map((structure) => ({
        id: structure._id,
        name: structure.name,
      }));
    } else {
      return [];
    }
  });

  
  const handledeleteSalarysetup = async (id, masterId) => {
    setdeleteSalarysetupId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteSalarysetup(id, masterId);
      if (response && response.status === 'success') {
        if (!toast.isActive('salarysetupDeletedToast')) {
          toast.success('Employee salarysetup deleted successfully', { toastId: 'holidayDeletedToast' });
        }
        fetchData();
      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting holiday:', error);
    }
  };
 
  

//   const rows = salarySetupData.flatMap((item) => item.Salary_Structure.map((structure) => ({
//     id: structure._id,
//     name: structure.name,
// })));



  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">

      <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                  <h4>Holidays</h4>
                </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/Viewempsalary">Salarysetup</Link></li>
              <li class="breadcrumb-item active">Lists</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
         
              <Link to="/admin/salarysetup">
              <div><button className="button-one" type="submit"><span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>Add Salarysetup</span></button></div>
              </Link>
           
          </nav>
        </div>



        <Card className="popup-card empHistory">
          <Card.Body>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: '100%', width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={salarySetupData.length} 
                  pagination 
                  responsive
                  autoHeight
                  getRowId={(row) => row.id}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this holiday?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handledeleteSalarysetup(deleteSalarysetupId, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ViewempSalarysetup;

