import React, { useState, useEffect, useCallback, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { Form, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { viewSalarySetup, editSalarySetup, updateSalarySetup } from '../../services/api/api';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Editsalarysetup = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    basic: [],
    deductions: [],
    allowances: []
  });
  const [masterId, setMasterId] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //   useEffect(() => {
  //     const debouncedFetchsalaryData = _debounce(fetchsalaryData, 100);

  //     debouncedFetchsalaryData();

  //     return () => {
  //         debouncedFetchsalaryData.cancel();
  //     };
  // }, []);
  // useEffect(() => {
  //   const debouncedFetchData = _debounce(fetchData, 100);

  //   debouncedFetchData();

  //   return () => {
  //       debouncedFetchData.cancel();
  //   };
  // }, [id, masterId]);


  //   const fetchsalaryData = async () => {
  //     try {
  //       setLoading(true);
  //       const responseData = await viewSalarySetup(id);
  //       const masterId = responseData.data[0]._id;
  //       setMasterId(masterId);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   const fetchData = async () => {
  //     try {
  //         setLoading(true);
  //         const responseData = await editSalarySetup(id, masterId);
  //         const { success } = responseData;

  //         if (success) {
  //             setFormData({
  //                 name: success.name,
  //                 basic: success.basic || [],
  //                 deductions: success.deduction || [],
  //                 allowances: success.allowence || []
  //             });
  //         }
  //     } catch (error) {
  //     } finally {
  //         setLoading(false);
  //     }
  // };

  const [fetchedMasterId, setFetchedMasterId] = useState(false);

  const fetchSalaryData = async () => {
    try {
      setLoading(true);
      const responseData = await viewSalarySetup(id);
      const newMasterId = responseData.data[0]._id;
      setMasterId(newMasterId);
      setFetchedMasterId(true);
      setLoading(false);
    } 
    catch (error) {

      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchSalaryData = _debounce(fetchSalaryData, 100);

  useEffect(() => {
    debouncedFetchSalaryData();
    return debouncedFetchSalaryData.cancel;
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const responseData = await editSalarySetup(id, masterId);
        const { success } = responseData;

        if (success) {
          setFormData({
            name: success.name,
            basic: success.basic || [],
            deductions: success.deduction || [],
            allowances: success.allowence || []
          });

          setLoading(false);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (id && fetchedMasterId) {
      fetchData();
    }
  }, [id, masterId, fetchedMasterId]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleAddDeductionAllowance = (type) => {
    const newData = { name: '', deduct_type: '' };
    setFormData({
      ...formData,
      [type]: [...formData[type], newData]
    });
  };

  const handleRemoveDeductionAllowance = (index, type) => {
    const newItems = formData[type].filter((item, i) => i !== index);
    setFormData({
      ...formData,
      [type]: newItems
    });
  };

  const handleInputChange = (index, e, type) => {
    const { name, value } = e.target;
    const updatedData = formData[type].map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData({
      ...formData,
      [type]: updatedData
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await updateSalarySetup(id, masterId, formData);
      // Handle the response as needed, e.g., show a success message
      console.log('Salary setup updated successfully:', response);
    } catch (error) {
      console.error('Error updating salary setup:', error);
      // Handle the error, e.g., show an error message
    } finally {
      setLoading(false);
    }
    navigate("/admin/Viewempsalary");
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="mb-4 border-bottom text-center">
          <h3 className="mb-0 pb- border-bottom custome-btn">Edit salary setup</h3>
        </div>

        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/Viewempsalary">Salarysetup</Link></li>
              <li class="breadcrumb-item active">Lists</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">

            {/* <Link to="admin/Viewempsalary">
              <div><button className="button-one" type="submit"><span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>Add Salarysetup</span></button></div>
              </Link> */}
            <nav aria-label="breadcrumb">
              <Link to="/admin/Viewempsalary" className="btn btn-danger mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                Back
              </Link>
            </nav>

          </nav>
        </div>

        {loading  &&
            <div className="loader-container">
              <div className="loader"></div>
            </div>
}

        <div className="form-container">
          <div className="row"
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <div className="col-md-12 formcontrols">
              <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <br/>

                  <Form.Group>
                    <Form.Label>Basic:</Form.Label>
                    {/* <span className="ms-2" style={{ cursor: 'pointer' }} onClick={() => handleAddDeductionAllowance('basic')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                      </svg>
                    </span> */}
                    {formData.basic.map((basicItem, index) => (
                      <div key={index} className="form-group">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={basicItem.name}
                              onChange={(e) => handleInputChange(index, e, 'basic')}
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={basicItem.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'basic')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>

                        </div>
                        {/* <Button
                          variant="danger"
                          onClick={() => handleRemoveDeductionAllowance(index, 'basic')}
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid black',
                            margin: '10px',
                            color: 'black',
                            transition: 'background-color 0.3s, border-color 0.3s'
                          }}
                          onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgrey'}
                          onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                        >
                          Remove
                        </Button> */}
                      </div>
                    ))}
                  </Form.Group>

                  {/* <Form.Group>
                    <Form.Label>Add Deductions:</Form.Label>
                    <span className="ms-2" style={{ cursor: 'pointer' }} onClick={() => handleAddDeductionAllowance('deductions')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                      </svg>
                    </span>
                    {formData.deductions.map((deductionItem, index) => (
                      <div key={index} className="form-group">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={deductionItem.name}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={deductionItem.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>

                        </div>
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveDeductionAllowance(index, 'deductions')}
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid black',
                            margin: '10px',
                            color: 'black',
                            transition: 'background-color 0.3s, border-color 0.3s'
                          }}
                          onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgrey'}
                          onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </Form.Group> */}

                  {/* <Form.Group>
                    <Form.Label>Add Deductions:</Form.Label>
                    <div className="form-group">
                      {formData.deductions.map((deductionItem, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-6">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Deduction Code"
                              value={deductionItem.name}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={deductionItem.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>
                        </div>
                      ))}
                      <div className="row">
                        <div className="col-md-10 col-sm-12 d-flex align-items-center justify-content-start">
                          <Button
                            variant="success"
                            className="ms-2"
                            onClick={() => handleAddDeductionAllowance('deductions')}
                            style={{ cursor: 'pointer' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                            </svg>
                            Add Deduction
                          </Button>
                        </div>
                        {formData.deductions.length > 0 && (
                          <div className="col-md-2 col-sm-12 d-flex align-items-center justify-content-start">
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveDeductionAllowance(formData.deductions.length - 1, 'deductions')}
                              style={{
                                backgroundColor: '#ff0000d1',
                                border: '0px solid black',
                                color: 'white',
                                transition: 'background-color 0.3s, border-color 0.3s'
                              }}
                              onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                              onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label>Add Deductions:</Form.Label>
                    <div className="form-group">
                      {formData.deductions.map((deductionItem, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-6">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Deduction Code"
                              value={deductionItem.name}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={deductionItem.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                            <br />
                          </div>
                          <div className="col-md-4 d-flex align-items-center">
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveDeductionAllowance(index, 'deductions')}
                              style={{
                                backgroundColor: '#ff0000d1',
                                border: '0px solid black',
                                color: 'white',
                                transition: 'background-color 0.3s, border-color 0.3s'
                              }}
                              onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                              onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                      <div className="row">
                        <div className="col-md-10 col-sm-12 d-flex align-items-center justify-content-start">
                          <Button
                            variant="success"
                            className="ms-2"
                            onClick={() => handleAddDeductionAllowance('deductions')}
                            style={{ cursor: 'pointer' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                            </svg>
                            Add Deduction
                          </Button>
                        </div>
                      </div>
                    </div>
                    <br/>
                  </Form.Group>


                  {/* <Form.Group>
                    <Form.Label>Add Allowances:</Form.Label>
                    <span className="ms-2" style={{ cursor: 'pointer' }} onClick={() => handleAddDeductionAllowance('allowances')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                      </svg>
                    </span>
                    {formData.allowances.map((allowanceItem, index) => (
                      <div key={index} className="form-group">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={allowanceItem.name}
                              onChange={(e) => handleInputChange(index, e, 'allowances')}
                            />
                          </div>

                          <div className="col-md-6 mb-3">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={allowanceItem.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'allowances')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>

                        </div>
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveDeductionAllowance(index, 'allowances')}
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid black',
                            margin: '10px',
                            color: 'black',
                            transition: 'background-color 0.3s, border-color 0.3s'
                          }}
                          onMouseEnter={(e) => e.target.style.backgroundColor = 'lightgrey'}
                          onMouseLeave={(e) => e.target.style.backgroundColor = 'white'}
                        >
                          Remove
                        </Button>
                      </div>
                    ))}
                  </Form.Group> */}

                  {/* <Form.Group>
                    <Form.Label>Add Allowances:</Form.Label>
                    <div className="form-group">
                      {formData.allowances.map((allowanceItem, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-6">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={allowanceItem.name}
                              onChange={(e) => handleInputChange(index, e, 'allowances')}
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={allowanceItem.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'allowances')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>
                        </div>
                      ))}
                      <div className="row">
                        <div className="col-md-10 col-sm-12 d-flex align-items-center justify-content-start">
                          <Button
                            variant="success"
                            className="ms-2"
                            onClick={() => handleAddDeductionAllowance('allowances')}
                            style={{ cursor: 'pointer' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                            </svg>
                            Add Allowance
                          </Button>
                        </div>
                        {formData.allowances.length > 0 && (
                          <div className="col-md-2 col-sm-12 d-flex align-items-center justify-content-start">
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveDeductionAllowance(formData.allowances.length - 1, 'allowances')}
                              style={{
                                backgroundColor: '#ff0000d1',
                                border: '0px solid black',
                                color: 'white',
                                transition: 'background-color 0.3s, border-color 0.3s'
                              }}
                              onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                              onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group> */}
                  <Form.Group>
                    <Form.Label>Add Allowances:</Form.Label>
                    <div className="form-group">
                      {formData.allowances.map((allowanceItem, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-6">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Name"
                              value={allowanceItem.name}
                              onChange={(e) => handleInputChange(index, e, 'allowances')}
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={allowanceItem.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'allowances')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                            <br />
                          </div>

                          <div className="col-md-4 d-flex align-items-center">
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveDeductionAllowance(index, 'allowances')}
                              style={{
                                backgroundColor: '#ff0000d1',
                                border: '0px solid black',
                                color: 'white',
                                transition: 'background-color 0.3s, border-color 0.3s'
                              }}
                              onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                              onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                      <div className="row">
                        <div className="col-md-10 col-sm-12 d-flex align-items-center justify-content-start">
                          <Button
                            variant="success"
                            className="ms-2"
                            onClick={() => handleAddDeductionAllowance('allowances')}
                            style={{ cursor: 'pointer' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                            </svg>
                            Add Allowance
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form.Group>

                  <br />

                  {/* <Button variant="success" type="submit">Update Salary Setup</Button> */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                   
                    <Button variant="primary" type="submit">Update Salary Setup</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Editsalarysetup;
