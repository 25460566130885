
import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { editUser, updateUser, viewBranch, viewDesignation } from '../../services/api/api';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Select from 'react-select';

const EditUser = () => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const parts = currentPath.split('/');
  const id = parts[parts.length - 1];
  const [loading, setLoading] = useState(true);
  const [masterId, setMasterId] = useState();
  const [branches, setBranchs] = useState([]);
  const [selectedBranchIds, setSelectedBranchIds] = useState([]);
  const [selectedBranchOptions, setSelectedBranchOptions] = useState([]);

  const [designations, setDesignations] = useState([]);
  const [submitting, setSubmitting] = useState(false);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');
  };

  const [state, setState] = useState({
    _id: '',
    company_id: '',
    user_email: '',
    user_name: '',
    user_password: '',
    user_type: '',
    user_add_date: '',
    role: '',
    designation: '',
    branch_id: '',
    employee: false,
    payroll: false,
    attendance: false,
    break: false,
    leave: false,
    letters: false,
    // Other fields...
  });

  const [errors, setErrors] = useState({});

  // useEffect(() => {

  //   fetchBranch();

  // }, [])
  useEffect(() => {
    const debouncedFetchBranch = _debounce(fetchBranch, 100);

    debouncedFetchBranch();

    return () => {
      debouncedFetchBranch.cancel();
    };
  }, []);
  // useEffect(() => {
  //   fetchDesignation();

  // }, []);
  useEffect(() => {
    const debouncedFetchDesignation = _debounce(fetchDesignation, 100);

    debouncedFetchDesignation();

    return () => {
      debouncedFetchDesignation.cancel();
    };
  }, []);

  const fetchBranch = async () => {

    try {
      setLoading(true);
      const formattedData = await viewBranch();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyBranch = formattedData[0].company_branch;
      setMasterId(formattedData[0]._id);
      const branchsArray = Object.values(companyBranch).map(item => ({
        _id: item._id,
        branch_name: item.branch_name || 'N/A',
      }));

      setBranchs(branchsArray);

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  const fetchDesignation = async () => {
    try {
      const formattedData = await viewDesignation();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyDesignation = formattedData[0].company_designation;
      setMasterId(formattedData[0]._id);
      const designationsArray = Object.values(companyDesignation).map((item) => ({
        _id: item._id,
        designation_name: item.designation_name || 'N/A',
      }));

      setDesignations(designationsArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleChangeb = (selectedOptions) => {
    const selectedBranchIds = selectedOptions.map((option) => option.value).join(',');
    setState((prevState) => ({
      ...prevState,
      branch_id: selectedBranchIds,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      branch_id: '',
    }));
  };


  // useEffect(() => {
  //   async function fetchUserData() {
  //     try {
  //       const userData = await editUser(id);

  //       console.log('userData:', userData);

  //       if (userData && userData.success) {
  //         const user = Array.isArray(userData.success) ? userData.success[0] : userData.success;

  //         if (user) {
  //           setState({
  //             _id: user._id || '',
  //             company_id: user.company_id || '',
  //             user_email: user.user_email || '',
  //             user_name: user.user_name || '',
  //             user_password: user.user_password || '',
  //             user_type: user.user_type || '',
  //             user_add_date: user.user_add_date || '',
  //             role: user.role || '',
  //             designation: user.designation || '',
  //             branch_id: user.branch_id || '',
  //             employee: user.employee || false,
  //             payroll: user.payroll || false,
  //             attendance: user.attendance || false,
  //             break: user.break || false,
  //             leave: user.leave || false,
  //             letters: user.letters || false,
  //             // Set other fields accordingly
  //           });

  //           const userBranches = user.branch_id ? user.branch_id.split(',') : [];
  //           const selectedBranches = branches
  //             .filter((branch) => userBranches.includes(branch._id))
  //             .map((branch) => ({
  //               value: branch._id,
  //               label: branch.branch_name,
  //             }));

  //           setSelectedBranchOptions(selectedBranches);
  //         } else {
  //           console.error('No user data found');
  //         }
  //       } else {
  //         console.error('No user data found or userData.success is not available');
  //       }
  //     } catch (error) {
  //       console.error('Error fetching user data:', error);
  //     }
  //   }

  //   fetchUserData();
  // }, [id, branches]);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await editUser(id);
        console.log('userData:', userData);

        if (userData && userData.success) {
          const user = Array.isArray(userData.success) ? userData.success[0] : userData.success;

          if (user) {
            setState({
              _id: user._id || '',
              company_id: user.company_id || '',
              user_email: user.user_email || '',
              user_name: user.user_name || '',
              user_password: user.user_password || '',
              user_type: user.user_type || '',
              user_add_date: user.user_add_date || '',
              role: user.role || '',
              designation: user.designation || '',
              branch_id: user.branch_id || '',
              employee: user.employee || false,
              payroll: user.payroll || false,
              attendance: user.attendance || false,
              break: user.break || false,
              leave: user.leave || false,
              letters: user.letters || false,
              // Set other fields accordingly
            });

            const userBranches = user.branch_id ? user.branch_id.split(',') : [];
            const selectedBranches = branches
              .filter((branch) => userBranches.includes(branch._id))
              .map((branch) => ({
                value: branch._id,
                label: branch.branch_name,
              }));

            setSelectedBranchOptions(selectedBranches);
          } else {
            console.error('No user data found');
          }
        } else {
          console.error('No user data found or userData.success is not available');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const debouncedFetchUserData = _debounce(fetchUserData, 100);

    debouncedFetchUserData();

    return () => {
      debouncedFetchUserData.cancel();
    };
  }, [id, branches]);




  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'role' && value === null) {
      setState((prevState) => ({
        ...prevState,
        [name]: '',
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };


  const handleAccessChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) {
      return;
    }
    const validationErrors = validateForm(state);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setSubmitting(true);

    try {
      // const response = await updateUser(id, { ...state, branch_id: selectedBranchIds.join(',') });
      const response = await updateUser(id, {
        ...state,
        branch_id: selectedBranchOptions.map((branch) => branch.label).join(','),
      });


      if (response && response.success && response.success.length > 0) {
        toast.success(response.success[0].message, {
          position: 'top-center',
          autoClose: 5000,
        });
      } else {
        toast.success('User updated successfully', {
          position: 'top-center',
          autoClose: 5000,
        });
        navigate('/pages/user/viewuser');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred', {
        position: 'top-right',
        autoClose: 1000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const validateForm = (state) => {
    let errors = {};
    // Add validations if necessary
    return errors;
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="mb-4 border-bottom text-center">
          <h3 className="mb-0 pb- border-bottom custome-btn">Edit User</h3>
        </div>
        <div>

          <div className="breadcrumb-warning d-flex justify-content-between ot-card">
            <div>
              <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/pages/user/viewuser">Users</Link>
                </li>
                <li className="breadcrumb-item active">Add User</li>
              </ol>
            </div>
            <nav aria-label="breadcrumb">
              <Link to="/pages/user/viewuser" className="btn btn-danger mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                Back
              </Link>
            </nav>
          </div>

          {loading &&
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          }
          
          <div className="row"
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >


            <div className="col-md-12 formcontrols">
              <div className="bg-white p-4 rounded" style={{ height: '100%' }}>
                <form onSubmit={handleSubmit}>



                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="user_email" className="form-label fw-bold">
                          User Email
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.user_email && 'is-invalid'}`}
                          id="user_email"
                          name="user_email"
                          value={state.user_email}
                          onChange={handleChange}
                        />
                        {errors.user_email && (
                          <div className="invalid-feedback">{errors.user_email}</div>
                        )}
                      </div>


                    </div>
                    <div className="col-md-6">

                      <div className="mb-3">
                        <label htmlFor="user_name" className="form-label fw-bold">
                          User Name
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.user_name && 'is-invalid'}`}
                          id="user_name"
                          name="user_name"
                          value={state.user_name}
                          onChange={handleChange}
                        />
                        {errors.user_name && (
                          <div className="invalid-feedback">{errors.user_name}</div>
                        )}
                      </div>


                    </div>
                  </div>

                  <div className="row">
                    {/* <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="user_password" className="form-label fw-bold">
                          User Password
                        </label>
                        <input
                          type="password"
                          className={`form-control custom-input ${errors.user_password && 'is-invalid'}`}
                          id="user_password"
                          name="user_password"
                          value={state.user_password}
                          onChange={handleChange}
                        />
                        {errors.user_password && (
                          <div className="invalid-feedback">{errors.user_password}</div>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="user_type" className="form-label fw-bold">
                          User Type
                        </label>
                        <input
                          type="text"
                          className={`form-control custom-input ${errors.user_type && 'is-invalid'}`}
                          id="user_type"
                          name="user_type"
                          value={state.user_type}
                          onChange={handleChange}
                        />
                        {errors.user_type && (
                          <div className="invalid-feedback">{errors.user_type}</div>
                        )}
                      </div>


                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="user_add_date" className="form-label fw-bold">
                          User Add Date
                        </label>
                        <input
                          type="date"
                          className={`form-control custom-input ${errors.user_add_date && 'is-invalid'}`}
                          id="user_add_date"
                          name="user_add_date"
                          value={state.user_add_date}
                          onChange={handleChange}
                        />
                        {errors.user_add_date && (
                          <div className="invalid-feedback">{errors.user_add_date}</div>
                        )}
                      </div>



                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="designation" className="form-label fw-bold">
                          Designation
                        </label>
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                          <select
                            className={`form-select ${errors.designation && 'is-invalid'}`}
                            id="designation"
                            name="designation"
                            value={state.designation}
                            onChange={handleChange}
                          >
                            <option value="">Select Designation</option>
                            {designations.map((designation) => (
                              <option key={designation._id} value={designation.designation_name}>
                                {designation.designation_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="branch" className="form-label fw-bold">
                          Branch
                        </label>

                        <Select
                          isMulti
                          options={branches.map((branch) => ({
                            value: branch._id,
                            label: branch.branch_name,
                          }))}
                          value={selectedBranchOptions}
                          onChange={(selectedOptions) => {
                            const selectedIds = selectedOptions.map((option) => option.value);
                            setSelectedBranchOptions(selectedOptions);
                            setSelectedBranchIds(selectedIds);
                          }}
                          inputValue={state.branch_id}
                          onInputChange={(inputValue) => {
                            handleChange({
                              target: {
                                name: 'branch_id',
                                value: inputValue,
                              },
                            });
                          }}
                          isClearable
                          placeholder="Select or type branches"
                        />

                        {errors.branch_id && (
                          <div className="invalid-feedback">{errors.branch_id}</div>
                        )}
                        {errors.branch && (
                          <div className="invalid-feedback">{errors.branch}</div>
                        )}
                      </div>
                    </div>

                  </div>

                  <div className="row">

                  </div>



















                  <div className="customContainer2">

                    <div className="mb-3">
                      <Form.Check
                        type="switch"
                        id="employee"
                        label="Employee"
                        checked={state.employee}
                        onChange={(e) => handleAccessChange("employee", e.target.checked)}
                        className="fw-bold"
                      />

                    </div>



                    <div className="mb-3">
                      <Form.Check
                        type="switch"
                        id="payroll"
                        label="Payroll"
                        checked={state.payroll}
                        onChange={(e) => handleAccessChange("payroll", e.target.checked)}
                        className="fw-bold"
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Check
                        type="switch"
                        id="attendance"
                        label="Attendance"
                        checked={state.attendance}
                        onChange={(e) => handleAccessChange("attendance", e.target.checked)}
                        className="fw-bold"
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Check
                        type="switch"
                        id="break"
                        label="Break"
                        checked={state.break}
                        onChange={(e) => handleAccessChange("break", e.target.checked)}
                        className="fw-bold"
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Check
                        type="switch"
                        id="leave"
                        label="Leave"
                        checked={state.leave}
                        onChange={(e) => handleAccessChange("leave", e.target.checked)}
                        className="fw-bold"
                      />
                    </div>
                    <div className="mb-3">
                      <Form.Check
                        type="switch"
                        id="letters"
                        label="Letters"
                        checked={state.letters}
                        onChange={(e) => handleAccessChange("letters", e.target.checked)}
                        className="fw-bold"
                      />
                    </div>

                    <div className="mb-3">
                      {/* Add other Form.Check for different access controls */}
                      {/* ... */}
                    </div>
                  </div>



                  {/* <button type="submit" className="btn btn-primary">
                    Update
                  </button> */}

                  <button
                    type="submit"
                    className={`custom-submit-btn ${submitting ? 'disabled' : ''}`}
                    disabled={submitting}
                  >
                    {submitting ? 'Submitting...' : 'Update User'}
                  </button>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div >
    </React.Fragment >
  );
};

export default EditUser;