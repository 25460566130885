import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { editDepartment, updateDepartment } from '../../../services/api/api';
import { useNavigate } from 'react-router-dom';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

// ... other imports

const EditDepartment = () => {
    const navigate = useNavigate();
    const currentPath = window.location.pathname;
    // const parts = currentPath.split('/');
    // const id = parts[parts.length - 1];
    // const id = parts[parts.length - 2]; 
    // const masterId = parts[parts.length - 1];
    const { id, masterId } = useParams();
    console.log("new id",masterId);
  

    const [state, setState] = useState({
        _id: '',
        company_id: '',
        department_name: '',
        delete_status: '',
        // Add other fields as needed
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [submittingDep, setSubmittingDep] = useState(false);
    // useEffect(() => {
    //     async function fetchDepartmentData() {
    //         try {
    //             const departmentData = await editDepartment(id,masterId);

    //             if (departmentData && departmentData.success) {
    //                 const department = departmentData.success;

    //                 setState({
    //                     _id: department._id || '',
    //                     company_id: department.company_id || '',
    //                     department_name: department.department_name || '',
    //                     delete_status: department.delete_status || '',
    //                     // Set other fields accordingly
    //                 });
    //             } else {
    //                 console.error('No department data found');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching department data:', error);
    //         }
    //     }

    //     fetchDepartmentData();
    // }, [id,masterId]);
    

    useEffect(() => {
        const fetchDepartmentData = debounce(async () => {
            try {
                setLoading(true)
                const departmentData = await editDepartment(id, masterId);
    
                if (departmentData && departmentData.success) {
                    const department = departmentData.success;
    
                    setState({
                        _id: department._id || '',
                        company_id: department.company_id || '',
                        department_name: department.department_name || '',
                        delete_status: department.delete_status || '',
                    });
                } else {
                    console.error('No department data found');
                }
                setLoading(false)
            } 
            catch (error) {
                console.error('Error fetching department data:', error);
                setLoading(false)
            }
        }, 100); 
    
        fetchDepartmentData();
    
        return () => {
            fetchDepartmentData.cancel();
        };
    }, [id, masterId]);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        if (submittingDep) {
            return;
          }
          setSubmittingDep(true)
        try {
          const response = await updateDepartment(id,masterId,state);
          if (response && response.success && response.success.length > 0) {
            toast.success(response.success[0].message, {
              position: 'top-center',
              autoClose: 5000,
            });
          } else {
            toast.success('Department updated successfully', {
              position: 'top-center',
              autoClose: 5000,
            });
            setSubmittingDep(false)
            navigate('/admin/department');
          }
        } catch (error) {
          console.error('An error occurred:', error);
          toast.error('An error occurred', {
            position: 'top-right',
            autoClose: 1000,
          });
        }
      };

    // ... similar functions for handleAccessChange, handleSubmit, validateForm

    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary
        return errors;
      };




    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />









            <div className="main p-4 p-lg-5 mt-5">
            <div className="mb-4 border-bottom text-center">
                        <h3 className="mb-0 pb- border-bottom custome-btn">Edit Department</h3>
                    </div>
                <div>

              
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/department">Department</Link>
                            </li>
                            <li className="breadcrumb-item active">Edit Department</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Link to="/admin/department" className="btn btn-danger mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>

                {loading  &&
            <div className="loader-container">
              <div className="loader"></div>
            </div>
}


                    <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >


                    <div className="col-md-12 formcontrols">
                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="department_name" className="form-label">
                                    Department Name
                                </label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.department_name && 'is-invalid'}`}
                                    id="department_name"
                                    name="department_name"
                                    value={state.department_name}
                                    onChange={handleChange}
                                />
                                {errors.department_name && (
                                    <div className="invalid-feedback">{errors.department_name}</div>
                                )}
                            </div>
                            <Button className='custom-submit-btn' type="submit">
                            {/* Add Department */}
                            {submittingDep && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {submittingDep ? 'Submitting...' : 'Update'}
                        </Button>
                        </form>
                        </div>
                    </div>
                </div>

                    
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditDepartment;
