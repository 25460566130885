import React, { useState, useEffect, useRef } from 'react';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from 'react-bootstrap-icons';

function Breaklist() {
    const tableRef = useRef(null);
    const [employeeSalaryData, setEmployeeSalaryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());

    const fetchEmployeeSalaryData = async () => {
        try {
            // Implement logic to fetch employee salary data
            // Example:
            // const salaryData = await fetchEmployeeSalary();
            // setEmployeeSalaryData(salaryData);

            // Simulating data for the example
            const salaryData = [
                // Example data, replace with your actual employee salary data
                {
                    _id: '1',
                    employee_name: 'John Doe',
                    basic: 50000,
                    working_days: 20,
                    present_days: 18,
                    absent_days: 2,
                    hra: 8000,
                    conveyance: 2000,
                    pf: 3000,
                    over_time: 1000,
                    incentive: 1500,
                    leave: 3,
                    break_violation: 1,
                    late_violation: 2,
                    net_salary: 55000
                },
                // ... more data
            ];

            setEmployeeSalaryData(salaryData);
        } catch (error) {
            console.error('Error fetching employee salary data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEmployeeSalaryData();
    }, []);

    const columns = [
        { field: 'id', headerName: 'Sr.', align: 'center', headerAlign: 'center',flex:1, renderCell: (params) => (<span>{params.row.id}</span>) },
        { field: 'employee_name', headerName: 'Employee Name', width: 200, align: 'center',flex:2, headerAlign: 'center' },
        { field: 'basic', headerName: 'Created ', width: 150, align: 'center', headerAlign: 'center',flex:2, },
        // { field: 'working_days', headerName: 'Working Days', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'present_days', headerName: 'Present Days', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'absent_days', headerName: 'Absent Days', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'hra', headerName: 'HRA', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'conveyance', headerName: 'Conveyance', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'pf', headerName: 'PF', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'over_time', headerName: 'Over Time', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'incentive', headerName: 'Incentive', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'leave', headerName: 'Leave', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'break_violation', headerName: 'Break Violation', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'late_violation', headerName: 'Late Violation', width: 150, align: 'center', headerAlign: 'center' },
        // { field: 'net_salary', headerName: 'Net Salary', width: 150, align: 'center', headerAlign: 'center' },
        {
            field: 'actions', headerName: 'Actions', width: 150, align: 'center', headerAlign: 'center', renderCell: (params) => (
                <>
                    {/* Add action buttons similar to the Branch component */}
                    <Button variant="info" size="sm" className="me-2" onClick={() => handleEditEmployee(params.row._id)}>
                        Edit
                    </Button>
                    <Button variant="danger" size="sm" onClick={() => openConfirmationModal(params.row._id)}>
                        Delete
                    </Button>
                </>
            )
        },
    ];

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const filterRows = () => {
        if (!searchText) {
            return [...employeeSalaryData];
        }

        return [...employeeSalaryData].filter((row) =>
            Object.values(row).some(
                (value) =>
                    typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
            )
        );
    };

    const handleEditEmployee = (id) => {
        // Implement logic to navigate to the edit employee salary page
        // Example: navigate(`/edit_employee_salary/${id}`);
        console.log(`Edit employee with ID: ${id}`);
    };

    const openConfirmationModal = (id) => {
        setDeleteEmployeeId(id);
        setShowConfirmation(true);
    };

    const closeConfirmationModal = () => {
        setShowConfirmation(false);
    };

    const handleDeleteEmployee = async (id) => {
        // Implement logic to delete employee salary data
        // Example:
        // const response = await deleteEmployeeSalary(id);
        // if (response && response.status === 'success') {
        //   fetchEmployeeSalaryData();
        //   // Show success toast
        // } else {
        //   // Show error toast
        // }

        // Simulating delete operation for the example
        console.log(`Delete employee with ID: ${id}`);
        setShowConfirmation(false);
    };
    const [showAddSalaryModal, setShowAddSalaryModal] = useState(false);
    const [salaryForm, setSalaryForm] = useState({
        month: '',
        year: '',
    });

    const openAddSalaryModal = () => {
        setShowAddSalaryModal(true);
    };

    const closeAddSalaryModal = () => {
        setShowAddSalaryModal(false);
        // Reset the form fields when the modal is closed
        setSalaryForm({
            month: '',
            year: '',
        });
    };

    const handleGenerateSalary = () => {
        // Implement logic to handle the generation of staff salary based on the entered details
        // Example:
        // const response = await generateStaffSalary(salaryForm);
        // if (response && response.status === 'success') {
        //   fetchEmployeeSalaryData();
        //   // Show success toast
        // } else {
        //   // Show error toast
        // }
        console.log('Generate staff salary with details:', salaryForm);
        closeAddSalaryModal();
    };

    // Function to generate an array of months (Jan to Dec)
    const generateMonthOptions = () => {
        const months = Array.from({ length: 12 }, (_, index) => ({
            value: (index + 1).toString(),
            label: new Date(2000, index, 1).toLocaleString('default', { month: 'long' }),
        }));
        return months;
    };

    // Function to generate an array of years (from current year to 10 years ahead)
    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => ({
            value: (currentYear + index).toString(),
            label: (currentYear + index).toString(),
        }));
        return years;
    };





    const [showExportSalaryModal, setShowExportSalaryModal] = useState(false);
    const [exportSalaryForm, setExportSalaryForm] = useState({
        month: '',
        year: '',
        bankName: '',
    });

    const openExportSalaryModal = () => {
        setShowExportSalaryModal(true);
    };

    const closeExportSalaryModal = () => {
        setShowExportSalaryModal(false);
        // Reset the form fields when the modal is closed
        setExportSalaryForm({
            month: '',
            year: '',
            bankName: '',
        });
    };

    const handleExportSalary = () => {
        // Implement logic to handle the export of staff salary based on the entered details
        // Example:
        // const response = await exportStaffSalary(exportSalaryForm);
        // if (response && response.status === 'success') {
        //   // Show success toast
        // } else {
        //   // Show error toast
        // }
        console.log('Export staff salary with details:', exportSalaryForm);
        closeExportSalaryModal();
    };
    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li class="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li class="breadcrumb-item active">
                                <Link to="/admin/breaklist">Break</Link>
                            </li>
                            <li class="breadcrumb-item active">List</li>
                        </ol>
                    </div>

                    {/* <nav aria-label="breadcrumb">
                        <Button variant="primary" className="mb-3">
                            <Link to="/admin/employeesalaryform">
                                <span style={{ color: "white" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg>
                                    Create salary
                                </span>
                            </Link>
                        </Button>
                    </nav> */}

                </div>
                <Card className="popup-card empSalary">
                    <Card.Body>
                        <div className="mb-4 border-bottom text-center">
                            <h3 className="mb-0 pb- border-bottom custome-btn">Break off List</h3>
                        </div>

                        {/* <div class="row">
                            <div class="col-md-2 mb-3">
                                <button onClick={openAddSalaryModal} class="btn btn-secondary w-100"> Add Salary</button>
                            </div>
                            <div class="col-md-2 mb-3">
                                <button onClick={openExportSalaryModal} class="btn btn-primary w-100"> Export Staff Salary</button>
                            </div>
                        </div> */}



                        <div className="mb-3 border rounded p-3" style={{ backgroundColor: '#f0f0f0' }}>
                            <strong>Break Info Monthly:</strong> {selectedDate && selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                        </div>
                        <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center mb-3">
                            <div className="d-flex align-items-center mb-2 mb-md-0 col-6">
                                <div className="input-group">
                                    <DatePicker
                                        id="datepicker"
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        className="form-control"

                                    />
                                    <span
                                        className="input-group-text"
                                        onClick={() => document.getElementById('datepicker').focus()} // Focus on the date picker input
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Calendar className="bi text-primary" />
                                    </span>
                                </div>
                            </div>
                            <div className="d-flex align-items-center col-6">
                                <label htmlFor="searchInput" className="me-2">
                                    
                                </label>
                                <input
                                    type="text"
                                    className="p-2 ms-2 w-100" // Increased width of the search box
                                    placeholder="Search"
                                    value={searchText}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                        {loading ? (
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <div style={{ height: 500, width: '100%' }}>
                                <DataGrid
                                    rows={filterRows()}
                                    columns={columns}
                                    pageSize={5}
                                    responsive
                                    autoHeight
                                    getRowId={(row) => row._id}
                                    headerClassName="custom-header"
                                    components={{
                                        NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                                    }}
                                />
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </div>

            <Modal show={showConfirmation} onHide={closeConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this employee's salary data?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeConfirmationModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteEmployee(deleteEmployeeId)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>




            <Modal show={showAddSalaryModal} onHide={closeAddSalaryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Staff Salary</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="month" className="form-label">
                                Month
                            </label>
                            <select
                                className="form-select"
                                id="month"
                                name="month"
                                value={salaryForm.month}
                                onChange={(e) => setSalaryForm({ ...salaryForm, month: e.target.value })}
                            >
                                <option value="" disabled>Select Month</option>
                                {generateMonthOptions().map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="year" className="form-label">
                                Year
                            </label>
                            <select
                                className="form-select"
                                id="year"
                                name="year"
                                value={salaryForm.year}
                                onChange={(e) => setSalaryForm({ ...salaryForm, year: e.target.value })}
                            >
                                <option value="" disabled>Select Year</option>
                                {generateYearOptions().map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeAddSalaryModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleGenerateSalary}>
                        Generate
                    </Button>
                </Modal.Footer>
            </Modal>






            <Modal show={showExportSalaryModal} onHide={closeExportSalaryModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Export Staff Salary</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="month" className="form-label">
                                Month
                            </label>
                            <select
                                className="form-select"
                                id="month"
                                name="month"
                                value={exportSalaryForm.month}
                                onChange={(e) => setExportSalaryForm({ ...exportSalaryForm, month: e.target.value })}
                            >
                                <option value="" disabled>Select Month</option>
                                {generateMonthOptions().map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="year" className="form-label">
                                Year
                            </label>
                            <select
                                className="form-select"
                                id="year"
                                name="year"
                                value={exportSalaryForm.year}
                                onChange={(e) => setExportSalaryForm({ ...exportSalaryForm, year: e.target.value })}
                            >
                                <option value="" disabled>Select Year</option>
                                {generateYearOptions().map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="bankName" className="form-label">
                                Bank Name (Example BANK Name - Branch Name)
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="bankName"
                                name="bankName"
                                value={exportSalaryForm.bankName}
                                onChange={(e) => setExportSalaryForm({ ...exportSalaryForm, bankName: e.target.value })}
                                placeholder="Enter bank name - branch name"
                            />
                        </div>
                    </form>
                </Modal.Body>
                {/* <Modal.Footer>
            <Button variant="secondary" onClick={closeExportSalaryModal}>
                Cancel
            </Button>
            <Button variant="success" onClick={handleExportSalary}>
                Export
            </Button>
        </Modal.Footer> */}
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeExportSalaryModal}>
                        Cancel
                    </Button>
                    <Button variant="success" onClick={handleExportSalary}>
                        Export
                    </Button>
                    <Button variant="outline-primary" onClick={() => console.log('PDF button clicked')}>
                        PDF
                    </Button>
                    <Button variant="outline-secondary" onClick={() => console.log('CSV button clicked')}>
                        CSV
                    </Button>
                </Modal.Footer>
            </Modal>


        </React.Fragment>
    )
}

export default Breaklist

