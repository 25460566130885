import React, { useState, useEffect, useRef, useDebugValue } from 'react';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar } from 'react-bootstrap-icons';
import { BASE_URL, editsalarystructuresetup, fetchEmployeeSalaryCalculation, getMonthlyAllSalaryEmployee, update_salary_structure_setup, viewsalarystructuresetup, viewsalarystructuresetuppaggination } from '../../services/api/api';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const Employeesalary = () => {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const [employeeSalaryData, setEmployeeSalaryData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showAddSalaryModal, setShowAddSalaryModal] = useState(false);
    const [salaryForm, setSalaryForm] = useState({ year: '', month: '' });
    const [rows, setRows] = useState([]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [rowId, setRowId] = useState(null);
    const [monthlySalary, setMonthlySalaryEmp] = useState(null);
    const [editEmployeeData, setEditEmployeeData] = useState(null);
    const [formData, setFormData] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [calculatedTotalSalary, setCalculatedTotalSalary] = useState('0.00');
    const [showModal, setShowModal] = useState(false);

    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setpageSize] = useState(10);
    const [count, setCount] = useState(0);
    const pageRef = useRef(page);
    const [error, setError] = useState(null);

    useEffect(() => {
        pageRef.current = page;
    }, [page]);

    const customStyles = {
        grid: {
            border: '1px solid #ddd',
        },
        cell: {
            border: 'none', // Remove all borders
            borderBottom: '1px solid #ddd', // Only horizontal border
            padding: '0.5rem 0.5rem',
            color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
            backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color
            fontWeight: 'bold', // Making text important
            fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
        },
        columnHeader: {
            border: 'none', // Remove all borders
            borderBottom: '1px solid #ddd', // Only horizontal border
            padding: '0.5rem 0.5rem',
            color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
            backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

            fontWeight: 'bold',
            fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
        },
        lastColumn: {
            borderRight: 'none',
        },
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getMonthEstimateSalary = async () => {
        try {
            const response = await getMonthlyAllSalaryEmployee();
            if (response && Array.isArray(response) && response.length > 0) {
                console.log("API response:", response);
                setMonthlySalaryEmp(response);
            } else {
                console.error("Invalid API response:", response);
            }
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        }
    };

    // Function to calculate total salary
    const calculateTotalSalary = () => {
        if (monthlySalary && monthlySalary.length > 0) {
            const totalBasic = monthlySalary[0]?.total_basic_monthly || 0;
            const totalAllowance = monthlySalary[0]?.total_allowance_monthly || 0;
            const totalDeduction = monthlySalary[0]?.total_deduction_monthly || 0;

            const totalSalary = (totalBasic + totalAllowance - totalDeduction).toFixed(2);
            return totalSalary;
        } else {
            console.error('monthlySalary is null or empty.');
            return '0.00';
        }
    };

    // useEffect to initialize data
    useEffect(() => {
        getMonthEstimateSalary();

        const generatedYears = Array.from({ length: 31 }, (_, i) => 2020 + i);
        setYears(generatedYears);

        const monthNames = [
            'january', 'february', 'march', 'april', 'may', 'june',
            'july', 'august', 'september', 'october', 'november', 'december'
        ];
        setMonths(monthNames);
    }, []);

    function formatNumberWithCommas(number) {
        return number
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    // useEffect to calculate total salary whenever monthlySalary changes
    useEffect(() => {
        const totalSalary = calculateTotalSalary();
        const formattedSalary = formatNumberWithCommas(totalSalary);
        console.log(formattedSalary);

        setCalculatedTotalSalary(formattedSalary);
        console.log("Calculated Total Salary:", totalSalary);
    }, [monthlySalary]);


    const handleShowModal = () => {
        setShowModal(true);
        setSubmitted(!submitted);
        setFormData({});

    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        // handleviewsalarystructuresetup()
    }, [submitted]);

    useEffect(() => {
        if (editEmployeeData) {
            setFormData(editEmployeeData);
        }
    }, [editEmployeeData]);


    // useEffect(() => {
    //     getMonthEstimateSalary();
    //     const calculateTotalSalary = () => {
    //         if (monthlySalary && monthlySalary.length > 0) {
    //             const totalBasic = monthlySalary[0]?.total_basic_monthly;
    //             const totalAllowance = monthlySalary[0]?.total_allowance_monthly;
    //             const totalDeduction = monthlySalary[0]?.total_deduction_monthly;

    //             const totalSalary = (totalBasic + totalAllowance - totalDeduction).toFixed(2);
    //             return totalSalary;
    //         } else {
    //             console.error('monthlySalary is null or empty.');
    //             return '0.00';
    //         }
    //     };
    //     const totalSalary = calculateTotalSalary();
    //     setCalculatedTotalSalary(totalSalary);
    //     console.log("++++++++++++++++++++++++++++++++++++++++++++++++",totalSalary);
    //     const generatedYears = Array.from({ length: 31 }, (_, i) => 2020 + i);
    //     setYears(generatedYears);

    //     const monthNames = [
    //         'january', 'february', 'march', 'april', 'may', 'june',
    //         'july', 'august', 'september', 'october', 'november', 'december'
    //     ];
    //     setMonths(monthNames);

    // }, []);

    useEffect(() => {
        // if (salaryForm.year && salaryForm.month) {
        handleviewsalarystructuresetup();
        // }
    }, [salaryForm.year, salaryForm.month, pageSize, page]);

    // const getMonthEstimateSalary = async () => {
    //     try {
    //         const response = await getMonthlyAllSalaryEmployee();
    //         console.log("manual salary", response);
    //         setMonthlySalaryEmp(response);
    //     } catch (error) {
    //         console.error("Error fetching salary structure setup:", error);
    //     }
    // };

    const getToken = () => {
        const token = sessionStorage.getItem('token');

        return token ? `Bearer ${token}` : '';


    };

    const handleClick = (rowData) => {
        let year = salaryForm.year
        let month = salaryForm.month
        navigate('/admin/salarypdf', { state: { rowData, year, month } });
    };

    const handleExportSalary = async () => {
        setLoading(true); // Show loading state

        if (salaryForm.year && salaryForm.month) {


            try {
                const response = await fetch(`${BASE_URL}/exportSalaryStructureData`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        "Content-Type": "application/json",
                        "Authorization": getToken(),
                    },
                    body: JSON.stringify({ year: salaryForm.year, month: salaryForm.month }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                // Convert JSON data to CSV
                const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

                // Create a Blob and trigger the download
                const blob = new Blob([csvData], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'employee_data.csv'; // Change the file name as needed
                link.click();

                // Clean up
                URL.revokeObjectURL(link.href);



            } catch (error) {
                console.error("Fetch error: ", error);
                setError(error.message || 'An error occurred');



            } finally {
                setLoading(false); // Hide loading state
            }
        }
        else {
            alert('select year and month first ')
        }

    };

    function convertToCSV(data) {
        const array = [Object.keys(data[0])].concat(data);

        return array.map(row => {
            return Object.values(row).map(value =>
                typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
            ).join(',');
        }).join('\n');
    }


    const columns = [
        { field: 'id', headerName: 'ID.', align: 'center', headerAlign: 'center', renderCell: (params) => (<span>{params.row.id}</span>) },
        { field: 'name', headerName: 'Employee Name', width: 200, align: 'center', headerAlign: 'center' },
        { field: 'gross_month_total', headerName: 'Gross Month Total', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'working_days', headerName: 'Working Days', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'absent_days', headerName: 'Absent Days', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'present_days', headerName: 'Present Days', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'break_violation', headerName: 'Break Violation', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'late_violation', headerName: 'Late Violation', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'leave_violation', headerName: 'Leave Violation', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'deduction_monthly_total', headerName: 'Deduction Monthly Total', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'net_total', headerName: 'Net Total', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'bank_name', headerName: 'Bank Name', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'account_no', headerName: 'Account No', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'IFSC_code', headerName: 'IFSC code', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'branch_name', headerName: 'Branch Name', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'PAN_no', headerName: 'PAN No', width: 150, align: 'center', headerAlign: 'center' },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 175,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <>
                    <Button variant="primary" size="sm" className="me-2" onClick={(e) => {
                        handleEditEmployee(params.row.id, params);
                        handleShowModal();
                    }}>
                        Edit
                    </Button>
                    {/* <Button variant="primary" size="sm" className="me-2" onClick={(e) => handleEditEmployee(params.row.id, params)}>
                        Edit
                    </Button> */}
                    <Modal show={showModal} onHide={handleCloseModal} className="custom-modal">
                        <Modal.Header closeButton className="custom-modal-header">
                            <Modal.Title className="custom-modal-title">Edit Employee Details</Modal.Title>
                        </Modal.Header>
                        <form>
                            <Modal.Body className="custom-modal-body">
                                {renderForm()}
                            </Modal.Body>
                            <Modal.Footer className="custom-modal-footer">
                                <Button variant="secondary" onClick={handleCloseModal}>
                                    Close
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={async (e) => {
                                        e.preventDefault(); // Prevent default form submission if applicable
                                        await handleSubmit();
                                        handleCloseModal();
                                    }}
                                >
                                    Save
                                </Button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                    <Button variant="danger" size="sm" className="me-2" onClick={() => openConfirmationModal(params.row._id)}>
                        Delete
                    </Button>
                    <Button variant="primary" size="sm" className="me-2" onClick={() => handleClick(params.row)}>
                        PDF
                    </Button>
                </>
            ),
        },
    ];

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();

        const payload = { ...editEmployeeData, ...formData };

        // Merge arrays for basic, allowence, and deduction
        ['basic', 'allowence', 'deduction'].forEach(key => {
            if (formData[key]) {
                payload[key] = formData[key];
            }
        });

        setLoading(true);
        try {
            const response = await update_salary_structure_setup(payload);
            console.log('Update successful:', response);
            setSubmitted(!submitted);
            setFormData({});
        } catch (error) {
            console.error('Error updating salary structure:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        setSalaryForm({ ...salaryForm, [name]: value });
        console.log(salaryForm);
    };

    const handlePageSizeChange = (event) => {
        setpageSize(parseInt(event.target.value, 10));
    };

    const handlePageChange = (event) => {
        setPage(parseInt(event.target.value, 10));
        console.log(event.target.value)
    };


    const handleInputChange = (e, key, index = null) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData };

        if (index !== null) {
            const [field, subIndex] = name.split('[');
            const subField = field.trim();
            const subFieldIndex = parseInt(subIndex.split(']')[0], 10);
            updatedFormData[subField][subFieldIndex].amount = value;
        } else {
            updatedFormData[key] = value;
        }

        setFormData(updatedFormData);
        calculateNetTotal(updatedFormData);
    };

    const calculateNetTotal = (data) => {
        const basicTotal = data.basic.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
        const allowanceTotal = data.allowence.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);
        const deductionTotal = data.deduction.reduce((sum, item) => sum + parseFloat(item.amount || 0), 0);

        const totalViolations = parseFloat(data.break_violation || 0) +
            parseFloat(data.leave_violation || 0) +
            parseFloat(data.late_violation || 0);

        const netTotal = basicTotal + allowanceTotal - deductionTotal - totalViolations;
        // const grossTotal = basicTotal + allowanceTotal;
        setFormData((prevState) => ({ ...prevState, net_total: netTotal }));
    };


    const handleviewsalarystructuresetup = async () => {
        setLoading(true);
        try {
            const response = await viewsalarystructuresetuppaggination({ year: `${salaryForm.year}`, month: `${salaryForm.month}`, page: page, pageSize: pageSize });
            console.log("API response:", response);

            if (response.success) {
                const fetchedRows = response.data.map((item, index) => {
                    // Log item to inspect its structure
                    console.log('item:', item);

                    // Ensure item.basic is processed correctly based on its structure
                    const basicAmt = Array.isArray(item.basic) ? item.basic.map((value) => value.monthly) :
                        (item.basic && item.basic.monthly ? [item.basic.monthly] : []);
                    console.log(basicAmt);

                    const bank_info = Array.isArray(item.bank_information) ? item.bank_information.map((bank) => ({
                        bank_name: bank.bank_name,
                        account_no: bank.account_no,
                        IFSC_code: bank.IFSC_code,
                        branch_name: bank.branch_name,
                        PAN_no: bank.PAN_no,
                    })) : [];
                    console.log(bank_info);

                    // Initialize allowances
                    let hraAmount = 0;
                    let conveyanceAmount = 0;
                    let medicalAmount = 0;
                    let incentiveAmount = 0;

                    // Ensure item.allowence is an array and process allowances
                    if (Array.isArray(item.allowence)) {
                        item.allowence.forEach((allowance) => {
                            switch (allowance.name) {
                                case 'hra':
                                    hraAmount = allowance.monthly;
                                    break;
                                case 'conveyance':
                                    conveyanceAmount = allowance.monthly;
                                    break;
                                case 'medical':
                                    medicalAmount = allowance.monthly;
                                    break;
                                case 'incentive':
                                    incentiveAmount = allowance.monthly;
                                    break;
                                default:
                                    break;
                            }
                        });
                    }



                    let totalEarning = hraAmount + conveyanceAmount + medicalAmount + incentiveAmount;
                    let pfAmount = 0;
                    let proftaxAmount = 0;
                    let esicAmount = 0;
                    let loandeductionNew = 0;

                    // Ensure item.deduction is an array and process deductions
                    if (Array.isArray(item.deduction)) {
                        item.deduction.forEach((deduction) => {
                            switch (deduction.name) {
                                case 'pf':
                                    pfAmount = deduction.monthly;
                                    break;
                                case 'proftax':
                                    proftaxAmount = deduction.monthly;
                                    break;
                                case 'esic':
                                    esicAmount = deduction.monthly;
                                    break;
                                case 'loandeduction':
                                    loandeductionNew = deduction.monthly;
                                    break;
                                default:
                                    break;
                            }
                        });
                    }

                    let totalDeductions = pfAmount + proftaxAmount + esicAmount + loandeductionNew;

                    return {
                        id: item._id || `${index + 1}`,  // Ensure unique ID
                        serial: index + 1,
                        name: item.name,
                        // gross_month_total: (item.gross_month_total != null ? Number(item.gross_month_total) : 0).toFixed(2),
                        gross_month_total: item.basic_amount,
                        deduction_monthly_total: item.deduction_monthly_total,
                        basic: basicAmt || [],
                        allowance: Array.isArray(item.allowence) ? item.allowence.map(a => a.monthly) : [],
                        deduction: Array.isArray(item.deduction) ? item.deduction.map(d => d.monthly) : [],
                        working_days: item.working_days,
                        absent_days: item.absent_days,
                        present_days: item.present_days,
                        break_violation: (item.break_violation != null ? Number(item.break_violation) : 0).toFixed(2),
                        late_violation: (item.late_violation != null ? Number(item.late_violation) : 0).toFixed(2),
                        leave_violation: (item.leave_violation != null ? Number(item.leave_violation) : 0).toFixed(2),
                        net_total: (item.net_total != null ? Number(item.net_total) : 0).toFixed(2),

                        bank_name: item.bank_information && item.bank_information[0] ? item.bank_information[0].bank_name : 'N/A',
                        account_no: item.bank_information && item.bank_information[0] ? item.bank_information[0].account_no : 'N/A',
                        IFSC_code: item.bank_information && item.bank_information[0] ? item.bank_information[0].IFSC_code : 'N/A',
                        branch_name: item.bank_information && item.bank_information[0] ? item.bank_information[0].branch_name : 'N/A',
                        PAN_no: item.bank_information && item.bank_information[0] ? item.bank_information[0].PAN_no : 'N/A'
                    };
                });

                console.log("Fetched rows:", fetchedRows);
                setRows(fetchedRows);
                setTotalPages(response.totalPages);
                setpageSize(pageSize);

            } else {
                console.error("API response was not successful");
            }
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        } finally {
            setLoading(false);
        }
    };

    // const handleviewsalarystructuresetup = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await viewsalarystructuresetup({ year: `${salaryForm.year}`, month: `${salaryForm.month}` });
    //         console.log("API response:", response);

    //         if (response.success) {
    //             const fetchedRows = response.data.map((item, index) => {
    //                 // Log item to inspect its structure
    //                 console.log('item:', item);

    //                 // Ensure item.basic is processed correctly based on its structure
    //                 const basicAmt = Array.isArray(item.basic) ? item.basic.map((value) => value.monthly) :
    //                     (item.basic && item.basic.monthly ? [item.basic.monthly] : []);
    //                 console.log(basicAmt);

    //                 const bank_info = Array.isArray(item.bank_information) ? item.bank_information.map((bank) => ({
    //                     bank_name: bank.bank_name,
    //                     account_no: bank.account_no,
    //                     IFSC_code: bank.IFSC_code,
    //                     branch_name: bank.branch_name,
    //                     PAN_no: bank.PAN_no,
    //                 })) : [];
    //                 console.log(bank_info);

    //                 // Initialize allowances
    //                 let hraAmount = 0;
    //                 let conveyanceAmount = 0;
    //                 let medicalAmount = 0;
    //                 let incentiveAmount = 0;

    //                 // Ensure item.allowence is an array and process allowances
    //                 if (Array.isArray(item.allowence)) {
    //                     item.allowence.forEach((allowance) => {
    //                         switch (allowance.name) {
    //                             case 'hra':
    //                                 hraAmount = allowance.monthly;
    //                                 break;
    //                             case 'conveyance':
    //                                 conveyanceAmount = allowance.monthly;
    //                                 break;
    //                             case 'medical':
    //                                 medicalAmount = allowance.monthly;
    //                                 break;
    //                             case 'incentive':
    //                                 incentiveAmount = allowance.monthly;
    //                                 break;
    //                             default:
    //                                 break;
    //                         }
    //                     });
    //                 }



    //                 let totalEarning = hraAmount + conveyanceAmount + medicalAmount + incentiveAmount;
    //                 let pfAmount = 0;
    //                 let proftaxAmount = 0;
    //                 let esicAmount = 0;
    //                 let loandeductionNew = 0;

    //                 // Ensure item.deduction is an array and process deductions
    //                 if (Array.isArray(item.deduction)) {
    //                     item.deduction.forEach((deduction) => {
    //                         switch (deduction.name) {
    //                             case 'pf':
    //                                 pfAmount = deduction.monthly;
    //                                 break;
    //                             case 'proftax':
    //                                 proftaxAmount = deduction.monthly;
    //                                 break;
    //                             case 'esic':
    //                                 esicAmount = deduction.monthly;
    //                                 break;
    //                             case 'loandeduction':
    //                                 loandeductionNew = deduction.monthly;
    //                                 break;
    //                             default:
    //                                 break;
    //                         }
    //                     });
    //                 }

    //                 let totalDeductions = pfAmount + proftaxAmount + esicAmount + loandeductionNew;

    //                 return {
    //                     id: item._id || `${index + 1}`,  // Ensure unique ID
    //                     serial: index + 1,
    //                     name: item.name,
    //                     gross_month_total: (item.gross_month_total != null ? Number(item.gross_month_total) : 0).toFixed(2),
    //                     deduction_monthly_total: item.deduction_monthly_total,
    //                     basic: basicAmt || [],
    //                     allowance: Array.isArray(item.allowence) ? item.allowence.map(a => a.monthly) : [],
    //                     deduction: Array.isArray(item.deduction) ? item.deduction.map(d => d.monthly) : [],
    //                     working_days: item.working_days,
    //                     absent_days: item.absent_days,
    //                     present_days: item.present_days,
    //                     break_violation: (item.break_violation != null ? Number(item.break_violation) : 0).toFixed(2),
    //                     late_violation: (item.late_violation != null ? Number(item.late_violation) : 0).toFixed(2),
    //                     leave_violation: (item.leave_violation != null ? Number(item.leave_violation) : 0).toFixed(2),
    //                     net_total: (item.net_total != null ? Number(item.net_total) : 0).toFixed(2),

    //                     bank_name: item.bank_information && item.bank_information[0] ? item.bank_information[0].bank_name : 'N/A',
    //                     account_no: item.bank_information && item.bank_information[0] ? item.bank_information[0].account_no : 'N/A',
    //                     IFSC_code: item.bank_information && item.bank_information[0] ? item.bank_information[0].IFSC_code : 'N/A',
    //                     branch_name: item.bank_information && item.bank_information[0] ? item.bank_information[0].branch_name : 'N/A',
    //                     PAN_no: item.bank_information && item.bank_information[0] ? item.bank_information[0].PAN_no : 'N/A'
    //                 };
    //             });

    //             console.log("Fetched rows:", fetchedRows);
    //             setRows(fetchedRows);

    //         } else {
    //             console.error("API response was not successful");
    //         }
    //     } catch (error) {
    //         console.error("Error fetching salary structure setup:", error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const renderForm = () => {
        if (!editEmployeeData) return null;

        // Filter out keys that should not be displayed
        const filteredKeys = Object.keys(editEmployeeData).filter((key) =>
            [
                "name",
                "basic",
                "allowence",
                "deduction",
                "working_days",
                "absent_days",
                "present_days",
                "break_violation",
                "leave_violation",
                "late_violation",
                "net_total",
            ].includes(key)
        );

        return (
            <form>
                {filteredKeys.map((key) => {
                    const value = formData[key];

                    if (!Array.isArray(value)) {
                        return (
                            <Form.Group as={Row} className="mb-3" key={key}>
                                <Form.Label column sm="4">
                                    {key}:
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control
                                        type="text"
                                        name={key}
                                        value={formData[key]}
                                        onChange={(e) => handleInputChange(e, key)}
                                    />
                                </Col>
                            </Form.Group>
                        );
                    }

                    return (
                        <div key={key}>
                            <h5 className="mt-4">{key.charAt(0).toUpperCase() + key.slice(1)}</h5>
                            {value.map((item, index) => (
                                <Form.Group as={Row} className="mb-3" key={`${key}-${index}`}>
                                    <Form.Label column sm="4">
                                        {item.name}:
                                    </Form.Label>
                                    <Col sm="8">
                                        <Form.Control
                                            type="text"
                                            name={`${key}[${index}].monthly`}
                                            value={formData[key][index].monthly}
                                            onChange={(e) => handleInputChange(e, key, index)}
                                        />
                                    </Col>
                                </Form.Group>
                            ))}
                        </div>
                    );
                })}
                <Button variant="primary" onClick={() => calculateNetTotal(formData)}>Calculate Total</Button>
            </form>
        );
    };

    const handleEditEmployee = async (id, params) => {
        setRowId(Number(id))
        console.log(params);
        // alert(id);

        setLoading(true);
        try {
            const response = await editsalarystructuresetup({ year: `${salaryForm.year}`, month: `${salaryForm.month}`, name: `${params.row.name}` });
            console.log("edit API response:", response);

            // setFormData(response.data.emp_new_salary)

            if (response.success) {
                setEditEmployeeData(response.data.emp_new_salary)
                // setFormData({ ...formData, id: response.data.emp_new_salary._id, year: response.data.emp_new_salary.year, month: response.data.emp_new_salary.month })
                setShowModal(true);
            } else {
                console.error("API response was not successful");
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching salary structure setup:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };


    const filterRows = () => {
        if (!searchText) {
            return Object.entries(employeeSalaryData).map(([id, data], index) => ({ id, serial: index + 1, ...data }));
        }
        return Object.entries(employeeSalaryData)
            .filter(([id, row]) =>
                Object.values(row).some(
                    (value) =>
                        typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
                )
            )
            .map(([id, data], index) => ({ id, serial: index + 1, ...data }));
    };

    const openConfirmationModal = (id) => {
        setDeleteEmployeeId(id);
        setShowConfirmation(true);
    };

    const closeConfirmationModal = () => {
        setShowConfirmation(false);
    };

    const handleDeleteEmployee = async (id) => {
        console.log(`Delete employee with ID: ${id}`);
        setShowConfirmation(false);
    };

    const openAddSalaryModal = () => {
        setShowAddSalaryModal(true);
    };

    const closeAddSalaryModal = () => {
        setShowAddSalaryModal(false);
        setSalaryForm({ month: '', year: '' });
    };

    const handleGenerateSalary = () => {
        const { year, month } = salaryForm;
        setSelectedYear(year);
        setSelectedMonth(month);
        fetchEmployeeSalaryData(year, month.toLowerCase());
        console.log('Generate staff salary with details:', salaryForm);
        closeAddSalaryModal();
    };

    const fetchEmployeeSalaryData = async (year, month) => {
        setLoading(true);
        try {
            const salaryData = await fetchEmployeeSalaryCalculation(year, month);
            setEmployeeSalaryData(salaryData);
            console.log("salaryData", salaryData);

            if (salaryData) {
                toast.success(salaryData.message);
            }
        } catch (error) {
            console.error('Error fetching employee salary data:', error);
        } finally {
            setLoading(false);
        }
    };

    const monthNames = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    const generateMonthOptions = () => {
        return monthNames.map((month, index) => ({
            value: month, // Using month name as value
            label: month, // Using month name as label
        }));
    };



    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => ({
            value: (currentYear + index).toString(),
            label: (currentYear + index).toString(),
        }));
        return years;
    };

    const handleNavigate = () => {
        navigate("/staffSalary");
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/employeesalary">Employee Salary</Link>
                            </li>
                            <li className="breadcrumb-item active">List</li>
                        </ol>
                    </div>
                </div>

                <Card className="popup-card empSalary">
                    <Card.Body>
                        <div className="mb-4 border-bottom text-center">
                            <h3 className="mb-0 pb-3 border-bottom custome-btn">Employee Salary</h3>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-md-5 mb-3">
                                <div className="btnw-100">
                                    <label>Monthly Salary Estimate:</label>
                                    <h4><span>₹</span>{calculatedTotalSalary}</h4>
                                </div>
                            </div>
                            <div className="col-md-7 mb-3"></div>
                            <div className="col-md-2 mb-3">
                                <button onClick={openAddSalaryModal} className="btn btn-primary w-100">Generate Salary</button>
                            </div>
                            {/* <div className="col-md-2 mb-3">
                                <Button variant="contained" className="btn btn-primary w-100" onClick={handleNavigate}>
                                    Add Staff Salary
                                </Button>
                            </div> */}
                            <div className="col-md-3 mb-3">
                                <Row>
                                    <Col md={6} className="mb-3 pt-3">
                                        <Form.Group>
                                            <Form.Control
                                                as="select"
                                                name="year"
                                                // value={formData.year || ""}
                                                // onChange={handleChange}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handleviewsalarystructuresetup(e)
                                                }}
                                            >
                                                <option value="">Select Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3 pt-3">
                                        <Form.Group>
                                            <Form.Control
                                                as="select"
                                                name="month"
                                                // value={formData.month || ""}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                    handleviewsalarystructuresetup(e)
                                                }}
                                            >
                                                <option value="">Select Month</option>
                                                {months.map((month) => (
                                                    <option key={month} value={month}>
                                                        {month.charAt(0).toUpperCase() + month.slice(1)}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div className="col-md-3 mb-3">
                                <Button className="bg-success border-0" onClick={() => handleExportSalary()} style={{ whiteSpace: 'nowrap' }}>
                                    Export Data to Excel Sheet
                                </Button>
                            </div>

                            <div className="col-md-3 mb-3">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchText}
                                        onChange={handleSearch}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="search-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12.5 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>


                        </div>

                        {/* <div className="mb-3 border rounded p-3" style={{ backgroundColor: '#f0f0f0' }}>
                            <strong>Employee Salary Info Monthly:</strong> {selectedDate && selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' })} : salaryForm.month + salaryForm.year
                        </div> */}
                        <div className="mb-3 border rounded p-3" style={{ backgroundColor: '#f0f0f0' }}>
                            <strong>Employee Salary Info Monthly: </strong>
                            {salaryForm.month && salaryForm.year
                                ? `${capitalizeFirstLetter(salaryForm.month)} ${salaryForm.year}`
                                : capitalizeFirstLetter(selectedDate.toLocaleString('default', { month: 'long', year: 'numeric' }))}
                        </div>

                        {/* <div style={{ height: 500, width: '100%' }}>
                            <DataGrid
                                ref={tableRef}
                                rows={rows}
                                columns={columns}
                                pageSize={10}
                                loading={loading}
                                getRowId={(row) => row.id}  // Make sure `id` is unique and present
                            />

                        </div> */}
                        <div style={customStyles.grid}>
                            <DataGrid
                                className="gridColor"
                                rows={rows}
                                columns={columns}
                                pageSize={pageSize}
                                pagination
                                paginationMode="client"
                                getRowId={(row) => row.id}
                                components={{
                                    Pagination: () => (
                                        // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                                        <div className="custom-select-container">
                                            <div>
                                                <label htmlFor="page-size">Rows per page: </label>
                                                <select
                                                    id="page-size"
                                                    value={pageSize}
                                                    onChange={handlePageSizeChange}
                                                >
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label htmlFor="page-number">Page: </label>
                                                <select
                                                    className="page-number"
                                                    id="page-number"
                                                    value={page}
                                                    onChange={handlePageChange}
                                                >
                                                    {[...Array(totalPages).keys()].map((pageNumber) => (
                                                        <option key={pageNumber} value={pageNumber + 1}>
                                                            {pageNumber + 1}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    ),
                                }}
                                componentsProps={{
                                    cell: { style: customStyles.cell },
                                    columnHeader: { style: customStyles.columnHeader },
                                }}
                                rowCount={count}
                                page={page - 1}
                                onPageChange={(newPage) => setPage(newPage + 1)}
                                rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                            />
                        </div>
                    </Card.Body>
                </Card>

                <Modal show={showConfirmation} onHide={closeConfirmationModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this employee?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeConfirmationModal}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={() => handleDeleteEmployee(deleteEmployeeId)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showAddSalaryModal} onHide={closeAddSalaryModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Staff Salary</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="year">Year:</label>
                            <select
                                id="year"
                                className="form-control"
                                value={salaryForm.year}
                                onChange={(e) => setSalaryForm({ ...salaryForm, year: e.target.value })}
                            >
                                <option value="">Select Year</option>
                                {generateYearOptions().map((year) => (
                                    <option key={year.value} value={year.value}>
                                        {year.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="month">Month:</label>
                            <select
                                id="month"
                                className="form-control"
                                value={salaryForm.month}
                                onChange={(e) => setSalaryForm({ ...salaryForm, month: e.target.value })}
                            >
                                <option value="">Select Month</option>
                                {generateMonthOptions().map((month) => (
                                    <option key={month.value} value={month.value}>
                                        {month.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeAddSalaryModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleGenerateSalary}>
                            Generate
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    );

};

export default Employeesalary;