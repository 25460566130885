
import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { debounce } from 'lodash';
import { editEmployeeAttendanceAdmin, updateEmployeeAttendanceAdmin } from '../../services/api/api';
import { useNavigate } from 'react-router-dom';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Editempattendance = () => {
    const navigate = useNavigate();
    const { id, masterId } = useParams();
    console.log("id",id);
    const [empId, setEmpId] = useState();

    const [state, setState] = useState({
        _id: '',
        attendance_status: '',
        attendance_time: new Date(),
    });

    const handleTimeChange = (date) => {
        setState((prevState) => ({
            ...prevState,
            attendance_time: date,
        }));
    };

    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const fetchAttendanceData = debounce(async () => {
            try {
                const attendanceData = await editEmployeeAttendanceAdmin(masterId, id);
                setState({
                    _id: attendanceData._id || '',
                    attendance_status: attendanceData.attendance_status || '',
                    attendance_time: attendanceData.attendance_time ? new Date(attendanceData.attendance_time) : new Date(),
                    employee_id: attendanceData.employee_id || '',
                });
                setEmpId(attendanceData.employee_id);
                console.log(empId);
            } catch (error) {
                console.error('Error fetching attendance data:', error);
            }
        }, 100);

        fetchAttendanceData();

        return () => {
            fetchAttendanceData.cancel();
        };
    }, [id, masterId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        if (submitting) {
            return;
        }
        setSubmitting(true);
        try {
            const response = await updateEmployeeAttendanceAdmin(masterId, id, empId, state.attendance_time, state.attendance_status);
            if (response && response.success && response.success.length > 0) {
                toast.success(response.success[0].message, {
                    position: 'top-center',
                    autoClose: 5000,
                });
            } else {
                toast.success('Attendance updated successfully', {
                    position: 'top-center',
                    autoClose: 5000,
                });
                setSubmitting(false);
                navigate('/admin/pages/employeeattendance');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred', {
                position: 'top-right',
                autoClose: 1000,
            });
        }
    };

    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary
        return errors;
    };
    const [deleteEmployeeAttendanceId, setDeleteEmployeeAttendanceId] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const openConfirmationModal = (id) => {
        setDeleteEmployeeAttendanceId(id);
        setShowConfirmation(true);
    };

    const closeConfirmationModal = () => {
        setShowConfirmation(false);
    };
    const handleDeleteEmployeeAttendance = async (id, masterId) => {
        setDeleteEmployeeAttendanceId(id);
        setShowConfirmation(true);

        try {
            const response = await deleteEmployeeAttendanceId(id, masterId);
            if (response && response.status === 'success') {
                if (!toast.isActive('employeeAttendanceDeletedToast')) {
                    toast.success('Employee attendance deleted successfully', { toastId: 'employeeAttendanceDeletedToast', autoClose: 1000 });
                }
                // fetchData();
            } else {
                // Handle failure case
            }
        } catch (error) {
            console.error('Error deleting employee attendance:', error);
        }
    };


    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Edit attendance</h3>
                </div>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/attendance">Attendance</Link>
                            </li>
                            <li className="breadcrumb-item active">Edit Attendance</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Link to="/admin/pages/employeeattendance" className="btn btn-danger mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-md-12 formcontrols">
                        <div className="bg-white p-4 rounded">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="mb-3">
                                            <label htmlFor="attendance_status" className="form-label">
                                                Attendance Status
                                            </label>
                                            <select
                                                className={`form-select ${errors.attendance_status && 'is-invalid'}`}
                                                id="attendance_status"
                                                name="attendance_status"
                                                value={state.attendance_status}
                                                onChange={handleChange}
                                            >
                                                <option value="IN">IN</option>
                                                <option value="OUT">OUT</option>
                                            </select>
                                            {errors.attendance_status && (
                                                <div className="invalid-feedback">{errors.attendance_status}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="attendance_time" className="form-label">
                                                Attendance Time
                                            </label>
                                            <br />
                                            <DatePicker
                                                selected={state.attendance_time}
                                                onChange={handleTimeChange}
                                                showTimeSelect
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                className="form-control custom-date-picker"
                                                inputClassName="custom-date-input"
                                            />
                                        </div>
                                    </div>
                                </div>


                                <Button className='custom-submit-btn' type="submit">
                                    {submitting && (
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                    {submitting ? 'Submitting...' : 'Update Employee Attendance'}
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showConfirmation} onHide={closeConfirmationModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this department?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeConfirmationModal}>
                        Cancel
                    </Button>
                    {/* <Button
              variant="danger"
              onClick={() => {
                handledeleteHoliday(deleteHolidayId);
                closeConfirmationModal();
              }}
            >
              Delete
            </Button> */}
                    <Button
                        variant="danger"
                        onClick={() => {
                            handleDeleteEmployeeAttendance(deleteEmployeeAttendanceId, masterId);
                            closeConfirmationModal();
                        }}

                    >
                        Delete
                    </Button>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};

export default Editempattendance;

