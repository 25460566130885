import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';

import { updateAnnouncement, editAnnouncement, viewAnnouncement } from '../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const Editannouncement = () => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false)
    const { id, masterId } = useParams();
    const [mastersId, setMasterId] = useState('');
    const [announcementData, setAnnouncementData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [state, setState] = useState({
        _id: '',
        company_id: '',
        announcement_subject: '',
    });

    const [errors, setErrors] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const formattedData = await viewAnnouncement();

                if (!formattedData || !formattedData.length) {
                    throw new Error('Failed to fetch data.');
                }

                const mastersId = formattedData[0]._id;
                setMasterId(mastersId);

                const announcementsArray = formattedData.reduce((accumulator, currentData) => {
                    const companyAnnouncement = currentData.announcement;

                    if (companyAnnouncement) {
                        if (Array.isArray(companyAnnouncement)) {
                            accumulator.push(
                                ...companyAnnouncement.map((item) => ({
                                    _id: item._id,
                                    announcement_subject: item.announcement_subject || 'N/A',
                                }))
                            );
                        } else if (typeof companyAnnouncement === 'object') {
                            const values = Object.values(companyAnnouncement).map((value) => ({
                                _id: value._id,
                                announcement_subject: value.announcement_subject || 'N/A',
                            }));
                            accumulator.push(...values);
                        }
                    }
                    return accumulator;
                }, []);

                setAnnouncementData(announcementsArray);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        const debouncedFetchData = _debounce(fetchData, 100);

        debouncedFetchData();

        return () => {
            debouncedFetchData.cancel();
        };
    }, []);

    // useEffect(() => {
    //     const fetchAnnouncementData = async () => {
    //         try {
    //             const announcementData = await editAnnouncement(id, mastersId);
    //             console.log(announcementData);

    //             if (announcementData && announcementData.results) {
    //                 const { results } = announcementData;
    //                 setState({
    //                     _id: results._id || '',
    //                     company_id: results.company_id || '',
    //                     announcement_subject: results.announcement_subject || '',
    //                     // Add other state keys if needed
    //                 });
    //             } else {
    //                 console.error('No announcement data found');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching announcement data:', error);
    //         }
    //     };

    //     const debouncedFetchAnnouncementData = _debounce(fetchAnnouncementData, 100);

    //     debouncedFetchAnnouncementData();

    //     return () => {
    //         debouncedFetchAnnouncementData.cancel();
    //     };
    // }, [id, mastersId]);


    useEffect(() => {
        const fetchAnnouncementData = async () => {
            try {
                setLoading(true);
                if (id && mastersId) {
                    const announcementData = await editAnnouncement(id, mastersId);
                    console.log(announcementData);

                    if (announcementData && announcementData.results) {
                        const { results } = announcementData;
                        setState({
                            _id: results._id || '',
                            company_id: results.company_id || '',
                            announcement_subject: results.announcement_subject || '',
                        });
                    } else {
                        console.error('No announcement data found');
                    }
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching announcement data:', error);
                setLoading(false);
            }
        };

        const debouncedFetchAnnouncementData = _debounce(fetchAnnouncementData, 100);

        debouncedFetchAnnouncementData();

        return () => {
            debouncedFetchAnnouncementData.cancel();
        };
    }, [id, mastersId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } if (submitting) {
            return;
        }
        setSubmitting(true)

        try {
            const response = await updateAnnouncement(id, masterId, state);
            if (response && response.success && response.success.length > 0) {
                toast.success(response.success[0].message, {
                    position: 'top-center',
                    autoClose: 1000,
                });
            } else {
                toast.success('Announcement updated successfully', {
                    position: 'top-center',
                    autoClose: 1000,
                });
                setSubmitting(true)
                navigate('/admin/announcement');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred', {
                position: 'top-right',
                autoClose: 1000,
            });
        }
    };




    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary
        return errors;
    };


    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />

            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Edit Announcement</h3>
                </div>
                <div>


                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="/admin/announcement">Announcements</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit Announcements</li>
                            </ol>
                        </div>
                        <nav aria-label="breadcrumb">
                            <Link to="/admin/announcement" className="btn btn-danger mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                    </div>

                    {loading &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }

                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >


                        <div className="col-md-12 formcontrols">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="announcement_subject" className="form-label">
                                                    Announcement subject
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.announcement_subject && 'is-invalid'}`}
                                                    id="announcement_subject"
                                                    name="announcement_subject"
                                                    value={state.announcement_subject}
                                                    onChange={handleChange}
                                                    style={{
                                                        padding: '20px',
                                                        lineHeight: '1',
                                                    }}
                                                />
                                                {errors.announcement_subject && (
                                                    <div className="invalid-feedback">{errors.announcement_subject}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">



                                        </div>
                                    </div>
                                    <button type="submit" className="custom-submit-btn" onClick={handleSubmit}
                                        disabled={submitting}>

                                        {submitting ? 'Submitting...' : 'Update'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    );
};

export default Editannouncement;
