import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card } from "react-bootstrap";
import '../../styles/globals.css';
import '././../../../src/empfile.css'

import $ from 'jquery';
import 'datatables.net';
import { BASE_URL, employeehistorypaggination, viewEmployee, viewEmployeeHistory, viewEmployeehistory } from '../../services/api/api';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { deleteEmployee } from '../../services/api/api';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import * as XLSX from 'xlsx'; // Import XLSX library
import { DataGrid } from '@mui/x-data-grid';
import { TextField } from '@mui/material';



const EmployeeHistory = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [employeehistoryData, setEmployeehistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [count, setCount] = useState(0);
  const pageRef = useRef(page);
  const [error, setError] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  const customStyles = {
    grid: {
      border: '1px solid #ddd',
    },
    cell: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color
      fontWeight: 'bold', // Making text important
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    columnHeader: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

      fontWeight: 'bold',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    lastColumn: {
      borderRight: 'none',
    },
  };

  const handlePageSizeChange = (event) => {
    setpageSize(parseInt(event.target.value, 10));
  };
  const handlePageChange = (event) => {
    setPage(parseInt(event.target.value, 10));
    console.log(event.target.value)
  };
  // const fetchData = async () => {
  //   try {
  //     const formData = {}; 
  //     const response = await viewEmployeehistory(formData);

  //     console.log(response);
  //     if (!response || !response.success) {
  //       throw new Error("Failed to fetch data or empty response.");
  //     }

  //     const employeeData = response.success; // Extract the array of employee records
  //     setEmployeehistoryData(employeeData);
  //     console.log("Fetched Employee Data:", employeeData); // Check the fetched data
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);


  const fetchData = async () => {
    try {
      const formData = {};
      const response = await employeehistorypaggination(page, pageSize);
      // const response = await viewEmployeehistory(formData);

      console.log(response);
      if (!response || !response.success) {
        throw new Error("Failed to fetch data or empty response.");
      }

      const employeeData = response.data;
      setEmployeehistoryData(employeeData);
      setTotalPages(response.totalPages);
      setpageSize(pageSize);
      console.log("Fetched Employee Data:", employeeData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);

    }
  };

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [page, pageSize]);

  const getToken = () => {
    const token = sessionStorage.getItem('token');

    return token ? `Bearer ${token}` : '';


  };

  const handleExportEmployeeHistory = async () => {
    setLoading(true); // Show loading state

    try {
      const response = await fetch(`${BASE_URL}/exportEmployeeHistoryData`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "Authorization": getToken(),
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Convert JSON data to CSV
      const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

      // Create a Blob and trigger the download
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'employee_data.csv'; // Change the file name as needed
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);



    } catch (error) {
      console.error("Fetch error: ", error);
      setError(error.message || 'An error occurred');



    } finally {
      setLoading(false); // Hide loading state
    }
  };

  function convertToCSV(data) {
    const array = [Object.keys(data[0])].concat(data);

    return array.map(row => {
      return Object.values(row).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',');
    }).join('\n');
  }


  // useEffect(() => {
  //   if ($.fn.DataTable.isDataTable('#commonTable')) {
  //     $('#commonTable').DataTable().destroy();
  //   }

  //   if ($.fn.DataTable) {
  //     tableRef.current = $('#commonTable').DataTable({
  //       data: employeehistoryData.map((dataItem, index) => {
  //         return [
  //           employeehistoryData.length - index,
  //           `<span style="white-space: nowrap">${dataItem.first_name} ${dataItem.last_name}</span>`,
  //           dataItem.email,
  //           formatDate(dataItem.joining_date),
  //           dataItem.delete_status,
  //         ];
  //       }),
  //       columns: [
  //         { title: 'Sr.' },
  //         { title: 'Name', className: 'single-line' },
  //         // { title: 'Email' },

  //         {
  //           title: 'Email',
  //           render: function (data, type, row) {
  //             const email = row[2];
  //             const emailText = typeof email === 'string' ? email : '';
  //             const truncatedEmail = emailText.length > 10 ? `${emailText.slice(0, 10)}...` : emailText;

  //             return `<span 
  //                       data-toggle="tooltip" 
  //                       data-placement="top" 
  //                       title="${emailText}" 
  //                       style="
  //                         white-space: nowrap; 
  //                         cursor: pointer; 
  //                         position: relative;
  //                       "
  //                     >
  //                       ${truncatedEmail}
  //                       <span 
  //                         class="tooltip-text" 
  //                         style="
  //                           visibility: hidden; 
  //                           width: auto; 
  //                           background-color: black; 
  //                           color: white; 
  //                           text-align: center; 
  //                           border-radius: 6px; 
  //                           padding: 5px; 
  //                           position: absolute; 
  //                           z-index: 1; 
  //                           bottom: 125%; 
  //                           left: 50%; 
  //                           margin-left: -60px; 
  //                           opacity: 0; 
  //                           transition: opacity 0.3s;
  //                         "
  //                       >
  //                         ${emailText}
  //                       </span>
  //                     </span>`;
  //           }
  //         },

  //         { title: 'Joining' + '\xa0'.repeat(2) + 'Date' },
  //         // { title: 'Delete Status' },
  //         // {
  //         //   title: 'Delete Status',
  //         //   render: function (data, type, row) {
  //         //     const deleteStatus = row[4]; // Assuming the delete_status is in the 5th position of the row array
  //         //     const statusText = deleteStatus === 'YES' ? 'Deactive' : 'Active';
  //         //     return statusText;
  //         //   }
  //         // },
  //         {
  //           title: 'Delete Status',
  //           render: function (data, type, row) {
  //             const deleteStatus = row[4]; // Assuming the delete_status is in the 5th position of the row array
  //             const statusText = deleteStatus === 'YES' ? 'Deactive' : 'Active';
  //             const statusStyle = deleteStatus === 'YES'
  //               ? 'border: 1px solid red; color: red;'
  //               : 'border: 1px solid green; color: green;';
  //             const hoverStyle = deleteStatus === 'YES'
  //               ? 'background-color: lightcoral;'
  //               : 'background-color: lightgreen;';

  //             const span = document.createElement('span');
  //             span.textContent = statusText;
  //             span.style.cssText = `
  //               display: inline-block;
  //               padding: 0px 10px;
  //               border-radius: 4px;
  //               transition: background-color 0.3s, border-color 0.3s;
  //               ${statusStyle}
  //               height: 30px; /* Set a fixed height for consistency */
  //               line-height: 30px; /* Center text vertically */
  //             `;

  //             span.addEventListener('mouseover', function () {
  //               this.style.cssText += hoverStyle;
  //             });

  //             span.addEventListener('mouseout', function () {
  //               this.style.backgroundColor = '';
  //             });

  //             return span.outerHTML;
  //           }
  //         }


  //       ],
  //     });
  //   }

  // }, [employeehistoryData]);

  const showTooltip = (element) => {
    const tooltip = element.querySelector('.tooltip-text');
    tooltip.style.visibility = 'visible';
    tooltip.style.opacity = '1';
  };

  const hideTooltip = (element) => {
    const tooltip = element.querySelector('.tooltip-text');
    tooltip.style.visibility = 'hidden';
    tooltip.style.opacity = '0';
  };

  const emailCells = document.querySelectorAll('#commonTable tbody tr td:nth-child(3)');

  emailCells.forEach((cell) => {
    cell.addEventListener('mouseover', () => {
      showTooltip(cell);
    });

    cell.addEventListener('mouseout', () => {
      hideTooltip(cell);
    });

    cell.addEventListener('click', (event) => {
      event.stopPropagation();
      hideTooltip(cell);
    });
  });
  const handleTooltips = () => {
    const emailCells = document.querySelectorAll('#commonTable tbody tr td:nth-child(3)');

    emailCells.forEach((cell) => {
      cell.addEventListener('mouseover', () => {
        showTooltip(cell);
      });

      cell.addEventListener('mouseout', () => {
        hideTooltip(cell);
      });

      cell.addEventListener('click', (event) => {
        event.stopPropagation();
        hideTooltip(cell);
      });
    });
  };
  handleTooltips();

  $('#commonTable').on('draw.dt', () => {
    handleTooltips();
  });

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Create a new worksheet with the data
    const worksheet = XLSX.utils.json_to_sheet(employeehistoryData.map(row => ({
      'Sr.': employeehistoryData.indexOf(row) + 1,
      'Name': `${row.first_name} ${row.last_name}`,
      'Email': row.email || '',
      'Joining Date': formatDate(row.joining_date),
      'Delete Status': row.delete_status,
    })));
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // Convert the workbook to a binary string
    const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Convert the binary string to a Blob
    const excelBlob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = 'Employee data.xlsx';
    // Trigger the download
    downloadLink.click();
  };
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Sr.',
      width: 20, align: 'left', headerAlign: 'left',
      flex: 1,
      renderCell: (params) => (
        <span>
          {employeehistoryData.indexOf(params.row) + 1}

        </span>

      ),
    },

    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      flex: 2,
      renderCell: (params) => (
        <span>
          {params.row.first_name} {params.row.last_name}
        </span>
      )
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      flex: 2,
      renderCell: (params) => (
        <span>{params.row.email || 'No email available'}</span>
      ),
    },


    {
      field: 'joining_date',
      headerName: 'Joining Date',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      flex: 2,
      renderCell: (params) => (
        <span>
          {formatDate(params.row.joining_date)}
        </span>
      ),
    },
    {
      field: 'delete_status',
      headerName: 'Delete Status',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      flex: 2,
      renderCell: (params) => (
        <span>
          {(params.row.delete_status)}
        </span>
      ),
    },



  ];

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      {/* Your header components */}
      <div className="main p-4 p-lg-5 mt-5">
        {/* Your breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                <h4>Employees</h4>
              </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/employeehistory">Employee history</Link></li>
              <li class="breadcrumb-item active">Lists</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            {/* <Button variant="primary" className="mb-3">
              <Link to="/pages/employee/employeeform">
                <span style={{ color: "white" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>
                  Create Employees
                </span>
              </Link>
            </Button> */}
          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            {/* Your button section */}
            <Button className="bg-success border-0 mb-3" onClick={handleExportEmployeeHistory} style={{ whiteSpace: 'nowrap' }}>
              Export Data to Excel Sheet
            </Button>

            <div style={customStyles.grid}>
              <DataGrid
                className="gridColor"
                rows={employeehistoryData}
                columns={columns}
                pageSize={pageSize}
                pagination
                paginationMode="client"
                getRowId={(row) => row._id}
                components={{
                  Pagination: () => (
                    // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                    <div className="custom-select-container">
                      <div>
                        <label htmlFor="page-size">Rows per page: </label>
                        <select
                          id="page-size"
                          value={pageSize}
                          onChange={handlePageSizeChange}
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                        </select>
                      </div>
                      <div>
                        <label htmlFor="page-number">Page: </label>
                        <select
                          className="page-number"
                          id="page-number"
                          value={page}
                          onChange={handlePageChange}
                        >
                          {[...Array(totalPages).keys()].map((pageNumber) => (
                            <option key={pageNumber} value={pageNumber + 1}>
                              {pageNumber + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ),
                }}
                componentsProps={{
                  cell: { style: customStyles.cell },
                  columnHeader: { style: customStyles.columnHeader },
                }}
                rowCount={count}
                page={page - 1}
                onPageChange={(newPage) => setPage(newPage + 1)}
                rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
              />
            </div>

            {/* {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <table id="commonTable" className="display table table-striped table-curved" width="100%"></table>
            )} */}
          </Card.Body>
        </Card>
      </div>

    </React.Fragment>
  );

}
export default EmployeeHistory;




















