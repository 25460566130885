     
  import React, { useState, useEffect, useRef } from 'react';
  import Header from '../layouts/Header';
  import HeaderMobile from '../layouts/HeaderMobile';
  import { Card, Button } from "react-bootstrap";
  import '../styles/globals.css';
  import $ from 'jquery';
  import 'datatables.net';
  import { Link } from "react-router-dom";
  import { Col, Dropdown, Nav, ProgressBar, Row, Table } from "react-bootstrap";
  
  const Filemanager = () => {
    const tableRef = useRef(null);
  
    const [activeButton, setActiveButton] = useState('open');
  
    const handleButtonClick = (button) => {
      setActiveButton(button);
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <Link
        to=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="dropdown-link"
      >
        {children}
      </Link>
    ));
  
 
  
    return (
      <React.Fragment>
        <HeaderMobile />
        <Header />
        <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
            <div>
              <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                {/* <li className="mx-2">
                  <h4>Employee History</h4>
                </li> */}
                <li class="breadcrumb-item ">
                      <Link to="/dashboard/helpdesk">Dashboard</Link>
                    </li>
                    <li class="breadcrumb-item active"><Link to="/pages/filemanager">Filemanager</Link></li>
                    <li class="breadcrumb-item active">Lists</li>
                {/* <li className="mx-2">
                  <Button
                    variant=""
                    className={`mb-3 btn-1 ${activeButton === 'posted' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('posted')}
                  >
                    Posted Jobs(0)
                  </Button>
                </li>
                <li className="mx-2">
                  <Button
                    variant=""
                    className={`mb-3 btn-1 ${activeButton === 'career' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('career')}
                  >
                    Posted On a Career Site(0)
                  </Button>
                </li> */}
              </ol>
            </div>
            {/* <nav aria-label="breadcrumb">
            <Button variant="primary" className="mb-3">
                    <Link to="/pages/#">
                      <span style={{ color: "white" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg>
                        Create a Branch
                      </span>
                    </Link>
                  </Button>
            </nav> */}
          </div>
          
          <Card className="popup-card empHistory">
            <Card.Body>
            <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                <div>
                <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
    <li className="mx-1">
      <Button
        variant=""
        className={`mb-3 btn-1 ${activeButton === 'open' ? 'active' : ''}`}
        onClick={() => handleButtonClick('open')}
        style={{
          backgroundColor: activeButton === 'open' ? '#506fd9' : 'white',
          color: activeButton === 'open' ? 'white' : 'black',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-hearts" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z" />
        </svg>Filemanager
      </Button>
    </li>
    <li className="mx-2">
      <Button
        variant=""
        className={`mb-3 btn-1 ${activeButton === 'closed' ? 'active' : ''}`}
        onClick={() => handleButtonClick('closed')}
        style={{
          backgroundColor: activeButton === 'closed' ? '#506fd9' : 'white',
          color: activeButton === 'closed' ? 'white' : 'black',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg> Status
      </Button>
    </li>
    <li className="mx-2">
      <Button
        variant=""
        className={`mb-3 btn-1 ${activeButton === 'actions' ? 'active' : ''}`}
        onClick={() => handleButtonClick('actions')}
        style={{
          backgroundColor: activeButton === 'actions' ? '#506fd9' : 'white',
          color: activeButton === 'actions' ? 'white' : 'black',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
          <path d="M11.133 1.962a6.3 6.3 0 0 1 3.139 5.101H14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-1.728a6.3 6.3 0 0 1-3.138 5.101A1 1 0 0 1 9 15a1 1 0 0 1-1-1v-1.961a6.3 6.3 0 0 1-3.139-5.101H2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h1.728A6.3 6.3 0 0 1 6.866 1.961 1 1 0 0 1 9 1a1 1 0 0 1 1 1v1.961zM8 2a4.278 4.278 0 0 0-2.121.575 4.279 4.279 0 0 0-2.121-1.15V1h-1v2.426a4.279 4.279 0 0 0-2.121 1.15A4.278 4.278 0 0 0 0 4.726h2.426a4.279 4.279 0 0 0 1.15 2.121 4.279 4.279 0 0 0-1.15 2.121H1v1h2.426a4.279 4.279 0 0 0 1.15 2.121 4.278 4.278 0 0 0 2.121 1.15V15h1v-2.426a4.279 4.279 0 0 0 2.121-1.15A4.278 4.278 0 0 0 16 11.274H13.574a4.279 4.279 0 0 0-1.15-2.121 4.278 4.278 0 0 0 1.15-2.121H15v-1h-2.426a4.279 4.279 0 0 0-1.15-2.121A4.278 4.278 0 0 0 8 2z"/>
        </svg> Actions
      </Button>
    </li>
   
  </ol>
  
                </div>
  
                <nav aria-label="breadcrumb">
                <Button variant="primary" className="mb-3">
  
  
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
  </svg>
  Export
  
  
  </Button>
                </nav>
              </div>
              {/* <table id="commonTable" className="display table table-striped table-curved" width="100%" ref={tableRef}></table> */}
           
              <h5 className="mb-0 text-dark fw-semibold">All Files</h5>
          


              <label className="d-block fs-sm text-secondary mb-3">Recently Accessed Files</label>
          <Row className="g-1 g-sm-2 g-xl-3 mb-5">
            {[
              {
                "bg": "danger",
                "icon": "ri-file-pdf-line",
                "name": "Medical Certificate.pdf",
                "size": "10.45kb",
                "access": "2 hours ago"
              }, {
                "bg": "primary",
                "icon": "ri-file-word-line",
                "name": "WorkContract.docx",
                "size": "22.67kb",
                "access": "5 hours ago"
              }, {
                "bg": "info",
                "icon": "ri-image-line",
                "name": "IMG_063037.jpg",
                "size": "4.1mb",
                "access": "6 hours ago"
              }, {
                "bg": "success",
                "icon": "ri-file-excel-line",
                "name": "Inventory.xls",
                "size": "965.1kb",
                "access": "6 hours ago"
              }, {
                "bg": "",
                "icon": "ri-file-zip-line",
                "name": "Backup102023.zip",
                "size": "2.31mb",
                "access": "8 hours ago"
              }
            ].map((file, index) => (
              <Col xs="6" sm="4" md="3" xl key={index}>
                <Card className="card-file">
                  <Dropdown align="end" className="dropdown-file">
                    <Dropdown.Toggle as={CustomToggle}>
                      <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#" className="details"><i className="ri-information-line"></i> View Details</Dropdown.Item>
                      <Dropdown.Item href="#" className="important"><i className="ri-star-line"></i> Mark as Important</Dropdown.Item>
                      <Dropdown.Item href="#" className="share"><i className="ri-share-forward-line"></i> Share</Dropdown.Item>
                      <Dropdown.Item href="#" className="download"><i className="ri-download-2-line"></i> Download</Dropdown.Item>
                      <Dropdown.Item href="#" className="copy"><i className="ri-file-copy-line"></i> Copy to</Dropdown.Item>
                      <Dropdown.Item href="#" className="move"><i className="ri-folders-line"></i> Move to</Dropdown.Item>
                      <Dropdown.Item href="#" className="rename"><i className="ri-edit-2-line"></i> Rename</Dropdown.Item>
                      <Dropdown.Item href="#" className="delete"><i className="ri-delete-bin-line"></i> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className={"card-file-icon " + file.bg}>
                    <i className={file.icon}></i>
                  </div>
                  <Card.Body>
                    <h6><Link href="" className="link-02">{file.name}</Link></h6>
                    <span>{file.size}</span>
                  </Card.Body>
                  <Card.Footer>
                    <span className="d-none d-sm-inline">Last access: </span> {file.access}
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>

          <label className="d-block fs-sm text-secondary mb-3">Folders</label>
          <Row className="g-2 g-lg-3 mb-5">
            {[
              {
                "name": "Downloads",
                "files": 2,
                "size": "14.05MB"
              }, {
                "name": "Personal Stuff",
                "files": 8,
                "size": "76.3MB"
              }, {
                "name": "3D Objects",
                "files": 5,
                "size": "126.8MB"
              }, {
                "name": "Recordings",
                "files": 0
              }, {
                "name": "Support",
                "files": 1,
                "size": "268.1KB"
              }, {
                "name": "Cloud Files",
                "files": 23,
                "size": "199.1MB"
              }
            ].map((folder, index) => (
              <Col xs="6" md="4" xl="3" key={index}>
                <Card className="card-folder">
                  <Dropdown align="end" className="dropdown-file">
                    <Dropdown.Toggle as={CustomToggle}>
                      <i className="ri-more-2-fill"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#" className="details"><i className="ri-information-line"></i> View Details</Dropdown.Item>
                      <Dropdown.Item href="#" className="important"><i className="ri-star-line"></i> Mark as Important</Dropdown.Item>
                      <Dropdown.Item href="#" className="share"><i className="ri-share-forward-line"></i> Share</Dropdown.Item>
                      <Dropdown.Item href="#" className="download"><i className="ri-download-2-line"></i> Download</Dropdown.Item>
                      <Dropdown.Item href="#" className="copy"><i className="ri-file-copy-line"></i> Copy to</Dropdown.Item>
                      <Dropdown.Item href="#" className="move"><i className="ri-folders-line"></i> Move to</Dropdown.Item>
                      <Dropdown.Item href="#" className="rename"><i className="ri-edit-2-line"></i> Rename</Dropdown.Item>
                      <Dropdown.Item href="#" className="delete"><i className="ri-delete-bin-line"></i> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Card.Body>
                    <i className="ri-folder-2-line"></i>
                    <div className="card-content">
                      <h6><Link href="" className="link-02">{folder.name}</Link></h6>
                      <span>{folder.files} files{folder.size && (", " + folder.size)}</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <Table className="table table-files" responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Last Modified</th>
                <th>File Size</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {[
                {
                  "color": "primary",
                  "icon": "ri-folder-5-line",
                  "name": "Dashboard Concepts",
                  "date": "Aug 20, 2023",
                  "size": "21.5MB"
                }, {
                  "color": "primary",
                  "icon": "ri-folder-5-line",
                  "name": "Gallery",
                  "date": "Aug 19, 2023",
                  "size": "341.2MB"
                }, {
                  "color": "primary",
                  "icon": "ri-folder-5-line",
                  "name": "Receipts",
                  "date": "Aug 19, 2023",
                  "size": "1.9MB"
                }, {
                  "color": "",
                  "icon": "ri-file-zip-line",
                  "name": "Backups091022.zip",
                  "date": "Aug 10, 2023",
                  "size": "4.7MB"
                }, {
                  "color": "orange",
                  "icon": "ri-file-ppt-line",
                  "name": "Presentation.ppt",
                  "date": "Aug 10, 2023",
                  "size": "11.21MB"
                }, {
                  "color": "success",
                  "icon": "ri-file-excel-line",
                  "name": "Expenses091122.xls",
                  "date": "Sep 11, 2023",
                  "size": "7.38MB"
                }, {
                  "color": "",
                  "icon": "ri-image-2-line",
                  "name": "IMG_101022.jpg",
                  "date": "Sep 10, 2023",
                  "size": "1.26MB"
                }, {
                  "color": "",
                  "icon": "ri-image-2-line",
                  "name": "IMG_101122.jpg",
                  "date": "Sep 10, 2023",
                  "size": "1.83MB"
                }, {
                  "color": "danger",
                  "icon": "ri-file-pdf-line",
                  "name": "Documents001.pdf",
                  "date": "Sep 05, 2023",
                  "size": "876.1KB"
                }, {
                  "color": "danger",
                  "icon": "ri-file-pdf-line",
                  "name": "Geting_Started.pdf",
                  "date": "Aug 01, 2023",
                  "size": "112.4KB"
                }
              ].map((file, index) => (
                <tr key={index}>
                  <td>
                    <div className="media">
                      <div className={"media-icon " + file.color}><i className={file.icon}></i></div>
                      <h6 className="file-name"><Link href="">{file.name}</Link></h6>
                    </div>
                  </td>
                  <td>{file.date}</td>
                  <td>{file.size}</td>
                  <td>
                    <Dropdown align="end" className="dropdown-file">
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-2-fill"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#" className="details"><i className="ri-information-line"></i> View Details</Dropdown.Item>
                        <Dropdown.Item href="#" className="important"><i className="ri-star-line"></i> Mark as Important</Dropdown.Item>
                        <Dropdown.Item href="#" className="share"><i className="ri-share-forward-line"></i> Share</Dropdown.Item>
                        <Dropdown.Item href="#" className="download"><i className="ri-download-2-line"></i> Download</Dropdown.Item>
                        <Dropdown.Item href="#" className="copy"><i className="ri-file-copy-line"></i> Copy to</Dropdown.Item>
                        <Dropdown.Item href="#" className="move"><i className="ri-folders-line"></i> Move to</Dropdown.Item>
                        <Dropdown.Item href="#" className="rename"><i className="ri-edit-2-line"></i> Rename</Dropdown.Item>
                        <Dropdown.Item href="#" className="delete"><i className="ri-delete-bin-line"></i> Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
           
            </Card.Body>
          </Card>
        </div>
      </React.Fragment>
    );
  }
  
  export default Filemanager;
  