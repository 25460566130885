

import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row, Spinner, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Prism from "prismjs";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { companyLogin, viewLogo } from "../services/api/api";
import { useHistory } from "react-router-dom";

import { Eye, EyeSlash } from 'react-bootstrap-icons';
import bg1 from "../assets/img/bg1.jpg";
import bgN1 from "../assets/img/bgN1.jpg";
import bgY1 from "../assets/img/bgY1.png";
import bgS1 from "../assets/img/bgS1.jpg";



const Signin = () => {



  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      const userType = sessionStorage.getItem("userType");
      const dashboardRoutes = {
        admin: "/admin/dashboard",
        employee: "/employee/dashboard",
      };
      const route = dashboardRoutes[userType || "admin" && userType || "employee"];
      navigate(route);
    }
  }, []);





  const [showPopup, setShowPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  // Change the input type based on show/hide password state
  const passwordInputType = showPassword ? 'text' : 'password';


  const [formData, setFormData] = useState({
    email: "",
    password: "",
    type: "admin",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [logoUrl, setLogoUrl] = useState(null);
  const fetchLogo = async () => {
    try {
      const logoUrl = await viewLogo();
      setLogoUrl(logoUrl);
    } catch (error) {
      console.error('Error fetching logo:', error);
      // Handle error fetching logo
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };





  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      let type = '';

      if (formData.type === "admin" || formData.type === "employee") {
        type = formData.type;
      }

      // Convert email to lowercase for comparison
      const emailLowerCase = formData.email.toLowerCase();

      const response = await companyLogin({ ...formData, email: emailLowerCase, type });
      const data = JSON.parse(response);

      if (data.success === false) {
        toast.warning(data.message, {
          autoClose: 5000,
          position: toast.POSITION.TOP_CENTER,
        });
      }

      else {
        if (data.data === "Email Not Verified") {
          toast.warning("Email not verified. Please verify your email.", {
            autoClose: 5000,
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
          return;
        }

        const token = data.data.token;
        const id = data.data.id;
        const companyId = data.data.companyID;
        const userEmail = data.data.userEmail;
        const admin_name = data.data.admin_name;
        const first_name = data.data.first_name;
        const last_name = data.data.last_name;
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("id", id);
        sessionStorage.setItem("companyId", companyId);
        sessionStorage.setItem("userEmail", userEmail);
        sessionStorage.setItem("isLoggedIn", "true")
        sessionStorage.setItem("userType", type);
        sessionStorage.setItem("admin_name", admin_name);
        sessionStorage.setItem("first_name", first_name);
        sessionStorage.setItem("last_name", last_name);

        const dashboardRoutes = {
          admin: "/admin/dashboard",
          employee: "/employee/dashboard",
        };

        const route = dashboardRoutes[formData.type];
        navigate(route);
      }

      setLoading(false);
    }

    catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const validateForm = (data) => {
    let validationErrors = {};

    if (!data.email) {
      validationErrors.email = "Email is required";
    }
    if (!data.password) {
      validationErrors.password = "Password is required";
    }

    return validationErrors;
  };

  const closePopup = () => {
    setShowPopup(false);
  };







  return (
    <div className=" py-0" >
      <div className="d-flex align-items-center justify-content-center" style={{ paddingTop: '50px', backgroundImage: `url(${bgS1})`, backgroundSize: 'cover' }}>
        {/* style={{ height: "100vh", backgroundImage: `url(${bgN1})`, backgroundSize: 'cover' }} */}
        <section className="vh-100" >

          {/* style={{ backgroundColor: "#9A616D" }} */}
          <div class="container py-1 h-95">
            <div class="row d-flex justify-content-center align-items-center h-100">
              <div class="col col-xl-10">
                <div class="card" style={{ borderRadius: "1rem" }}>
                  <div class="row g-0">
                    <div className="col-md-6 col-lg-5 d-none d-md-block" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img
                        src={bgY1}
                        alt="login form"
                        className="img-fluid"
                        style={{ width: '100%', paddingTop: '200px', borderRadius: "1rem 0 0 1rem" }}
                      />
                    </div>
                    <div class="col-md-6 col-lg-7 d-flex align-items-center">
                      <div class="card-body p-4 p-lg-5 text-black">
                        <Card.Body>
                          <div class="d-flex align-items-center mb-0 pb-1">
                            <i class="fas fa-cubes fa-2x me-3" style={{ color: "#ff6219" }}></i>
                            <span class="h1 fw-bold mb-0"> <Link to="/" className="header-logo mb-2">
                              <img src="payroll.png" style={{ width: "200px" }} alt="Logo" />
                            </Link></span>

                          </div>
                          <Card.Title>Sign In</Card.Title>
                          Welcome back! Please sign in to continue.
                          <Card.Text>

                            <div className="form-check form-check-inline mt-0">
                              <label className="containers">Admin
                                <input
                                  type="radio"
                                  value="admin"
                                  id="setadmin"
                                  name="type"
                                  checked={formData.type === "admin"}
                                  onChange={handleChange}
                                  style={{ margin: '9px' }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>

                            <div className="form-check form-check-inline mt-0">
                              <label className="containers">Employee
                                <input
                                  type="radio"
                                  value="employee"
                                  id="setemployee"
                                  name="type"
                                  checked={formData.type === "employee"}
                                  onChange={handleChange}
                                  style={{ margin: '9px' }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </Card.Text>
                          <Form onSubmit={handleSubmit}>
                            <div className="mb-4">
                              <Form.Label>Email address</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your email address"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                              {errors.email && (
                                <Form.Text className="text-danger">{errors.email}</Form.Text>
                              )}
                            </div>


                            <div className="mb-4">
                              <Form.Label className="d-flex justify-content-between">
                                Password <Link to="/pages/forgot">Forgot password?</Link>
                              </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type={passwordInputType}
                                  placeholder="Enter your pasword"
                                  name="password"
                                  value={formData.password}
                                  onChange={handleChange}
                                />
                                <InputGroup.Text onClick={togglePasswordVisibility} className="password-toggle-icon">
                                  {showPassword ? <EyeSlash /> : <Eye />}
                                </InputGroup.Text>
                              </InputGroup>
                              {errors.password && (
                                <Form.Text className="text-danger">{errors.password}</Form.Text>
                              )}
                            </div>
                            {/* <Button type="submit" className="btn-sign" disabled={loading}>
                              Sign In
                            </Button> */}
                            <div class="sub-main">
                              <button className="button-two" type="submit" disabled={loading}><span>Sign In</span></button></div>
                            {loading && (
                              <div
                                style={{
                                  position: "fixed",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  zIndex: "9999",
                                }}
                              >
                                <Spinner animation="border" role="status" />
                              </div>
                            )}

                          </Form>
                        </Card.Body>

                        <Card.Footer>
                          {location.pathname !== "/dashboard" && location.pathname !== "/employee/dashboard" && (
                            <Link to="#"></Link>
                          )}
                          Don't have an account? <Link to="/signup">Create an Account</Link>
                        </Card.Footer>



                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {showPopup && (
        <div className="popup-container">
          <div className="custom-popup">
            <p>Login successful!</p>
            <Button onClick={closePopup}>Close</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signin;


























































































































