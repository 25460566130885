import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const Salaryreportemp = () => {


    const handlePrint = () => {
        window.print();
    };

    return (
        <React.Fragment>
            {/* <Header /> */}
            <style>
                {`
          @media print {
            .headerNew {
              display: none;
            }
          }
        `}
            </style>

            {/* Header section */}
            <div className="headerNew">
                {/* Your Header component content */}
                <Header />
            </div>

            {/* Your other layout code here */}
            <div className="main p-4 p-lg-5 mt-5">
                {/* Your layout structure */}
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Salary Report</h3>
                </div>


                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li class="breadcrumb-item ">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li class="breadcrumb-item active"><Link to="/employee/salaryreport">Salary Report
                            </Link></li>
                            <li class="breadcrumb-item active">Add Branch</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        {/* <Button variant="primary" className="mb-3 custom-submit-btn">
              <Link to="/admin/employeeform">
                <span style={{ color: "white" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                  </svg>
                  
                </span>

              </Link>

            </Button> */}
                        <Link to="">
                            <div><button className="button-oneX" onClick={handlePrint}><span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer" viewBox="0 0 16 16" style={{ marginRight: '10px' }}>
                                    <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                    <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                                </svg>

                                Print</span></button></div>
                        </Link>
                    </nav>
                </div>



                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >
                    <div class="row">
                        <div class="col-md-12">
                            <div class="text-center lh-1 mb-2">
                                {/* <h6 class="fw-bold">Payslip</h6> <span class="fw-normal">PAYSLIP FOR THE MONTH OF JAN 2024</span> */}
                                <div class="border p-3 mt-4">
                                    <div class="d-flex flex-column">
                                        <span class="fw-bold"><u>PAYSLIP FOR THE MONTH OF JAN 2024</u></span>

                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-end"> <span>Working Branch:ROHINI</span> </div>
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div> <span class="fw-bolder">EMP Code</span> <small class="ms-3">39124</small> </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div> <span class="fw-bolder">EMP Name</span> <small class="ms-3">Ashok</small> </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div> <span class="fw-bolder">PF No.</span> <small class="ms-3">101523065714</small> </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div> <span class="fw-bolder">NOD</span> <small class="ms-3">28</small> </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div> <span class="fw-bolder">ESI No.</span> <small class="ms-3"></small> </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div> <span class="fw-bolder">Mode of Pay</span> <small class="ms-3">SBI</small> </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div> <span class="fw-bolder">Designation</span> <small class="ms-3">Marketing Staff (MK)</small> </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div> <span class="fw-bolder">Ac No.</span> <small class="ms-3">*******0701</small> </div>
                                        </div>
                                    </div>
                                </div>
                                <table class="mt-4 table table-bordered">
                                    <thead class="bg-dark text-white">
                                        <tr>
                                            <th scope="col">Earnings</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Deductions</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">Basic</th>
                                            <td>0</td>
                                            <td>PF</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">DA</th>
                                            <td>0</td>
                                            <td>ESI</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">HRA</th>
                                            <td>0</td>
                                            <td>TDS</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">WA</th>
                                            <td>0</td>
                                            <td>LOP</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">CA</th>
                                            <td>0</td>
                                            <td>PT</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">CCA</th>
                                            <td>0</td>
                                            <td>SPL. Deduction</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">MA</th>
                                            <td>0</td>
                                            <td>EWF</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Sales Incentive</th>
                                            <td>0</td>
                                            <td>CD</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Leave Encashment</th>
                                            <td>0</td>
                                            <td colspan="2"></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Holiday Wages</th>
                                            <td>0</td>
                                            <td colspan="2"></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Special Allowance</th>
                                            <td>0</td>
                                            <td colspan="2"></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Bonus</th>
                                            <td>0</td>
                                            <td colspan="2"></td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Individual Incentive</th>
                                            <td>0</td>
                                            <td colspan="2"></td>
                                        </tr>
                                        <tr class="border-top">
                                            <th scope="row">Total Earning</th>
                                            <td>0</td>
                                            <td>Total Deductions</td>
                                            <td>0</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row">
                                <div class="col-md-4"> <br /> <span class="fw-bold">Net Pay : 0</span> </div>
                                <div class="border col-md-8">
                                    <div class="d-flex flex-column"> <span>In Words</span> <span>Zero</span> </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end">
                                <div class="d-flex flex-column mt-2">
                                    {/* <span class="fw-bolder">For Kalyan Jewellers</span>  */}
                                    <span class="mt-4">Authorised Signatory</span> </div>
                            </div>
                        </div>
                    </div>




                </div>



            </div>
        </React.Fragment>
    );
};

export default Salaryreportemp;
