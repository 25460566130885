// import React from "react";

import React, { useEffect } from "react";
import { Route, Navigate, useNavigate } from "react-router-dom";

// Dashboard
import AdminDashboard from "../dashboard/AdminDashboard";
import EmployeeDashboard from "../dashboard/EmplooyeeDashboard"

// Apps



import FileManager from "../apps/FileManager";

// Pages
import Settings from "../pages/Settings";

// UI Elements
import ChartJs from "../docs/ChartJs";
import Email from "../pages/Email";





// import DesignationForm from "../pages/hr/designationform";
import Roleform from "../pages/hr/roleform";
// import Department from "../pages/hr/department";
import Role from "../pages/hr/role";
import Holidays from "../pages/hr/holidays/holidays";
import Joblist from "../pages/joblist";
import Resume from "../pages/recruitment/resume";
import Contract from "../pages/recruitment/contract";
import Employees from "../pages/employee/employees";
import Employeeform from "../pages/employee/employeeform";
import Job from "../pages/recruitment/job";
import Jobform from "../pages/recruitment/jobform";
import Jobs from "../apps/jobs";
import Employeehistory from "../pages/employee/employeehistory";
import Shift from "../pages/hr/shifts/shift";
import Filemanager from "../pages/filemanager";
// import Departmentform from "../pages/hr/departmentform";
import Holidaysform from "../pages/hr/holidays/holidaysform";
// import Shiftform from "../pages/hr/shiftform";
import ShiftsForm from "../pages/hr/shifts/shiftsform";
// import Employeeview from "../pages/employee/employeeview";
// import Employees from "../pages/employee/employees";
import Resumeform from "../pages/recruitment/resumeform";
import Contractform from "../pages/recruitment/contractform";
import Candidate from "../pages/recruitment/candidate";
import Interviews from "../pages/recruitment/interviews";
import Candidateform from "../pages/recruitment/candidateform";
import Interviewform from "../pages/recruitment/interviewform";
import Profile from "../pages/Profile";
import AddUserForm from "../pages/user/adduserform";
import Viewuser from "../pages/user/viewuser";
import EditUser from "../pages/user/edituser";
import EditEmployee from "../pages/employee/editemployee";
import ViewDepartment from "../pages/hr/department/viewdepartment";
import AddDepartmentForm from "../pages/hr/department/departmentform";
import EditDepartment from "../pages/hr/department/editdepartment";
import EmpDepartmentForm from "../pages/employee/empDepartment";
import EditHoliday from "../pages/hr/holidays/editholiday";
// import ShiftsForm from "../pages/hr/shifts/shiftsform";
import EditShift from "../pages/hr/shifts/editshift";
import AddDesignation from "../pages/hr/designation/designationform";
import Designation from "../pages/hr/designation/viewdesignation";
import EditDesignation from "../pages/hr/designation/editdesignation";
import BranchForm from "../pages/hr/branch/branchform";
import EditBranch from "../pages/hr/branch/editbranch";
import Branch from "../pages/hr/branch/viewbranch";
import AddDesignationForm from "../pages/hr/designation/designationform";
import ResignationForm from "../pages/hr/resignation/resignationform";
import ViewResignation from "../pages/hr/resignation/viewresignation";
import EditResignation from "../pages/hr/resignation/editresignation";
import ViewWorkingDays from "../pages/workingdays/viewworkingdays";
import addWorKingDays from "../pages/workingdays/addworkingdays";
import Applicantstatusform from "../pages/applicantstatusform"

import ViewLeave from "../pages/leaves/viewleave";
import AddLeave from "../pages/leaves/addleave";
import EditLeave from "../pages/leaves/editleave";
import Announcement from "../pages/announcement/viewannouncement";
import Editannouncement from "../pages/announcement/editannouncement";
import AnnouncementForm from "../pages/announcement/announcementform";

import AddWorkingDays from "../pages/workingdays/addworkingdays";
import EditWorkingDays from "../pages/workingdays/editworkingday";

import AddEmpLeave from "../pages/employee/empleave/addempleave";
import ViewAddEmpLeave from "../pages/employee/empleave/viewempleave";
import Attendance from "../pages/attendance/attendance";
import Empprofile from "../pages/employee/empprofile";
import Companyprofile from "../pages/company/companyprofile";
import Automateduser from "../pages/automateduser/automateduser";
import Breakreport from "../pages/hr/breakreport/breakreport";
import Changepassword from "../pages/employee/password/changepassword";
import FileManageradmin from "../apps/Filemangeradmin";
import EditEmp from "../pages/employee/editemp";
import Addcompanylogo from "../pages/company/addcompanylogo";
import Employeebreakcount from "../pages/employee/break/empbreakcount";
import SingleAttendanceView from "../pages/attendance/singleattendanceview";
import AttendanceTotalList from "../pages/attendance/attendancetotallist";
import EmployeeBreakCalendar from "../pages/breakemp/empcalender";
import Employeebreakdata from "../pages/admin/empbreakdata";
import EmployeeBreakhistory from "../pages/employee/break/breakhistory";
import EmployeeBreakstatic from "../pages/employee/break/breakstatics";
import DashbaordBreakCalendar from "../pages/breakemp/dashbreakcalender";
import Employeesalary from "../pages/admin/employeesalary";
import Employeesalaryform from "../pages/admin/empsalaryform";
import Empbreakofflist from "../pages/breakoffemp/empbreakoflist";
import Addsalarysetup from "../pages/empsalarysetup/addsalarysetup";
import Bresklist from "../pages/admin/bresklist";
import Breaklist from "../pages/admin/bresklist";
import Salaryreportemp from "../pages/admin/empsalaryreport";
import ViewSalarysetup from "../pages/empsalarysetup/viewsalarysetup";
import ViewCreditleave from "../pages/empsalarysetup/empcreditleave";
import Editsalarysetup from "../pages/empsalarysetup/editsalarysetup";
import AddBreakOffForm from "../pages/breakoffemp/addbreakoffemp";
import ViewempSalarysetup from "../pages/empsalarysetup/viewempsalarysetup";
import Addempsalarysetup from "../pages/empsalarysetup/addempsalarysetup";
import { Link } from "react-router-dom";
import PrivateRoute from "./PublicRoutes";
import Viewsalarysetuptwo from "../pages/empsalarysetup/viewsalarysetuptwo";
import Employeeattendance from "../pages/attendance/employeeattendance";
import EmployeeBreak from "../pages/admin/break/employeebreak";
import InviteCandidate from "../pages/recruitment/inviteCandidate";
import AddApplicantsStatus from "../pages/addApplicantsStatus";
import EditStatus from "../pages/editStatus";














const protectedRoutes = [

  { path: "admin/dashboard", element: <AdminDashboard /> },

  { path: "employee/dashboard", element: <EmployeeDashboard /> },
  { path: "admin/viewemployeesalarysetup", element: <Viewsalarysetuptwo /> },

  { path: "pages/email", element: <Email /> },
  { path: "apps/jobs", element: <Jobs /> },
  { path: "apps/file-manager", element: <FileManager /> },



  { path: "/pages/leave", element: <ViewLeave /> },
  { path: "/pages/leaves/addleave", element: <AddLeave /> },
  { path: "/edit_leave", element: <EditLeave /> },



  { path: "/pages/workingdays/addworkingdays", element: <AddWorkingDays /> },
  { path: "/pages/workingdays", element: <ViewWorkingDays /> },
  
  { path: "pages/settings/addapplicantstatus/applicantstatusform", element: <Applicantstatusform /> },


  // { path: "/pages/workingdays/editworkingday", element: <EditWorkingDays /> },
  { path: "/pages/edit_workingday", element: <EditWorkingDays /> },

  { path: "admin/employees", element: <Employees /> },
  { path: "pages/attendance", element: <Attendance /> },

  { path: "pages/user/userform", element: <AddUserForm /> },
  { path: "/edit_user", element: <EditUser /> },
  // { path: "/employee/editempform", element: < Editempform/>},
  { path: "/edit_employee", element: <EditEmployee /> },
  { path: "/edit_emp", element: <EditEmp /> },
  { path: "/companyprofile", element: <Companyprofile /> },
  { path: "pages/employee/empDepartment", element: <EmpDepartmentForm /> },
  { path: "pages/user/viewuser", element: <Viewuser /> },
  { path: "admin/employeeform", element: <Employeeform /> },
  { path: "admin/employeehistory", element: <Employeehistory /> },
  // { path:"pages/employee/employeeview", element: <Employees/>},
  { path: "/breakhistory", element: <EmployeeBreakhistory /> },
  { path: "/breakstatic", element: <EmployeeBreakstatic /> },
  { path: "/breakcalender", element: <DashbaordBreakCalendar /> },
  { path: "admin/employeesalary", element: <Employeesalary /> },
  { path: "admin/employeesalaryform", element: <Employeesalaryform /> },
  { path: "admin/breakofflist", element: <Empbreakofflist /> },
  { path: "admin/salarysetup", element: <Addsalarysetup /> },
  { path: "admin/empsalarysetup", element: <Addempsalarysetup /> },
  { path: "admin/breakoffemployees", element: <AddBreakOffForm /> },








  { path: "/employee/addleave", element: <AddEmpLeave /> },
  { path: "/pages/empleave/empleave", element: <ViewAddEmpLeave /> },
  { path: "/automateduser", element: <Automateduser /> },
  { path: "/employee/break", element: <Breakreport /> },
  { path: "/employee/changepassword", element: <Changepassword /> },

  { path: "/employee/attendance", element: <Attendance /> },
  { path: "/employee/attendanceview", element: <SingleAttendanceView /> },
  { path: "/admin/pages/totalattendance", element: <AttendanceTotalList /> },
  { path: "/admin/pages/employeeattendance", element: <Employeeattendance /> },
  { path: "/admin/pages/employeebreak", element: <EmployeeBreak /> },

  { path: "/employee/breakview", element: <EmployeeBreakCalendar /> },
  { path: "/admin/empbreakdata", element: <Employeebreakdata /> },
  { path: "/employee/salaryreport", element: <Salaryreportemp /> },
  { path: "/admin/viewempsalary", element: <ViewSalarysetup /> },
  { path: "/admin/empsalarysetup", element: <ViewempSalarysetup /> },
  { path: "/admin/viewcreditempleave", element: <ViewCreditleave /> },
  { path: "/admin/edit_salary_setup", element: <Editsalarysetup /> },




  { path: "admin/designation", element: <Designation /> },
  { path: "pages/hr/addDesignation", element: <AddDesignation /> },
  { path: "/edit_designation", element: <EditDesignation /> },

  // { path: "pages/hr/designationform", element: <DesignationForm /> },
  { path: "pages/hr/adddesignationform", element: <AddDesignationForm /> },
  { path: "/emoloyee/resignation", element: <ResignationForm /> },
  // { path: "pages/hr/departmentform", element: <Departmentform /> },
  { path: "pages/hr/editdepartment", element: <EditDepartment /> },
  { path: "admin/department", element: <ViewDepartment /> },
  { path: "admin/adddepartment", element: <AddDepartmentForm /> },
  { path: "pages/hr/roleform", element: <Roleform /> },
  { path: "pages/hr/holidaysform", element: <Holidaysform /> },
  { path: "/edit_holiday", element: <EditHoliday /> },
  { path: "pages/hr/shiftsform", element: <ShiftsForm /> },
  { path: "pages/hr/editshift", element: <EditShift /> },
  // { path:"pages/hr/shiftform", element: <ShiftsForm/>},

  { path: "pages/hr/branchform", element: <BranchForm /> },
  { path: "/edit_branch", element: <EditBranch /> },
  { path: "pages/hr/branch", element: <Branch /> },
  { path: "admin/announcement", element: <Announcement /> },
  { path: "/edit_announcement", element: <Editannouncement /> },
  { path: "/pages/announcementform", element: <AnnouncementForm /> },
  { path: "/companylogo", element: <Addcompanylogo /> },
  { path: "/employee/empbreaktime", element: <Employeebreakcount /> },



  { path: "/employee/profile", element: <Empprofile /> },






  // { path:"pages/hr/resignationform", element: <ResignationForm/>},


  { path: "/edit_resignation", element: <EditResignation /> },
  { path: "admin/resignation", element: <ViewResignation /> },


  { path: "pages/hr/editdepartment", element: <EditDepartment /> },
  { path: "admin/department", element: <ViewDepartment /> },
  { path: "admin/adddepartment", element: <AddDepartmentForm /> },
  { path: "pages/hr/roleform", element: <Roleform /> },
  { path: "pages/hr/holidaysform", element: <Holidaysform /> },
  { path: "pages/hr/editholiday", element: <EditHoliday /> },
  { path: "pages/hr/shiftsform", element: <ShiftsForm /> },
  { path: "pages/hr/editshift", element: <EditShift /> },
  // { path:"pages/hr/shiftform", element: <ShiftsForm/>},

  { path: "pages/hr/branchform", element: <BranchForm /> },
  { path: "pages/hr/editbranch", element: <EditBranch /> },
  { path: "pages/hr/branch", element: <Branch /> },


  // { path:"pages/hr/resignationform", element: <ResignationForm/>},


  { path: "pages/hr/editresignation", element: <EditResignation /> },
  { path: "admin/resignation", element: <ViewResignation /> },





  // { path: "pages/hr/department", element: <Department /> },
  { path: "pages/hr/role", element: <Role /> },
  { path: "admin/holidays", element: <Holidays /> },
  { path: "pages/hr/shift", element: <Shift /> },
  { path: "pages/filemanager", element: <Filemanager /> },
  { path: "/filemanager", element: <FileManageradmin /> },
  { path: "pages/roleform", element: <Roleform /> },

  { path: "pages/joblist", element: <Joblist /> },
  { path: "pages/recruitment/resume", element: <Resume /> },
  { path: "pages/recruitment/resumeform", element: <Resumeform /> },
  { path: "pages/recruitment/contractform", element: <Contractform /> },

  { path: "pages/recruitment/contract", element: <Contract /> },
  { path: "pages/recruitment/candidate", element: <Candidate /> },
  { path: "pages/recruitment/invitecandidate", element: <InviteCandidate /> },
  { path: "pages/recruitment/candidateform", element: <Candidateform /> },
  { path: "pages/recruitment/interviews", element: <Interviews /> },
  { path: "pages/recruitment/interviewform", element: <Interviewform /> },
  
  { path: "pages/settings/addapplicantstatus", element: <AddApplicantsStatus /> },
  { path: "pages/settings/addapplicantstatus/editstatusform", element: <EditStatus /> },


  { path: "pages/recruitment/job", element: <Job /> },
  { path: "pages/recruitment/jobform", element: <Jobform /> },
  { path: "pages/profile", element: <Profile /> },




  { path: "/admin/breaklist", element: <Breaklist /> },





  { path: "pages/settings", element: <Settings /> },
  { path: "docs/chart/chartjs", element: <ChartJs /> },


]

// const PrivateRoutes = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const isLoggedIn = sessionStorage.getItem("isLoggedIn");
//     if (isLoggedIn !== "true") {
//       navigate("/login");
//     }
//   }, [navigate]);

//   return (
//     <>
//       {protectedRoutes.map((route, index) => (
//         <Route
//           key={index}
//           path={route.path}
//           element={
//             sessionStorage.getItem("isLoggedIn") === "true" ? (
//               route.element
//             ) : (
//               <Navigate to="/login" />
//             )
//           }
//         />
//       ))}
//     </>
//   );
// };


export default protectedRoutes;