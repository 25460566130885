import React, { useState, useEffect } from "react";
import Header from "../../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addShift, basicSetup } from "../../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import { Padding } from "@mui/icons-material";


const ShiftsForm = () => {
    const [submitting, setSubmitting] = useState(false);
    const navigate = useNavigate();
    const [state, setState] = useState({
        shift_no: "",
        company_start_time: "",
        company_end_time: "",
        company_break_time: "",
        company_break_fine: "",
        company_late_fine: "",
        breakList: [{ break_name: '', break_time: '' }],
        timezone: "",
    });
    const [departments, setDepartments] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setState({
            shift_no: "",
            company_start_time: "",
            company_end_time: "",
            company_break_time: "",
            company_break_fine: "",
            company_late_fine: "",
            breakList: [{ break_name: '', break_time: '' }],
            timezone: "",
        });
        setErrors({});
    }, []);

    const handleTimeZone = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
        console.log(state);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value);

        if (name === 'company_break_fine' && !/^\d*$/.test(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: 'Please enter numeric values only',
            }));
        } else if (name === 'company_late_fine' && !/^\d*$/.test(value)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: 'Please enter numeric values only',
            }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
            setState((prevState) => ({ ...prevState, [name]: value }));
        }
    };
    const handleInputChange = (index, e, type) => {
        const { name, value } = e.target;
        const updatedData = state[type].map((item, i) =>
            i === index ? { ...item, [name]: value } : item
        );
        setState({
            ...state,
            [type]: updatedData
        });
    };
    const handleRemovebreakListsallowence = (index, type) => {
        const newItems = state[type].filter((item, i) => i !== index);
        setState({
            ...state,
            [type]: newItems
        });
    };

    const handleAddbreakListsallowence = (type) => {
        const newData = { break_name: '', break_time: '' };
        setState({
            ...state,
            [type]: [...state[type], newData]
        });
    };

    const handleSubmit = async (e) => {

        e.preventDefault();


        const validationErrors = validateForm(state);
        // if (Object.keys(validationErrors).length > 0) {
        //     setErrors(validationErrors);
        //     return;
        // } if (submitting) {
        //     return; // If already submitting, do nothing
        // }

        setSubmitting(true);

        try {
            const response = await addShift(state);

            console.log(response);
            if (response.message) {
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
                // navigate("/pages/hr/shift");
                // navigate('/admin/dashboard');
                const storedSetupData = JSON.parse(sessionStorage.getItem('setupData'));
                const updateProfileData = {
                    ...storedSetupData, // Spread the stored values
                    companyTime: "true", // Update companyProfile field
                };
                console.log(updateProfileData);
                const secondResponse = await basicSetup(updateProfileData);
                console.log(secondResponse);
            } else {
                toast.error(response.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } catch (er) {
            // toast.success("Shift added successfully", {
            //     position: "top-right",
            //     autoClose: 1000,
            // });
            // navigate("/pages/hr/shift");
        } finally {
            setSubmitting(false);
        }
    };

    const validateForm = (state) => {
        let errors = {};

        if (!state.shift_no.trim()) {
            errors.shift_no = "Shift number is required";
        }

        if (!state.company_start_time.trim()) {
            errors.company_start_time = "Company start time is required";
        }
        if (!state.company_end_time.trim()) {
            errors.company_end_time = "Company end time is required";
        }
        if (!state.company_break_time.trim()) {
            errors.company_break_time = "Company Break time is required";
        }
        if (!state.company_break_fine.trim()) {
            errors.company_break_fine = "Company Break time is required";
        }
        if (!state.company_late_fine.trim()) {
            errors.company_late_fine = "Company late fine is required";
        }
        // if (!state.timezone.trim()) {
        //     errors.timezone = "Timezone is required";
        // }


        return errors;
    };



    return (
        <React.Fragment>
            <Header />
            {/* Your other layout code here */}
            <div className="main p-4 p-lg-5 mt-5">
                {/* Your layout structure */}
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Add Shift</h3>
                </div>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/pages/hr/shift">Shift</Link>
                            </li>
                            <li className="breadcrumb-item active">Add Shift</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Link to="/pages/hr/shift" className="btn btn-danger mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>



                <div className="row"
                    style={{
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >


                    <div className="col-md-12 formcontrols">
                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                            <form onSubmit={handleSubmit}>


                                <div className="row">
                                    {/* <div className="col-md-4">

                                        <div className="mb-3">
                                            <label htmlFor="shift_no" className="form-label">
                                                Shift no.
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.shift_no && "is-invalid"}`}
                                                id="shift_no"
                                                name="shift_no"
                                                value={state.shift_no}
                                                onChange={handleChange}
                                                placeholder="Enter shift number"
                                            />
                                            {errors.shift_no && (
                                                <div className="invalid-feedback">{errors.shift_no}</div>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="company_start_time" className="form-label">
                                                Company Start Time
                                            </label>
                                            <input
                                                type="time"
                                                className={`form-control ${errors.company_start_time && "is-invalid"}`}
                                                id="company_start_time"
                                                name="company_start_time"
                                                value={state.company_start_time}
                                                onChange={handleChange}
                                                placeholder="Enter company start time"
                                            />
                                            {errors.company_start_time && (
                                                <div className="invalid-feedback">{errors.company_start_time}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="mb-3">
                                            <label htmlFor="company_end_time" className="form-label">
                                                Company End Time
                                            </label>
                                            <input
                                                type="time"
                                                className={`form-control ${errors.company_end_time && "is-invalid"}`}
                                                id="company_end_time"
                                                name="company_end_time"
                                                value={state.company_end_time}
                                                onChange={handleChange}
                                                placeholder="Enter company end time"
                                            />
                                            {errors.company_end_time && (
                                                <div className="invalid-feedback">{errors.company_end_time}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <Form.Group>

                                    <div className="form-group">

                                        {state.breakList.map((breakLists, index) => (
                                            <div key={index} className="row mb-3">
                                                <div className="col-md-6 mb-4">
                                                    <Form.Label>Break Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="break_name"
                                                        required
                                                        placeholder="Break Name"
                                                        value={breakLists.break_name}
                                                        onChange={(e) => handleInputChange(index, e, 'breakList')}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Form.Label>Break Time (in Minutes)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="break_time"
                                                        required
                                                        placeholder="Break Time"
                                                        value={breakLists.break_time}
                                                        onChange={(e) => handleInputChange(index, e, 'breakList')}
                                                    >
                                                    </Form.Control>
                                                </div>
                                                <div className="col-md-12">
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => handleRemovebreakListsallowence(index, 'breakList')}
                                                        style={{
                                                            backgroundColor: '#ff0000d1',
                                                            border: '0px solid black',
                                                            color: 'white',
                                                            transition: 'background-color 0.3s, border-color 0.3s'
                                                        }}
                                                        onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                                                        onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                                                    >
                                                        Remove
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="row">
                                            <div className="col-md-12 d-flex align-items-center justify-content-start">
                                                <Button
                                                    variant="success"
                                                    className="ms-2"
                                                    onClick={() => handleAddbreakListsallowence('breakList')}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-plus-circle-fill me-1"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                                                    </svg>
                                                    Add breakLists
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form.Group>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="company_break_time" className="form-label">
                                                Company Break Time (in Minutes)
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.company_start_time && "is-invalid"}`}
                                                id="company_break_time"
                                                name="company_break_time"
                                                value={state.company_break_time}
                                                onChange={handleChange}
                                                placeholder="Enter company break time"
                                            />
                                            {errors.company_break_time && (
                                                <div className="invalid-feedback">{errors.company_break_time}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4">

                                        <div className="mb-3">
                                            <label htmlFor="company_break_fine" className="form-label">
                                                Company Break Fine (in Ruppes)
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.company_break_fine && "is-invalid"}`}
                                                id="company_break_fine"
                                                name="company_break_fine"
                                                value={state.company_break_fine}
                                                onChange={handleChange}
                                                placeholder="Enter company break fine"
                                            />
                                            {errors.company_break_fine && (
                                                <div className="invalid-feedback">{errors.company_break_fine}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="company_late_fine" className="form-label">
                                                Company Late Fine (in Ruppes)
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.company_late_fine && "is-invalid"}`}
                                                id="company_late_fine"
                                                name="company_late_fine"
                                                value={state.company_late_fine}
                                                onChange={handleChange}
                                                placeholder="Enter company late fine"
                                            />
                                            {errors.company_late_fine && (
                                                <div className="invalid-feedback">{errors.company_late_fine}</div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="company_late_fine" className="form-label">
                                                Time Zone <span className="req text-danger">*</span>
                                            </label>
                                            {/* <input
                                                type="text"
                                                className={`form-control ${errors.company_late_fine && "is-invalid"}`}
                                                id="company_late_fine"
                                                name="company_late_fine"
                                                value={state.company_late_fine}
                                                onChange={handleChange}
                                                placeholder="Enter company late fine"
                                            />
                                            {errors.company_late_fine && (
                                                <div className="invalid-feedback">{errors.company_late_fine}</div>
                                            )} */}
                                            <select id="timezone" style={{ width: '100%', padding: '5px' }} onChange={handleTimeZone} class="custom-select" name="timezone">
                                                <option value="" disabled selected>Select Your TimeZone</option>
                                                <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                                                <option value="America/Adak">(GMT-10:00) Hawaii-Aleutian</option>
                                                <option value="Etc/GMT+10">(GMT-10:00) Hawaii</option>
                                                <option value="Pacific/Marquesas">(GMT-09:30) Marquesas Islands</option>
                                                <option value="Pacific/Gambier">(GMT-09:00) Gambier Islands</option>
                                                <option value="America/Anchorage">(GMT-09:00) Alaska</option>
                                                <option value="America/Ensenada">(GMT-08:00) Tijuana, Baja California</option>
                                                <option value="Etc/GMT+8">(GMT-08:00) Pitcairn Islands</option>
                                                <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                                <option value="America/Denver">(GMT-07:00) Mountain Time (US & Canada)</option>
                                                <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                                <option value="America/Dawson_Creek">(GMT-07:00) Arizona</option>
                                                <option value="America/Belize">(GMT-06:00) Saskatchewan, Central America</option>
                                                <option value="America/Cancun">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                                <option value="Chile/EasterIsland">(GMT-06:00) Easter Island</option>
                                                <option value="America/Chicago">(GMT-06:00) Central Time (US & Canada)</option>
                                                <option value="America/New_York">(GMT-05:00) Eastern Time (US & Canada)</option>
                                                <option value="America/Havana">(GMT-05:00) Cuba</option>
                                                <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                                <option value="America/Caracas">(GMT-04:30) Caracas</option>
                                                <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                                <option value="America/La_Paz">(GMT-04:00) La Paz</option>
                                                <option value="Atlantic/Stanley">(GMT-04:00) Faukland Islands</option>
                                                <option value="America/Campo_Grande">(GMT-04:00) Brazil</option>
                                                <option value="America/Goose_Bay">(GMT-04:00) Atlantic Time (Goose Bay)</option>
                                                <option value="America/Glace_Bay">(GMT-04:00) Atlantic Time (Canada)</option>
                                                <option value="America/St_Johns">(GMT-03:30) Newfoundland</option>
                                                <option value="America/Araguaina">(GMT-03:00) UTC-3</option>
                                                <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                                <option value="America/Miquelon">(GMT-03:00) Miquelon, St. Pierre</option>
                                                <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                                <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires</option>
                                                <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                                <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                                <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                                <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                                <option value="Europe/Belfast">(GMT) Greenwich Mean Time : Belfast</option>
                                                <option value="Europe/Dublin">(GMT) Greenwich Mean Time : Dublin</option>
                                                <option value="Europe/Lisbon">(GMT) Greenwich Mean Time : Lisbon</option>
                                                <option value="Europe/London">(GMT) Greenwich Mean Time : London</option>
                                                <option value="Africa/Abidjan">(GMT) Monrovia, Reykjavik</option>
                                                <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                                <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                                <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                                <option value="Africa/Algiers">(GMT+01:00) West Central Africa</option>
                                                <option value="Africa/Windhoek">(GMT+01:00) Windhoek</option>
                                                <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                                <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                                <option value="Asia/Gaza">(GMT+02:00) Gaza</option>
                                                <option value="Africa/Blantyre">(GMT+02:00) Harare, Pretoria</option>
                                                <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                                <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                                <option value="Asia/Damascus">(GMT+02:00) Syria</option>
                                                <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                                <option value="Africa/Addis_Ababa">(GMT+03:00) Nairobi</option>
                                                <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                                <option value="Asia/Dubai">(GMT+04:00) Abu Dhabi, Muscat</option>
                                                <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                                <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                                <option value="Asia/Yekaterinburg">(GMT+05:00) Ekaterinburg</option>
                                                <option value="Asia/Tashkent">(GMT+05:00) Tashkent</option>
                                                <option value="Asia/Kolkata">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                                <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                                <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                                <option value="Asia/Novosibirsk">(GMT+06:00) Novosibirsk</option>
                                                <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                                <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                                <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                                <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                                <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                                <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                                <option value="Australia/Eucla">(GMT+08:45) Eucla</option>
                                                <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                                <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                                <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                                <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                                <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                                <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                                <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                                <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                                <option value="Australia/Lord_Howe">(GMT+10:30) Lord Howe Island</option>
                                                <option value="Etc/GMT-11">(GMT+11:00) Solomon Is., New Caledonia</option>
                                                <option value="Asia/Magadan">(GMT+11:00) Magadan</option>
                                                <option value="Pacific/Norfolk">(GMT+11:30) Norfolk Island</option>
                                                <option value="Asia/Anadyr">(GMT+12:00) Anadyr, Kamchatka</option>
                                                <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                                <option value="Etc/GMT-12">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                                <option value="Pacific/Chatham">(GMT+12:45) Chatham Islands</option>
                                                <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                                <option value="Pacific/Kiritimati">(GMT+14:00) Kiritimati</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                </div>
                                <div className="row">
                                    <div className="col-md-6">

                                        {/* <div className="mb-3">
                                            <label htmlFor="company_break_fine" className="form-label">
                                            Time Zone
                                            </label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.company_break_fine && "is-invalid"}`}
                                                id="company_break_fine"
                                                name="company_break_fine"
                                                value={state.company_break_fine}
                                                onChange={handleChange}
                                            />
                                            {errors.company_break_fine && (
                                                <div className="invalid-feedback">{errors.company_break_fine}</div>
                                            )}
                                        </div> */}
                                    </div>

                                </div>
                                <button
                                    type="submit"
                                    className="custom-submit-btn"
                                    disabled={submitting}
                                    onClick={handleSubmit}
                                >
                                    {submitting && (
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                    {submitting ? 'Submitting Shift...' : 'Add Shift'}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ShiftsForm;

