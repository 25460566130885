import React, { useState } from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import '../../styles/globals.css';

const CandidateForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    department: "",
    position: "",
    email: "",
    joiningDate: "",
    status: "",
    action: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required.";
    if (!formData.department) errors.department = "Department is required.";
    if (!formData.position) errors.position = "Position is required.";
    if (!formData.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(formData.email)) {
      errors.email = "Invalid email format.";
    }
    if (!formData.joiningDate) errors.joiningDate = "Joining Date is required.";
    if (!formData.status) errors.status = "Status is required.";
    if (!formData.action) errors.action = "Action is required.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    // A simple email validation function
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Form is valid, you can submit it to your server or perform any desired action
      console.log(formData);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <h4>Add Candidate</h4>

        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/dashboard/helpdesk">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/pages/recruitment/candidate">Candidates</Link>
              </li>
              <li className="breadcrumb-item active">Add Candidate</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/pages/recruitment/candidate" className="btn btn-danger mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link>
          </nav>
        </div>

        <div className="row" style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", borderRadius: "10px", overflow: "hidden" }}>
          <div className="col-md-12">
            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
              <form onSubmit={handleSubmit} className="form-labels">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">Name</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.name && "is-invalid"}`}
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="department" className="form-label">Department</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.department && "is-invalid"}`}
                        id="department"
                        name="department"
                        value={formData.department}
                        onChange={handleInputChange}
                      />
                      {formErrors.department && <div className="invalid-feedback">{formErrors.department}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="position" className="form-label">Position</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.position && "is-invalid"}`}
                        id="position"
                        name="position"
                        value={formData.position}
                        onChange={handleInputChange}
                      />
                      {formErrors.position && <div className="invalid-feedback">{formErrors.position}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input
                        type="email"
                        className={`form-control ${formErrors.email && "is-invalid"}`}
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="joiningDate" className="form-label">Joining Date</label>
                      <input
                        type="date"
                        className={`form-control ${formErrors.joiningDate && "is-invalid"}`}
                        id="joiningDate"
                        name="joiningDate"
                        value={formData.joiningDate}
                        onChange={handleInputChange}
                      />
                      {formErrors.joiningDate && <div className="invalid-feedback">{formErrors.joiningDate}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="status" className="form-label">Status</label>
                      <select
                        className={`form-select ${formErrors.status && "is-invalid"}`}
                        id="status"
                        name="status"
                        value={formData.status}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        {/* Add more status options here */}
                      </select>
                      {formErrors.status && <div className="invalid-feedback">{formErrors.status}</div>}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="action" className="form-label">Action</label>
                      <input
                        type="text"
                        className={`form-control ${formErrors.action && "is-invalid"}`}
                        id="action"
                        name="action"
                        value={formData.action}
                        onChange={handleInputChange}
                      />
                      {formErrors.action && <div className="invalid-feedback">{formErrors.action}</div>}
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CandidateForm;
