import React, { useState, useEffect } from "react";
import _debounce from "lodash/debounce";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  getId,
  searchEmployeeBreakCount,
  singleViewEmployeeAttendance,
} from "../../services/api/api";
import HeaderMobile from "../../layouts/HeaderMobile";
import Header from "../../layouts/Header";
import {
  Card,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const SingleAttendanceView = () => {
  const [events, setEvents] = useState([]);
  const [searchParams, setSearchParams] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1, // Months are 0-indexed, so adding 1
  });
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //     fetchEmployeeBreakCount();
  // }, [searchParams]);
  useEffect(() => {
    const debouncedFetchEmployeeBreakCount = _debounce(
      fetchEmployeeBreakCount,
      300
    );

    debouncedFetchEmployeeBreakCount();

    return () => {
      debouncedFetchEmployeeBreakCount.cancel();
    };
  }, [searchParams]);

  const fetchEmployeeBreakCount = async () => {
    try {
      setLoading(true);
      const employeeId = getId(); // Assuming getId() is a function that returns the employee ID
      const apiResponse = await singleViewEmployeeAttendance(
        employeeId,
        searchParams.year,
        searchParams.month
      );
      console.log(apiResponse);
      const parsedEvents = parseApiResponse(apiResponse);
      setEvents(parsedEvents);
    } catch (error) {
      console.error("Error fetching employee break count:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchEmployeeBreakCount();
  };

  const handleYearChange = (e) => {
    setSearchParams({ ...searchParams, year: e.target.value });
  };

  const handleMonthChange = (e) => {
    setSearchParams({ ...searchParams, month: e.target.value });
  };

  // const parseApiResponse = (apiResponse) => {
  //     const formattedEvents = [];

  //     apiResponse.data.forEach((employeeData) => {
  //         employeeData.attendance_list.forEach((attendance) => {
  //             attendance.attendance.forEach((attendanceData) => {
  //                 const date = attendanceData.date;
  //                 const presentAttendance = attendanceData.break_start_present;
  //                 const time = attendanceData.IN || '';

  //                 // Customize the text based on the break_start_present value
  //                 const title = !presentAttendance ? 'Present' : '';

  //                 // Add the event to the formattedEvents array
  //                 formattedEvents.push({
  //                     title: title,
  //                     date: date,
  //                     time: time,

  //                     backgroundColor: presentAttendance ? '#000000' : '#00000',
  //                     borderColor: presentAttendance ? '#000000' : '#000000',
  //                     borderRadius: '5px',
  //                 });
  //             });
  //         });
  //     });

  //     return formattedEvents;
  // };

  const parseApiResponse = (apiResponse) => {
    const formattedEvents = {};

    apiResponse.data.forEach((employeeData) => {
      employeeData.attendance_list.forEach((attendance) => {
        attendance.attendance.forEach((attendanceData) => {
          const date = attendanceData.date;
          const presentAttendance = attendanceData.break_start_present;
          const inTime = attendanceData.IN || "";
          const outTime = attendanceData.OUT || "";

          // console.log(`Date: ${date}, InTime: ${inTime}, OutTime: ${outTime}`);
          // Customize the text based on the break_start_present value
          const title = presentAttendance ? "Present" : "";
          // console.log(title);

          // Add the event to the formattedEvents array
          if (inTime !== null) {
            if (!formattedEvents[date]) {
              formattedEvents[date] = {
                title: title,
                date: date,
                inTime: inTime,
                outTime: outTime,
                backgroundColor: "#3c57b1",
                borderColor: "#000000",
                borderRadius: "5px",
              };
            }
          }
        });
      });
    });

    return Object.values(formattedEvents);
  };
  return (
    <React.Fragment>
      {/* Header components */}
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="mb-4 border-bottom text-center">
          <h3 className="mb-0 pb- border-bottom custome-btn">
            Employee Attendance Report
          </h3>
        </div>
        <div className="col-md-12">
          <div className="breadcrumb-warning d-flex justify-content-between ot-card">
            <div>
              <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                <li class="breadcrumb-item">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li class="breadcrumb-item active">
                  <Link to="/employee/attendanceview">Attendance Details</Link>
                </li>
                <li class="breadcrumb-item active">List</li>
              </ol>
            </div>
          </div>
          <Card
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              borderRadius: "10px",
              overflow: "hidden",
              border: "none",
            }}
          >
            <CardBody>
              <Form onSubmit={handleSearch}>
                <Row className="mb-3">
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder="Year"
                      value={searchParams.year}
                      onChange={handleYearChange}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      value={searchParams.month}
                      onChange={handleMonthChange}
                    >
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </Form.Control>
                  </Col>
                  <Col>
                    <Button type="submit">Search</Button>
                  </Col>
                </Row>
              </Form>

              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                // <FullCalendar
                //     plugins={[dayGridPlugin]}
                //     initialView="dayGridMonth"
                //     events={events}
                //     eventContent={(eventInfo) => {
                //         return (
                //             <div>
                //                 <div>{eventInfo.event.title}</div>
                //                 {eventInfo.event.extendedProps.time && (
                //                     <div style={{ fontSize: '12px', color: 'white' }}>
                //                       <strong>Intime: ({eventInfo.event.extendedProps.time})</strong>
                //                     </div>
                //                 )}
                //             </div>
                //         );
                //     }}
                // />
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  events={events}
                  eventContent={(eventInfo) => (
                    <div>
                      <div>{eventInfo.event.title}</div>
                      {eventInfo.event.extendedProps.inTime && (
                        <div style={{ fontSize: "12px", color: "white" }}>
                          {/* <strong>Total Duration: {eventInfo.event.extendedProps.durationSum} min</strong> */}
                        </div>
                      )}
                    </div>
                  )}
                />
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SingleAttendanceView;
{
  /* <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  events={events}
                  eventContent={(eventInfo) => {
                    console.log("Event Info:", eventInfo);
                    // return (
                    <div>
                      <div>{eventInfo.event.title}</div>
                      {eventInfo.event.extendedProps.inTime && (
                        <div style={{ fontSize: "12px", color: "white" }}>
                          <strong>
                            Intime: ({eventInfo.event.extendedProps.inTime})
                          </strong>
                        </div>
                      )}
                      {eventInfo.event.extendedProps.outTime && (
                        <div style={{ fontSize: "12px", color: "white" }}>
                          <strong>
                            Outtime: ({eventInfo.event.extendedProps.outTime})
                          </strong>
                        </div>
                      )}
                    </div>;
                    // );
                  }}
                /> */
}
