  
  import React, { useState, useEffect, useRef } from 'react';
  import Header from '../../layouts/Header';
  import HeaderMobile from '../../layouts/HeaderMobile';
  import { Card, Button } from "react-bootstrap";
  import '../../styles/globals.css';
  import $ from 'jquery';
  import 'datatables.net';
  import { Link } from "react-router-dom";
  
  const Role = () => {
    const tableRef = useRef(null);
  
    const [activeButton, setActiveButton] = useState('open');
  
    const handleButtonClick = (button) => {
      setActiveButton(button);
    };
  
    useEffect(() => {
      if ($.fn.DataTable.isDataTable('#commonTable')) {
        tableRef.current = $('#commonTable').DataTable();
        tableRef.current.destroy();
      }
      if ($.fn.DataTable) {
        tableRef.current = $('#commonTable').DataTable({
          data: [
              [1, 'Sales Department', 'Active', 'applogin1', 'weblogin1'],
              [2, 'HR Department', 'Active', 'applogin2', 'weblogin2'],
              [3, 'Marketing Team', 'Active', 'applogin3', 'weblogin3'],
              [4, 'Development Team', 'Active', 'applogin4', 'weblogin4'],
              [5, 'Customer Service', 'Active', 'applogin5', 'weblogin5'],
              [6, 'Accounting Team', 'Active', 'applogin6', 'weblogin6'],
              [7, 'Design Team', 'Active', 'applogin7', 'weblogin7'],
              [8, 'Research & Innovation', 'Active', 'applogin8', 'weblogin8'],
              [9, 'Human Resources', 'Active', 'applogin9', 'weblogin9'],
              [10, 'Legal Department', 'Active', 'applogin10', 'weblogin10'],
              [11, 'Product Management', 'Active', 'applogin11', 'weblogin11'],
              [12, 'Testing Team', 'Active', 'applogin12', 'weblogin12'],
              [13, 'Production Department', 'Active', 'applogin13', 'weblogin13'],
              [14, 'Quality Assurance', 'Active', 'applogin14', 'weblogin14'],
              [15, 'Logistics Team', 'Active', 'applogin15', 'weblogin15'],
              [16, 'Customer Success', 'Active', 'applogin16', 'weblogin16'],
              [17, 'Marketing Department', 'Active', 'applogin17', 'weblogin17'],
              [18, 'IT Support', 'Active', 'applogin18', 'weblogin18'],
              [19, 'Customer Relations', 'Active', 'applogin19', 'weblogin19'],
              [20, 'Financial Planning', 'Active', 'applogin20', 'weblogin20'],
              // Add more department data here
          ],
          
            
          columns: [
              { title: 'ID' },
              { title: 'Name' },
              { title: 'Status' },
              { title: 'AppLogin' }, // Add this line for "applogin"
              { title: 'WebLogin' }, // Add this line for "weblogin"
              { title: 'Actions' },
          ],
          
          paging: true,
          ordering: true,
          searching: true,
          columnDefs: [
            {
              targets: -1,
              orderable: false,
              searchable: false,
              render: function (data, type, full, meta) {
                return createActionButton(full);
              },
            },
          ],
          responsive: true,
        });
      }
  
      const updatePaginationStyles = () => {
        const paginationElements = $('.paginate_button');
        paginationElements.each(function (index) {
          const $this = $(this);
          if ($this.hasClass('current')) {
            $this.css({
              border: '0px solid blue',
              padding: '3px 8px 3px 8px',
              borderRadius: '3px',
              color: 'white',
              fontWeight: 500,
              background: '#506fd9',
            });
          }
        });
      };
  
      tableRef.current.on('draw.dt', updatePaginationStyles);
      updatePaginationStyles();
    }, []);
  
    const createActionButton = (data) => {
  
      
     
      const editButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill view" viewBox="0 0 16 16">
          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
        </svg>`;
      const deleteButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-fill edit " viewBox="0 0 16 16">
          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
        </svg>`;
      const viewButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill delete " viewBox="0 0 16 16">
          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
        </svg>`;
     
  
      // return `${editButton} ${deleteButton} ${viewButton}`;
      return ` ${editButton} <span style="margin-right: 8px;"></span> ${deleteButton} <span style="margin-right: 8px;"></span> ${viewButton} <span style="margin-right: 8px;"></span> `;
    };
  
    $('#commonTable tbody').on('click', 'button', function () {
      const data = tableRef.current.row($(this).parents('tr')).data();
      const action = $(this).data('action');
  
      switch (action) {
        case 'view':
          console.log(`View: ${data[0]}`);
          break;
        case 'edit':
          console.log(`Edit: ${data[0]}`);
          break;
        case 'delete':
          console.log(`Delete: ${data[0]}`);
          break;
        // Add more actions here
        default:
          break;
      }
    });
  
    return (
      <React.Fragment>
        <HeaderMobile />
        <Header />
        <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
            <div>
              <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                {/* <li className="mx-2">
                  <h4>Employee History</h4>
                </li> */}
                <li class="breadcrumb-item ">
                      <Link to="/dashboard/helpdesk">Dashboard</Link>
                    </li>
                    <li class="breadcrumb-item active"><Link to="/pages/hr/Role">Role</Link></li>
                    <li class="breadcrumb-item active">Lists</li>
                {/* <li className="mx-2">
                  <Button
                    variant=""
                    className={`mb-3 btn-1 ${activeButton === 'posted' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('posted')}
                  >
                    Posted Jobs(0)
                  </Button>
                </li>
                <li className="mx-2">
                  <Button
                    variant=""
                    className={`mb-3 btn-1 ${activeButton === 'career' ? 'active' : ''}`}
                    onClick={() => handleButtonClick('career')}
                  >
                    Posted On a Career Site(0)
                  </Button>
                </li> */}
              </ol>
            </div>
            <nav aria-label="breadcrumb">
            <Button variant="primary" className="mb-3">
                    <Link to="/pages/hr/roleform">
                      <span style={{ color: "white" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg>
                        Create a Role
                      </span>
                    </Link>
                  </Button>
            </nav>
          </div>
          
          <Card className="popup-card empHistory">
            <Card.Body>
            <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                <div>
                <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
    <li className="mx-1">
      <Button
        variant=""
        className={`mb-3 btn-1 ${activeButton === 'open' ? 'active' : ''}`}
        onClick={() => handleButtonClick('open')}
        style={{
          backgroundColor: activeButton === 'open' ? '#506fd9' : 'white',
          color: activeButton === 'open' ? 'white' : 'black',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-hearts" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z" />
        </svg>Roles
      </Button>
    </li>
    <li className="mx-2">
      <Button
        variant=""
        className={`mb-3 btn-1 ${activeButton === 'closed' ? 'active' : ''}`}
        onClick={() => handleButtonClick('closed')}
        style={{
          backgroundColor: activeButton === 'closed' ? '#506fd9' : 'white',
          color: activeButton === 'closed' ? 'white' : 'black',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg> Status
      </Button>
    </li>
    <li className="mx-2">
      <Button
        variant=""
        className={`mb-3 btn-1 ${activeButton === 'actions' ? 'active' : ''}`}
        onClick={() => handleButtonClick('actions')}
        style={{
          backgroundColor: activeButton === 'actions' ? '#506fd9' : 'white',
          color: activeButton === 'actions' ? 'white' : 'black',
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
          <path d="M11.133 1.962a6.3 6.3 0 0 1 3.139 5.101H14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-1.728a6.3 6.3 0 0 1-3.138 5.101A1 1 0 0 1 9 15a1 1 0 0 1-1-1v-1.961a6.3 6.3 0 0 1-3.139-5.101H2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h1.728A6.3 6.3 0 0 1 6.866 1.961 1 1 0 0 1 9 1a1 1 0 0 1 1 1v1.961zM8 2a4.278 4.278 0 0 0-2.121.575 4.279 4.279 0 0 0-2.121-1.15V1h-1v2.426a4.279 4.279 0 0 0-2.121 1.15A4.278 4.278 0 0 0 0 4.726h2.426a4.279 4.279 0 0 0 1.15 2.121 4.279 4.279 0 0 0-1.15 2.121H1v1h2.426a4.279 4.279 0 0 0 1.15 2.121 4.278 4.278 0 0 0 2.121 1.15V15h1v-2.426a4.279 4.279 0 0 0 2.121-1.15A4.278 4.278 0 0 0 16 11.274H13.574a4.279 4.279 0 0 0-1.15-2.121 4.278 4.278 0 0 0 1.15-2.121H15v-1h-2.426a4.279 4.279 0 0 0-1.15-2.121A4.278 4.278 0 0 0 8 2z"/>
        </svg> Actions
      </Button>
    </li>
   
  </ol>
  
                </div>
  
                <nav aria-label="breadcrumb">
                <Button variant="primary" className="mb-3">
  
  
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
  </svg>
  Export
  
  
  </Button>
                </nav>
              </div>
              <table id="commonTable" className="display table table-striped table-curved" width="100%" ref={tableRef}></table>
            </Card.Body>
          </Card>
        </div>
      </React.Fragment>
    );
  }
  
  export default Role;
  