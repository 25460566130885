import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addAnnouncement } from "../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const AnnouncementForm = () => {

  const navigate = useNavigate();
  const [state, setState] = useState({
    branch_name: "",
  });

  const [announcements, setannouncements] = useState([]);
  const [errors, setErrors] = useState({});
  const [submittingAnc, setSubmittingAnc] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setState({
      announcement_subject: "",
    });
    setErrors({});
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submittingAnc) {
      return;
    }
    const validationErrors = validateForm(state);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setSubmittingAnc(true);
    try {
      const response = await addAnnouncement(state);
      const data = JSON.parse(response);
      if (data.success === true) {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
        });

      } else {
        toast.success(data.message, {
          position: "top-center",
          autoClose:1000,
        });
      }
    } catch (er) {
      toast.success("Announcement added successfully", {
        position: "top-right",
        autoClose: 1000,
      });
      navigate("/admin/announcement");
    }
    finally {
      setSubmittingAnc(false);
    }
  };







  const validateForm = (state) => {
    let errors = {};

    if (!state.announcement_subject.trim()) {
      errors.announcement_subject = "Announcement subject is required";
    }

    return errors;
  };




  return (
    <React.Fragment>
      <Header />
      {/* Your other layout code here */}
      <div className="main p-4 p-lg-5 mt-5">
        {/* Your layout structure */}
        <div className="mb-4 border-bottom text-center">
                        <h3 className="mb-0 pb- border-bottom custome-btn">Add Announcement</h3>
                    </div>
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/announcement">Announcements</Link>
              </li>
              <li className="breadcrumb-item active">Add announcement</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/admin/announcement" className="btn btn-danger mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link>
          </nav>
        </div>



        <div className="row"
          style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >


          <div className="col-md-12 formcontrols">
            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
              <form onSubmit={handleSubmit}>


                <div className="row">
                  <div className="col-md-12">

                    <div className="mb-3">
                      <label htmlFor="announcement_subject" className="form-label">
                        Announcement subject
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.announcement_subject && "is-invalid"}`}
                        id="announcement_subject"
                        name="announcement_subject"
                        value={state.announcement_subject}
                        onChange={handleChange}
                        placeholder="Enter..."
                        style={{
                          padding: '20px',
                          lineHeight: '1',
                        }}
                      />
                     

                      {errors.announcement_subject && (
                        <div className="invalid-feedback">{errors.announcement_subject}</div>
                      )}
                    </div>

                  </div>

                </div>
                <button type="submit" className="custom-submit-btn">
                  {/* Submit */}
                  {submittingAnc && (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                            {submittingAnc ? 'Submitting announcement...' : 'Add Announcement'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AnnouncementForm;
