import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { updateDepartment, editShift, updateShift } from '../../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const EditShift = () => {
    const navigate = useNavigate();
    const { id, masterId, shiftNo } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [state, setState] = useState({
        _id: '',
        company_id: '',
        shift_no: "",
        company_start_time: "",
        company_end_time: "",
        company_break_time: "",
        company_break_fine: "",
        company_late_fine: "",
        timezone: "",
        breakList: [{ break_id: null, break_name: "", break_time: null }],
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     async function fetchShiftData() {
    //         try {
    //             const shiftData = await editShift(id, masterId);

    //             if (shiftData && shiftData.success) {
    //                 const shift = shiftData.success;

    //                 setState({
    //                     _id: shift._id || '',
    //                     company_id: shift.company_id || '',
    //                     shift_no: shift.shift_no || '',
    //                     company_start_time: shift.company_start_time || '',
    //                     company_end_time: shift.company_end_time || '',
    //                     company_break_time: shift.company_break_time || '',
    //                     company_break_fine: shift.company_break_fine || '',
    //                     company_late_fine: shift.company_late_fine || '',
    //                     timezone: shift.timezone || '',
    //                 });
    //             } else {
    //                 console.error('No shift data found');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching shift data:', error);
    //         }
    //     }

    //     fetchShiftData();
    // }, [id, masterId]);

    useEffect(() => {
        const fetchShiftData = debounce(async () => {
            try {
                setLoading(true);
                const shiftData = await editShift(id, masterId,shiftNo);

                if (shiftData && shiftData.success) {
                    const shift = shiftData.success;

                    setState({
                        _id: shift._id || '',
                        company_id: shift.company_id || '',
                        shift_no: shift.shift_no || '',
                        company_start_time: shift.company_start_time || '',
                        company_end_time: shift.company_end_time || '',
                        company_break_time: shift.company_break_time || '',
                        company_break_fine: shift.company_break_fine || '',
                        company_late_fine: shift.company_late_fine || '',
                        timezone: shift.timezone || '',
                        breakList: shift.breakList || [],
                    });
                } else {
                    console.error('No shift data found');
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching shift data:', error);
                setLoading(false);
            }
        }, 100);

        fetchShiftData();

        return () => {
            fetchShiftData.cancel();
        };
    }, [id, masterId]);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setState((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    //     setErrors((prevErrors) => ({
    //         ...prevErrors,
    //         [name]: '',
    //     }));
    // };


    const handleChange = (e, break_id) => {
        const { name, value } = e.target;

        setState(prevState => {
            // Check if the field name is in the top-level state
            if (['shift_no', 'company_start_time', 'company_end_time', 'timezone', 'company_break_time', 'company_break_fine', 'company_late_fine'].includes(name)) {
                return {
                    ...prevState,
                    [name]: value,
                };
            } else {
                console.log(name, value);
                // Update the breakList
                return {
                    ...prevState,
                    breakList: prevState.breakList.map(item => {
                        if (item.break_id === break_id) {
                            return {
                                ...item,
                                [name]: value,
                            };
                        }
                        return item;
                    }),
                };
            }
        });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        } if (submitting) {
            return;
        }
        setSubmitting(true);

        try {
            const response = await updateShift(id, masterId, state);
            if (response && response.success && response.success.length > 0) {
                toast.success(response.success[0].message, {
                    position: 'top-center',
                    autoClose: 1000,
                });
            } else {
                toast.success('shift updated successfully', {
                    position: 'top-center',
                    autoClose: 1000,
                });
                setSubmitting(false);
                navigate('/pages/hr/shift');
            }
        } catch (error) {
            console.error('An error occurred:', error);
            toast.error('An error occurred', {
                position: 'top-right',
                autoClose: 1000,
            });
        }
    };

    const validateForm = (state) => {
        let errors = {};
        // Add validations if necessary
        return errors;
    };

    const [displayedDate, setDisplayedDate] = useState('');
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US');
    };
    useEffect(() => {
        if (state.shift_date) {
            setDisplayedDate(formatDate(state.shift_date));
        }
    }, [state.joining_date]);

    const handleDateChange = (event) => {
        const newDate = event.target.value;
        setState((prevState) => ({
            ...prevState,
            shift_date: newDate,
        }));
        setDisplayedDate(formatDate(newDate));
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />

            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Edit Shift</h3>
                </div>
                <div>


                    <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                        <div>
                            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                                <li className="breadcrumb-item">
                                    <Link to="/admin/dashboard">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to="/pages/hr/shift">Shift</Link>
                                </li>
                                <li className="breadcrumb-item active">Edit Shift</li>
                            </ol>
                        </div>
                        <nav aria-label="breadcrumb">
                            <Link to="/pages/hr/shift" className="btn btn-danger mb-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                                Back
                            </Link>
                        </nav>
                    </div>

                    {loading &&
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    }

                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >


                        <div className="col-md-12">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="shift_no" className="form-label">
                                                    Shift no.
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.shift_no && 'is-invalid'}`}
                                                    id="shift_no"
                                                    name="shift_no"
                                                    value={state.shift_no}
                                                    onChange={handleChange}
                                                />
                                                {errors.shift_no && (
                                                    <div className="invalid-feedback">{errors.shift_no}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">

                                            <div className="mb-3">
                                                <label htmlFor="company_start_time" className="form-label">
                                                    Company start time
                                                </label>
                                                <input
                                                    type="time"
                                                    className={`form-control ${errors.company_start_time && 'is-invalid'}`}
                                                    id="company_start_time"
                                                    name="company_start_time"
                                                    value={state.company_start_time}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_start_time && (
                                                    <div className="invalid-feedback">{errors.company_start_time}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="company_end_time" className="form-label">
                                                    Company end time
                                                </label>
                                                <input
                                                    type="time"
                                                    className={`form-control ${errors.company_end_time && 'is-invalid'}`}
                                                    id="company_end_time"
                                                    name="company_end_time"
                                                    value={state.company_end_time}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_end_time && (
                                                    <div className="invalid-feedback">{errors.company_end_time}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">

                                            <div className="mb-3">
                                                <label htmlFor="company_break_time" className="form-label">
                                                    Company break time
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.company_break_time && 'is-invalid'}`}
                                                    id="company_break_time"
                                                    name="company_break_time"
                                                    value={state.company_break_time}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_break_time && (
                                                    <div className="invalid-feedback">{errors.company_break_time}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="company_break_fine" className="form-label">
                                                    Break fine
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.company_break_fine && 'is-invalid'}`}
                                                    id="company_break_fine"
                                                    name="company_break_fine"
                                                    value={state.company_break_fine}
                                                    onChange={handleChange}
                                                />
                                                {errors.shift_no && (
                                                    <div className="invalid-feedback">{errors.company_break_fine}</div>
                                                )}
                                            </div>


                                        </div>
                                        <div className="col-md-6">

                                            <div className="mb-3">
                                                <label htmlFor="company_late_fine" className="form-label">
                                                    Late fine
                                                </label>
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.company_late_fine && 'is-invalid'}`}
                                                    id="company_late_fine"
                                                    name="company_late_fine"
                                                    value={state.company_late_fine}
                                                    onChange={handleChange}
                                                />
                                                {errors.company_late_fine && (
                                                    <div className="invalid-feedback">{errors.company_late_fine}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row">

                                        {state.breakList.map((item, index) => (
                                            <div key={index} className="mb-3">
                                                <label htmlFor={`break_name_${item.break_id}`} className="form-label">
                                                    {item.break_name}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id={`break_name_${item.break_id}`}
                                                    name='break_time'
                                                    value={item.break_time}
                                                    onChange={(e) => handleChange(e, item.break_id)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    <button type="submit" className="custom-submit-btn" onClick={handleSubmit}
                                        disabled={submitting}>

                                        {submitting ? 'Submitting...' : 'Update'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </React.Fragment>
    );
};

export default EditShift;

