import React, { useState, useEffect, useRef } from 'react';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Button, Modal, Form } from "react-bootstrap";
import '../../styles/globals.css';
import $ from 'jquery';
import _debounce from 'lodash/debounce';
import 'datatables.net';
import { Link } from "react-router-dom";
import { addCandidateInfo, addCandidateInvite, confirmempolyee, directShortListedEmployee, downloadPdfFile, getToken, getcompanyId, shortListedemployee, statuschangesapplicate, viewEmployeeDetails, viewSingleEmployeeDetail, viewapplicatestatus, viewshortlistedemployeeshortlisteddetail } from '../../services/api/api';
import { toast } from "react-toastify";
import { viewDesignation, deleteDesignation } from '../../services/api/api';
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { useReactToPrint } from 'react-to-print';
import DownloadIcon from '@mui/icons-material/Download';


const Candidate = () => {
  const modalRef = useRef(null);
  const printRef = useRef();
  const tableRef = useRef(null);
  const [activeButton, setActiveButton] = useState('open');
  const [applicantStatus, setApplicantStatus] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentSubId, setCurrentSubId] = useState(null);
  const [modalAction, setModalAction] = useState(null);
  const [employeeEmail, setEmployeeEmail] = useState('')

  const handleStatusChange = (id, subId, action, email) => {
    setCurrentId(id);
    setCurrentSubId(subId);
    setModalAction(action);
    setShowModal(true);
    setEmployeeEmail(email)
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentId(null);
    setCurrentSubId(null);
    setModalAction(null);
  };

  const handleConfirmModal = async () => {
    if (currentId && currentSubId && modalAction) {
      // if (modalAction == 'shortList') {
      const response = await directShortListedEmployee(currentId, currentSubId, employeeEmail);
      console.log(response);
      // }
      // Add other actions if needed
    }
    setShowModal(false);
    setCurrentId(null);
    setCurrentSubId(null);
    setModalAction(null);
  };
  // const handleConfirmModal = async () => {
  //   if (currentId && currentSubId && modalAction) {
  //     // if (modalAction == 'shortList') {
  //     const response = await shortListedemployee(currentId, currentSubId);
  //     console.log(response);
  //     // }
  //     // Add other actions if needed
  //   }
  //   setShowModal(false);
  //   setCurrentId(null);
  //   setCurrentSubId(null);
  //   setModalAction(null);
  // };


  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const fetchApplicantStatus = async () => {
    try {
      const response = await viewapplicatestatus();

      console.log('API Response:', response);

      if (response[0]) {
        let applicatStat = response[0].applicat_status.map(status => status.status_name)
        setApplicantStatus(applicatStat);
        console.log(applicatStat);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchApplicantStatus, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);




  useEffect(() => {
    if ($.fn.DataTable.isDataTable('#commonTable')) {
      tableRef.current = $('#commonTable').DataTable();
      tableRef.current.destroy();
    }
    if ($.fn.DataTable) {
      tableRef.current = $('#commonTable').DataTable({
        columns: [
          { title: 'Sr.No.' },
          { title: 'Name' },
          // { title: 'Department' },
          { title: 'Position' },
          { title: 'Email' },
          { title: 'Joining Date' },
          { title: 'Status' },
          // { title: 'Actions' },
        ],
        paging: true,
        ordering: true,
        searching: true,
        columnDefs: [
          {
            targets: -1,
            orderable: false,
            searchable: false,
            // render: function (data, type, full, meta) {
            //   return createActionButton(full);
            // },
          },
        ],
        responsive: true,
        language: {
          emptyTable: "No data available"
        }
      });
    }

    const updatePaginationStyles = () => {
      const paginationElements = $('.paginate_button');
      paginationElements.each(function (index) {
        const $this = $(this);
        if ($this.hasClass('current')) {
          $this.css({
            border: '0px solid blue',
            padding: '3px 8px 3px 8px',
            borderRadius: '3px',
            color: 'white',
            fontWeight: 500,
            background: '#506fd9',
          });
        }
      });
    };

    tableRef.current.on('draw.dt', updatePaginationStyles);
    updatePaginationStyles();
  }, []);



  $('#commonTable tbody').on('click', 'button', function () {
    const data = tableRef.current.row($(this).parents('tr')).data();
    const action = $(this).data('action');

    switch (action) {

      case 'view':
        console.log(`View: ${data[0]}`);
        break;
      case 'edit':
        console.log(`Edit: ${data[0]}`);
        break;
      case 'delete':
        console.log(`Delete: ${data[0]}`);
        break;
      // Add more actions here
      default:
        break;
    }
  });

  const handleStatusChange1 = async (id, subId) => {
    const response = await shortListedemployee(id, subId);
    console.log(response);
  }

  const handleDynamicStatusChange = async (id, subId, subStatus) => {
    const response = await statuschangesapplicate(id, subId, subStatus);
    console.log(response);
  }


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };






  const buttonRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSending(true);
      buttonRef.current.disabled = true;
      const token = getToken();
      console.log("token", token);
      const companyId = getcompanyId();
      const response = await addCandidateInvite({
        email: formData.email,
        token: token,
      });

      toast.success(response.message, {
        position: "top-center",
        autoClose: 5000,
      });
      handleClose();

    } catch (error) {
      console.error('Error sending invite:', error);

    } finally {
      setIsSending(false); // Re-enable the button
      buttonRef.current.disabled = false; // Re-enable the button
    }
  };


  const navigate = useNavigate();

  const [designationData, setDesignationData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteDesignationId, setDeleteDesignationId] = useState(null);
  const [masterId, setMasterId] = useState();
  const [shortListedEmp, setShortListedEmp] = useState([]);
  const [enrolledEmp, setEnrolledEmp] = useState([]);
  const [displayData, setDisplayData] = useState([]);


  const validData = Array.isArray(displayData)
    ? displayData.filter(row => row && row.emp_id)
    : [];

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);


  const [searchText, setSearchText] = useState('');
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };


  const filterRows = () => {
    if (!searchText) {
      return [...employeeData].reverse();
    }

    return [...employeeData].reverse().filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };


  const handleDeleteDesignation = async (id, masterId) => {
    setDeleteDesignationId(id);
    setShowConfirmation(true);

    try {
      const response = await deleteDesignation(id, masterId);
      if (response && response.status === 'success') {
        if (!toast.isActive('designationDeletedToast')) {
          toast.success('Designation deleted successfully', { toastId: 'designationDeletedToast' });
        }
        fetchData();
      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting designation:', error);
    }
  };

  const handleShortlistedApplicant = async () => {
    let response = await viewshortlistedemployeeshortlisteddetail()

    setShortListedEmp(response)
    setDisplayData(response)
  }

  const handleEnrolledApplicant = async () => {
    let response = await confirmempolyee()

    setEnrolledEmp(response)
    setDisplayData(response)
  }

  const [candidateId, setCandidateId] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);


  const [id, setStoredIds] = useState([]);
  const [subId, setStoredEmpIds] = useState([]);

  const fetchData = async () => {
    try {
      const response = await viewEmployeeDetails();
      setEmployeeData(response);
      setDisplayData(response)

      const ids = response.length > 0 ? response[0]._id : null;
      const empIds = response.length > 0 ? response[0].emp_id : null;
      setStoredIds(ids);
      setStoredEmpIds(empIds);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    } finally {
      setLoading(false);
    }
  };



  const reversedRows = [...employeeData]
  const columns = [
    {
      field: 'id',
      headerName: 'Sr No.',
      width: 20,
      align: 'center',
      headerAlign: 'center',
      flex: 2,
      renderCell: (params) => (
        // console.log(params)
        <span>

          {validData.indexOf(params.row) + 1}
        </span>
      ),
    },
    {
      field: 'full_name',
      headerName: 'Full Name',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      flex: 1.5,
      valueGetter: (params) => `${params.row.first_name} ${params.row.middle_name} ${params.row.last_name}`,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 100,
      flex: 1.5,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'position',
      headerName: 'Position',
      width: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      flex: 3,
      renderCell: (params) => {
        let buttonLabel = '';
        let buttonColor = '';
        let borderColor = '';

        if (params.row.status === 3) {
          buttonLabel = 'Enrolled';
          buttonColor = 'green';
          borderColor = 'green';
        } else if (params.row.newStatus === "" && params.row.status === 1) {
          buttonLabel = 'Shortlist';
          buttonColor = '#007bff';
          borderColor = '#007bff';
        } else if (params.row.newStatus === "" && params.row.status === 2) {
          buttonLabel = 'Shortlisted';
          buttonColor = 'grey';
          borderColor = 'grey';
        } else if (params.row.newStatus === "shortListed" && params.row.status === 2) {
          buttonLabel = 'Confirm';
          buttonColor = 'blue';
          borderColor = 'blue';
        }

        return (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="blue"
              className="bi bi-eye-fill"
              viewBox="0 0 16 16"
              style={{ cursor: 'pointer', marginRight: '16px' }}
              onClick={() => handleViewEmployee(params.row._id, params.row.emp_id)}
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
            </svg>

            <div className="select-container">
              <select
                onChange={(e) => {
                  handleDynamicStatusChange(params.row._id, params.row.emp_id, e.target.value);
                  console.log(params);
                }}
                defaultValue={params.row.subStatus}
              >
                <option value="" disabled>Select Status</option>
                {applicantStatus.map((status, index) => (
                  <option key={index} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ padding: "6px 20px" }}>


              {(params.row.status !== 3 && params.row.newStatus === "" && params.row.status === 1) && (
                <Button
                  onClick={() => handleStatusChange(params.row._id, params.row.emp_id, 'shortList', params.row.email)}
                  style={{
                    fontSize: '10px',
                    backgroundColor: buttonColor,
                    borderColor: borderColor,
                  }}
                >
                  {buttonLabel}
                </Button>
              )}

              {(params.row.status !== 3 && params.row.newStatus === "" && params.row.status === 2) && (
                <Button
                  onClick={() => handleStatusChange(params.row._id, params.row.emp_id, 'shortList', params.row.email)}
                  style={{
                    fontSize: '10px',
                    // padding: '6px 12px',
                    backgroundColor: buttonColor,
                    borderColor: borderColor,
                  }}
                >
                  {buttonLabel}
                </Button>
              )}

              {(params.row.status !== 3 && params.row.newStatus === "shortListed" && params.row.status === 2) && (
                <Button
                  onClick={() => handleStatusChange(params.row._id, params.row.emp_id, 'confirm', params.row.email)}
                  style={{
                    fontSize: '10px',
                    padding: '6px 12px',
                    backgroundColor: buttonColor,
                    borderColor: borderColor,
                  }}
                >
                  {buttonLabel}
                </Button>
              )}

              {(params.row.status === 3) && (
                <Button
                  onClick={() => handleStatusChange(params.row._id, params.row.emp_id, 'enrolled', params.row.email)}
                  style={{
                    fontSize: '10px',
                    padding: '6px 12px',
                    backgroundColor: buttonColor,
                    borderColor: borderColor,

                  }}
                  disabled={params.row.status === 3}
                >
                  {buttonLabel}
                </Button>
              )}
            </div>

          </>
        );
      }
    }
  ];

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [selectedSubId, setSelectedSubId] = useState(null);
  const [resumeNo, setResumeNo] = useState(null);

  const [employees, setEmployees] = useState([]);
  const handleViewEmployee = async (id, subId) => {
    try {
      const response = await viewSingleEmployeeDetail(id, subId);
      if (response && response.length > 0) {
        setSelectedId(id);
        setSelectedSubId(subId);
        setEmployees(response);
        setSelectedEmployee(response[0].application_info);
        setResumeNo(response[0].application_info.resume);
        console.log(response);
        setModalOpen(true);
      } else {
        console.error('Empty response or invalid data format received');
      }
    } catch (error) {
      console.error('Error fetching employee details:', error);
    }
  };


  // const downloadResume = async () => {
  //   console.log(resumeNo);

  //   try {
  //     const response = await downloadPdfFile(resumeNo);
  //     // console.log(response);

  //     // if (response && response.length > 0) {

  //     // } else {
  //     //   console.error('Empty response or invalid data format received');
  //     // }
  //   } catch (error) {
  //     console.error('Error fetching employee details:', error);
  //   }
  // };

 // Ensure that resumeNo is a string or number
 const downloadResume = async () => {
  try {
    console.log('Resume No:', resumeNo); // Debug log
    
    const blob = await downloadPdfFile(resumeNo);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'resume.pdf'); // Customize the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};




  const captureAndGeneratePDF = () => {
    const element = modalRef.current; // Ensure modalRef is defined and points to your modal element
    if (!element) {
      console.error('Element not found. Make sure the ref is set correctly and the element is rendered.');
      return;
    }

    html2canvas(element, { scrollX: 0, scrollY: -window.scrollY }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4'); // 'p' for portrait, 'mm' for millimeters, 'a4' for A4 size

      const pdfWidth = pdf.internal.pageSize.getWidth(); // A4 width
      const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 height

      const imgWidth = canvas.width * 0.264583; // Convert pixel to mm
      const imgHeight = canvas.height * 0.264583; // Convert pixel to mm

      let position = 0;

      if (imgHeight > pdfHeight) {
        // If the image height is greater than PDF height, add multiple pages
        while (position < imgHeight) {
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
          position += pdfHeight;
          if (position < imgHeight) {
            pdf.addPage();
          }
        }
      } else {
        // If the image fits on one page
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, imgHeight);
      }

      pdf.save('Applicant_Details.pdf');
    }).catch(error => {
      console.error('Error capturing the screenshot:', error);
    });
  };


  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <div>
              <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                <li className="mx-1">

                  <Button className="mb-3 btn-1" onClick={fetchData} style={{ backgroundColor: '#007bff', borderColor: '#28a745', fontWeight: 'bold', borderRadius: '5px', padding: '10px 20px', color: '#fff', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                    All Applicants
                  </Button>
                  <Button variant="primary" className="mb-3 btn-1" onClick={handleShortlistedApplicant} style={{ backgroundColor: '#2196f3', borderColor: '#2196f3', fontWeight: 'bold', borderRadius: '5px', padding: '10px 20px', color: '#fff', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                    Shortlisted Applicants
                  </Button>
                  <Button variant="primary" className="mb-3 btn-1" onClick={handleEnrolledApplicant} style={{ backgroundColor: '#28a745', borderColor: '#28a745', fontWeight: 'bold', borderRadius: '5px', padding: '10px 20px', color: '#fff', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
                    Show Enrolled Applicants
                  </Button>

                </li>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Invite Applicants</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Enter email"
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>

                    <Button
                      ref={buttonRef}
                      variant="primary"
                      onClick={handleSubmit}
                      disabled={isSending}
                    >
                      {isSending ? 'Sending...' : 'Send Invites'}
                    </Button>

                  </Modal.Footer>
                </Modal>
              </ol>
            </div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">

              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/pages/recruitment/Candidate">Applicants</Link></li>
              <li class="breadcrumb-item active">Lists</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">

          </nav>
        </div>

        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                <label htmlFor="searchInput" style={{ flexShrink: 0, marginRight: '10px' }}></label>
                <input
                  type="text"
                  className='p-1 w-30'
                  id="searchInput"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>


            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: '100%', width: '100%' }}>

                <DataGrid
                  rows={validData}
                  columns={columns}
                  pageSize={5}
                  responsive
                  autoHeight

                  // checkboxSelection
                  getRowId={(row) => row.emp_id} // Change from '_id' to 'emp_id'
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No employee data found</div>,
                  }}
                />
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Are you sure you want to proceed</Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmModal}>
                      Confirm
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>


      <Modal show={selectedEmployee !== null} onHide={() => setSelectedEmployee(null)} centered size="xl">

        <Modal.Header closeButton style={{ backgroundColor: '#f8f9fa', borderBottom: '1px solid #dee2e6' }}>
          <Modal.Title>
            <p className="">Candidate Details</p>


            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ cursor: 'pointer', float: 'right' }} className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16" onClick={captureAndGeneratePDF}>
              <path d="M5.523 12.424q.21-.124.459-.238a8 8 0 0 1-.45.606c-.28.337-.498.516-.635.572l-.035.012a.3.3 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548m2.455-1.647q-.178.037-.356.078a21 21 0 0 0 .5-1.05 12 12 0 0 0 .51.858q-.326.048-.654.114m2.525.939a4 4 0 0 1-.435-.41q.344.007.612.054c.317.057.466.147.518.209a.1.1 0 0 1 .026.064.44.44 0 0 1-.06.2.3.3 0 0 1-.094.124.1.1 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256M8.278 6.97c-.04.244-.108.524-.2.829a5 5 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.5.5 0 0 1 .145-.04c.013.03.028.092.032.198q.008.183-.038.465z" />
              <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.7 11.7 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.86.86 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.84.84 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.8 5.8 0 0 0-1.335-.05 11 11 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.24 1.24 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a20 20 0 0 1-1.062 2.227 7.7 7.7 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103" />
            </svg> */}

            <Button onClick={captureAndGeneratePDF}>Download PDF</Button>
            <Button onClick={downloadResume}>Download Resume</Button>

          </Modal.Title>

        </Modal.Header>
        <div ref={modalRef}>
          <Modal.Body style={{ background: '#a7cbf2' }}>
            {selectedEmployee && (
              <div className="container">
                <div className="row">
                  {/* Personal Information */}
                  <div className="col-md-4">
                    <div className="card mb-3 shadow-sm">
                      <div className="card-body">
                        {/* <h5 className="card-title font-weight-bold">Personal Information</h5> */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                          <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '18px 10px 0 10px' }}>
                            <p className="title_small">
                              Personal Information
                            </p></h2>
                          <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                          <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div>
                        </div>
                        <div className="field-box mb-3">
                          <strong>First Name:</strong> {selectedEmployee.first_name}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Middle Name:</strong> {selectedEmployee.middle_name}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Last Name:</strong> {selectedEmployee.last_name}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Email:</strong> {selectedEmployee.email}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Date of Birth:</strong> {selectedEmployee.dob}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Blood Group:</strong> {selectedEmployee.blood_grp}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Reference By:</strong> {selectedEmployee.referenceby}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Contact Information */}
                  <div className="col-md-4">
                    <div className="card mb-3 shadow-sm">
                      <div className="card-body">
                        {/* <h5 className="card-title font-weight-bold">Contact Information</h5> */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                          <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '18px 10px 0 10px' }}>
                            <p className="title_small">
                              Contact Information
                            </p></h2>
                          <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                          <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div>
                        </div>
                        <div className="field-box mb-3">
                          <strong>Phone:</strong> {selectedEmployee.phone}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Alternate Phone:</strong> {selectedEmployee.alt_phone}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Address:</strong> {selectedEmployee.address}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Apartment/Unit:</strong> {selectedEmployee.apt_unit_address}
                        </div>
                        <div className="field-box mb-3">
                          <strong>City:</strong> {selectedEmployee.city}
                        </div>
                        <div className="field-box mb-3">
                          <strong>State:</strong> {selectedEmployee.state}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Pincode:</strong> {selectedEmployee.pincode}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Additional Information */}
                  <div className="col-md-4">
                    <div className="card mb-3 shadow-sm">
                      <div className="card-body">
                        {/* <h5 className="card-title font-weight-bold">Additional Information</h5> */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                          <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '18px 10px 0 10px' }}>
                            <p className="title_small">
                              Additional Information
                            </p></h2>
                          <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                          <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div>
                        </div>
                        <div className="field-box mb-3">
                          <strong>Position Applied For:</strong> {selectedEmployee.position}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Experience:</strong> {selectedEmployee.referance_friend.experienceLevel}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Expected Start Date:</strong> {selectedEmployee.des_startdate}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Expected Salary:</strong> {selectedEmployee.des_salary}
                        </div>
                        <div className="field-box mb-3">
                          <strong>Has Criminal Record:</strong> {selectedEmployee.con_crime === 'yes' ? 'Yes' : 'No'}
                        </div>
                        {selectedEmployee.con_crime === 'yes' && (
                          <div className="field-box mb-3">
                            <strong>Criminal Record Details:</strong> {selectedEmployee.yes_crime}
                          </div>
                        )}
                        <div className="field-box mb-3">
                          <strong>Health Issues:</strong> {selectedEmployee.hel_issue === 'yes' ? 'Yes' : 'No'}
                        </div>
                        {selectedEmployee.hel_issue === 'yes' && (
                          <div className="field-box mb-3">
                            <strong>Health Issue Details:</strong> {selectedEmployee.yes_issue}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {selectedEmployee.education.highschool_university || selectedEmployee.education.highschool_location || selectedEmployee.education.highschool_stream || selectedEmployee.education.highschool_passing_year ||
                    selectedEmployee.education.bachelors_university || selectedEmployee.education.bachelors_location || selectedEmployee.education.bachelors_stream || selectedEmployee.education.bachelors_passing_year ||
                    selectedEmployee.education.masters_university || selectedEmployee.education.masters_location || selectedEmployee.education.masters_stream || selectedEmployee.education.masters_passing_year ? (
                    <div className="row">
                      <div className="card mb-3 shadow-sm">
                        <div className="card-body">
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                            <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '18px 10px 0 10px' }}>
                              <p className="title_small">
                                Education
                              </p>
                            </h2>
                            <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                            <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              {selectedEmployee.education.highschool_university && (
                                <div className="field-box mb-3">
                                  <strong>High School University:</strong> {selectedEmployee.education.highschool_university}
                                </div>
                              )}
                              {selectedEmployee.education.highschool_location && (
                                <div className="field-box mb-3">
                                  <strong>High School Location:</strong> {selectedEmployee.education.highschool_location}
                                </div>
                              )}
                              {selectedEmployee.education.highschool_stream && (
                                <div className="field-box mb-3">
                                  <strong>High School Stream:</strong> {selectedEmployee.education.highschool_stream}
                                </div>
                              )}
                              {selectedEmployee.education.highschool_passing_year && (
                                <div className="field-box mb-3">
                                  <strong>High School Passing Year:</strong> {selectedEmployee.education.highschool_passing_year}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              {selectedEmployee.education.bachelors_university && (
                                <div className="field-box mb-3">
                                  <strong>Bachelor's University:</strong> {selectedEmployee.education.bachelors_university}
                                </div>
                              )}
                              {selectedEmployee.education.bachelors_location && (
                                <div className="field-box mb-3">
                                  <strong>Bachelor's Location:</strong> {selectedEmployee.education.bachelors_location}
                                </div>
                              )}
                              {selectedEmployee.education.bachelors_stream && (
                                <div className="field-box mb-3">
                                  <strong>Bachelor's Stream:</strong> {selectedEmployee.education.bachelors_stream}
                                </div>
                              )}
                              {selectedEmployee.education.bachelors_passing_year && (
                                <div className="field-box mb-3">
                                  <strong>Bachelor's Passing Year:</strong> {selectedEmployee.education.bachelors_passing_year}
                                </div>
                              )}
                            </div>
                            <div className="col-md-4">
                              {selectedEmployee.education.masters_university && (
                                <div className="field-box mb-3">
                                  <strong>Master's University:</strong> {selectedEmployee.education.masters_university}
                                </div>
                              )}
                              {selectedEmployee.education.masters_location && (
                                <div className="field-box mb-3">
                                  <strong>Master's Location:</strong> {selectedEmployee.education.masters_location}
                                </div>
                              )}
                              {selectedEmployee.education.masters_stream && (
                                <div className="field-box mb-3">
                                  <strong>Master's Stream:</strong> {selectedEmployee.education.masters_stream}
                                </div>
                              )}
                              {selectedEmployee.education.masters_passing_year && (
                                <div className="field-box mb-3">
                                  <strong>Master's Passing Year:</strong> {selectedEmployee.education.masters_passing_year}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}


                  <div className="col-md-8 col-sm-12">
                    <div className="card mb-3 shadow-sm">
                      <div className="card-body">
                        <h5 className="card-title font-weight-bold text-center">Emergency Contact</h5>
                        <div style={{ display: 'flex' }}>
                          <div className="col-md-6 col-sm-6">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                              <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '18px 10px 0 10px' }}>
                                <p className="title_small">
                                  (Blood Relation)
                                </p></h2>
                              <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                              <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div>
                            </div>
                            <div className="field-box mb-3">
                              <strong>Full Name:</strong> {selectedEmployee.referance_blood_relation.full_name1} {selectedEmployee.referance_blood_relation.last_name1}
                            </div>
                            <div className="field-box mb-3">
                              <strong>Relationship:</strong> {selectedEmployee.referance_blood_relation.relationship1}
                            </div>
                            <div className="field-box mb-3">
                              <strong>Occupation:</strong> {selectedEmployee.referance_blood_relation.occupation1}
                            </div>
                            <div className="field-box mb-3">
                              <strong>Phone:</strong> {selectedEmployee.referance_blood_relation.phone1}
                            </div>
                            <div className="field-box mb-3">
                              <strong>Address:</strong> {selectedEmployee.referance_blood_relation.address1}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                              <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '18px 10px 0 10px' }}>
                                <p className="title_small">
                                  (Friend)
                                </p></h2>
                              <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                              <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div>
                            </div>
                            <div className="field-box mb-3">
                              <strong>Full Name:</strong> {selectedEmployee.referance_friend.full_name2} {selectedEmployee.referance_friend.last_name2}
                            </div>
                            <div className="field-box mb-3">
                              <strong>Relationship:</strong> {selectedEmployee.referance_friend.relationship2}
                            </div>
                            <div className="field-box mb-3">
                              <strong>Occupation:</strong> {selectedEmployee.referance_friend.occupation}
                            </div>
                            <div className="field-box mb-3">
                              <strong>Phone:</strong> {selectedEmployee.referance_friend.phone2}
                            </div>
                            <div className="field-box mb-3">
                              <strong>Address:</strong> {selectedEmployee.referance_friend.address2}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                    {employees.previous_employment.map((employee, empIndex) => (
                      !employee.experienceLevel || employee.experienceLevel !== 'fresher' ? (
                        <div key={empIndex} className="row">
                          <div key={empIndex} className="col-md-12">
                            {employee.previous_employment.map((employment, index) => (
                              <div key={index} className="card mb-3 shadow-sm" style={{ width: '100%' }}>
                                <div className="card-body">
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                                    <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '18px 10px 0 10px' }}>
                                      <p className="title_small">
                                        Previous Employment {index + 1}
                                      </p>
                                    </h2>
                                    <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                                    <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div>
                                  </div>
                                  <div className="mb-3">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="field-box mb-3">
                                          <strong>Company:</strong> {employees.previous_employment.company}
                                        </div>
                                        <div className="field-box mb-3">
                                          <strong>Supervisor's number:</strong> {employees.previous_employment.authority_phone
                                          }
                                        </div>
                                        <div className="field-box mb-3">
                                          <strong>Address:</strong> {employees.previous_employment.address_precom}
                                        </div>
                                        <div className="field-box mb-3">
                                          <strong>Supervisor Name:</strong> {employees.previous_employment.authority_name}
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="field-box mb-3">
                                          <strong>Starting Salary:</strong> {employees.previous_employment.salary}
                                        </div>
                                        <div className="field-box mb-3">
                                          <strong>Joining From:</strong> {new Date(employees.previous_employment.joined_from).toLocaleDateString()}
                                        </div>
                                        <div className="field-box mb-3">
                                          <strong>Joining To:</strong> {employees.previous_employment.joined_to ? new Date(employees.previous_employment.joined_to).toLocaleDateString() : 'Present'}
                                        </div>
                                        <div className="field-box mb-3">
                                          <strong>Your Designation:</strong> {employees.previous_employment.job_title}
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="field-box mb-3">
                                          <strong>Responsibility:</strong> {employees.previous_employment.responsibility}
                                        </div>
                                        <div className="field-box mb-3">
                                          <strong>Reason for Leaving:</strong> {employees.previous_employment.reason_leaving}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null
                    ))}
                  </div> */}
                  <div className="row">
                    {(employees.previous_employment || []).map((employment, index) => (
                      !employees.experienceLevel || employees.experienceLevel !== 'fresher' ? (
                        <div key={index} className="col-md-12">
                          <div className="card mb-3 shadow-sm" style={{ width: '100%' }}>
                            <div className="card-body">
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  marginBottom: '20px',
                                  marginTop: '20px',
                                  position: 'relative'
                                }}
                              >
                                <h2
                                  style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    zIndex: 1,
                                    position: 'relative',
                                    backgroundColor: '#fff',
                                    padding: '18px 10px 0 10px'
                                  }}
                                >
                                  <p className="title_small">
                                    Previous Employment {index + 1}
                                  </p>
                                </h2>
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: 0,
                                    right: 0,
                                    height: '1px',
                                    backgroundColor: '#000'
                                  }}
                                ></div>
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: 'calc(50% - 1px)',
                                    left: '10px',
                                    right: '10px',
                                    height: '1px',
                                    backgroundColor: '#fff'
                                  }}
                                ></div>
                              </div>
                              <div className="mb-3">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="field-box mb-3">
                                      <strong>Company:</strong> {employment.company || 'N/A'}
                                    </div>
                                    <div className="field-box mb-3">
                                      <strong>Supervisor's number:</strong> {employment.authority_phone || 'N/A'}
                                    </div>
                                    <div className="field-box mb-3">
                                      <strong>Address:</strong> {employment.address || 'N/A'}
                                    </div>
                                    <div className="field-box mb-3">
                                      <strong>Supervisor Name:</strong> {employment.authority_name || 'N/A'}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="field-box mb-3">
                                      <strong>Starting Salary:</strong> {employment.salary || 'N/A'}
                                    </div>
                                    <div className="field-box mb-3">
                                      <strong>Joining From:</strong> {employment.joined_from ? new Date(employment.joined_from).toLocaleDateString() : 'N/A'}
                                    </div>
                                    <div className="field-box mb-3">
                                      <strong>Joining To:</strong> {employment.joined_to ? new Date(employment.joined_to).toLocaleDateString() : 'Present'}
                                    </div>
                                    <div className="field-box mb-3">
                                      <strong>Your Designation:</strong> {employment.job_title || 'N/A'}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="field-box mb-3">
                                      <strong>Responsibility:</strong> {employment.responsibility || 'N/A'}
                                    </div>
                                    <div className="field-box mb-3">
                                      <strong>Reason for Leaving:</strong> {employment.reason_leaving || 'N/A'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null
                    ))}
                  </div>


                </div>
              </div>
            )}


          </Modal.Body>

        </div>

        <Modal.Footer style={{ background: '#d3d3d375' }}>
          <Button variant="secondary" onClick={() => setSelectedEmployee(null)}>Close</Button>
        </Modal.Footer>
      </Modal>










    </React.Fragment >
  );
}

export default Candidate;
