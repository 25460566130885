import React, { useState, useEffect, useRef } from "react";
import { Checkbox } from "@mui/material";
import _debounce from "lodash/debounce";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { TableCell } from "@mui/material";
import { toast } from "react-toastify";

import {
  searchEmployeeAttendance,
  getId,
  addEmployeeAttendanceAdmin,
  viewEmpAttendance,
  editEmployeeAttendanceAdmin,
  updateEmployeeAttendanceAdmin,
  viewemployeepaginationattendanceadmin,
  BASE_URL,
} from "../../services/api/api";
import { Card, Button, Form, Row, Col, Spinner, Modal } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import DatePicker from "react-datepicker";

import * as XLSX from "xlsx";
const Attendancetotallist = () => {
  const [responseData, setResponseData] = useState(null);
  const [id, setId] = useState(0);
  const [masterId, setMasterId] = useState();
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchText, setSearchText] = useState("");
  const [events, setEvents] = useState([]);
  const [employee_id, setEmployeeId] = useState(null);
  const [attendance_status, setAttstatus] = useState(null);

  const [attendanceTime, setAttendanceTime] = useState(null);

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [count, setCount] = useState(0);
  const pageRef = useRef(page);
  const [error, setError] = useState(null);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const customStyles = {
    grid: {
      border: '1px solid #ddd',
    },
    cell: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color
      fontWeight: 'bold', // Making text important
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    columnHeader: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
      backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

      fontWeight: 'bold',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    lastColumn: {
      borderRight: 'none',
    },
  };


  console.log("attendanceTime", attendanceTime);

  const [searchParams, setSearchParams] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    date: "",
  });
  const datepickerRef = useRef(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState(new Date());

  const handleYearChange = (e) => {
    setSearchParams({ ...searchParams, year: e.target.value });
  };

  const handleMonthChange = (e) => {
    setSearchParams({ ...searchParams, month: e.target.value });
  };

  const handleSearchDateChange = (date) => {
    setSearchParams({
      ...searchParams,
      date: date.toISOString().split("T")[0],
    });
  };

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [page, pageSize]);

  // const fetchData = async (date) => {
  //   console.log(date);

  //   try {
  //     const formattedDate = formatDateString(date);
  //     console.log(formattedDate);

  //     setLoading(true);
  //     const year = selectedDate.getFullYear();
  //     const month = selectedDate.getMonth() + 1;
  //     const apiResponse = await viewemployeepaginationattendanceadmin(formattedDate, page, pageSize);

  //     // if (!apiResponse?.data?.attendance_list?.length) {
  //     //   throw new Error("Failed to fetch data.");
  //     // }

  //     console.log(apiResponse);

  //     if (apiResponse.data.attendance_list.length > 0) {
  //       const attendanceData = apiResponse.data.attendance_list.map(
  //         (employee) => ({
  //           id: employee.emp_id,
  //           name: employee.name,
  //           status: employee.status_attandance,
  //           fine: employee.fine_attandance_status,

  //           attendance: employee.attendances.map((entry) => ({
  //             inTime:
  //               entry.attendance.attendance_status === "IN"
  //                 ? entry.attendance.attendance_time
  //                 : "",
  //             outTime:
  //               entry.attendance.attendance_status === "OUT"
  //                 ? entry.attendance.attendance_time
  //                 : "",
  //             // status_attandance:
  //             // entry.attendances.attendance_status ,
  //           })),
  //         })
  //       );

  //       console.log(attendanceData);

  //       setAttendanceData(attendanceData);
  //       setRows(attendanceData)
  //       setTotalPages(apiResponse.totalPages);
  //       setpageSize(pageSize);
  //     }
  //     else {
  //       setAttendanceData([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
 
 
 
 
 
  const fetchData = async (date) => {
    console.log(date);

    try {
      const formattedDate = formatDateString(date);
      console.log(formattedDate);

      setLoading(true);
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1;
      const apiResponse = await viewEmpAttendance(formattedDate, page, pageSize);

      // if (!apiResponse?.data?.attendance_list?.length) {
      //   throw new Error("Failed to fetch data.");
      // }

      console.log(apiResponse);

      if (apiResponse.data.attendance_list.length > 0) {
        const attendanceData = apiResponse.data.attendance_list.map(
          (employee) => ({
            id: employee.emp_id,
            name: employee.name,
            status: employee.status_attandance,
            fine: employee.fine_attandance_status,

            attendance: employee.attendances.map((entry) => ({
              inTime:
                entry.attendance.attendance_status === "IN"
                  ? entry.attendance.attendance_time
                  : "",
              outTime:
                entry.attendance.attendance_status === "OUT"
                  ? entry.attendance.attendance_time
                  : "",
              // status_attandance:
              // entry.attendances.attendance_status ,
            })),
          })
        );

        console.log(attendanceData);

        setAttendanceData(attendanceData);
      }
      else {
        setAttendanceData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDateFilter == null) {

      fetchData(selectedDate);
    }
    else {
      fetchData(selectedDateFilter);
    }

    return () => { };
  }, [selectedDateFilter]);

  const handlePageChange = (event) => {
    const newPage = parseInt(event.target.value, 10);
    setPage(newPage);
    console.log('Selected Page:', newPage);
  };

  const handlePageSizeChange = (event) => {
    console.log('Page Change Event:', event);
    const newPageSize = parseInt(event.target.value, 10);
    setpageSize(newPageSize);
    console.log('Selected Page Size:', newPageSize);
  };

  const getToken = () => {
    const token = sessionStorage.getItem('token');
    return token ? `Bearer ${token}` : '';
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(
      reversedRows.flatMap((row, index) =>
        row.attendance.map((attendanceItem, itemIndex) => ({
          "Sr.": index + 1,
          Name: row.name || "",
          [`In Time ${itemIndex + 1}`]: attendanceItem.inTime || "",
          [`Out Time ${itemIndex + 1}`]: attendanceItem.outTime || "",
          "Total Days": row.totalDays || "",
        }))
      )
    );

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBinaryString = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });

    const excelBlob = new Blob([s2ab(excelBinaryString)], {
      type: "application/octet-stream",
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = "Employee Attendance Data.xlsx";

    downloadLink.click();
  };

  const handleExportDepartmentData = async () => {
    setLoading(true); // Show loading state

    try {
      const response = await fetch(`${BASE_URL}/exportDepartments`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "Authorization": getToken(),
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Convert JSON data to CSV
      const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

      // Create a Blob and trigger the download
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'Department Data.csv'; // Change the file name as needed
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);



    } catch (error) {
      console.error("Fetch error: ", error);
      setError(error.message || 'An error occurred');



    } finally {
      setLoading(false); // Hide loading state
    }
  };

  function convertToCSV(data) {
    const array = [Object.keys(data[0])].concat(data);

    return array.map(row => {
      return Object.values(row).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',');
    }).join('\n');
  }

  const reversedRows = [...attendanceData];

  const filterRows = () => {
    if (!searchText && !selectedDateFilter) {
      return reversedRows.map((row, index) => ({
        ...row,
        id: index + 1,
        serial: index + 1,
      }));
    }

    return reversedRows
      .filter(
        (row) =>
          Object.values(row).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText.toLowerCase())
          ) &&
          (!selectedDateFilter ||
            row.attendance.some(
              (entry) =>
                entry.date === selectedDateFilter.toISOString().split("T")[0]
            ))
      )
      .map((row, index) => ({ ...row, id: index + 1, serial: index + 1 }));
  };

  const columns = [
    {
      field: "serial",
      headerName: "Sr.",
      width: 50,
      align: "center",
      flex: 1,
      headerAlign: "center",
    },

    {
      field: "name",
      headerName: "Name",
      width: 100,
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "inTime",
      headerName: "In Time",
      width: 100,
      align: "center",
      headerAlign: "center",
      flex: 2,

      renderCell: (params) => (
        
        <div>
          {params.row.attendance.map((attendanceItem, index) => (
            <div key={index} onClick={() => handleRowClick(params)}>
              {attendanceItem.inTime}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      align: "center",
      headerAlign: "center",
      flex: 2,
      renderCell: (params) => {
        console.log(params);
        
        const isRed = params.row.status === "Late"; // Change this condition as needed
        

        return (
          <div style={{ color: isRed ? "red" : "green" }}>
            {params.row.status}{" "}
            {/* Assuming you want to display the fine value */}
            {params.row.attendance.map((attendanceItem, index) => (
              <div key={index}>{attendanceItem.status}</div>
            ))}
          </div>
        );
      },
    },
    {
      field: "fine",
      headerName: "Fine",
      width: 100,
      align: "center",
      headerAlign: "center",
      flex: 2,

      renderCell: (params) => {
        const isRed = params.row.fine; // Change this condition as needed
        return (
          <div style={{ color: isRed ? "red" : "green" }}>
            {params.row.fine}{" "}
            {/* Assuming you want to display the fine value */}
            {params.row.attendance.map((attendanceItem, index) => (
              <div key={index}>{attendanceItem.fine}</div>
            ))}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        if (selectedRowId) {
          const employeeId = selectedRowId;
          const year = selectedDate.getFullYear();
          const month = selectedDate.getMonth() + 1;
          const apiResponse = await searchEmployeeAttendance(
            employeeId,
            year,
            month
          );
          console.log(apiResponse);

          if (!apiResponse || !apiResponse.data.length) {
            throw new Error("Failed to fetch data.");
          }

          const attendanceList = apiResponse.data[0].attendance_list;

          const events = attendanceList
            .flatMap((employee) => {
              if (employee.id === selectedRowId) {
                return employee.attendance.map((entry) => {
                  const title = entry.IN ? "Present" : "Absent";
                  return {
                    title,
                    start: new Date(entry.date),
                    allDay: true,
                    backgroundColor: title === "Present" ? "green" : "red",
                  };
                });
              } else {
                return [];
              }
            })
            .filter((event) => event.start !== undefined);

          setEvents(events);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [selectedRowId, selectedDate]);

  const fetchAttendanceData = async () => {
    try {
      setLoading(true);
      const currentDate = new Date().toISOString().split("T")[0];
      const response = await editEmployeeAttendanceAdmin(
        masterId,
        selectedRowId,
        currentDate
      );
      console.log("employeeattte res", response);
      setResponseData(response);
      // setShowModal(true);
      console.log("employee_id:", response.employee_id);
      setEmployeeId(response.employee_id);
      setAttstatus(response.attendance_status);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchAttendanceData();
  }, [selectedRowId]);

  const handleRowClick = (params) => {
    const selectedId = params.row.id;
    setSelectedRowId(selectedId);
    fetchAttendanceData(selectedId);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const formatTime = (time) => {
    const formattedTime = new Date(`2022-01-01 ${time}`);
    return formattedTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [attendanceStatus, setAttendanceStatus] = useState("IN");

  const calculateTotalDays = (attendance) => {
    return attendance.reduce((total, entry) => {
      return entry.inTime !== "N/A" ? total + 1 : total;
    }, 0);
  };

  const handleUpdateAttendance = async () => {
    try {
      const attendanceTimes = new Date(attendanceTime).toLocaleString("en-US", {
        timeZone: "America/Chicago",
      });
      console.log("attendanceTimes", attendanceTimes);
      const response = await updateEmployeeAttendanceAdmin(
        masterId,
        id,
        employee_id,
        attendanceTimes,
        attendance_status
      );
      console.log("Attendance updated successfully:", response);
      toast.success("Employee Attendance IN status updated successfully");

      setShowModal(false);

      // fetchData();
    } catch (error) {
      console.error("Error updating attendance:", error);
      // Handle error (e.g., show error message)
    }
  };

  const [chicagoTime, setChicagoTime] = useState("");

  useEffect(() => {
    // Assume responseData.attendance_time is the Unix timestamp
    const unixTimestamp = responseData?.attendance_time;
    if (!unixTimestamp) return;

    const chicagoTimeDate = new Date(unixTimestamp * 1000); // Convert to milliseconds
    chicagoTimeDate.setHours(chicagoTimeDate.getHours() - 19); // Adjust for Chicago timezone offset (-5 hours)
    const chicagoTimeString = chicagoTimeDate.toTimeString().split(" ")[0]; // Format as HH:MM:SS

    setChicagoTime(chicagoTimeString);
  }, [responseData]);

  const [selectedTime, setSelectedTime] = useState(new Date(chicagoTime));
  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/empattendancedata">
                  Employee attendance Details
                </Link>
              </li>
              <li className="breadcrumb-item active">List</li>
            </ol>
          </div>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div className="mb-4 border-bottom text-center">
              <h3 className="mb-0 pb- border-bottom custome-btn">
                Attendance Details
              </h3>
            </div>
            <div
              className="mb-3 border rounded p-3"
              style={{ backgroundColor: "#f0f0f0" }}
            >
              <strong>Attendance Info Monthly:</strong>{" "}
              {selectedDateFilter &&
                selectedDateFilter.toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })}
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center mb-2 mb-md-0 mr-md-2 col-12 col-md-8">
                <div className="input-group">
                  {/* <DatePicker
                    selected={selectedDateFilter}
                    onChange={(date) => {
                      setSelectedDateFilter(date), fetchAttendanceData();
                    }}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    ref={datepickerRef}
                  /> */}
                  <DatePicker
                    selected={selectedDateFilter}
                    onChange={(date) => {
                      setSelectedDateFilter(date);
                      fetchData(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                    ref={datepickerRef}
                  />

                  <Button
                    className="bg-success border-0 ms-2"
                    onClick={exportToExcel}
                  >
                    Export Data to Excel Sheet
                  </Button>
                </div>
              </div>

              {/* Search Input */}
              <div className="d-flex align-items-center col-12 col-md-4">
                <label htmlFor="searchInput" className="me-2">
                  
                </label>
                <input
                  type="text"
                  className="p-2 ms-2 w-100"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>
            </div>

            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: 400, width: "100%" }}>
               
                <DataGrid
                  rows={reversedRows.map((row, index) => ({
                    ...row,
                    id: index + 1,
                    serial: index + 1,
                  }))}
                  columns={columns}
                  pageSize={5}
                  components={{
                    NoRowsOverlay: () => (
                      <div style={{ textAlign: "center", padding: "20px" }}>
                        No data available
                      </div>
                    ),
                  }}
                />
              </div>
            //   <div style={customStyles.grid}>
            //   <DataGrid
            //     className="gridColor"
            //     rows={rows}
            //     columns={columns}
            //     pageSize={pageSize}
            //     pagination
            //     paginationMode="client"
            //     getRowId={(row) => row.id}
            //     components={{
            //       Pagination: () => (
            //         <div className="custom-select-container">
            //           <div>
            //             <label htmlFor="page-size">Rows per page: </label>
            //             <select
            //               id="page-size"
            //               value={pageSize}
            //               onChange={handlePageSizeChange}  // Using the handlePageSizeChange function
            //             >
            //               <option value={1}>1</option>
            //               <option value={10}>10</option>
            //               <option value={20}>20</option>
            //               <option value={50}>50</option>
            //             </select>
            //           </div>
            //           <div>
            //             <label htmlFor="page-number">Page: </label>
            //             <select
            //               className="page-number"
            //               id="page-number"
            //               value={page}
            //               onChange={handlePageChange}  // Using the handlePageChange function
            //             >
            //               {[...Array(totalPages).keys()].map((pageNumber) => (
            //                 <option key={pageNumber} value={pageNumber + 1}>
            //                   {pageNumber + 1}
            //                 </option>
            //               ))}
            //             </select>
            //           </div>
            //         </div>
            //       ),
            //     }}
            //     componentsProps={{
            //       cell: { style: customStyles.cell },
            //       columnHeader: { style: customStyles.columnHeader },
            //     }}
            //     rowCount={count}        // Total row count
            //     page={page - 1}         // Syncing the page number
            //     onPageChange={(newPage) => setPage(newPage + 1)} // This keeps the actual page selection from 0-index to 1-index
            //     onPageSizeChange={handlePageSizeChange} // To handle changes in the page size
            //     rowsPerPageOptions={[]} // To disable the default options provided by the DataGrid
            //   />
            // </div>
            )}
          </Card.Body>
        </Card>
      </div>

      {showModal && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Employee Attendance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {responseData ? (
              <Form>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    ID
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control type="text" placeholder="ID" value={responseData?.employee_id} readOnly />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Attendance Status
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control type="text" placeholder="Attendance Status" value={responseData?.attendance_status} readOnly />
                  </Col>

                </Form.Group>
             

                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Intime
                  </Form.Label>
                  <Col sm="8">
                    <Row className="gx-0">
                      <Col sm="6">
                        <DatePicker
                          selected={attendanceTime}
                          onChange={(date) => setAttendanceTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select Time"
                          className="form-control"
                        />
                      </Col>
                      <Col sm="6">
                        <Form.Control type="text" placeholder="Attendance Status" value={chicagoTime} />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>



              </Form>
            ) : (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )} */}
            {loading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Form>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    ID
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="ID"
                      value={responseData?.employee_id}
                      readOnly
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="4">
                    Attendance Status
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      type="text"
                      placeholder="Attendance Status"
                      value={responseData?.attendance_status}
                      readOnly
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="4">
                    Intime
                  </Form.Label>
                  <Col sm="8">
                    <Row className="gx-0">
                      <Col sm="3">
                        <DatePicker
                          selected={attendanceTime}
                          onChange={(date) => setAttendanceTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          placeholderText="Select Time"
                          className="form-controlN"
                        />
                      </Col>
                      <Col sm="6">
                        <Form.Control
                          type="text"
                          placeholder="Attendance Status"
                          value={chicagoTime}
                          readOnly
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleUpdateAttendance}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default Attendancetotallist;
