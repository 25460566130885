import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { searchEmployeeBreak, viewEmployee, viewWorkingDays } from '../../../services/api/api';

const EmployeeBreak = () => {
    const [showEmployeeList, setShowEmployeeList] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [breakData, setBreakData] = useState([]);
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [searchText, setSearchText] = useState('');
    const [searchParams, setSearchParams] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
    });

    useEffect(() => {
        setShowEmployeeList(true);
    }, []);

    useEffect(() => {
        const debouncedFetchData = _debounce(fetchData, 100);
        debouncedFetchData();

        return () => {
            debouncedFetchData.cancel();
        };
    }, [searchParams, selectedMonth, selectedYear]);

    const fetchEmployeeData = async () => {
        try {
            const formData = {};
            const response = await viewEmployee(formData);
            if (!response || !response.success || !response.data) {
                throw new Error('Failed to fetch data.');
            }

            const mappedEmployees = response.data.map((item, index) => ({
                id: item.employeeData._id,
                serial: index + 1,
                firstName: item.employeeData.first_name,
                lastName: item.employeeData.last_name,
                name: item.employeeData.first_name + ' ' + item.employeeData.last_name
            }));

            if (mappedEmployees) {
                console.log(mappedEmployees);
            }


            setEmployees(mappedEmployees);

        } catch (error) {
            toast.error('Failed to fetch employees.');
        }
    };

    useEffect(() => {
        const debouncedFetchEmpData = _debounce(fetchEmployeeData, 100);

        debouncedFetchEmpData();

        return () => {
            debouncedFetchEmpData.cancel();
        };
    }, []);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const [workingDays, setWorkingDays] = useState([]);

    const fetchWorkingDay = async () => {
        try {
            const response = await viewWorkingDays();
            console.log("response", response);

            // Assuming the response has the expected structure
            const workingDaysData = response[0]; // Assuming you have only one object in the array

            // Extract the days with value true (working days)
            const workingDays = Object.keys(workingDaysData).filter(day =>
                typeof workingDaysData[day] === 'boolean' && workingDaysData[day]
            );

            console.log("Working days:", workingDays);
            setWorkingDays(workingDays);
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch working day");
        }
    };


    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const daysOfWeek = [];
    for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(selectedYear, selectedMonth - 1, i);
        const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
        daysOfWeek.push(dayOfWeek);
    }

    const fetchData = async () => {
        try {
            const apiResponse = await searchEmployeeBreak(selectedYear, selectedMonth);
            console.log(apiResponse);

            const breakList = apiResponse.data[0].break_list;

            const formattedData = breakList.map((employee) => {
                return {
                    attendances: Object.entries(employee.attendance).map(([date, attendance]) => ({
                        date,
                        total_minutes: attendance.total_minutes,
                        name: attendance.name
                    })),
                };
            });
            console.log(formattedData);


            setBreakData(formattedData);
        } catch (error) {
            toast.error('Failed to fetch break data.');
        }
    };

    const renderCells1 = () => {
        if (!selectedMonth || !selectedYear) {
            return null;
        }

        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
        const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

        return (
            <>
                <tr>
                    <th style={{ background: 'white', color: 'black' }}>Employees</th>
                    {days.map(day => (
                        <th key={day} style={{ background: 'white', color: 'black' }}>{day}</th>
                    ))}
                </tr>

                {employees.map(employee => {

                    const employeeData = breakData.find(empData => empData.attendances.some(att => att.name === employee.name));
                    const employeeAttendances = employeeData ? employeeData.attendances : [];

                    return (
                        <tr key={employee.id}>
                            <td style={{ whiteSpace: 'nowrap' }}>{employee.firstName} {employee.lastName}</td>

                            {days.map(day => {
                                const formattedDate = `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                const attendanceForDay = employeeAttendances.find(att => att.date === formattedDate);

                                const currentDate = new Date(selectedYear, selectedMonth - 1, day);
                                const isCurrentDate = currentDate.toISOString().slice(0, 10) === formattedDate;
                                let totalBreakMinutes = attendanceForDay?.total_minutes || 0;

                                // Remove decimal places and apply rounding based on the value
                                totalBreakMinutes = totalBreakMinutes > 5 ? Math.ceil(totalBreakMinutes) : Math.floor(totalBreakMinutes);

                                if (attendanceForDay) {
                                    return (
                                        <td key={day} className={`status-${totalBreakMinutes > 0 ? 'IN' : 'OUT'}`}>
                                            {totalBreakMinutes}
                                        </td>
                                    );
                                } else {
                                    const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;

                                    return (
                                        <td key={day} className={isWeekend ? 'status-weekend' : (isCurrentDate ? 'status-absent' : 'status-none')}>
                                            {isWeekend ? 'W' : (isCurrentDate ? <span style={{ color: 'red' }}>0</span> : '0')}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    );
                })}
            </>
        );
    };

    const renderCells = () => {
        if (!selectedMonth || !selectedYear) {
            return null;
        }

        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
        const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

        return (
            <>
                <tr>
                    <th style={{ background: 'white', color: 'black' }}>Employees</th>
                    {days.map(day => (
                        <th key={day} style={{ background: 'white', color: 'black' }}>{day}</th>
                    ))}
                </tr>

                {employees.map(employee => {

                    const employeeData = breakData.find(empData => empData.attendances.some(att => att.name === employee.name));
                    const employeeAttendances = employeeData ? employeeData.attendances : [];

                    return (
                        <tr key={employee.id}>
                            <td style={{ whiteSpace: 'nowrap' }}>{employee.firstName} {employee.lastName}</td>

                            {days.map(day => {
                                const formattedDate = `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                const attendanceForDay = employeeAttendances.find(att => att.date === formattedDate);

                                const currentDate = new Date(selectedYear, selectedMonth - 1, day);
                                const isCurrentDate = currentDate.toISOString().slice(0, 10) === formattedDate;
                                let totalBreakMinutes = attendanceForDay?.total_minutes || 0;

                                // Remove decimal places and apply rounding based on the value
                                totalBreakMinutes = totalBreakMinutes > 5 ? Math.ceil(totalBreakMinutes) : Math.floor(totalBreakMinutes);

                                const dayOfWeek = currentDate.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
                                const isWorkingDay = workingDays.includes(dayOfWeek);

                                if (attendanceForDay) {
                                    return (
                                        <td key={day} className={`status-${totalBreakMinutes > 0 ? 'IN' : 'OUT'}`}>
                                            {totalBreakMinutes}
                                        </td>
                                    );
                                } else {
                                    const isWeekend = !isWorkingDay && (currentDate.getDay() === 0 || currentDate.getDay() === 6);

                                    return (
                                        <td key={day} className={isWeekend ? 'status-weekend' : (isCurrentDate ? 'status-absent' : 'status-none')}>
                                            {isWeekend ? 'W' : (isCurrentDate ? <span style={{ color: 'red' }}>0</span> : '0')}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    );
                })}
            </>
        );
    };



    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="">Employee Break Details</Link>
                            </li>
                            <li className="breadcrumb-item active">List</li>
                        </ol>
                    </div>
                </div>
                <Card className="popup-card empHistory">
                    <Card.Body>
                        <div className="mb-3 border rounded p-3" style={{ backgroundColor: '#f0f0f0' }}>
                            <strong>Break Info Monthly:</strong> {`${searchParams.month}/${searchParams.year}`}
                        </div>
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
                            <div className="d-flex align-items-center mb-2 mb-md-0 mr-md-2 col-12 col-md-8">
                                <div className="dropdowns-container">
                                    <div>
                                        <select value={`${selectedMonth}/${selectedYear}`} onChange={(e) => {
                                            const [month, year] = e.target.value.split('/');
                                            setSelectedMonth(parseInt(month));
                                            setSelectedYear(parseInt(year));
                                        }} className="dropdownBox1">
                                            <option value="">Select Month</option>
                                            {Array.from({ length: 12 }, (_, i) => (
                                                <option key={i + 1} value={`${i + 1}/${currentYear}`}>{`${i + 1}/${currentYear}`}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center col-12 col-md-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by name"
                                    value={searchText}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">

                        </div>
                        <div className="calendar">
                            <div className="calendar-table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ background: '#ffc107', color: 'black' }}>{new Date(selectedYear, selectedMonth - 1).toLocaleString('default', { month: 'long' })} {selectedYear}</th>
                                            {daysOfWeek.map((day, index) => (
                                                <th key={index}>{day}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderCells()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default EmployeeBreak;
