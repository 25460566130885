import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addempSalarySetup, searchEmployeeSalarySetup, viewEmployee, viewSalarySetup } from '../../services/api/api';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { TableCell, TableRow } from '@mui/material';

const Addempsalarysetup = (e, params) => {
    const [formData, setFormData] = useState({
        employee_id: '',
        name: '',
        basic: [{ name: '', deduct_type: '', amount: '' }],
        allowences: [{ name: '', deduct_type: '', amount: '' }],
        deductions: [{ name: '', deduct_type: '', amount: '' }],
        structure_id: ''
    });


    const [existingSetup, setExistingSetup] = useState('');
    console.log("existingSetup", existingSetup);

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [structureId, setStructureId] = useState('');
    const [salaryStructure, setSalaryStructure] = useState([]);
    const [salaryData, setSalaryData] = useState(null);
    const [salarySetupData, setSalarySetupData] = useState([]);
    const [salaryctc, setSalaryctc] = useState([]);
    const [masterId, setMasterId] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [MonthlyAmount, SetMonthlyAmount] = useState([]);
    const [annualAmount, setannualAmount] = useState([]);
    const [Amount, setAmount] = useState([]);
    const [submitsalary, Setsubmitsalary] = useState({
        basic: [],
        allowance: [],
        deduction: []
    });
    const [monthWithoutUpdate, setMonthWithoutUpdate] = useState(0)
    const [annualWithoutUpdate, setAnnualWithoutUpdate] = useState(0)

    const [monthlyAmountTotal, SetMonthlyAmountTotal] = useState(null);
    const [annualAmountTotal, SetAnnualAmountTotal] = useState(null);

    const [salarySetupBtn, SetSalarySetupBtn] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [basicEditedValue, setBasicEditedValue] = useState(null);
    const [allowanceEditedValue, setAllowanceEditedValue] = useState({});
    const [deductionEditedValues, setDeductionEditedValues] = useState({});

    const [flag, setFlag] = useState(true);
    const [focus, setFocus] = useState(false);



    const handleSalarySetupBtn = () => {
        SetSalarySetupBtn(!salarySetupBtn);
        if (salarySetupBtn === false) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    };
    useEffect(() => {
        handleSalarySetupBtn();
    }, []);

    const [abc, setAbc] = useState(0);

    // const handleTotal = () => {

    //     if (existingSetup == true) {
    //         let monthlyTotal = 0;
    //         let annualTotal = 0;
    //         alert('1')
    //         MonthlyAmount.forEach(amount => {
    //             monthlyTotal += Number(amount);
    //         });
    //         annualTotal = monthlyTotal * 12;
    //         SetMonthlyAmountTotal((monthlyTotal).toFixed(2));
    //         SetAnnualAmountTotal((annualTotal).toFixed(2));
    //         localStorage.setItem('MonthlyAmountTotal', monthlyTotal);
    //         localStorage.setItem('AnnualAmountTotal', annualTotal);
    //         setFlag(false);
    //     }
    //     else if (existingSetup.length == 1) {
    //         if (flag == false) {
    //             alert("2")
    //             SetMonthlyAmountTotal(localStorage.getItem('MonthlyAmountTotal'))
    //             SetAnnualAmountTotal(localStorage.getItem('AnnualAmountTotal'))
    //             setFlag(true);
    //         }
    //         else {
    //             alert("3")
    //             let monthlyTotal = 0;
    //             let annualTotal = 0;
    //             MonthlyAmount.forEach(amount => {
    //                 console.log(MonthlyAmount);
    //                 monthlyTotal += Number(amount);
    //             });

    //             annualTotal = monthlyTotal * 12;
    //             SetMonthlyAmountTotal((monthlyTotal).toFixed(2));
    //             SetAnnualAmountTotal((annualTotal).toFixed(2));
    //             localStorage.setItem('MonthlyAmountTotal', monthlyTotal);
    //             localStorage.setItem('AnnualAmountTotal', annualTotal);
    //             setFlag(false);
    //             setFocus(true);
    //         }

    //     }
    // }

    const calculateTotals = (items) => {
        let monthlyTotal = 0;
        let annualTotal = 0;

        // console.log(items);
        items.forEach(item => {
            monthlyTotal += Number(item.monthly);
            annualTotal += Number(item.Annually);
        });

        return { monthlyTotal, annualTotal };
    };


    const handleTotal = () => {
        if (existingSetup && existingSetup.length > 0) {
            // Calculate basicTotals, allowanceTotals, and deductionTotals based on submitSalary or existingSetup if submitSalary is empty
            const basicItems = submitsalary.basic.length > 0 ? submitsalary.basic : existingSetup[0].basic;
            const allowanceItems = submitsalary.allowance.length > 0 ? submitsalary.allowance : existingSetup[0].allowance;
            const deductionItems = submitsalary.deduction.length > 0 ? submitsalary.deduction : existingSetup[0].deduction;

            const basicTotals = calculateTotals(basicItems);
            const allowanceTotals = calculateTotals(allowanceItems);
            const deductionTotals = calculateTotals(deductionItems);

            const monthlyTotal = basicTotals.monthlyTotal + allowanceTotals.monthlyTotal - deductionTotals.monthlyTotal;
            const annualTotal = basicTotals.annualTotal + allowanceTotals.annualTotal - deductionTotals.annualTotal;

            // Update state with the calculated totals
            SetMonthlyAmountTotal(monthlyTotal.toFixed(2));
            SetAnnualAmountTotal(annualTotal.toFixed(2));

            // Update localStorage with the calculated totals
            localStorage.setItem('MonthlyAmountTotal', monthlyTotal.toFixed(2));
            localStorage.setItem('AnnualAmountTotal', annualTotal.toFixed(2));

            setFocus(true); // Example update focus state
        } else {
            // Calculate totals based on submitSalary when existingSetup is not available or empty
            const basicTotals = calculateTotals(submitsalary.basic);
            const allowanceTotals = calculateTotals(submitsalary.allowance);
            const deductionTotals = calculateTotals(submitsalary.deduction);

            const monthlyTotal = basicTotals.monthlyTotal + allowanceTotals.monthlyTotal - deductionTotals.monthlyTotal;
            const annualTotal = basicTotals.annualTotal + allowanceTotals.annualTotal - deductionTotals.annualTotal;

            // Update state with the calculated totals
            SetMonthlyAmountTotal(monthlyTotal.toFixed(2));
            SetAnnualAmountTotal(annualTotal.toFixed(2));

            // Update localStorage with the calculated totals
            localStorage.setItem('MonthlyAmountTotal', monthlyTotal.toFixed(2));
            localStorage.setItem('AnnualAmountTotal', annualTotal.toFixed(2));

            setFocus(true); // Example update focus state
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (salaryData) {
            const initialAnuualAmounts = salaryData.basic.map(() => '');
            setannualAmount(initialAnuualAmounts);

        }
        if (salaryData) {
            const initialMonthlyAmounts = salaryData.basic.map(() => '');
            SetMonthlyAmount(initialMonthlyAmounts);

        }
    }, [salaryData]);

    const handleAmountChange = (newValue1, rowIndex) => {
        setFocus(true);
        const newAnnualAmounts = [...annualAmount];
        newAnnualAmounts[rowIndex] = newValue1;
        setannualAmount(newAnnualAmounts);
        setFocus(false);

        // console.log("newValue1", newValue1);
    };
    const AmountChange = (newValue1, rowIndex) => {
        // alert("New value")
        setFocus(true);
        console.log(newValue1);
        const newAmount = [...Amount];
        newAmount[rowIndex] = newValue1;
        setAmount(newAmount);
        setFocus(false);
        // console.log("newValue1", newValue1);
    };
    const handleMonthAmountChange = (newValue1, rowIndex) => {
        setFocus(true);
        // alert('MonthAmount');
        const newMothAmounts = [...MonthlyAmount];
        newMothAmounts[rowIndex] = newValue1;
        console.log(newValue1);
        SetMonthlyAmount(newMothAmounts);
        setFocus(false);

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const [editedValue, setEditedValue] = useState(null);

    const handleChangenew = (e, params) => {
        console.log("params", params);
        const newValue = e.target.value.trim(); // Trim any leading/trailing spaces

        // Ensure the input is a valid number or empty
        if (newValue === '' || (!isNaN(newValue) && parseFloat(newValue) >= 0)) {
            setEditedValue(newValue); // Update editedValue with the trimmed input value

            // Determine the type of change (Basic, Allowance, Deduction)
            if (params && params.row) {
                let anamount = 0;
                let mamount = 0;
                let updatedItem = null;
                let updatedList = [];

                // Handle Basic type
                if (params.row.type === 'Basic') {
                    if (params.row.deduct_type === 'Percentage') {
                        anamount = parseFloat((salaryctc * (newValue / 100)).toFixed(2));
                        mamount = parseFloat((anamount / 12).toFixed(2));
                        if (params.rowNode.id === 1) {
                            setAbc(mamount); // Assuming setAbc is a setter function for abc
                        }
                    }
                    else
                        if (params.row.deduct_type === 'Fixed') {
                            anamount = parseFloat(newValue.toFixed(2));
                            mamount = parseFloat((anamount / 12).toFixed(2));
                        }

                    handleAmountChange(anamount, params.rowNode.id);
                    handleMonthAmountChange(mamount, params.rowNode.id);

                    const basicIndex = submitsalary.basic.findIndex(item => item.name === 'BASIC');
                    if (basicIndex !== -1) {
                        updatedItem = {
                            ...submitsalary.basic[basicIndex],
                            amount: newValue,
                            monthly: mamount,
                            Annually: anamount
                        };
                        updatedList = [...submitsalary.basic];
                        updatedList[basicIndex] = updatedItem;
                    } else {
                        updatedItem = {
                            name: 'BASIC',
                            type: params.row.deduct_type,
                            amount: newValue,
                            monthly: mamount,
                            Annually: anamount
                        };
                        updatedList = [...submitsalary.basic, updatedItem];
                    }
                    Setsubmitsalary(prevState => ({
                        ...prevState,
                        basic: updatedList
                    }));
                }
                // Handle Allowance type
                else if (params.row.type === 'Allowance') {
                    if (params.row.deduct_type === 'Percentage') {
                        anamount = parseFloat(abc * 12 * (newValue / 100));
                        mamount = parseFloat(anamount / 12);
                    } else if (params.row.deduct_type === 'Fixed') {
                        anamount = parseFloat(newValue);
                        mamount = parseFloat(anamount / 12);
                    }
                    AmountChange(newValue, params.row.id)
                    handleAmountChange(anamount, params.rowNode.id);
                    handleMonthAmountChange(mamount, params.rowNode.id);

                    const allowanceIndex = submitsalary.allowance.findIndex(item => item.name === params.row.name);
                    if (allowanceIndex !== -1) {
                        updatedItem = {
                            ...submitsalary.allowance[allowanceIndex],
                            amount: newValue,
                            monthly: mamount,
                            Annually: anamount
                        };
                        updatedList = [...submitsalary.allowance];
                        updatedList[allowanceIndex] = updatedItem;
                    } else {
                        updatedItem = {
                            name: params.row.name,
                            type: params.row.deduct_type,
                            amount: newValue,
                            monthly: mamount,
                            Annually: anamount
                        };
                        updatedList = [...submitsalary.allowance, updatedItem];
                    }
                    Setsubmitsalary(prevState => ({
                        ...prevState,
                        allowance: updatedList
                    }));
                }
                // Handle Deduction type
                else if (params.row.type === 'Deduction') {
                    if (params.row.deduct_type === 'Percentage') {
                        anamount = parseFloat((abc * 12 * (newValue / 100)).toFixed(2));
                        mamount = parseFloat((anamount / 12).toFixed(2));
                    } else if (params.row.deduct_type === 'Fixed') {
                        anamount = parseFloat(newValue.toFixed(2));
                        mamount = parseFloat((anamount / 12).toFixed(2));
                    }

                    handleAmountChange(anamount, params.rowNode.id);
                    handleMonthAmountChange(mamount, params.rowNode.id);

                    const deductionIndex = submitsalary.deduction.findIndex(item => item.name === params.row.name);
                    if (deductionIndex !== -1) {
                        updatedItem = {
                            ...submitsalary.deduction[deductionIndex],
                            amount: newValue,
                            monthly: mamount,
                            Annually: anamount
                        };
                        updatedList = [...submitsalary.deduction];
                        updatedList[deductionIndex] = updatedItem;
                    } else {
                        updatedItem = {
                            name: params.row.name,
                            type: params.row.deduct_type,
                            amount: newValue,
                            monthly: mamount,
                            Annually: anamount
                        };
                        updatedList = [...submitsalary.deduction, updatedItem];
                    }
                    Setsubmitsalary(prevState => ({
                        ...prevState,
                        deduction: updatedList
                    }));
                }
            }
        }
    };


    // const handleBasicChange = (e, params) => {
    //     const newValue1 = e.target.value.trim();
    //     if (newValue1 === '' || (!isNaN(newValue1) && parseFloat(newValue1) >= 0)) {
    //         setBasicEditedValue(newValue1);
    //         if (params && params.row) {
    //             let anamount = 0;
    //             let mamount = 0;
    //             const existingSetupp = { ...existingSetup[0] };
    //             const deduct_type = existingSetupp.basic.find(item => item.name === 'BASIC')?.type;
    //             console.log(deduct_type);

    //             if (deduct_type === 'Percentage') {
    //                 if (params.rowNode.id === 1) {
    //                     setAbc(mamount)
    //                 }
    //                 anamount = parseFloat((salaryctc * (newValue1 / 100)).toFixed(2));
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             } else if (deduct_type === 'Fixed') {
    //                 anamount = parseFloat(newValue1).toFixed(2);
    //                 mamount = parseFloat((anamount / 12)).toFixed(2);
    //             }

    //             handleAmountChange(anamount, params.rowNode.id);
    //             handleMonthAmountChange(mamount, params.rowNode.id);

    //             const basicIndex = submitsalary.basic.findIndex(item => item.name === 'BASIC');

    //             let updatedItem = {
    //                 name: 'BASIC',
    //                 type: deduct_type,
    //                 amount: newValue1,
    //                 monthly: mamount,
    //                 Annually: anamount
    //             };
    //             let updatedList = [...submitsalary.basic];
    //             if (basicIndex !== -1) {
    //                 updatedList[basicIndex] = updatedItem;
    //             } else {
    //                 updatedList.push(updatedItem);
    //             }
    //             Setsubmitsalary(prevState => ({
    //                 ...prevState,
    //                 basic: updatedList
    //             }));
    //         }
    //     }
    // };



    // const handleAllowanceChange = (e, params) => {
    //     const newValue2 = e.target.value.trim();
    //     console.log("New Value2:", newValue2);
    //     if (newValue2 === '' || (!isNaN(newValue2) && parseFloat(newValue2) >= 0)) {
    //         setAllowanceEditedValue(newValue2);
    //         if (params && params.row) {
    //             let anamount = 0;
    //             let mamount = 0;
    //             const existingSetupp = { ...existingSetup[0] };
    //             const deduct_type = existingSetupp.allowance.find(item => item.name === params.row.name)?.type;
    //             console.log("Allowance Deduct Type:", deduct_type);

    //             if (deduct_type === 'Percentage') {
    //                 // anamount = parseFloat((abc * 12 * (newValue2 / 100)).toFixed(2));
    //                 // mamount = parseFloat((anamount / 12).toFixed(2));
    //                 anamount = parseFloat(newValue2).toFixed(2);
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             } else if (deduct_type === 'Fixed') {
    //                 anamount = parseFloat(newValue2).toFixed(2);
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             }

    //             handleAmountChange(anamount, params.rowNode.id);
    //             handleMonthAmountChange(mamount, params.rowNode.id);

    //             const allowanceIndex = submitsalary.allowance.findIndex(item => item.name === params.row.name);
    //             let updatedItem = {
    //                 name: params.row.name,
    //                 type: deduct_type,
    //                 amount: newValue2,
    //                 monthly: mamount,
    //                 Annually: anamount
    //             };
    //             let updatedList = [...submitsalary.allowance];
    //             if (allowanceIndex !== -1) {
    //                 updatedList[allowanceIndex] = updatedItem;
    //             } else {
    //                 updatedList = [...submitsalary.allowance, updatedItem];
    //             }
    //             Setsubmitsalary(prevState => ({
    //                 ...prevState,
    //                 allowance: updatedList
    //             }));
    //         }
    //     }
    // };


    // const handleDeductionChange = (e, params) => {
    //     const newValue3 = e.target.value.trim();
    //     console.log("New Value3:", newValue3);
    //     if (newValue3 === '' || (!isNaN(newValue3) && parseFloat(newValue3) >= 0)) {
    //         setDeductionEditedValue(newValue3);
    //         if (params && params.row) {
    //             let anamount = 0;
    //             let mamount = 0;
    //             const existingSetupp = { ...existingSetup[0] };
    //             const deduct_type = existingSetupp.deduction.find(item => item.name === params.row.name)?.type;
    //             console.log("Deduction Deduct Type:", deduct_type);

    //             if (deduct_type === 'Percentage') {
    //                 // anamount = parseFloat((abc * 12 * (newValue3 / 100)).toFixed(2));
    //                 // mamount = parseFloat((anamount / 12).toFixed(2));
    //                 anamount = parseFloat(newValue3).toFixed(2);
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             } else if (deduct_type === 'Fixed') {
    //                 anamount = parseFloat(newValue3).toFixed(2);
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             }

    //             handleAmountChange(anamount, params.rowNode.id);
    //             handleMonthAmountChange(mamount, params.rowNode.id);

    //             const deductionIndex = submitsalary.deduction.findIndex(item => item.name === params.row.name);
    //             const updatedItem = {
    //                 name: params.row.name,
    //                 type: deduct_type,
    //                 amount: newValue3,
    //                 monthly: mamount,
    //                 Annually: anamount
    //             };
    //             let updatedList = [...submitsalary.deduction];
    //             if (deductionIndex !== -1) {
    //                 updatedList[deductionIndex] = updatedItem;
    //             } else {
    //                 updatedList = [...submitsalary.deduction, updatedItem];
    //             }
    //             Setsubmitsalary(prevState => ({
    //                 ...prevState,
    //                 deduction: updatedList
    //             }));
    //         }
    //     }
    // };

    // const handleBasicChange = (e, params) => {
    //     const newValue1 = e.target.value.trim();
    //     if (newValue1 === '' || (!isNaN(newValue1) && parseFloat(newValue1) >= 0)) {
    //         setBasicEditedValue(newValue1);
    //         if (params && params.row) {
    //             let anamount = 0;
    //             let mamount = 0;
    //             const existingSetupp = { ...existingSetup[0] };
    //             console.log("existingSetup", existingSetup);
    //             const deduct_type = existingSetupp.basic.find(item => item.name === 'BASIC')?.type;

    //             if (deduct_type === 'Percentage') {
    //                 anamount = parseFloat((salaryctc * (newValue1 / 100)).toFixed(2));
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             } else if (deduct_type === 'Fixed') {
    //                 anamount = parseFloat(newValue1).toFixed(2);
    //                 mamount = parseFloat((anamount / 12)).toFixed(2);
    //             }

    //             handleAmountChange(anamount, params.rowNode.id);
    //             handleMonthAmountChange(mamount, params.rowNode.id);

    //             const basicIndex = submitsalary.basic.findIndex(item => item.name === 'BASIC');
    //             let updatedItem = {
    //                 name: 'BASIC',
    //                 type: deduct_type,
    //                 amount: newValue1,
    //                 monthly: mamount,
    //                 Annually: anamount
    //             };

    //             let updatedBasicList = [...submitsalary.basic];
    //             if (basicIndex !== -1) {
    //                 updatedBasicList[basicIndex] = updatedItem;
    //             } else {
    //                 updatedBasicList.push(updatedItem);
    //             }

    //             Setsubmitsalary(prevState => ({
    //                 ...prevState,
    //                 basic: updatedBasicList
    //             }));
    //         }
    //     }
    // };

    // const handleAllowanceChange = (e, params) => {
    //     const newValue2 = e.target.value.trim();
    //     if (newValue2 === '' || (!isNaN(newValue2) && parseFloat(newValue2) >= 0)) {
    //         setAllowanceEditedValue(newValue2);
    //         if (params && params.row) {
    //             let anamount = 0;
    //             let mamount = 0;
    //             const existingSetupp = { ...existingSetup[0] };
    //             const deduct_type = existingSetupp.allowance.find(item => item.name === params.row.name)?.type;

    //             if (deduct_type === 'Percentage') {
    //                 anamount = parseFloat(newValue2).toFixed(2);
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             } else if (deduct_type === 'Fixed') {
    //                 anamount = parseFloat(newValue2).toFixed(2);
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             }

    //             handleAmountChange(anamount, params.rowNode.id);
    //             handleMonthAmountChange(mamount, params.rowNode.id);

    //             const allowanceIndex = submitsalary.allowance.findIndex(item => item.name === params.row.name);
    //             let updatedItem = {
    //                 name: params.row.name,
    //                 type: deduct_type,
    //                 amount: newValue2,
    //                 monthly: mamount,
    //                 Annually: anamount
    //             };

    //             let updatedAllowanceList = [...submitsalary.allowance];
    //             if (allowanceIndex !== -1) {
    //                 updatedAllowanceList[allowanceIndex] = updatedItem;
    //             } else {
    //                 updatedAllowanceList.push(updatedItem);
    //             }

    //             Setsubmitsalary(prevState => ({
    //                 ...prevState,
    //                 allowance: updatedAllowanceList
    //             }));
    //         }
    //     }
    // };

    // const handleDeductionChange = (e, params) => {
    //     const newValue3 = e.target.value.trim();
    //     if (newValue3 === '' || (!isNaN(newValue3) && parseFloat(newValue3) >= 0)) {
    //         setDeductionEditedValue(newValue3);
    //         if (params && params.row) {
    //             let anamount = 0;
    //             let mamount = 0;
    //             const existingSetupp = { ...existingSetup[0] };


    //             const deduct_type = existingSetupp.deduction.find(item => item.name === params.row.name)?.type;

    //             if (deduct_type === 'Percentage') {
    //                 anamount = parseFloat(newValue3).toFixed(2);
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             } else if (deduct_type === 'Fixed') {
    //                 anamount = parseFloat(newValue3).toFixed(2);
    //                 mamount = parseFloat((anamount / 12).toFixed(2));
    //             }

    //             handleAmountChange(anamount, params.rowNode.id);
    //             handleMonthAmountChange(mamount, params.rowNode.id);

    //             const deductionIndex = submitsalary.deduction.findIndex(item => item.name === params.row.name);
    //             const updatedItem = {
    //                 name: params.row.name,
    //                 type: deduct_type,
    //                 amount: newValue3,
    //                 monthly: mamount,
    //                 Annually: anamount
    //             };

    //             let updatedDeductionList = [...submitsalary.deduction];
    //             if (deductionIndex !== -1) {
    //                 updatedDeductionList[deductionIndex] = updatedItem;
    //             } else {
    //                 updatedDeductionList.push(updatedItem);
    //             }

    //             Setsubmitsalary(prevState => ({
    //                 ...prevState,
    //                 deduction: updatedDeductionList
    //             }));
    //         }
    //     }
    // };


    const handleBasicChange = (e, params) => {
        setFlag(true)
        const newValue1 = e.target.value.trim();
        if (newValue1 === '' || (!isNaN(newValue1) && parseFloat(newValue1) >= 0)) {
            setBasicEditedValue(newValue1);
            if (params && params.row) {
                let anamount = 0;
                let mamount = 0;
                const existingSetupp = { ...existingSetup[0] };
                console.log("existingSetup", existingSetup);
                const deduct_type = existingSetupp.basic.find(item => item.name === 'BASIC')?.type;

                if (deduct_type === 'Percentage') {
                    anamount = parseFloat((salaryctc * (newValue1 / 100)).toFixed(2));
                    mamount = parseFloat((anamount / 12).toFixed(2));
                } else if (deduct_type === 'Fixed') {
                    anamount = parseFloat(newValue1).toFixed(2);
                    mamount = parseFloat((anamount / 12)).toFixed(2);
                }

                handleAmountChange(anamount, params.rowNode.id);
                handleMonthAmountChange(mamount, params.rowNode.id);

                const basicIndex = submitsalary.basic.findIndex(item => item.name === 'BASIC');
                let updatedItem = {
                    name: 'BASIC',
                    type: deduct_type,
                    amount: newValue1,
                    monthly: mamount,
                    Annually: anamount
                };

                let updatedBasicList = [...submitsalary.basic];
                if (basicIndex !== -1) {
                    updatedBasicList[basicIndex] = updatedItem;
                } else {
                    updatedBasicList.push(updatedItem);
                }

                Setsubmitsalary(prevState => ({
                    ...prevState,
                    basic: updatedBasicList
                }));
            }
        }
    };

    const handleAllowanceChange = (e, params) => {
        setFlag(true)
        const newValue = e.target.value.trim();
        const rowIndex = params.rowNode.id;

        if (newValue === '' || (!isNaN(newValue) && parseFloat(newValue) >= 0)) {
            setAllowanceEditedValue(prev => ({ ...prev, [rowIndex]: newValue }));

            let anamount = 0;
            let mamount = 0;
            const existingSetupp = { ...existingSetup[0] };
            const deduct_type = existingSetupp.allowance.find(item => item.name === params.row.name)?.type;

            if (deduct_type === 'Percentage') {
                anamount = parseFloat(newValue).toFixed(2);
                mamount = parseFloat((anamount / 12).toFixed(2));
            } else if (deduct_type === 'Fixed') {
                anamount = parseFloat(newValue).toFixed(2);
                mamount = parseFloat((anamount / 12).toFixed(2));
            }

            const allowanceIndex = submitsalary.allowance.findIndex(item => item.name === params.row.name);
            const updatedItem = {
                name: params.row.name,
                type: deduct_type,
                amount: newValue,
                monthly: mamount,
                Annually: anamount
            };

            let updatedAllowanceList = [...submitsalary.allowance];
            if (allowanceIndex !== -1) {
                updatedAllowanceList[allowanceIndex] = updatedItem;
            } else {
                updatedAllowanceList.push(updatedItem);
            }

            Setsubmitsalary(prevState => ({
                ...prevState,
                allowance: updatedAllowanceList
            }));
        }
    };



    const handleDeductionChange = (e, params) => {
        setFlag(true)
        const newValue = e.target.value.trim();
        const rowIndex = params.rowNode.id;

        if (newValue === '' || (!isNaN(newValue) && parseFloat(newValue) >= 0)) {
            setDeductionEditedValues(prev => ({ ...prev, [rowIndex]: newValue }));

            let anamount = 0;
            let mamount = 0;
            const existingSetupp = { ...existingSetup[0] };
            const deduct_type = existingSetupp.deduction.find(item => item.name === params.row.name)?.type;

            if (deduct_type === 'Percentage') {
                anamount = parseFloat(newValue).toFixed(2);
                mamount = parseFloat((anamount / 12).toFixed(2));
            } else if (deduct_type === 'Fixed') {
                anamount = parseFloat(newValue).toFixed(2);
                mamount = parseFloat((anamount / 12).toFixed(2));
            }

            const deductionIndex = submitsalary.deduction.findIndex(item => item.name === params.row.name);
            const updatedItem = {
                name: params.row.name,
                type: deduct_type,
                amount: newValue,
                monthly: mamount,
                Annually: anamount
            };

            let updatedDeductionList = [...submitsalary.deduction];
            if (deductionIndex !== -1) {
                updatedDeductionList[deductionIndex] = updatedItem;
            } else {
                updatedDeductionList.push(updatedItem);
            }

            Setsubmitsalary(prevState => ({
                ...prevState,
                deduction: updatedDeductionList
            }));
        }
    };


    let salaryval = [];

    // const handleAddSalarySetup = async (e) => {
    //     console.log(submitsalary);
    //     console.log("e", e);

    //     e.preventDefault();
    //     try {
    //         const payload = {
    //             name: salaryData.name,
    //             salary_structure_id: structureId,
    //             basic: submitsalary.basic,
    //             allowences: submitsalary.allowance,
    //             deductions: submitsalary.deduction
    //         };

    //         const response = await addempSalarySetup(formData.employee_id, structureId, payload);
    //         console.log("response", response);
    //         if (response) {
    //             toast.success('Salary setup added successfully.');
    //             setFormData({
    //                 employee_id: '',
    //                 name: '',
    //                 salary_structure_id: '',
    //                 basic: [],
    //                 allowences: [],
    //                 deductions: []
    //             });
    //         }
    //     } catch (error) {
    //         toast.success('Salary setup added successfully.');
    //     }
    //     // if (formData.employee_id && structureId) {
    //     // fetchExistingSetup();
    //     // }

    //     navigate("/admin/empsalarysetup");
    // };


    // const handleAddSalarySetup = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const existingSetupp = { ...existingSetup[0] }; 

    //         const mergedBasic = existingSetupp.basic.map(item => {
    //             const found = submitsalary.basic.find(i => i.name === item.name);
    //             return found ? { ...item, ...found } : item;
    //         });

    //         const mergedAllowance = existingSetupp.allowance.map(item => {
    //             const found = submitsalary.allowance.find(i => i.name === item.name);
    //             return found ? { ...item, ...found } : item;
    //         });

    //         const mergedDeduction = existingSetupp.deduction.map(item => {
    //             const found = submitsalary.deduction.find(i => i.name === item.name);
    //             return found ? { ...item, ...found } : item;
    //         });

    //         // Check for any new entries added to submitsalary that were not in existingSetup
    //         submitsalary.basic.forEach(item => {
    //             if (!mergedBasic.find(i => i.name === item.name)) {
    //                 mergedBasic.push(item);
    //             }
    //         });

    //         submitsalary.allowance.forEach(item => {
    //             if (!mergedAllowance.find(i => i.name === item.name)) {
    //                 mergedAllowance.push(item);
    //             }
    //         });

    //         submitsalary.deduction.forEach(item => {
    //             if (!mergedDeduction.find(i => i.name === item.name)) {
    //                 mergedDeduction.push(item);
    //             }
    //         });

    //         // Create payload with merged data
    //         const payload = {
    //             name: salaryData.name,
    //             salary_structure_id: structureId,
    //             basic: mergedBasic,
    //             allowences: mergedAllowance,
    //             deductions: mergedDeduction,
    //             employee_id: formData.employee_id
    //         };

    //         const response = await addempSalarySetup(formData.employee_id, structureId, payload);
    //         console.log("response", response);
    //         if (response) {
    //             toast.success('Salary setup added successfully.');
    //             setFormData({
    //                 employee_id: '',
    //                 name: '',
    //                 salary_structure_id: '',
    //                 basic: [],
    //                 allowences: [],
    //                 deductions: []
    //             });
    //         }
    //     } catch (error) {
    //         toast.error('Failed to add salary setup.');
    //     }

    //     navigate("/admin/empsalarysetup");
    // };




    useEffect(() => {
        const debouncedFetchexistData = _debounce(fetchExistingSetup, 100);

        debouncedFetchexistData();

        return () => {
            debouncedFetchexistData.cancel();
        };
    }, [formData.employee_id, structureId]);

    // const fetchExistingSetup = async () => {
    //     try {
    //         setLoading(true);
    //         const response = await searchEmployeeSalarySetup(formData.employee_id, structureId);
    //         console.log("searchEmployeeSalarySetup", response);

    //         if (response && response.data) {
    //             setExistingSetup(response.data);

    //         } else if (response && response.message === "Employee Salary structure Not Found") {
    //             setExistingSetup(response.message === "Employee Salary structure Not Found");

    //         }
    //     } catch (error) {


    //         console.error("Error fetching salary setup:", error);
    //     } finally {

    //         setLoading(false);
    //     }
    // };
    const fetchExistingSetup = async () => {
        try {
            setLoading(true);
            const response = await searchEmployeeSalarySetup(formData.employee_id, structureId);
            console.log("searchEmployeeSalarySetup", response);

            if (response && response.data) {
                setExistingSetup(response.data);
                const totalMonthlyAmount = response.data.reduce((total, companyTime) => {
                    const basicTotal = companyTime.basic.reduce((sum, item) => sum + item.monthly, 0);
                    const allowanceTotal = companyTime.allowance.reduce((sum, item) => sum + item.monthly, 0);
                    const deductionTotal = companyTime.deduction.reduce((sum, item) => sum + item.monthly, 0);

                    return total + basicTotal + allowanceTotal - deductionTotal; // Assuming deduction is subtracted
                }, 0);

                setMonthWithoutUpdate(totalMonthlyAmount);

                let totalAnnualAmount = totalMonthlyAmount * 12
                setAnnualWithoutUpdate(totalAnnualAmount);



            } else if (response && response.message === "Employee Salary structure Not Found") {
                setExistingSetup(response.message === "Employee Salary structure Not Found");
            }
        } catch (error) {
            console.error("Error fetching salary setup:", error);
        } finally {
            setLoading(false);
        }
    };


    const fetchempData = async () => {
        try {
            const formData = {};
            const response = await viewEmployee(formData);
            if (!response || !response.success || !response.data) {
                throw new Error('Failed to fetch data.');
            }

            setEmployees(response.data);
        } catch (error) {
            console.error(error);
            toast.error('Failed to fetch employees.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const debouncedFetchEmpData = _debounce(fetchempData, 100);

        debouncedFetchEmpData();

        return () => {
            debouncedFetchEmpData.cancel();
        };
    }, []);

    const handleEmployeeChange = (e) => {
        let selectedEmployeeId = e.target.value.trim();

        const employee = employees.find(emp => emp && emp.employeeData && emp.employeeData._id === parseInt(selectedEmployeeId));

        setSelectedEmployee(employee ? employee.employeeData : null);
    };

    useEffect(() => {
        if (selectedEmployee) {
            setSalaryctc(selectedEmployee.salary);
        }
    }, [selectedEmployee]);

    // const handleStructureChange = (e) => {
    //     handleSalarySetupBtn()
    //     const selectedStructureId = e.target.value;
    //     setStructureId(selectedStructureId);

    //     const selectedStructure = salarySetupData.find(structure => structure.Salary_Structure[selectedStructureId]);
    //     if (selectedStructure) {
    //         setSalaryData(selectedStructure.Salary_Structure[selectedStructureId]);
    //     }
    // };
    // const handleStructureChange = async (e) => {
    //     handleSalarySetupBtn();
    //     const selectedStructureId = e.target.value;
    //     setStructureId(selectedStructureId);

    //     setLoading(true); // Set loading to true when the structure change starts

    //     try {
    //         const selectedStructure = salarySetupData.find(structure => structure.Salary_Structure[selectedStructureId]);
    //         if (selectedStructure) {
    //             setSalaryData(selectedStructure.Salary_Structure[selectedStructureId]);
    //         }
    //     } finally {
    //         setLoading(false); // Ensure loading is set to false when the operation completes
    //     }
    // };
    const handleStructureChange = async (e) => {
        const { name, value } = e.target
        console.log("8888888888888888888888888888888888888888888888888888888888888888888888888888888",name, value);
        console.log(salarySetupData);

        const struct_id = salarySetupData[0].Salary_Structure
        const salaryStructuresArray  = Object.values(struct_id);

        console.log(salaryStructuresArray);
        setSalaryStructure(salaryStructuresArray)
        

        let b = salaryStructuresArray.map((structure) => structure._id);
        console.log(b);
        

        
        

        handleSalarySetupBtn();
        const selectedStructureId = e.target.value;
        console.log(selectedStructureId);
        setStructureId(selectedStructureId);

        setLoading(true); // Set loading to true when the structure change starts

        try {
            const selectedStructure = salarySetupData.find(structure => structure.Salary_Structure[selectedStructureId]);
            if (selectedStructure) {
                setSalaryData(selectedStructure.Salary_Structure[selectedStructureId]);
            }

            // Call fetchExistingSetup to refresh the data
            await fetchExistingSetup();
        } finally {
            setLoading(false); // Ensure loading is set to false when the operation completes
        }
    };



    const fetchData = async () => {
        try {
            const responseData = await viewSalarySetup();

            if (!responseData || !responseData.data) {
                throw new Error('Failed to fetch data.');
            }

            setSalarySetupData(responseData.data);
            setMasterId(responseData.data[0]._id);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateAnnualSalary = (salary) => {
        return parseInt(salary) / 12;
    };

    useEffect(() => {
        const debouncedFetchData = _debounce(fetchData, 100);

        debouncedFetchData();

        return () => {
            debouncedFetchData.cancel();
        };
    }, []);

    const handleAddSalarySetup = async (e) => {
        e.preventDefault();
        try {
            let payload;

            if (existingSetup.length > 0) {
                const existingSetupp = { ...existingSetup[0] };

                // Merge existing data with updated data
                const mergedBasic = existingSetupp.basic.map(item => {
                    const found = submitsalary.basic.find(i => i.name === item.name);
                    return found ? { ...item, ...found } : item;
                });

                const mergedAllowance = existingSetupp.allowance.map(item => {
                    const found = submitsalary.allowance.find(i => i.name === item.name);
                    return found ? { ...item, ...found } : item;
                });

                const mergedDeduction = existingSetupp.deduction.map(item => {
                    const found = submitsalary.deduction.find(i => i.name === item.name);
                    return found ? { ...item, ...found } : item;
                });

                // Add any new entries that were not in the existing setup
                submitsalary.basic.forEach(item => {
                    if (!mergedBasic.find(i => i.name === item.name)) {
                        mergedBasic.push(item);
                    }
                });

                submitsalary.allowance.forEach(item => {
                    if (!mergedAllowance.find(i => i.name === item.name)) {
                        mergedAllowance.push(item);
                    }
                });

                submitsalary.deduction.forEach(item => {
                    if (!mergedDeduction.find(i => i.name === item.name)) {
                        mergedDeduction.push(item);
                    }
                });

                // Create payload with merged data
                payload = {
                    name: salaryData.name,
                    salary_structure_id: structureId,
                    basic: mergedBasic,
                    allowences: mergedAllowance,
                    deductions: mergedDeduction,
                    employee_id: formData.employee_id
                };
            } else {
                // Create payload with new data directly
                payload = {
                    name: salaryData.name,
                    salary_structure_id: structureId,
                    basic: submitsalary.basic,
                    allowences: submitsalary.allowance,
                    deductions: submitsalary.deduction,
                    employee_id: formData.employee_id
                };
            }

            // Log payload to verify its contents
            console.log("Payload:", JSON.stringify(payload, null, 2));

            const response = await addempSalarySetup(formData.employee_id, structureId, payload);
            console.log("response", response);
            if (response) {
                toast.success('Salary setup added successfully.');

                // Only reset form fields except employee_id
                setFormData(prevState => ({
                    ...prevState,
                    name: '',
                    salary_structure_id: '',
                    basic: [],
                    allowences: [],
                    deductions: []
                }));

                // Call fetchExistingSetup after successful submission
                fetchExistingSetup();
            }
        } catch (error) {
            // toast.error('An error occurred while adding the salary setup.');
            toast.success('Salary setup added successfully.')
        }


        navigate("/admin/empsalarysetup");
    };
    // const getMonthlyAmount = (params) => {
    //     const { type, name } = params.row;

    //     switch (type) {
    //         case 'Basic':
    //             return getValue(submitsalary.basic, existingSetup[0].basic, 'BASIC', basicEditedValue, 'monthly');
    //         case 'Allowance':
    //             return getValue(submitsalary.allowance, existingSetup[0].allowance, name, allowanceEditedValue, 'monthly');
    //         case 'Deduction':
    //             return getValue(submitsalary.deduction, existingSetup[0].deduction, name, deductionEditedValue, 'monthly');
    //         default:
    //             return 0;
    //     }
    // };

    const getMonthlyAmount = (params) => {
        const { type, name } = params.row;
        const rowIndex = params.rowNode.id;

        switch (type) {
            case 'Basic':
                return getValue(submitsalary.basic, existingSetup[0].basic, 'BASIC', basicEditedValue, 'monthly');
            case 'Allowance':
                return getValue(submitsalary.allowance, existingSetup[0].allowance, name, allowanceEditedValue, 'monthly');
            case 'Deduction':
                return getValue(submitsalary.deduction, existingSetup[0].deduction, name, deductionEditedValues[rowIndex], 'monthly');
            default:
                return 0;
        }
    };
    // const getAnnualAmount = (params) => {
    //     const { type, name } = params.row;

    //     switch (type) {
    //         case 'Basic':
    //             return getValue(submitsalary.basic, existingSetup[0].basic, 'BASIC', basicEditedValue, 'Annually');
    //         case 'Allowance':
    //             return getValue(submitsalary.allowance, existingSetup[0].allowance, name, allowanceEditedValue, 'Annually');
    //         case 'Deduction':
    //             return getValue(submitsalary.deduction, existingSetup[0].deduction, name, deductionEditedValue, 'Annually');
    //         default:
    //             return 0; // Return 0 or handle other cases as needed
    //     }
    // };

    const getAnnualAmount = (params) => {
        const { type, name } = params.row;
        const rowIndex = params.rowNode.id;

        switch (type) {
            case 'Basic':
                return getValue(submitsalary.basic, existingSetup[0].basic, 'BASIC', basicEditedValue, 'Annually');
            case 'Allowance':
                return getValue(submitsalary.allowance, existingSetup[0].allowance, name, allowanceEditedValue, 'Annually');
            case 'Deduction':
                return getValue(submitsalary.deduction, existingSetup[0].deduction, name, deductionEditedValues[rowIndex], 'Annually');
            default:
                return 0;
        }
    };
    // const getValue = (submitData, existingData, itemName, editedValue, key) => {
    //     const editedItem = submitData.find(item => item.name === itemName);
    //     const existingItem = existingData.find(item => item.name === itemName);

    //     // Parse or convert to ensure numeric value
    //     let value = 0;

    //     if (editedValue !== null && editedItem) {
    //         value = parseFloat(editedItem[key]) || 0;
    //     } else if (existingItem) {
    //         value = parseFloat(existingItem[key]) || 0;
    //     }

    //     return value;
    // };
    const getValue = (submitData, existingData, itemName, editedValue, key) => {
        const editedItem = submitData.find(item => item.name === itemName);
        const existingItem = existingData.find(item => item.name === itemName);

        let value = 0;

        if (editedValue !== null && editedItem) {
            value = parseFloat(editedItem[key]) || 0;
        } else if (existingItem) {
            value = parseFloat(existingItem[key]) || 0;
        }

        return value;
    };


    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    <h3 className="mb-0 pb- border-bottom custome-btn">Add salary setup</h3>
                </div>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item ">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active"><Link to="/admin/Salarysetup">Salary Setup</Link></li>
                            <li className="breadcrumb-item active">Lists</li>
                        </ol>
                    </div>
                    <nav aria-label="breadcrumb">
                        <Link to="/admin/Viewempsalary" className="btn btn-danger mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav>
                </div>
                <div className="form-container">
                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >
                        <div className="col-md-12 formcontrols">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <Form onSubmit={handleAddSalarySetup}>

                                    {/* <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label>Employee Name:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="employee_id"
                                                    value={formData.employee_id}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleEmployeeChange(e);
                                                    }}
                                                >
                                                    <option value="">Select Employee</option>
                                                    {employees.map((employee) => (
                                                        <option key={employee.employeeData._id} value={employee.employeeData._id}>
                                                            {employee.employeeData.first_name} {employee.employeeData.last_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label>Select Salary Structure:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="structureId"
                                                    value={structureId}
                                                    onChange={handleStructureChange}
                                                >
                                                    <option value="">Select Salary Structure</option>
                                                    {salarySetupData.length > 0 && Object.keys(salarySetupData[0].Salary_Structure).map((key) => (
                                                        <option key={key} value={key}>{salarySetupData[0].Salary_Structure[key].name}</option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                    </div> */}

                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label>Employee Name:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="employee_id"
                                                    value={formData.employee_id}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        handleEmployeeChange(e);
                                                    }}
                                                >
                                                    <option value="">Select Employee</option>
                                                    {employees.map((employee) => (
                                                        <option key={employee.employeeData._id} value={employee.employeeData._id}>
                                                            {employee.employeeData.first_name} {employee.employeeData.last_name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label>Select Salary Structure:</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name="structureId"
                                                    value={structureId}
                                                    onChange={handleStructureChange}
                                                >
                                                    <option value="">Select Salary Structure</option>
                                                    {salarySetupData.length > 0 && Object.keys(salarySetupData[0].Salary_Structure).map((key) => (
                                                        <option key={key._id} value={key}>{salarySetupData[0].Salary_Structure[key].name}</option>
                                                    ))}
                                                    
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {/* <Button variant="primary" onClick={fetchExistingSetup}>
                                        Fetch
                                    </Button> */}
                                    {/* <Button variant="primary" onClick={() => {
                                        fetchExistingSetup();
                                        window.location.reload();
                                    }}>
                                        Fetch
                                    </Button> */}



                                    <div>
                                        <div className="row">
                                            <h3>Payroll Summary</h3>
                                            <div className="row">
                                                {formData.employee_id && (
                                                    <div className="row">
                                                        <div className="col-md-2 mb-2">
                                                            <div className="earning-item">
                                                                <div className="earning-icon bg-primary"><i className="ri-medal-2-line"></i></div>
                                                                <h4><span>₹</span>{parseInt(salaryctc).toLocaleString()}</h4>
                                                                <label>Annual CTC</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2 mb-2">
                                                            <div className="earning-item">
                                                                <div className="earning-icon bg-primary"><i className="ri-medal-2-line"></i></div>
                                                                <h4><span>₹</span>{calculateAnnualSalary(salaryctc).toFixed(2).toLocaleString()}</h4>
                                                                <label>Monthly CTC</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>

                                        </div>

                                        <div className="row">
                                            <div class="col-md-6 col-xl-12">
                                                <div class="card card-one">
                                                    <div class="card-header">
                                                        <h6 class="card-title">Employee Salary Setup</h6>

                                                    </div>
                                                    <div class="card-body">
                                                        {loading ? (
                                                            <div className="loader-container">
                                                                <div className="loader"></div>
                                                            </div>
                                                        ) : existingSetup && existingSetup.length > 0 ? (
                                                            <>
                                                                {existingSetup && existingSetup.length > 0 ? (
                                                                    // <DataGrid
                                                                    //     rows={[
                                                                    //         ...existingSetup[0].basic.map((item, index) => ({ ...item, id: index + 1, type: 'Basic' })),
                                                                    //         ...existingSetup[0].allowance.map((item, index) => ({ ...item, id: existingSetup[0].basic.length + index + 1, type: 'Allowance' })),
                                                                    //         ...existingSetup[0].deduction.map((item, index) => ({ ...item, id: existingSetup[0].basic.length + existingSetup[0].allowance.length + index + 1, type: 'Deduction' }))
                                                                    //     ]}
                                                                    //     columns={[
                                                                    //         { field: 'id', headerName: 'S.No.', flex: 0, width: 10 },
                                                                    //         { field: 'type', headerName: 'TYPE', flex: 1 },
                                                                    //         { field: 'name', headerName: 'COMPONENTS', flex: 1 },
                                                                    //         {
                                                                    //             field: 'calculationtype',
                                                                    //             headerName: 'CALCUNATION TYPE',
                                                                    //             flex: 1,
                                                                    //             renderCell: (params) => (
                                                                    //                 <TableCell>
                                                                    //                     {params.row.type === 'Basic' && (
                                                                    //                         <span>{existingSetup[0].basic.find(item => item.name === 'BASIC')?.type}</span>
                                                                    //                     )}
                                                                    //                     {params.row.type === 'Allowance' && (
                                                                    //                         <span>{existingSetup[0].allowance.find(item => item.name === params.row.name)?.type}</span>
                                                                    //                     )}
                                                                    //                     {params.row.type === 'Deduction' && (
                                                                    //                         <span>{existingSetup[0].deduction.find(item => item.name === params.row.name)?.type}</span>
                                                                    //                     )}
                                                                    //                 </TableCell>
                                                                    //             ),
                                                                    //         },
                                                                    //         {
                                                                    //             field: 'amount',
                                                                    //             headerName: 'AMOUNT',
                                                                    //             flex: 1,
                                                                    //             renderCell: (params) => (
                                                                    //                 <TableCell>
                                                                    //                     <input
                                                                    //                         type="text"
                                                                    //                         value={
                                                                    //                             params.row.type === 'Basic' ? (basicEditedValue !== null ? basicEditedValue.toString() : params.value) :
                                                                    //                                 params.row.type === 'Allowance' ? (allowanceEditedValue !== null ? allowanceEditedValue.toString() : params.value) :
                                                                    //                                     params.row.type === 'Deduction' ? (deductionEditedValue !== null ? deductionEditedValue.toString() : params.value) :
                                                                    //                                         params.value
                                                                    //                         }
                                                                    //                         onChange={(e) => {
                                                                    //                             if (params.row.type === 'Basic') {
                                                                    //                                 handleBasicChange(e, params);
                                                                    //                             }
                                                                    //                             if (params.row.type === 'Allowance') {
                                                                    //                                 handleAllowanceChange(e, params);
                                                                    //                             }
                                                                    //                             if (params.row.type === 'Deduction') {
                                                                    //                                 handleDeductionChange(e, params);
                                                                    //                             }
                                                                    //                         }}
                                                                    //                     />
                                                                    //                 </TableCell>
                                                                    //             ),


                                                                    //         },
                                                                    //         {
                                                                    //             field: 'monthlyamount',
                                                                    //             headerName: 'MONTHLY AMOUNT',
                                                                    //             flex: 1,
                                                                    //             renderCell: (params) => (
                                                                    //                 <TableCell>
                                                                    //                     <span>{getMonthlyAmount(params).toFixed(2)}</span>
                                                                    //                 </TableCell>
                                                                    //             )
                                                                    //         },
                                                                    //         // {
                                                                    //         //     field: 'annualamount',
                                                                    //         //     headerName: 'ANNUAL AMOUNT',
                                                                    //         //     flex: 1,
                                                                    //         //     renderCell: (params) => {
                                                                    //         //         const annualAmount = params.row.type === 'Basic' ? (
                                                                    //         //             basicEditedValue !== null ?
                                                                    //         //                 (submitsalary.basic.find(item => item.name === 'BASIC')?.Annually || 0) :
                                                                    //         //                 (existingSetup[0].basic.find(item => item.name === 'BASIC')?.Annually || 0)
                                                                    //         //         ) : (
                                                                    //         //             params.row.type === 'Allowance' ? (
                                                                    //         //                 allowanceEditedValue !== null ?
                                                                    //         //                     (submitsalary.allowance.find(item => item.name === params.row.name)?.Annually || 0) :
                                                                    //         //                     (existingSetup[0].allowance.find(item => item.name === params.row.name)?.Annually || 0)
                                                                    //         //             ) : (
                                                                    //         //                 params.row.type === 'Deduction' ? (
                                                                    //         //                     deductionEditedValue !== null ?
                                                                    //         //                         (submitsalary.deduction.find(item => item.name === params.row.name)?.Annually || 0) :
                                                                    //         //                         (existingSetup[0].deduction.find(item => item.name === params.row.name)?.Annually || 0)
                                                                    //         //                 ) : null
                                                                    //         //             )
                                                                    //         //         );
                                                                    //         //         return (
                                                                    //         //             <TableCell>
                                                                    //         //                 <span>{annualAmount}</span>
                                                                    //         //             </TableCell>
                                                                    //         //         );
                                                                    //         //     },
                                                                    //         // },
                                                                    //         {
                                                                    //             field: 'annualamount',
                                                                    //             headerName: 'ANNUAL AMOUNT',
                                                                    //             flex: 1,
                                                                    //             renderCell: (params) => (
                                                                    //                 <TableCell>
                                                                    //                     <span>{getAnnualAmount(params).toFixed(2)}</span>
                                                                    //                 </TableCell>
                                                                    //             ),
                                                                    //         },

                                                                    //     ]}
                                                                    //     autoHeight
                                                                    //     headerClassName="custom-header-color"
                                                                    // />
                                                                    <DataGrid
                                                                        rows={[
                                                                            ...existingSetup[0].basic.map((item, index) => ({ ...item, id: index + 1, type: 'Basic' })),
                                                                            ...existingSetup[0].allowance.map((item, index) => ({ ...item, id: existingSetup[0].basic.length + index + 1, type: 'Allowance' })),
                                                                            ...existingSetup[0].deduction.map((item, index) => ({ ...item, id: existingSetup[0].basic.length + existingSetup[0].allowance.length + index + 1, type: 'Deduction' }))
                                                                        ]}
                                                                        columns={[
                                                                            { field: 'id', headerName: 'S.No.', flex: 0, width: 10 },
                                                                            { field: 'type', headerName: 'TYPE', flex: 1 },
                                                                            { field: 'name', headerName: 'COMPONENTS', flex: 1 },
                                                                            {
                                                                                field: 'calculationtype',
                                                                                headerName: 'CALCULATION TYPE',
                                                                                flex: 1,
                                                                                renderCell: (params) => (
                                                                                    <TableCell style={{ width: '100%', textAlign: 'center' }}>
                                                                                        <span
                                                                                            style={{
                                                                                                display: 'block',
                                                                                                padding: '8px',
                                                                                                fontFamily: 'inherit', // Example: use font family from parent container
                                                                                                fontSize: 'inherit', // Example: use font size from parent container
                                                                                                backgroundColor: '#f0f0f0', // Example: custom background color
                                                                                                color: '#333', // Example: custom text color
                                                                                                border: 'none',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        >
                                                                                            {params.row.type === 'Basic' && (
                                                                                                <span>{existingSetup[0].basic.find(item => item.name === 'BASIC')?.type}</span>
                                                                                            )}
                                                                                            {params.row.type === 'Allowance' && (
                                                                                                <span>{existingSetup[0].allowance.find(item => item.name === params.row.name)?.type}</span>
                                                                                            )}
                                                                                            {params.row.type === 'Deduction' && (
                                                                                                <span>{existingSetup[0].deduction.find(item => item.name === params.row.name)?.type}</span>
                                                                                            )}
                                                                                        </span>
                                                                                    </TableCell>
                                                                                ),
                                                                            },
                                                                            // {
                                                                            //     field: 'amount',
                                                                            //     headerName: 'AMOUNT',
                                                                            //     flex: 1,
                                                                            //     renderCell: (params) => (
                                                                            //         <TableCell>
                                                                            //             <input
                                                                            //                 type="text"
                                                                            //                 value={
                                                                            //                     params.row.type === 'Basic' ? (basicEditedValue !== null ? basicEditedValue.toString() : params.value) :
                                                                            //                         params.row.type === 'Allowance' ? (allowanceEditedValue !== null ? allowanceEditedValue.toString() : params.value) :
                                                                            //                             params.row.type === 'Deduction' ? (deductionEditedValue !== null ? deductionEditedValue.toString() : params.value) :
                                                                            //                                 params.value
                                                                            //                 }
                                                                            //                 onChange={(e) => {
                                                                            //                     if (params.row.type === 'Basic') {
                                                                            //                         handleBasicChange(e, params);
                                                                            //                     }
                                                                            //                     if (params.row.type === 'Allowance') {
                                                                            //                         handleAllowanceChange(e, params);
                                                                            //                     }
                                                                            //                     if (params.row.type === 'Deduction') {
                                                                            //                         handleDeductionChange(e, params);
                                                                            //                     }
                                                                            //                 }}
                                                                            //                 style={{
                                                                            //                     width: '100%', // Ensure input takes full width
                                                                            //                     boxSizing: 'border-box',
                                                                            //                     padding: '8px',
                                                                            //                     border: 'none',
                                                                            //                     outline: 'none',
                                                                            //                     fontFamily: 'inherit', // Example: use font family from parent container
                                                                            //                     fontSize: 'inherit', // Example: use font size from parent container
                                                                            //                     backgroundColor: '#f9f9f9', // Example: custom background color
                                                                            //                     color: '#333', // Example: custom text color
                                                                            //                 }}
                                                                            //             />
                                                                            //         </TableCell>
                                                                            //     ),
                                                                            // },
                                                                            {
                                                                                field: 'amount',
                                                                                headerName: 'AMOUNT',
                                                                                flex: 1,
                                                                                renderCell: (params) => (
                                                                                    <TableCell>
                                                                                        <input
                                                                                            type="text"
                                                                                            value={
                                                                                                params.row.type === 'Basic' ? (basicEditedValue !== null ? basicEditedValue.toString() : params.value) :
                                                                                                    params.row.type === 'Allowance' ? (allowanceEditedValue[params.rowNode.id] !== undefined ? allowanceEditedValue[params.rowNode.id].toString() : params.value) :
                                                                                                        params.row.type === 'Deduction' ? (deductionEditedValues[params.rowNode.id] !== undefined ? deductionEditedValues[params.rowNode.id].toString() : params.value) :
                                                                                                            params.value
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                if (params.row.type === 'Basic') {
                                                                                                    handleBasicChange(e, params);
                                                                                                }
                                                                                                if (params.row.type === 'Allowance') {
                                                                                                    handleAllowanceChange(e, params);
                                                                                                }
                                                                                                if (params.row.type === 'Deduction') {
                                                                                                    handleDeductionChange(e, params);
                                                                                                }
                                                                                            }}
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                boxSizing: 'border-box',
                                                                                                padding: '8px',
                                                                                                border: 'none',
                                                                                                outline: 'none',
                                                                                                fontFamily: 'inherit',
                                                                                                fontSize: 'inherit',
                                                                                                backgroundColor: '#f9f9f9',
                                                                                                color: '#333',
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                ),
                                                                            },
                                                                            {
                                                                                field: 'monthlyamount',
                                                                                headerName: 'MONTHLY AMOUNT',
                                                                                flex: 1,
                                                                                renderCell: (params) => (
                                                                                    <TableCell style={{ width: '100%', textAlign: 'center' }}>
                                                                                        <span
                                                                                            style={{
                                                                                                display: 'block',
                                                                                                padding: '8px',
                                                                                                fontFamily: 'inherit', // Example: use font family from parent container
                                                                                                fontSize: 'inherit', // Example: use font size from parent container
                                                                                                backgroundColor: '#f0f0f0', // Example: custom background color
                                                                                                color: '#333', // Example: custom text color
                                                                                                border: 'none',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        >
                                                                                            {getMonthlyAmount(params).toFixed(2)}
                                                                                        </span>
                                                                                    </TableCell>
                                                                                ),
                                                                            },
                                                                            {
                                                                                field: 'annualamount',
                                                                                headerName: 'ANNUAL AMOUNT',
                                                                                flex: 1,
                                                                                renderCell: (params) => (
                                                                                    <TableCell style={{ width: '100%', textAlign: 'center' }}>
                                                                                        <span
                                                                                            style={{
                                                                                                display: 'block',
                                                                                                padding: '8px',
                                                                                                fontFamily: 'inherit', // Example: use font family from parent container
                                                                                                fontSize: 'inherit', // Example: use font size from parent container
                                                                                                backgroundColor: '#f0f0f0', // Example: custom background color
                                                                                                color: '#333', // Example: custom text color
                                                                                                border: 'none',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        >
                                                                                            {getAnnualAmount(params).toFixed(2)}
                                                                                        </span>
                                                                                    </TableCell>
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        autoHeight
                                                                        headerClassName="custom-header-color"
                                                                        className="datagrid-root"
                                                                    />


                                                                ) : null}
                                                            </>


                                                        ) : (
                                                            salaryData && (
                                                                <DataGrid
                                                                    rows={[
                                                                        ...salaryData.basic.map((item, index) => ({ ...item, id: index + 1, type: 'Basic' })),
                                                                        ...salaryData.allowence.map((item, index) => ({ ...item, id: salaryData.basic.length + index + 1, type: 'Allowance' })),
                                                                        ...salaryData.deduction.map((item, index) => ({ ...item, id: salaryData.basic.length + salaryData.allowence.length + index + 1, type: 'Deduction' }))
                                                                    ]}

                                                                    columns={[
                                                                        { field: 'id', headerName: 'S.No.', flex: 0, width: 10 },
                                                                        { field: 'type', headerName: 'TYPE', flex: 1 },
                                                                        { field: 'name', headerName: 'COMPONENTS', flex: 1 },
                                                                        { field: 'deduct_type', headerName: 'CALCUNATION TYPE', flex: 1 },
                                                                        {
                                                                            field: 'amount',
                                                                            headerName: 'AMOUNT',
                                                                            flex: 1,
                                                                            renderCell: (params) => (

                                                                                <TableCell>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        value={params.value}
                                                                                        onChange={(e) => {
                                                                                            const newValue = parseFloat(e.target.value);

                                                                                            if (isNaN(newValue) || newValue < 0) return;

                                                                                            let anamount = 0;
                                                                                            let mamount = 0;

                                                                                            if (params.row.type === 'Basic') {
                                                                                                setFlag(true)
                                                                                                if (params.row.deduct_type === 'Percentage') {
                                                                                                    anamount = parseFloat((salaryctc * (newValue / 100)).toFixed(2));
                                                                                                    mamount = parseFloat((anamount / 12).toFixed(2));
                                                                                                    if (params.rowNode.id === 1) {
                                                                                                        setAbc(mamount)
                                                                                                    }
                                                                                                    handleAmountChange(anamount, params.rowNode.id);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id);
                                                                                                }

                                                                                                if (params.row.deduct_type === 'Fixed') {
                                                                                                    anamount = parseFloat(newValue.toFixed(2));
                                                                                                    mamount = parseFloat((anamount / 12).toFixed(2));
                                                                                                    handleAmountChange(anamount, params.rowNode.id);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id);
                                                                                                }

                                                                                                const basicIndex = submitsalary.basic.findIndex(item => item.name === 'BASIC');

                                                                                                if (basicIndex !== -1) {
                                                                                                    const updatedBasic = {
                                                                                                        ...submitsalary.basic[basicIndex],
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };

                                                                                                    const updatedList = [...submitsalary.basic];
                                                                                                    updatedList[basicIndex] = updatedBasic;
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        basic: updatedList
                                                                                                    }));
                                                                                                } else {
                                                                                                    const newBasic = {
                                                                                                        name: 'BASIC',
                                                                                                        type: params.row.deduct_type,
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        basic: [...prevState.basic, newBasic]
                                                                                                    }));
                                                                                                }
                                                                                            }

                                                                                            else if (params.row.type === 'Allowance') {
                                                                                                setFlag(true)
                                                                                                if (params.row.deduct_type === 'Percentage') {
                                                                                                    anamount = parseFloat((abc * 12 * (newValue / 100)).toFixed(2));
                                                                                                    mamount = parseFloat((anamount / 12).toFixed(2));
                                                                                                    handleAmountChange(anamount, params.rowNode.id);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id);
                                                                                                }

                                                                                                if (params.row.deduct_type === 'Fixed') {
                                                                                                    anamount = parseFloat(newValue.toFixed(2));
                                                                                                    mamount = parseFloat((anamount / 12).toFixed(2));
                                                                                                    handleAmountChange(anamount, params.rowNode.id);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id);
                                                                                                }

                                                                                                const allowanceIndex = submitsalary.allowance.findIndex(item => item.name === params.row.name);

                                                                                                if (allowanceIndex !== -1) {
                                                                                                    const updatedAllowance = {
                                                                                                        ...submitsalary.allowance[allowanceIndex],
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };

                                                                                                    const updatedList = [...submitsalary.allowance];
                                                                                                    updatedList[allowanceIndex] = updatedAllowance;
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        allowance: updatedList
                                                                                                    }));
                                                                                                } else {
                                                                                                    const newAllowance = {
                                                                                                        name: params.row.name,
                                                                                                        type: params.row.deduct_type,
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        allowance: [...prevState.allowance, newAllowance]
                                                                                                    }));
                                                                                                }
                                                                                            }

                                                                                            else if (params.row.type === 'Deduction') {
                                                                                                setFlag(true)
                                                                                                if (params.row.deduct_type === 'Percentage') {
                                                                                                    anamount = parseFloat((abc * 12 * (newValue / 100)).toFixed(2));
                                                                                                    mamount = parseFloat((anamount / 12).toFixed(2));
                                                                                                } else if (params.row.deduct_type === 'Fixed') {
                                                                                                    anamount = parseFloat(newValue.toFixed(2));
                                                                                                    mamount = parseFloat((anamount / 12).toFixed(2));
                                                                                                }

                                                                                                handleAmountChange(anamount, params.rowNode.id);
                                                                                                handleMonthAmountChange(mamount, params.rowNode.id);

                                                                                                const deductionIndex = submitsalary.deduction.findIndex(item => item.name === params.row.name);

                                                                                                if (deductionIndex !== -1) {
                                                                                                    const updatedDeduction = {
                                                                                                        ...submitsalary.deduction[deductionIndex],
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };

                                                                                                    const updatedList = [...submitsalary.deduction];
                                                                                                    updatedList[deductionIndex] = updatedDeduction;
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        deduction: updatedList
                                                                                                    }));
                                                                                                } else {
                                                                                                    const newDeduction = {
                                                                                                        name: params.row.name,
                                                                                                        type: params.row.deduct_type,
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        deduction: [...prevState.deduction, newDeduction]
                                                                                                    }));
                                                                                                }
                                                                                            }

                                                                                        }}
                                                                                    />
                                                                                </TableCell>

                                                                            ),
                                                                        },
                                                                        // {
                                                                        //     field: 'monthlyamount',
                                                                        //     headerName: 'MONTHLY AMOUNT',
                                                                        //     flex: 1,
                                                                        //     renderCell: (params) => (
                                                                        //         <TableCell>
                                                                        //             <Form.Control
                                                                        //                 type="text"
                                                                        //                 value={MonthlyAmount[params.rowNode.id]}
                                                                        //                 onChange={(e) => {
                                                                        //                     const newValue = parseFloat(e.target.value);
                                                                        //                     const updatedItem = { ...params.row, amount: e.target.value };
                                                                        //                     let updatedList = [];
                                                                        //                     if (params.row.type === 'Basic') {
                                                                        //                         updatedList = [...salaryData.basic];
                                                                        //                         updatedList[params.rowNode.id].amount = e.target.value;
                                                                        //                         setSalaryData({ ...salaryData, basic: updatedList });
                                                                        //                     } else if (params.row.type === 'Allowance') {
                                                                        //                         updatedList = [...salaryData.allowence];
                                                                        //                         updatedList[params.rowNode.id].amount = e.target.value;
                                                                        //                         setSalaryData({ ...salaryData, allowence: updatedList });
                                                                        //                     } else if (params.row.type === 'Deduction') {
                                                                        //                         updatedList = [...salaryData.deduction];
                                                                        //                         updatedList[params.rowNode.id].amount = e.target.value;
                                                                        //                         setSalaryData({ ...salaryData, deduction: updatedList });
                                                                        //                     }
                                                                        //                     const newMonthlyAmounts = [...annualAmount]; 
                                                                        //                         newMonthlyAmounts[params.rowIndex] = newValue; 
                                                                        //                         SetMonthlyAmount(newMonthlyAmounts); 
                                                                        //                 }}
                                                                        //             />
                                                                        //         </TableCell>
                                                                        //     ),
                                                                        // },
                                                                        {
                                                                            field: 'monthlyamount',
                                                                            headerName: 'MONTHLY AMOUNT',
                                                                            flex: 1,
                                                                            renderCell: (params) => {
                                                                                return (
                                                                                    <TableCell>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            value={MonthlyAmount[params.rowNode.id]}
                                                                                            onChange={(e) => {
                                                                                                const newValue = parseFloat(e.target.value);

                                                                                                if (isNaN(newValue) || newValue < 0) return;

                                                                                                let updatedList = [];
                                                                                                if (params.row.type === 'Basic') {
                                                                                                    updatedList = [...salaryData.basic];
                                                                                                    updatedList[params.rowIndex].monthlyamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, basic: updatedList }));
                                                                                                } else if (params.row.type === 'Allowance') {
                                                                                                    updatedList = [...salaryData.allowance];
                                                                                                    updatedList[params.rowIndex].monthlyamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, allowance: updatedList }));
                                                                                                } else if (params.row.type === 'Deduction') {
                                                                                                    updatedList = [...salaryData.deduction];
                                                                                                    updatedList[params.rowIndex].monthlyamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, deduction: updatedList }));
                                                                                                }

                                                                                                const newMonthlyAmounts = [...MonthlyAmount];
                                                                                                newMonthlyAmounts[params.rowIndex] = newValue;
                                                                                                SetMonthlyAmount(newMonthlyAmounts);
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                );
                                                                            },
                                                                        },

                                                                        {
                                                                            field: 'anualamount',
                                                                            headerName: 'ANNUAL AMOUNT',
                                                                            flex: 1,
                                                                            renderCell: (params) => {


                                                                                return (
                                                                                    <TableCell>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            value={annualAmount[params.rowNode.id]}
                                                                                            onChange={(e) => {
                                                                                                const newValue = parseFloat(e.target.value);

                                                                                                if (isNaN(newValue) || newValue < 0) return;
                                                                                                let updatedList = [];
                                                                                                if (params.row.type === 'Basic') {
                                                                                                    updatedList = [...salaryData.basic];
                                                                                                    updatedList[params.rowIndex].anualamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, basic: updatedList }));
                                                                                                } else if (params.row.type === 'Allowance') {
                                                                                                    updatedList = [...salaryData.allowence];
                                                                                                    updatedList[params.rowIndex].anualamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, allowence: updatedList }));
                                                                                                } else if (params.row.type === 'Deduction') {
                                                                                                    updatedList = [...salaryData.deduction];
                                                                                                    updatedList[params.rowIndex].anualamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, deduction: updatedList }));
                                                                                                }


                                                                                                const newAnnualAmounts = [...annualAmount];
                                                                                                newAnnualAmounts[params.rowIndex] = newValue;
                                                                                                setannualAmount(newAnnualAmounts);
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                );
                                                                            },
                                                                        },

                                                                    ]}
                                                                    autoHeight
                                                                    headerClassName="custom-header-color"

                                                                />

                                                            )
                                                        )}
                                                    </div>
                                                    <Button variant="primary" className='btn btn-small' onClick={(e) => { handleTotal() }}>Calculate Total</Button>
                                                    <p>
                                                        <strong>Annual Amount :</strong> {focus ? annualAmountTotal : annualWithoutUpdate}
                                                    </p>
                                                    <p>
                                                        <strong>Monthly Amount :</strong> {focus ? monthlyAmountTotal : monthWithoutUpdate}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <br />
                                    {/* <Button variant="success" type="submit"
                                        disabled={isButtonDisabled}>{isButtonDisabled ? "Add Salary Setup" : "Update Salary Setup"}</Button> */}

                                    <Button variant="success" type="submit">
                                        {existingSetup && existingSetup.length > 0 ? "Update Salary Setup" : "Add Salary Setup"}
                                    </Button>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Addempsalarysetup;