import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addSalarySetup, viewEmployee } from '../../services/api/api';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Addsalarysetup = () => {
  const [formData, setFormData] = useState({
    name: '',
    basic: [{ name: '', deduct_type: '' }],
    deductions: [{ name: '', deduct_type: '' }],
    allowences: [{ name: '', deduct_type: '' }]
  });
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleAddDeductionallowence = (type) => {
    const newData = { name: '', deduct_type: '' };
    setFormData({
      ...formData,
      [type]: [...formData[type], newData]
    });
  };

  const handleRemoveDeductionallowence = (index, type) => {
    const newItems = formData[type].filter((item, i) => i !== index);
    setFormData({
      ...formData,
      [type]: newItems
    });
  };

  const handleInputChange = (index, e, type) => {
    const { name, value } = e.target;
    const updatedData = formData[type].map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData({
      ...formData,
      [type]: updatedData
    });
  };

  const handleAddSalarySetup = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      const payload = {
        name: formData.name,
        basic: formData.basic.map((item) => ({
          name: item.name,
          deduct_type: "Percentage"
        })),
        allowences: formData.allowences.map(item => ({
          name: item.name,
          deduct_type: item.deduct_type
        })),
        deductions: formData.deductions.map(item => ({
          name: item.name,
          deduct_type: item.deduct_type
        })),
      };

      const response = await addSalarySetup(payload);
      console.log("responsejson", response)
      if (response) {
        toast.success('Salary setup added successfully.');
        setFormData({
          name: '',
          basic: [],
          deductions: [],
          allowences: []
        });
      }
    } catch (error) {
      console.error(error);
      toast.success('Salary setup added successfully.');
    }

    navigate("/admin/Viewempsalary");
  };


  const fetchData = async () => {
    try {
      const response = await viewEmployee({});
      if (!response || !response.success || !response.data) {
        throw new Error('Failed to fetch data.');
      }
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="mb-4 border-bottom text-center">
          <h3 className="mb-0 pb- border-bottom custome-btn">Add salary setup</h3>
        </div>
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                  <h4>Holidays</h4>
                </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/Viewempsalary">Salarysetup</Link></li>
              <li class="breadcrumb-item active">Lists</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">

            <nav aria-label="breadcrumb">
              <Link to="/admin/Viewempsalary" className="btn btn-danger mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                </svg>
                Back
              </Link>
            </nav>

          </nav>
        </div>
        <div className="form-container">
          <div className="row"
            style={{
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <div className="col-md-12 formcontrols">
              <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                <Form onSubmit={handleAddSalarySetup}>

                  <Form.Group>
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      required
                      value={formData.name}
                      onChange={handleChange}
                    />


                  </Form.Group>
                  <br />
                  <Form.Group>
                    <Form.Label>Basic:</Form.Label>

                    {formData.basic.map((basicItem, index) => (
                      <div key={index} className="form-group">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Salary"
                              required
                              value={basicItem.name}
                              onChange={(e) => handleInputChange(index, e, 'basic')}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              required
                              value={basicItem.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'basic')}
                            >
                              <option value="" disabled selected>Select Basic Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>

                        </div>
                      </div>
                    ))}
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label>Add Deductions:</Form.Label>
                    
                    <span className="ms-2" style={{ cursor: 'pointer' }} onClick={() => handleAddDeductionallowence('deductions')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                      </svg>
                    </span>
                    {formData.deductions.map((deduction, index) => (
                      <div key={index} className="form-group">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Deduction Code"
                              value={deduction.name}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            />
                          </div>
                          
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={deduction.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>

                        </div>
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveDeductionallowence(index, 'deductions')}
                          style={{
                            backgroundColor: '#ff0000d1',
                            border: '0px solid black',
                            margin: '10px',
                            color: 'white',
                            transition: 'background-color 0.3s, border-color 0.3s'
                          }}
                          onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                          onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                        >
                          Remove
                        </Button>
                        <span className="ms-2" style={{ cursor: 'pointer' }} onClick={() => handleAddDeductionallowence('deductions')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                      </svg>
                    </span>
                      </div>
                    ))}
                  </Form.Group> */}

                  {/* <Form.Group>
                    <Form.Label>Add Deductions:</Form.Label>
                    <div className="form-group">

                      {formData.deductions.map((deduction, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-6 mb-4">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Deduction"
                              value={deduction.name}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            />
                          </div>


                          <div className="col-md-6">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={deduction.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>
                          <br />
                        </div>
                      ))}

                      <div className="row">
                        <div className="col-md-10 col-sm-12 d-flex align-items-center justify-content-start">
                          <Button
                            variant="success"
                            className="ms-2"
                            onClick={() => handleAddDeductionallowence('deductions')}
                            style={{ cursor: 'pointer' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                            </svg>
                            Add Deduction
                          </Button>

                        </div>
                        {formData.deductions.length > 0 && (
                          <div className="col-md-2 col-sm-12 d-flex align-items-center justify-content-start">
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveDeductionallowence(formData.deductions.length - 1, 'deductions')}
                              style={{
                                backgroundColor: '#ff0000d1',
                                border: '0px solid black',
                                color: 'white',
                                transition: 'background-color 0.3s, border-color 0.3s'
                              }}
                              onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                              onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                            >
                              Remove
                            </Button>
                          </div>
                        )}

                      </div>
                    </div>
                    <br />

                  </Form.Group> */}

                  <Form.Group>
                    <Form.Label>Add Deductions:</Form.Label>
                    <div className="form-group">
                      {formData.deductions.map((deduction, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-6 mb-4">
                            <Form.Control
                              type="text"
                              name="name"
                              required
                              placeholder="Deduction"
                              value={deduction.name}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              required
                              value={deduction.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'deductions')}
                            >
                              <option value="" selected disabled>Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>
                          <div className="col-md-12">
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveDeductionallowence(index, 'deductions')}
                              style={{
                                backgroundColor: '#ff0000d1',
                                border: '0px solid black',
                                color: 'white',
                                transition: 'background-color 0.3s, border-color 0.3s'
                              }}
                              onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                              onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                      <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-start">
                          <Button
                            variant="success"
                            className="ms-2"
                            onClick={() => handleAddDeductionallowence('deductions')}
                            style={{ cursor: 'pointer' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                            </svg>
                            Add Deduction
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form.Group>



                  {/* <Form.Group>
                    <Form.Label>Add allowences:</Form.Label>
                    <span className="ms-2" style={{ cursor: 'pointer' }} onClick={() => handleAddDeductionallowence('allowences')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                      </svg>
                    </span>
                    {formData.allowences.map((allowence, index) => (
                      <div key={index} className="form-group">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Allowence Code"
                              value={allowence.name}
                              onChange={(e) => handleInputChange(index, e, 'allowences')}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              value={allowence.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'allowences')}
                            >
                              <option value="">Select Deduction Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>

                        </div>
                        <Button
                          variant="danger"
                          onClick={() => handleRemoveDeductionallowence(index, 'allowences')}
                          style={{
                            // backgroundColor: 'white',
                            // border: '1px solid black',
                            // margin: '10px',
                            // color: 'black',
                            // transition: 'background-color 0.3s, border-color 0.3s'
                            backgroundColor: '#ff0000d1',
                            border: '0px solid black',
                            margin: '10px',
                            color: 'white',
                            transition: 'background-color 0.3s, border-color 0.3s'
                          }}
                          onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                          onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                        >
                          Remove
                        </Button>
                        <span className="ms-2" style={{ cursor: 'pointer' }} onClick={() => handleAddDeductionallowence('allowences')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                      </svg>
                    </span>
                      </div>
                    ))}
                  </Form.Group> */}


                  {/* <Form.Group>
        <Form.Label>Add allowences:</Form.Label>
        <div className="form-group">
          {formData.allowences.map((allowance, index) => (
            <div key={index} className="row mb-3">
              <div className="col-md-6 mb-4">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Allowance"
                  value={allowance.name}
                  onChange={(e) => handleInputChange(index, e, 'allowences')}
                />
              </div>
              <div className="col-md-6">
                <Form.Control
                  as="select"
                  name="deduct_type"
                  value={allowance.deduct_type}
                  onChange={(e) => handleInputChange(index, e, 'allowences')}
                >
                  <option value="">Select Deduction Type</option>
                  <option value="Percentage">Percentage</option>
                  <option value="Fixed">Fixed</option>
                </Form.Control>
              </div>
            </div>
          ))}
          <div className="row">
            <div className="col-md-10 col-sm-12 d-flex align-items-center justify-content-start">
              <Button
                variant="success"
                className="ms-2"
                onClick={() => handleAddDeductionallowence('allowences')}
                style={{ cursor: 'pointer' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus-circle-fill me-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                </svg>
                Add Allowance
              </Button>
            </div>
            {formData.allowences.length > 0 && (
              <div className="col-md-2 col-sm-12 d-flex align-items-center justify-content-start">
                <Button
                  variant="danger"
                  onClick={() => handleRemoveDeductionallowence(formData.allowences.length - 1, 'allowences')}
                  style={{
                    backgroundColor: '#ff0000d1',
                    border: '0px solid black',
                    color: 'white',
                    transition: 'background-color 0.3s, border-color 0.3s'
                  }}
                  onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                  onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                >
                  Remove
                </Button>
              </div>
            )}
          </div>
        </div>
      </Form.Group> */}
                  <Form.Group>
                    <Form.Label>Add Allowances:</Form.Label>
                    <div className="form-group">
                      {formData.allowences.map((allowance, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-6 mb-4">
                            <Form.Control
                              type="text"
                              name="name"
                              placeholder="Allowance"
                              required
                              value={allowance.name}
                              onChange={(e) => handleInputChange(index, e, 'allowences')}
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              as="select"
                              name="deduct_type"
                              required
                              value={allowance.deduct_type}
                              onChange={(e) => handleInputChange(index, e, 'allowences')}
                            >
                              <option value="" disabled selected>Select Allowance Type</option>
                              <option value="Percentage">Percentage</option>
                              <option value="Fixed">Fixed</option>
                            </Form.Control>
                          </div>
                          <div className="col-md-12">
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveDeductionallowence(index, 'allowences')}
                              style={{
                                backgroundColor: '#ff0000d1',
                                border: '0px solid black',
                                color: 'white',
                                transition: 'background-color 0.3s, border-color 0.3s'
                              }}
                              onMouseEnter={(e) => e.target.style.backgroundColor = '#ff0000a6'}
                              onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      ))}
                      <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-start">
                          <Button
                            variant="success"
                            className="ms-2"
                            onClick={() => handleAddDeductionallowence('allowences')}
                            style={{ cursor: 'pointer' }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-plus-circle-fill me-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"></path>
                            </svg>
                            Add Allowance
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form.Group>


                  <br />
                  {/* <Button variant="success" type="submit">Add Salary Setup</Button> */}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <Button type="submit" variant="primary" className="">
                      Submit
                    </Button> */}
                    <Button variant="primary" type="submit">Add Salary Setup</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Addsalarysetup;




